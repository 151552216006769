import './BookmarkIcon.css';

import * as React from 'react';

export interface Props {
    isBookmarked?: boolean
    disabled?: boolean
    onToggle?: () => void
}

const BookmarkIcon: React.SFC<Props> = ({ isBookmarked, disabled, onToggle }) => {
    const bookmarkClass = isBookmarked ? "bookmark-icon isBookmarked" : disabled ? "bookmark-icon disabled": "bookmark-icon"

    return (
        <div title="Bookmark" onClick={onToggle} className={`btn-has-hover-img ` + bookmarkClass}>
            {isBookmarked ?
                <img src="/assets/images/icons/v2/ico-bookmark-active.svg" className="non-hover-icon" width="20" height="20" alt="" />
                :
                <img src="/assets/images/icons/v2/ico-bookmark.svg" className="non-hover-icon" width="20" height="20" alt="" />
            }
            <img src="/assets/images/icons/v2/ico-bookmark-hover.svg" className="priority-icon hover-icon d-none" width="20" height="20" alt="" />
        </div>
    );
};

BookmarkIcon.defaultProps = {};
BookmarkIcon.displayName = 'BookmarkIcon';

export default BookmarkIcon;
