import React, {Component, PureComponent} from "react"
import { BarChart, Bar, LineChart, Line, Legend, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts"
import { SECS_IN_MIN, getDailyBinsBackwardFromYesterday, getHumanTime, getMonthlyBinsBackwardFromYesterday, getWeeklyBinsBackwardFromYesterday, timeDiffInDays } from "../../../services/utils/date-util"
import { ChartType, TimeFrame } from '../SuperintendentFilters'

const DATEDIFF_BINSIZE_THRESHOLD_FOR_MONTH = 120
const DATEDIFF_BINSIZE_THRESHOLD_FOR_WEEK  = 7

interface Props {
  days,
  chartType: ChartType
  timeFrame: TimeFrame
  subHeadline: string
}

interface State {
  dataBinSize: "day" | "week" | "month"
}

export class TonPProgressChartView extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      dataBinSize: "day"
    }
  }

  setDataBinSize = (binSize) => {
    let {dataBinSize} = this.state
    if (dataBinSize != binSize) {
      this.setState({dataBinSize: binSize})
    }
  }

  render() {
    let {days, chartType, timeFrame, subHeadline} = this.props
    let {dataBinSize} = this.state
    let binSizeInfo
    let timeUnitInfo = "Minutes"
    let chartData = [] as any[]
    let earliestDate
    let latestDate    

    if (days.length > 0) {
      earliestDate = days[0].date
      latestDate = days[days.length - 1].date
    }

    let maxVal = days.reduce((acc, cur) => {
      let {lessons, tests, mistakebanks, staysharps} = cur
      let total = lessons + tests + mistakebanks + staysharps
      return total > acc ? total: acc
    }, 0)
   
    let dateDiff = timeDiffInDays(earliestDate, latestDate)

    switch (timeFrame) {
      case TimeFrame.ALL_TIME:
        dataBinSize = "month"
        break
      case TimeFrame.CURRENT_ACADEMIC_YEAR:
        dataBinSize = "week"
        break
      case TimeFrame.LAST_MONTH:
        dataBinSize = "week"
        break
      case TimeFrame.LAST_WEEK:
        dataBinSize = "day"
        break
      case TimeFrame.LAST_DAY:
        dataBinSize = "day"
        break
    }

    if (dataBinSize === "day") {
      binSizeInfo = "Daily"
      let nofDays = timeFrame === TimeFrame.LAST_DAY ? 1: 7
      let dailyBins = getDailyBinsBackwardFromYesterday(nofDays)
      let bins = dailyBins.map(bin => {
        let label = `${bin.displayDate}`
        let d = {
          label,
          lessons: 0,
          tests: 0,
          mistakebanks: 0,
          staysharps: 0
        }

        days.forEach(day => {
          if (day.date === bin.date) {
            d.lessons += day.lessons
            d.tests += day.tests
            d.mistakebanks += day.mistakebanks
            d.staysharps += day.staysharps
          }
        })
        return d
      })

      chartData = bins.map(day => {
        let {label, lessons, tests, mistakebanks, staysharps} = day
        let factor = SECS_IN_MIN
        
        lessons = Math.round(lessons/factor)
        tests = Math.round(tests/factor)
        mistakebanks = Math.round(mistakebanks/factor)
        staysharps = Math.round(staysharps/factor)
        
        return {
          label,
          lessons,
          tests,
          mistakebanks,
          staysharps
        }
      })
    }
    else if (dataBinSize === "week") {
      binSizeInfo = "Weekly"
      let nofCalculatedWeeks = Math.ceil(dateDiff/7)
      let nofDefaultWeeks = 4
      let nofWeeks = Math.max(nofCalculatedWeeks, nofDefaultWeeks)
      let weeklyBins = getWeeklyBinsBackwardFromYesterday(nofWeeks)
      let weeks = weeklyBins.map(bin => {
        let label = `${bin.displayStartDate}-${bin.displayEndDate}`
        let week = {
          label,
          lessons: 0,
          tests: 0,
          mistakebanks: 0,
          staysharps: 0
        }

        days.forEach(day => {
          if (day.date >= bin.startDate && day.date <= bin.endDate) {
            week.lessons += day.lessons
            week.tests += day.tests
            week.mistakebanks += day.mistakebanks
            week.staysharps += day.staysharps
          }
        })
        return week
      })
      
      chartData = weeks.map(week => {
        let {label, lessons, tests, mistakebanks, staysharps} = week
        let factor = SECS_IN_MIN

        lessons = Math.round(lessons/factor)
        tests = Math.round(tests/factor)
        mistakebanks = Math.round(mistakebanks/factor)
        staysharps = Math.round(staysharps/factor)
        
        return {
          label,
          lessons,
          tests,
          mistakebanks,
          staysharps
        }
      })
    }
    else {
      binSizeInfo = "Monthly"
      let nofCalculatedMonths = Math.ceil(dateDiff/30)
      let nofDefaultMonths = 4
      let nofMonths = Math.max(nofCalculatedMonths, nofDefaultMonths)
      let monthlyBins = getMonthlyBinsBackwardFromYesterday(nofMonths)
      let months = monthlyBins.map(bin => {
        let label = `${bin.displayStartDate}-${bin.displayEndDate}`
        let month = {
          label,
          lessons: 0,
          tests: 0,
          mistakebanks: 0,
          staysharps: 0
        }

        days.forEach(day => {
          if (day.date >= bin.startDate && day.date <= bin.endDate) {
            month.lessons += day.lessons
            month.tests += day.tests
            month.mistakebanks += day.mistakebanks
            month.staysharps += day.staysharps
          }
        })
        return month
      })
      chartData = months.map(month => {
        let {label, lessons, tests, mistakebanks, staysharps} = month
        let factor = SECS_IN_MIN

        lessons = Math.round(lessons/factor)
        tests = Math.round(tests/factor)
        mistakebanks = Math.round(mistakebanks/factor)
        staysharps = Math.round(staysharps/factor)
        
        return {
          label,
          lessons,
          tests,
          mistakebanks,
          staysharps
        }
      })
    }

    let progressTitle = <span>Daily Progress</span>
    let progressSubTitle = <span>Viewing as <b><i>{binSizeInfo}</i></b> Aggregated Data in <b><i>{timeUnitInfo}</i></b></span>
    
    return (
      <div className="si-tonp-progress-charts-container">
        <div>
          <h3 className="h3">Progress Charts</h3>
          <hr className="half summary-headline mb-2"/>
          <div className="summary-headline--subtext"><i>{subHeadline}</i></div>
        </div>
        <div className="si-tonp-progress-charts" style={{height: 580}}>
          <TonpProgressChartView data={chartData} chartType={chartType} progressTitle={progressTitle} progressSubTitle={progressSubTitle}/>
          {/* 
          <div className="si-tonp-progress-charts-control-panel">
            <button onClick={() => this.setDataBinSize("day")}>Day</button>
            <button onClick={() => this.setDataBinSize("week")}>Week</button>
            <button onClick={() => this.setDataBinSize("month")}>Month</button>
          </div> 
          */}
        </div>
      </div>
    )
  }
}

//--- Various Charts ---
interface ChartProps {
  data
  progressTitle
  progressSubTitle
  chartType: ChartType
}

// Define props based on the expected structure of the tooltip data
interface CustomTooltipProps {
  fill: string;
  color: string;
  value: number;
  active?: boolean;
  payload?: Array<{ name: string; value: string | number; color?: string }>;
  label?: string;
}

const TonpProgressChartView = (props: ChartProps) => {
  let {data, chartType, progressTitle, progressSubTitle} = props
  let chartMargins = { top: 5, right: 75, left: 5, bottom: 5 }
  let fillColorLessons      = "#009EDB"
  let fillColorTests        = "#21AA71"
  let fillColorMistakeBanks = "#6A5CC7"
  let fillColorStaySharps   = "#8E3E9F"
  let lineColorLessons      = fillColorLessons
  let lineColorTests        = fillColorTests
  let lineColorMistakeBanks = fillColorMistakeBanks
  let lineColorStaySharps   = fillColorStaySharps
  let lineStrokeWidth = 3
  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <ul className="legendList">
        {
          payload.map((entry, index) => (
            <li key={`item-${index}`}><span className="legendIcon" style={{ backgroundColor: entry.color }}></span>{entry.value}</li>
          ))
        }
      </ul>
    );
  }

  const CustomizedTick = (props) => {
    const { x, y, stroke, payload } = props;
    const dateString = payload.value;
    const dates = dateString.split('-');
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={14} fill="#2B2D2D" style={{fontSize: "14px", fontWeight: 600}}>
                <tspan textAnchor="middle" x="0">
                    {dates[0]}{dates.length > 1 && (<> – </>)}
                </tspan>
                {dates.length > 1 && (
                    <tspan textAnchor="middle" x="0" dy="20">
                        {dates[1]}
                    </tspan>
                )}
            </text>
        </g>
    );
  }

  // Custom Tooltip Component
  const CustomTooltip: React.FC<CustomTooltipProps> = (props) => {
    const { active, payload, label } = props;
    const formattedLabel = label ? label.split('-').join(' – ') : '';
    if (active && payload && payload.length) {
      return (
        <div className="rechart-ww-tooltip">
          <div className="rechart-ww-label">{formattedLabel}</div>
          <div className="rechart-ww-data">
            {payload.map((entry, index) => (
              <div key={index} className="rechart-tooltip-swatch-wrapper"><span className="rechart-tooltip-color-swatch" style={{ backgroundColor: `${entry.color}` }}></span>{`${entry.name} : ${entry.value}`}</div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  // Regular bar chart
  const BarChartRegular = () => {
    return (
      <ResponsiveContainer width="100%" height="90%">
        <BarChart data={data} margin={chartMargins}>
          <CartesianGrid strokeOpacity={1} stroke="#949797" vertical={false} strokeDasharray="3 3" />
          <XAxis dataKey="label" tick={<CustomizedTick />} />
          <YAxis
            style={{
              fontSize: '14px',
              fontFamily: 'Raleway',
              fontWeight: '600',
              color: '#2B2D2D'
            }}
          />
          <Tooltip content={CustomTooltip} />
          <Legend content={renderLegend} />
          <Bar name="Lessons" dataKey="lessons" fill={fillColorLessons} />
          <Bar name="Practice Tests" dataKey="tests" fill={fillColorTests} />
          <Bar name="Mistake Bank" dataKey="mistakebanks" fill={fillColorMistakeBanks} />
          <Bar name="Stay Sharp" dataKey="staysharps" fill={fillColorStaySharps} />
        </BarChart>
      </ResponsiveContainer>
    )
  }

  // Stacked bar chart
  const BarChartStacked = () => {
    return (
      <ResponsiveContainer width="100%" height="90%">
        <BarChart data={data} margin={chartMargins}>
        <CartesianGrid strokeOpacity={1} stroke="#949797" vertical={false} strokeDasharray="3 3" />
          <XAxis dataKey="label" tick={<CustomizedTick />} />
          <YAxis
            style={{
              fontSize: '14px',
              fontFamily: 'Raleway',
              fontWeight: '600',
              color: '#2B2D2D'
            }}
          />
          <Tooltip content={CustomTooltip} />
          <Legend content={renderLegend} />
          <Bar name="Lessons" stackId="s1" dataKey="lessons" fill={fillColorLessons} />
          <Bar name="Tests" stackId="s1" dataKey="tests" fill={fillColorTests} />
          <Bar name="Mistake Bank" stackId="s1" dataKey="mistakebanks" fill={fillColorMistakeBanks} />
          <Bar name="Stay Sharp" stackId="s1" dataKey="staysharps" fill={fillColorStaySharps} />
        </BarChart>
      </ResponsiveContainer>
    )
  }

  // Line chart
  const BarChartLine = () => {
    return (
      <ResponsiveContainer width="100%" height="90%">
        <LineChart data={data} margin={chartMargins}>
        <CartesianGrid strokeOpacity={1} stroke="#949797" vertical={false} strokeDasharray="3 3" />
          <XAxis dataKey="label" tick={<CustomizedTick />} />
          <YAxis
            style={{
              fontSize: '16px',
              fontFamily: 'Raleway',
              fontWeight: '600',
              color: '#2B2D2D'
            }}
          />
          <Tooltip content={CustomTooltip} />
          <Legend content={renderLegend} />
          <Line name="Lessons" type="monotone" dataKey="lessons" stroke={lineColorLessons} strokeWidth={lineStrokeWidth}/>
          <Line name="Tests" type="monotone" dataKey="tests" stroke={lineColorTests} strokeWidth={lineStrokeWidth}/>
          <Line name="Mistake Bank" type="monotone" dataKey="mistakebanks" stroke={lineColorMistakeBanks} strokeWidth={lineStrokeWidth}/>
          <Line name="Stay Sharp" type="monotone" dataKey="staysharps" stroke={lineColorStaySharps} strokeWidth={lineStrokeWidth}/>
        </LineChart>
      </ResponsiveContainer>
    )
  }

  return (
    <>
      <div className="si-tonp-progress-chart-title">{progressTitle}</div>
      <div className="si-tonp-progress-chart-subtitle">{progressSubTitle}</div>
      {chartType === ChartType.Bar && <BarChartRegular/>}
      {chartType === ChartType.Line && <BarChartLine/>}
      {chartType === ChartType.Stacked && <BarChartStacked/>}
    </>
  )
}
