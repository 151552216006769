import './ExplanationButton.css';

import * as React from 'react';

export interface Props {
    onClick: () => void;
}

const ExplanationButton: React.SFC<Props> = ({ onClick }) => {
    return (
        <button title="View the explanation" className="practice-explanation-button" onClick={onClick}>
            <img
              className="explanation-button-image"
              src={`/assets/images/icons/v2/ico-play.svg`}
              width="20"
              height="20"
              alt=""
            />
        </button>
    );
};

ExplanationButton.defaultProps = { };
ExplanationButton.displayName = 'AnalysisButton';

export default ExplanationButton;
