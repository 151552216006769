import './LessonList.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';
import { isDevelopment } from '../../services/utils/env-helper';

import { Column } from '../DataGrid/types/types';
import DataGrid from '../DataGrid';
import { LessonProgress, MaterialType } from '../../services/elearn/lesson-types';

import Button from '../Button/Button';
import LessonListTitle from './components/LessonListTitle/LessonListTitle';
import { RouteComponentProps } from 'react-router-dom';
import { RowData } from '../DataGrid/types/types';
import FrequencyModal from './components/FrequencyModal/FrequencyModal';
import { UserInfo } from '../../services/account/account-rest-interface';
import Modal from '../Modal/Modal';
import ExpirationDialog from '../../scenes/Home/components/ExpirationDialog';
/* import { DispatchProps } from '../../scenes/Home/components/TeacherDashboard/TeacherDashboard'; */
import { hasSchoolSafety } from '../../services/account/account-utils';
import { CDN_PUB_URL_HANDOUTS } from '../../constants';

export interface LessonListProps {
    lessons: LessonProgress[];
    isTeacher?: boolean;
    frequency: string;
    popPurchaseModal: () => void;
}

export interface StoreProps {
    userInfo: UserInfo;
}

export interface DispatchProps {
    setPriority: (lessonId: string, status: boolean) => Promise<any>;
}

enum LessonStatus {
    NOT_STARTED,
    STARTED,
    COMPLETED
}

const sortStr = (a, b, c?, d?, reverseSort?) => {
  if (!reverseSort) {
    if(a < b) {
      return 1;
    }
    if(b < a) {
        return -1;
    }
    if (b === a) {
      if (c && d) {
        if(c < d) {
          return 1;
        }
        if(d < c) {
            return -1;
        }
      }
    }
    return 0;
  } else {
    if(a < b) {
      return 1;
    }
    if(b < a) {
        return -1;
    }
    if (b === a) {
      if (c && d) {
        if(c < d) {
          return -1;
        }
        if(d < c) {
            return 1;
        }
      }
    }
    return 0;
  }
};

const teacherColumns: Column[] = [
    {
        title: 'LESSONS',
        dataProperty: 'lesson',
        width: '22%',
        sortMethod: sortStr,
    },
    {
        title: 'FREQUENCY',
        dataProperty: 'frequency',
        width: '11.2%',
        sortMethod: sortStr,
        customTooltip: 'The frequency icons demonstrate how frequently each lesson is tested from 0 (low) to 4 (high) based on your selection of the ACT or the SAT test.'
    },
    {
        title: 'LESSON<br/>PLAN',
        dataProperty: 'instrGuide',
        width: '11.2%',
        sortable: false
    },
    {
        title: 'BLANK<br/>HANDOUT',
        dataProperty: 'handout',
        width: '11.2%',
        sortable: false
    },
    {
        title: 'EXEMPLAR<br/>HANDOUT',
        dataProperty: 'handoutAnnotated',
        width: '11.2%',
        sortable: false
    },
    {
        title: 'VIDEO<br/>LESSON',
        dataProperty: 'video',
        width: '11.2%',
        sortable: false,
    },
    {
        title: 'MULTIPLE<br/>CHOICE',
        dataProperty: 'questions',
        width: '11.2%',
        sortable: false,
    },
    {
        title: 'ILLUMINATE<br/>DATA',
        dataProperty: 'status',
        width: '11.2%',
        sortable: false
    },
];
const schoolSafetyColumns: Column[] = [
    {
        title: 'LESSONS',
        dataProperty: 'lesson',
        width: '50%',
        sortMethod: sortStr
    },
    {
        title: 'VIDEO',
        dataProperty: 'video',
        sortMethod: sortStr,
        width: '12%'
    },
    {
        title: 'QUESTIONS',
        dataProperty: 'questions',
        sortMethod: sortStr,
        width: '12%'
    },
    {
        title: 'STATUS',
        dataProperty: 'status',
        width: '210px',
        sortMethod: sortStr
    },
];
const lessonColumns: Column[] = [
    {
        title: 'LESSONS',
        dataProperty: 'lesson',
        width: '36%',
        sortMethod: sortStr,
    },
    {
        title: 'PRIORITY',
        dataProperty: 'star',
        width: '12%',
        sortMethod: sortStr
        },
    {
        title: 'FREQUENCY',
        dataProperty: 'frequency',
        width: '12%',
        sortMethod: sortStr,
        customTooltip: 'The frequency icons demonstrate how frequently each lesson is tested from 0 (low) to 4 (high) based on your selection of the ACT or the SAT test.'
    },
    {
        title: 'VIDEO',
        dataProperty: 'video',
        sortMethod: sortStr,
        width: '12%'
    },
    {
        title: 'QUESTIONS',
        dataProperty: 'questions',
        sortMethod: sortStr,
        width: '12%'
    },
    {
        title: 'STATUS',
        dataProperty: 'status',
        width: '220px',
        sortMethod: sortStr
    },
];

interface State {
  showVideo: string;
  showFrequencyModal: boolean;
  showUnlockModal: boolean;
  lessons: any;
}

type AllProps = LessonListProps & StoreProps & DispatchProps & RouteComponentProps<any>;

export default class LessonList extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            showVideo: '',
            showFrequencyModal: false,
            showUnlockModal: false,
            lessons: this.props.lessons
        };
    }

    public render() {
        const data = this.createLessonData;
        const lessonData = this.props.lessons.map(data);
        const { isTeacher } = this.props;
        let columns = isTeacher ? teacherColumns : lessonColumns;
        if (hasSchoolSafety(this.props.userInfo)) {
            columns = schoolSafetyColumns;
        }
        return (
            <div>
                <DataGrid
                    columns={columns}
                    className={isTeacher ? 'teacher-lesson-table' : 'lesson-dashboard-table'}
                    data={lessonData}
                    emptyText="No lessons to display"
                    sortByAttribute="frequency"
                />

                <Modal
                    className="heading-banner-home unlock-modal expired-account-dialog"
                    show={this.state.showUnlockModal}
                    onClose={this.closeUnlockModal}
                    width="auto"
                >
                    <ExpirationDialog {...this.props} unlockAccount closeModal={this.closeUnlockModal} />
                </Modal>
            </div>
        );
    }

    flipStar(lessonId: string, status: boolean) {
        let newStatus = !status;
        let props = this.props as any;
        props.setPriority(lessonId, newStatus);
    }

    private popUnlockModal = () => {
        this.setState({
            showUnlockModal: true
        });
    }

    private closeUnlockModal = () => {
        this.setState({
            showUnlockModal: false
        });
    }

    
    @boundMethod
    private createLessonData(lesson: LessonProgress, i: number): RowData {

        const teacherData = {
            lesson: {
                content: this.createTeacherLessonTitle(lesson),
                comparator: lesson.lessonName,
                subComparator: lesson.lessonOrder
            },
            frequency: {
              content: this.createFrequency(lesson),
              comparator: this.getFrequencyType(lesson),
              subComparator: lesson.lessonOrder
            },
            instrGuide: {
                content: this.createTeacherHandoutInstructionGuide(lesson),
                comparator: '', // lesson.lessonName,
                subComparator: '', // lesson.lessonOrder
            },
            handout: {
                content: this.createTeacherHandout(lesson),
                comparator: '', // lesson.lessonName,
                subComparator: '', // lesson.lessonOrder
            },
            handoutAnnotated: {
                content: this.createTeacherHandoutAnnotated(lesson),
                comparator: '', // lesson.lessonName,
                subComparator: '', // lesson.lessonOrder
            },
            video: {
                content: this.createTeacherVideoControl(lesson),
                comparator: lesson.aggregateVideoDuration,
                subComparator: lesson.lessonOrder
            },
            questions: {
                content: this.createTeacherQuestionsControl(lesson),
                comparator: lesson.numCompletedQuestions || 0
            },
            status: {
                content: this.createDownloadStudentDataControl(lesson),
                comparator: this.getStatusButtonData(lesson).order,
                // comparator: () => lesson.currentLessonContent,
                subComparator: lesson.lessonOrder
            }
        };
        let studentData;
        if (hasSchoolSafety(this.props.userInfo)) {
            studentData = {
                lesson: {
                    content: this.createLessonTitle(lesson),
                    comparator: lesson.lessonName,
                    subComparator: lesson.lessonOrder
                },
                scheduled: {
                    content: '', // rawData.scheduled,
                    comparator: '' // rawData.scheduled
                },
                video: {
                    content: lesson.aggregateVideoDuration,
                    comparator: lesson.aggregateVideoDuration,
                    subComparator: lesson.lessonOrder
                },
                questions: {
                    content: this.formatQuestions(lesson),
                    comparator: lesson.numCompletedQuestions || 0
                },
                status: {
                    content: this.createStatusButton(lesson),
                    comparator: this.getStatusButtonData(lesson).order,
                    subComparator: lesson.lessonOrder
                },
                isOwned: {
                    content: lesson.isOwned,
                    comparator: lesson.isOwned,
                }
            };
        } else {
            studentData = {
                lesson: {
                    content: this.createLessonTitle(lesson),
                    comparator: lesson.lessonName,
                    subComparator: lesson.lessonOrder
                },
                star: {
                    content: this.createStar(lesson),
                    comparator: lesson.star,
                    subComparator: lesson.lessonOrder
                },
                scheduled: {
                    content: '', // rawData.scheduled,
                    comparator: '' // rawData.scheduled
                },
                frequency: {
                  content: this.createFrequency(lesson),
                  comparator: this.getFrequencyType(lesson),
                  subComparator: lesson.lessonOrder
                },
                video: {
                    content: lesson.aggregateVideoDuration,
                    comparator: lesson.aggregateVideoDuration,
                    subComparator: lesson.lessonOrder
                },
                questions: {
                    content: this.formatQuestions(lesson),
                    comparator: lesson.numCompletedQuestions || 0
                },
                status: {
                    content: this.createStatusButton(lesson),
                    comparator: this.getStatusButtonData(lesson).order,
                    subComparator: lesson.lessonOrder
                },
                isOwned: {
                    content: lesson.isOwned,
                    comparator: lesson.isOwned,
                }
            };
        }

        return {
            data: this.props.isTeacher ? teacherData : studentData,
            id: `lesson-data${i}`
        };
    }

    private createLessonTitle(lesson: LessonProgress) {
        const titleIcon = this.getLessonTitleIcon(lesson);
        const lessonStatus = this.getStatusButtonData(lesson).text;
        const icon = '/assets/images/icons/v2/' + titleIcon.img;
        const iconAltText = titleIcon.alt;
        const lessonIsOwned = lesson.isOwned === 'true';
        const clickHandler = lessonIsOwned ? 
            () => this.handleLessonButtonClick(lesson.lessonId) :
            () => console.log('no-op');

        return () => (
            <LessonListTitle
                enabled={lessonIsOwned}
                icon={icon}
                title={lessonStatus}
                lessonName={lesson.lessonName}
                onIconClick={clickHandler}
            />
        );
    }

    private createFrequency(lesson: LessonProgress) {
      let freq = this.getFrequencyType(lesson);
      let iconTitle, iconAlt;
      switch(freq.toString()) {
        case '1':
            iconTitle = 'Low Frequency';
            iconAlt = '1'
            break;
        case '2':
            iconTitle = 'Low-Medium Frequency';
            iconAlt = '2'
            break;
        case '3':
            iconTitle = 'Medium-High Frequency';
            iconAlt = '3'
            break;
        case '4':
            iconTitle = 'High Frequency';
            iconAlt = '4'
            break;
        default:
            iconTitle = 'No Activity';
            iconAlt = '0'
            break;
        }
      const icon = '/assets/images/icons/v2/vis-frequency-' + freq + '.svg';
      return () => (
        <img title={iconTitle} alt={iconAlt} src={icon} className="frequency-icon" />
      );
    }

    private createStar(lesson: LessonProgress) {
        let starType = this.getStarType(lesson);
        const icon = '/assets/images/icons/v2/' + starType + '.svg';

        return () => (
            <button className="btn-div__inline btn-has-hover-img" onClick={() => this.flipStar(lesson.lessonId, lesson.star)} title="Toggle lesson priority">
                <img
                    src={icon}
                    className="priority-icon non-hover-icon"
                    alt={starType}
                />
                <img src="/assets/images/icons/v2/ico-star-hover.svg" className="priority-icon hover-icon d-none" alt="" />
            </button>
        );
    }

    private getFrequencyType(lesson: LessonProgress) {
      if (this.props.frequency === 'SAT') {
        return lesson.satFrequency;
      } else {
        return lesson.actFrequency;
      }
    }

    private getStarType(lesson: LessonProgress) {
        if (lesson.star === true ) {
          return 'ico-star-active';
        } else {
          return 'ico-star';
        }
      }

    private createTeacherHandout(lesson: LessonProgress) {
        // TODO: Create a function for parsing lesson PDF name.
        // This is logic is needed elsewhere too.
        const pdfFileName = lesson.lessonId.replace(':', '_');
        return () => (
                <div className="text-center">
                    <a
                        href={`/assets/handouts/${pdfFileName}.pdf`}
                        target="_blank"
                        title={`View the ${lesson.lessonName} handout`}
                    >
                        <img
                            alt=""
                            width="16"
                            height="20"
                            src="/assets/images/icons/v2/ico-handout-download.svg" />
                    </a>
                </div>
        );
    }

    private getHandoutUrl(lesson: LessonProgress, handoutType: "regular" | "exemplar" | "instructional") {
        try {
            let {category, lessonId} = lesson
            let categoryName = category.categoryName.toLowerCase()
            let cndResUrl = `${CDN_PUB_URL_HANDOUTS}/${categoryName}`
            let pdfFileName = lessonId.replace(':', '_')
            let suffix = handoutType === "regular" ? "_Handout": handoutType === "exemplar" ? "_ExemplarHandout": ""
            let ext = "pdf"
            let resFileName = `${pdfFileName}${suffix}.${ext}`

            return `${cndResUrl}/${resFileName}`
        }
        catch (ex) {
            // Shouldn't happen
            console.log("Error in getResourceUrlForLesson", ex)
            return "?" 
        }    
    }

    private createTeacherHandoutAnnotated(lesson: LessonProgress) {
        let url = this.getHandoutUrl(lesson, "exemplar")
        return () => (
            <div className="text-center">
                <a
                    href={url}
                    target="_blank"
                    title={`View the ${lesson.lessonName} annotated handout`}
                >
                    <img
                        alt=""
                        width="16"
                        height="20"
                        src="/assets/images/icons/v2/ico-handout-download.svg" />
                </a>
            </div>
        )
    }

    private createTeacherHandoutInstructionGuide(lesson: LessonProgress) {
        let url = this.getHandoutUrl(lesson, "instructional")
        return () => (
            <div className="text-center">
                <a
                    href={url}
                    target="_blank"
                    title={`View the ${lesson.lessonName} annotated handout`}
                >
                    <img
                        alt=""
                        width="16"
                        height="20"
                        src="/assets/images/icons/v2/ico-handout-download.svg" />
                </a>
            </div>
        )
    }  

    private createTeacherLessonTitle(lesson: LessonProgress) {
        return () => (
            <div className="row">
                <span className="col-sm-10">{lesson.lessonName}</span>
            </div>
        );
    }

    private createTeacherVideoControl(lesson: LessonProgress) {
        const goToRewatchVideo = (lessonId: string) => this.props.history.push(`/app/lessons/${lessonId}/rewatchVideo`);
        return () => (
            <div className="teacher-video text-center">
                {lesson.aggregateVideoDuration}
                <a
                    onClick={() => goToRewatchVideo(lesson.lessonId)}
                    href="javascript:void(0)"
                    target="_self"
                    title="Play Video"
                    className="d-block pt-1"
                >
                    <img
                        className="teacher-control-icon"
                        alt="Play"
                        width="20"
                        height="20"
                        src="/assets/images/icons/v2/ico-start.svg"/>
                </a>
          </div>
        );
    }

    private createTeacherQuestionsControl(lesson: LessonProgress) {
        return () => (lesson.numTotalQuestions > 0 ?
            <div className="text-center">
                {lesson.numTotalQuestions} Q's
                <a
                    onClick={() => this.handleLessonButtonClick(lesson.lessonId)}
                    href="javascript:void(0)"
                    target="_self"
                    title="Go to Questions"
                    className="d-block pt-1"
                >
                    <img
                        className="teacher-control-icon"
                        alt="Questions"
                        width="20"
                        height="20"
                        src="/assets/images/icons/v2/ico-question.svg"
                    />
                </a>
            </div> :
            <div className="text-center not-applicable">N/A</div>
       );
    }

    private formatQuestions(lesson: LessonProgress) {
        if(!lesson.numTotalQuestions) { return 'N/A'; }
        return `${lesson.numCompletedQuestions}/${lesson.numTotalQuestions}`;
    }

    private createDownloadStudentDataControl(lesson: LessonProgress) {
        // Proxy works differently in latest version of React.
        // TODO: Check if this affects SAT Expl images?
        const baseUri = process.env.REACT_APP_ELEARN_URL;
        const userId = this.props.userInfo.id;
        const resourcePath = `/reports/illuminate/${lesson.lessonId}/${userId}`;
        let relativeUri = `${baseUri}${resourcePath}`;
        let finalUrl = relativeUri;
        if (isDevelopment()) {
            finalUrl = `http://localhost:8080${relativeUri}`;
        }
        const statusButtonData = this.getStatusButtonData(lesson);
        return () => (lesson.numTotalQuestions > 0 ?
            <div>
                <a title="Download Student Data" href={finalUrl} className="link-icon--download d-flex justify-content-center" download>
                    <img
                        alt=""
                        width="16"
                        height="20"
                        src="/assets/images/icons/v2/ico-document-download.svg" />
                </a>
            </div> :
            <div className="text-center not-applicable">N/A</div>
        );
    }

    private createStatusButton(lesson: LessonProgress) {
        const statusButtonData = this.getStatusButtonData(lesson);
        return () => (
            <Button
                textColor={'white'}
                bgColor={statusButtonData.color as any}
                text={statusButtonData.text}
                className="lesson-list__btn"
                onClick={() => lesson.isOwned === 'false' ?
                this.popUnlockModal() :
                this.handleLessonButtonClick(lesson.lessonId)}
            />
        );
    }

    private getLessonTitleIcon(lesson: LessonProgress) {
        const status = this.determineLessonStatus(lesson);
        switch(status) {
            case LessonStatus.STARTED: return { img: 'ico-resume.svg', alt: 'In Progress' };
            case LessonStatus.COMPLETED: return { img: 'ico-summary.svg', alt: 'Completed' };
            default: return { img: 'ico-start.svg', alt: 'To Start' };
        }
    }

    private getStatusButtonData(lesson: LessonProgress) {
        const status = this.determineLessonStatus(lesson);
        if (lesson.isOwned === 'false') {
            switch(status) {
                case LessonStatus.STARTED: return { text: 'RESUME', color: 'gray', order: 2 };
                case LessonStatus.COMPLETED: return { text: 'SUMMARY', color: 'gray', order: 3 };
                default: return { text: 'START', color: 'gray', order: 1 };
            }
        } else {
            switch(status) {
                case LessonStatus.STARTED: return { text: 'RESUME', color: 'green', order: 2 };
                case LessonStatus.COMPLETED: return { text: 'SUMMARY', color: 'purple', order: 3 };
                default: return { text: 'START', color: 'blue', order: 1 };
            }
        }
    }

    private handleLessonButtonClick = (lessonId: string) => {
        this.props.history.push(`lessons/${lessonId}`);
    }

    private determineLessonStatus(lesson: LessonProgress) {
        if(lesson.currentLessonContent && lesson.currentLessonContent.type === MaterialType.REPORT) {
            return LessonStatus.COMPLETED;
        }
        if(lesson.currentLessonContent && lesson.currentLessonContent.type === MaterialType.POST_LESSON_QUESTION) {
            return LessonStatus.STARTED;
        }
        if(lesson.currentLessonContent && lesson.currentLessonContent.id) {
            return LessonStatus.STARTED;
        }
        return LessonStatus.NOT_STARTED;
    }
}
