import './WelcomeModalThree.css';

import * as React from 'react';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';

interface Props { 
    onClose: () => void;
    show: boolean;
}

const WelcomeModalThree: React.SFC<Props> = (props) => {
    return (
            <Modal onClose={props.onClose} className="welcome-three-modal" show={props.show}>
                <div className="welcome-three-modal-header">
                    <h2>Final Heads-Up Before You Begin!</h2>
                </div>
                <div className="welcome-three-modal-body">
                    <p>If you're using a smaller device,</p>
                    <p><span className="bold"> please rotate it sideways</span> </p>
                    <p>for optimal viewing and function.</p>
                    <img src={'/assets/images/dashboard/modal-cards/phone-flip.svg'} />
                    <p>We sincerely hope this program advances<br /> you toward your educational goals.</p>
                    <p><span className="bold">Happy Studying!</span></p>
                    <button className="btn btn--text-white btn--bg-blue" onClick={props.onClose}>GET STARTED!</button>
                </div>
            </Modal>
    );
};

WelcomeModalThree.displayName = 'WelcomeModalThree';

export default WelcomeModalThree;
