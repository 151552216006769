import './QuestionProgressBarv2.css';

import React, { createRef } from 'react';
import * as autobind from 'autobind-decorator';
import * as _ from 'lodash';

import CloseIcon from '../CloseIcon/CloseIcon';
// tslint:disable-next-line:max-line-length
import QuestionNumber from '../../scenes/Lesson/components/QuestionProgressBar/components/QuestionNumber/QuestionNumber';
import QuestionProgressButton from '../../scenes/Lesson/components/QuestionProgressBar/components/QuestionProgressButton/QuestionProgressButton';

interface Props {
    questionNumbers?: any[];
    ssQuestionNumbers?: any[];
    currentQuestionIndex: number;
    farthestQuestionIndex: number;
    onQuestionClick: (questionId: string) => void;
    onCloseClick: (e: any) => void;
    exitText?: string;
    disableNavigation?: boolean;
    isTeacher: boolean;
}

interface State {
    navWidth: number;
}


export default class QuestionProgressBarv2 extends React.Component<Props, State> {
    public static defaultProps = {
        exitText: 'Exit Lesson'
    };

    private navRef = createRef<HTMLDivElement>();

    constructor(props: Props) {
        super(props);
        this.state = {
            navWidth: 0
        };
    }

    public componentDidMount() {
        const currentNode = this.navRef.current;
        this.setState({
            navWidth: currentNode ? currentNode.clientWidth : 0
        });
    }

    public render() {
        const { currentQuestionIndex, farthestQuestionIndex, onQuestionClick, 
            disableNavigation, questionNumbers, ssQuestionNumbers, onCloseClick, exitText, isTeacher 
        } = this.props;

        let farthestIndex = -1;
        if (questionNumbers) {
            farthestIndex = questionNumbers.findIndex(q => q.questionId === farthestQuestionIndex);
        }
        if (farthestIndex === -1 && ssQuestionNumbers) {
            farthestIndex = ssQuestionNumbers.findIndex(q => q.questionId === farthestQuestionIndex);
        }

        let questions;
        let currQIdx;
        if (questionNumbers && ssQuestionNumbers) {
            questions = questionNumbers.concat(ssQuestionNumbers);
            currQIdx = questions.findIndex(q => q.questionId === currentQuestionIndex);
        }

        if (questionNumbers && questionNumbers.length > 0 && ssQuestionNumbers && ssQuestionNumbers.length > 0) {
            let onStaySharpQuestions = ssQuestionNumbers.findIndex(q => q.questionId === farthestQuestionIndex);
            if (onStaySharpQuestions > -1) {
                farthestIndex = questionNumbers.length + onStaySharpQuestions;
            } else {
                farthestIndex = questionNumbers.findIndex(q => q.questionId === farthestQuestionIndex);
            }
        }

        let navStyle = { marginLeft: ''};
        const pixelsPerIndexComponent = 48;
        const thresholdIndex = 10;
        let newOffset = pixelsPerIndexComponent * (currQIdx - thresholdIndex);
        if (currQIdx > thresholdIndex) { 
          navStyle = { marginLeft: `${-newOffset}px` };
        }

        return (
            <div className="question-progress-bar">
                <QuestionProgressButton
                    direction="left"
                    onClick={this.handlePrev}
                    enabled={isTeacher || currQIdx > 0 && !disableNavigation}
                />

                <div className="question-progress-bar__nav" style={navStyle} 
                ref={this.navRef}>
                    {(questionNumbers && questionNumbers.length > 0 && 
                    ssQuestionNumbers && ssQuestionNumbers.length > 0) &&
                        <img 
                            src={process.env.PUBLIC_URL + '/assets/images/lesson-progress-bar-icon.svg'} 
                            className="progress-bar-icon" 
                        />
                    }
                    {(questionNumbers && questionNumbers.length > 0) &&
                        questionNumbers.map((num, i) => (
                            <QuestionNumber
                                num={num.questionNumber}
                                key={num.questionId}
                                isSelected={num.questionId === currentQuestionIndex}
                                disabled={this.isDisabled(num.questionId)}
                                onClick={() => onQuestionClick(num.questionId)}
                            />
                        ))
                    }
                    {(ssQuestionNumbers && ssQuestionNumbers.length > 0) &&
                        <img 
                            src={process.env.PUBLIC_URL + '/assets/images/stay-sharp-progress-bar-icon.svg'} 
                            className="progress-bar-icon stay-sharp-mb-icon" 
                        />
                    }
                    {(ssQuestionNumbers && ssQuestionNumbers.length > 0) &&
                        ssQuestionNumbers.map((num, i) => (
                            <QuestionNumber
                                num={num.questionNumber}
                                key={num.questionId}
                                isSelected={num.questionId === currentQuestionIndex}
                                disabled={this.isDisabled(num.questionId)}
                                onClick={() => onQuestionClick(num.questionId)}
                            />
                        ))
                    }
                </div>

                <QuestionProgressButton
                    direction="right"
                    onClick={this.handleNext}
                    enabled={isTeacher || (currQIdx < farthestIndex && !disableNavigation)}
                />

                <div className="question-progress-bar__close">
                    <div className="question-progress-bar__close-btn" title={exitText} onClick={onCloseClick}>
                        <span className="question-progress-bar__close-text">{exitText}</span>
                        <CloseIcon />
                    </div>
                </div>
            </div>
        );
    }

    private isDisabled(questionId: string) {
        const { isTeacher, questionNumbers, ssQuestionNumbers, 
            currentQuestionIndex, farthestQuestionIndex, disableNavigation } = this.props;
        if (isTeacher) {
            return false;
        }

        let questions;
        if (questionNumbers && ssQuestionNumbers && questionNumbers.length > 0 && ssQuestionNumbers.length > 0) {
            questions = questionNumbers.concat(ssQuestionNumbers);
            let questionIDIndex = questions.findIndex(q => q.questionId === questionId);
            let farthestQuestionIDIndex = questions.findIndex(q => q.questionId === farthestQuestionIndex);
            if (questionIDIndex <= farthestQuestionIDIndex) {
                return false;
            }
            return true;
        } else if (questionNumbers && questionNumbers.length > 0) {
            let questionIDIndex = questionNumbers.findIndex(q => q.questionId === questionId);
            let farthestQuestionIDIndex = questionNumbers.findIndex(q => q.questionId === farthestQuestionIndex);
            if (questionIDIndex <= farthestQuestionIDIndex) {
                return false;
            }
            return true;
        } else if (ssQuestionNumbers && ssQuestionNumbers.length > 0) {
            let questionIDIndex = ssQuestionNumbers.findIndex(q => q.questionId === questionId);
            let farthestQuestionIDIndex = ssQuestionNumbers.findIndex(q => q.questionId === farthestQuestionIndex);
            if (questionIDIndex <= farthestQuestionIDIndex) {
                return false;
            }
            return true;
        } else {
            console.log('Fatal Error in Mistake Bank Loading Questions');
            return true;
        }
    }

    private handlePrev() {
        if (!this) return
        const {questionNumbers, ssQuestionNumbers, currentQuestionIndex, onQuestionClick } = this.props;
        let idx = -1;
        let previousQuestionIndex;
        if (questionNumbers && questionNumbers.length > 0) {
            idx = questionNumbers.findIndex((q) => {
                return q.questionId === currentQuestionIndex;
            });
            if (idx === -1 && ssQuestionNumbers) {
                idx = ssQuestionNumbers.findIndex((q) => {
                    return q.questionId === currentQuestionIndex;
                });
                if (idx === 0 && questionNumbers && questionNumbers.length > 0) {
                    previousQuestionIndex = questionNumbers[questionNumbers.length - 1].questionId;
                } else {
                    previousQuestionIndex = ssQuestionNumbers[idx - 1].questionId;    
                }
            } else {
                previousQuestionIndex = questionNumbers[idx - 1].questionId;
            }
        } else if (ssQuestionNumbers && ssQuestionNumbers.length > 0) {
            idx = ssQuestionNumbers.findIndex((q) => {
                return q.questionId === currentQuestionIndex;
            });
            if (idx === -1 && questionNumbers) {
                idx = questionNumbers.findIndex((q) => {
                    return q.questionId === currentQuestionIndex;
                });    
            } else {
                previousQuestionIndex = ssQuestionNumbers[idx - 1].questionId;
            }
        }

        onQuestionClick(previousQuestionIndex);
    }

    private handleNext() {
        if (!this) return
        const {questionNumbers, ssQuestionNumbers, currentQuestionIndex, onQuestionClick } = this.props;
        let idx = -1;
        let previousQuestionIndex;
        if (questionNumbers && questionNumbers.length > 0) {
            idx = questionNumbers.findIndex((q) => {
                return q.questionId === currentQuestionIndex;
            });
            if (idx === (questionNumbers.length - 1) && ssQuestionNumbers && ssQuestionNumbers.length > 0) {
                previousQuestionIndex = ssQuestionNumbers[0].questionId;    
            } else if (idx === -1 && ssQuestionNumbers && ssQuestionNumbers.length > 0) {
                idx = ssQuestionNumbers.findIndex((q) => {
                    return q.questionId === currentQuestionIndex;
                });
                previousQuestionIndex = ssQuestionNumbers[idx + 1].questionId;  
            } else {
                previousQuestionIndex = questionNumbers[idx + 1].questionId;    
            }
        } else if (ssQuestionNumbers && ssQuestionNumbers.length > 0) {
            idx = ssQuestionNumbers.findIndex((q) => {
                return q.questionId === currentQuestionIndex;
            });
            if (idx === -1 && questionNumbers) {
                idx = questionNumbers.findIndex((q) => {
                    return q.questionId === currentQuestionIndex;
                });    
            } else {
                previousQuestionIndex = ssQuestionNumbers[idx + 1].questionId;
            }
        }

        onQuestionClick(previousQuestionIndex);
    }
}
