import './MistakeLessonListTitle.css';

import * as React from 'react';

interface Props {
    icon?: string;
    alt?: string;
    title?: string;
    lessonName: string;
    onIconClick: () => void;
}

const MistakeLessonListTitle: React.SFC<Props> = (props) => {
    return (
        <div className="lesson-list__title">
            <img
                src={props.icon}
                alt={props.alt ? props.alt : ''}
                title={props.title ? props.title : ''}
                onClick={props.onIconClick} />
            <span>{props.lessonName}</span>
        </div>
    );
};

MistakeLessonListTitle.displayName = 'MistakeLessonListTitle';

export default MistakeLessonListTitle;
