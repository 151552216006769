import * as React from 'react';

import ProgressBar from '../../../../../components/ProgressBar/ProgressBar';

export interface Props { 
    subject: string;
    score: number;
    totalScore: number;
    barColor: string;
    className?: any;
}

const PracticeTestScoreRow: React.SFC<Props> = (props) => {
    return (
        <div className={'practice-test-score-row ' + props.className}> 
            <span className="practice-test-score">{props.score}</span>
            <span className="practice-test-subject">{props.subject}</span>
            <ProgressBar
                current={props.score}
                total={props.totalScore}
                barCurrentColor={props.barColor}
                barTotalColor="gray"
                size="small"
            />
        </div>
    );
};

export default PracticeTestScoreRow;
