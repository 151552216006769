import * as React from 'react';
import './index.css';
import SelectCheckbox from '../SelectCheckbox';
import Multiselect from 'multiselect-dropdownx';
import { pluralize } from '../../../services/utils/text-util';
import { persistSITestKindSelection } from '../../../persistance/local';

const SELECT_ALL   = "Select All"
const DESELECT_ALL = "Deselect All"

export interface Props {
  filterSelections: FilterSelections
  showTimeFrameSelection?: boolean
  enableTestScoreKindSelection?: boolean
  enableTestTypeSelection?: boolean
  enableViewBySelection?: boolean
  enableChartTypeSelection?: boolean
  onGraduationYearsChanged?: (graduationYears: SelectableItem[]) => void
  onSchoolsChanged?: (schools: SelectableItem[]) => void
  onTimeFrameChanged?: (timeFrame: TimeFrame) => void
  onTestKindChanged?: (testKind: TestKind) => void
  onTestScoreKindChanged?: (testScoreKind: TestKind) => void
  onViewByChanged?: (viewBy: ViewBy) => void
  onChartTypeChanged?: (chartType: ChartType) => void
}

interface State {}

export interface SelectableItem {
  name: string
  selected: boolean
}

export interface FilterSelections {
  graduationYears: SelectableItem[]
  schools: SelectableItem[]
  timeFrame: TimeFrame
  testScoreKind: TestKind
  testKind: TestKind
  viewBy: ViewBy
  chartType: ChartType
  selectionInfoForLessons: string
  selectionInfoForReports: string
  selectionInfoForTonP: string
}

export enum TimeFrame {
  ALL_TIME   = "allTime",
  LAST_DAY   = "lastDay",
  LAST_WEEK  = "lastWeek",
  LAST_MONTH = "lastMonth",
  CURRENT_ACADEMIC_YEAR = "currentAcademicYear"
}

export function getTimeFrameInfo(timeFrame: TimeFrame) {
  return timeFrame === TimeFrame.ALL_TIME ? "All Time":
    timeFrame === TimeFrame.LAST_DAY ? "Last Day":
    timeFrame === TimeFrame.LAST_WEEK ? "Last Week":
    timeFrame === TimeFrame.LAST_MONTH ? "Last Month":
    timeFrame === TimeFrame.CURRENT_ACADEMIC_YEAR ? "Current Academic Year": "Unknown"
}

export enum TestKind {
  ALL = "ALL",
  ACT = "ACT",
  IA  = "IA",
  SAT = "SAT"
}

export enum ViewBy {
  School = "School",
  Test   = "Test"
}

export enum ChartType {
  Bar      = "Bar",
  Stacked  = "Stacked",
  Line     = "Line",
}

export function prepareSelectionsInfo(filterSelections: FilterSelections) {
  let {graduationYears, schools, timeFrame: selectedTimeFrame, testKind: selectedTestKind} = filterSelections
  let nofSchools = schools.filter(s => s.selected).length
  let nofGraduationYears = graduationYears.filter(gy => gy.selected).length
  let allSchoolsShown = nofSchools === filterSelections.schools.length
  let allGraduationYearsShown = nofGraduationYears === graduationYears.length
  let testTypeInfo = `${selectedTestKind === TestKind.ALL ? "All": selectedTestKind} Tests`
  let schoolsInfo = `${allSchoolsShown ? "All Schools": `${nofSchools} ${pluralize("School", nofSchools)}`}`
  let grauationYearsInfo = `${allGraduationYearsShown ? "All Graduation Years": `${nofGraduationYears} ${pluralize("Graduation Year", nofGraduationYears)}`}`
  let timeFrameInfo = getTimeFrameInfo(selectedTimeFrame)

  filterSelections.selectionInfoForLessons = `Showing Data for <b><i>${schoolsInfo}</i></b>, <b><i>${grauationYearsInfo}</i></b>, and <b><i>${timeFrameInfo}</i></b>`
  filterSelections.selectionInfoForReports = `Showing Data for <b><i>${schoolsInfo}</i></b>, <b><i>${grauationYearsInfo}</i></b>, and <b><i>${testTypeInfo}</i></b>`
  filterSelections.selectionInfoForTonP = `Showing Data for <b><i>${schoolsInfo}</i></b>, <b><i>${grauationYearsInfo}</i></b>, and <b><i>${timeFrameInfo}</i></b>`

  return filterSelections
}

export default class SuperintendentFilters extends React.Component<Props, State> {
  unsubscribe

  constructor(props: Props) {
    super(props)
  }

  renderPluralString(studentCount, message: string) {
    if(studentCount !== 1){
      message += 's';
    }
    return studentCount + ' ' + message;
  }

  handleGraduationYearsSelected = (selectedList, selectedItem) => {
    let {onGraduationYearsChanged, filterSelections} = this.props
    let {graduationYears} = filterSelections
    let selectedItemName = selectedItem.name

    if (selectedItemName === SELECT_ALL) {
      graduationYears.forEach(gy => gy.selected = true)
    }
    else if (selectedItemName === DESELECT_ALL) {
      graduationYears.forEach(gy => gy.selected = false)
    }
    
    selectedItem.selected = true

    if (onGraduationYearsChanged) onGraduationYearsChanged(selectedList)
  }

  handleGraduationYearsRemoved = (selectedList, selectedItem) => {
    let {onGraduationYearsChanged} = this.props
    selectedItem.selected = false
    if (onGraduationYearsChanged) onGraduationYearsChanged(selectedList)
  }

  handleSchoolsSelected = (selectedList, selectedItem) => {
    let {onSchoolsChanged, filterSelections} = this.props
    let {schools} = filterSelections
    let selectedItemName = selectedItem.name

    if (selectedItemName === SELECT_ALL) {
      schools.forEach(s => s.selected = true)
    }
    else if (selectedItemName === DESELECT_ALL) {
      schools.forEach(s => s.selected = false)
    }
      
    selectedItem.selected = true

    if (onSchoolsChanged) onSchoolsChanged(selectedList)
  }

  handleSchoolsRemoved = (selectedList, selectedItem) => {
    let {onSchoolsChanged} = this.props
    selectedItem.selected = false
    if (onSchoolsChanged) onSchoolsChanged(selectedList)
  }

  handleTimeFrameChanged = (e) => {
    let {onTimeFrameChanged} = this.props
    let selectedTimeFrame = e.target.value
    if (onTimeFrameChanged) onTimeFrameChanged(selectedTimeFrame)
  }

  handleTestTypeChanged = (e) => {
    let {onTestKindChanged} = this.props
    let selectedTestKind = e.target.value
    if (onTestKindChanged) onTestKindChanged(selectedTestKind)
  }
  
  
  handleTestScoreKindChanged = (selectedScoreKind) => {
    let {onTestScoreKindChanged} = this.props
    persistSITestKindSelection(selectedScoreKind)
    if (onTestScoreKindChanged) onTestScoreKindChanged(selectedScoreKind)
  }

  handleViewByChanged = (selectedViewBy) => {
    let {onViewByChanged} = this.props
    if (onViewByChanged) onViewByChanged(selectedViewBy)
  }

  handleChartTypeChanged = (selectedChartType) => {
    let {onChartTypeChanged} = this.props
    if (onChartTypeChanged) onChartTypeChanged(selectedChartType)
  }

  public render() {
    let {filterSelections} = this.props
    let {graduationYears, schools, timeFrame: selectedTimeFrame, testKind: selectedTestKind} = filterSelections
    let {showTimeFrameSelection, enableTestScoreKindSelection, enableTestTypeSelection, enableViewBySelection, enableChartTypeSelection} = this.props
    let timeFrameOptions = [
      { value: TimeFrame.CURRENT_ACADEMIC_YEAR, display: 'Current Academic Year' },
      { value: TimeFrame.ALL_TIME, display: 'All Time' },
      { value: TimeFrame.LAST_DAY, display: 'Last Day' },
      { value: TimeFrame.LAST_WEEK, display: 'Last Week' },
      { value: TimeFrame.LAST_MONTH, display: 'Last Month' }
    ]
    let testKindOptions = [{ value: TestKind.ALL, display: 'All' }, { value: TestKind.ACT, display: 'ACT' }, { value: TestKind.SAT, display: 'SAT' }, { value: TestKind.IA, display: 'IA' }]
    let selectedSchools = schools.filter(s => s.selected)
    let selectedGraduationYears = graduationYears.filter(gy => gy.selected)
    // let placeholderForSchools = schools.length === selectedSchools.length ? "All": selectedSchools.length === 0 ? "No selection!": `Selected: ${selectedSchools.length}`
    // let placeholderForGraduationYears = graduationYears.length === selectedGraduationYears.length ? "All": selectedGraduationYears.length === 0 ? "No selection!": `Selected: ${selectedGraduationYears.length}`
    let placeholderForSchools = schools.length === selectedSchools.length ? "All":  selectedSchools.length === 1 ? selectedSchools[0].name:  selectedSchools.length === 0 ? "No selection!": `Selected: ${selectedSchools.length}`
    let placeholderForGraduationYears = graduationYears.length === selectedGraduationYears.length ? "All": selectedGraduationYears.length === 1 ? selectedGraduationYears[0].name:  selectedGraduationYears.length === 0 ? "No selection!": `Selected: ${selectedGraduationYears.length}`
    
    // Extended values (adding Select/Deselect All support)
    let graduationYearsAllSelected = graduationYears.length === selectedGraduationYears.length
    let graduationYearsNoneSelected = selectedGraduationYears.length === 0
    let schoolsAllSelected = schools.length === selectedSchools.length
    let schoolsNoneSelected = selectedSchools.length === 0
    let graduationYearsExt = [
      {name: SELECT_ALL,   selected: graduationYearsAllSelected}, 
      {name: DESELECT_ALL, selected: graduationYearsNoneSelected}, 
      ...graduationYears]
    let selectedGraduationYearsExt = graduationYearsExt.filter(gy => gy.selected)
    let schoolsExt = [
      {name: SELECT_ALL,   selected: schoolsAllSelected}, 
      {name: DESELECT_ALL, selected: schoolsNoneSelected}, 
      ...schools]
    let selectedSchoolsExt = schoolsExt.filter(s => s.selected)
    
    return (
      <div className="summary-filters summary-filters--inline theme-box">
        <h4>VIEW DATA FOR</h4>
        <div>
          <div className="summary-select form-group">
            <label className="label" htmlFor="graduation-year-filter">Graduation Year</label>
            <Multiselect
              id="graduation-year-filter"
              options={graduationYearsExt}
              selectedValues={selectedGraduationYearsExt}            
              displayValue="name"
              placeholder={placeholderForGraduationYears}
              showArrow={true}
              showCheckbox={true}
              onSelect={this.handleGraduationYearsSelected}
              onRemove={this.handleGraduationYearsRemoved}
              avoidHighlightFirstOption={true}
              hideSelectedList={true}
              />
           
          </div>
          <div className="summary-select wide-select">
            <label className="label" htmlFor="school-filter">School / Organization</label>
            <Multiselect
              id="school-filter"
              options={schoolsExt}
              selectedValues={selectedSchoolsExt}
              displayValue="name"
              showArrow={true}
              showCheckbox={true}
              placeholder={placeholderForSchools}
              onSelect={this.handleSchoolsSelected}
              onRemove={this.handleSchoolsRemoved}
              avoidHighlightFirstOption={true}
              hideSelectedList={true}
              />
            
          </div>

          {showTimeFrameSelection &&
            <div className="summary-select">
              <label className="label" htmlFor="time-filter">Time Frame</label>
              <SelectCheckbox
                id="time-filter"
                selected={selectedTimeFrame}
                onChange={this.handleTimeFrameChanged}
                options={timeFrameOptions}
              />      
            </div>
          }

          {enableTestTypeSelection &&
            <div className="summary-select">
              <label className="label" htmlFor="test-score-filter">Test Type</label>
              <SelectCheckbox
                id="test-score-filter"
                selected={selectedTestKind}
                onChange={this.handleTestTypeChanged}
                options={testKindOptions}
              />            
            </div>
          }

          {enableTestScoreKindSelection &&
            <div className="summary-select">
              <div className="test-kind-selection-container">
                <label className="label" htmlFor="test-kind-filter">Test Score</label>
                <div className="test-kind">
                  <input id="test-act" name="test-kind" type="radio" 
                    value="ACT"
                    checked={this.props.filterSelections.testScoreKind === "ACT"}
                    onChange={e => this.handleTestScoreKindChanged("ACT")}/> &nbsp;
                  <label htmlFor="test-act">ACT</label>
                  
                  <input id="test-sat" name="test-kind" type="radio" 
                    value="SAT"
                    checked={this.props.filterSelections.testScoreKind === "SAT"}
                    onChange={e => this.handleTestScoreKindChanged("SAT")}/> &nbsp;
                  <label htmlFor="test-sat">SAT</label>
                </div>
              </div>
            </div>
          }

          {enableViewBySelection &&
            <div className="summary-select">
              <div className="viewby-selection-container">
                <label className="label" htmlFor="viewby-filter">View By</label>
                <div className="viewby-kind">
                  <input id="viewby-school" name="viewby-school" type="radio" 
                    value="School"
                    checked={this.props.filterSelections.viewBy === "School"}
                    onChange={e => this.handleViewByChanged("School")}/> <label htmlFor="viewby-school">School</label>
                  
                  <input id="viewby-test" name="viewby-test" type="radio" 
                    value="Test"
                    checked={this.props.filterSelections.viewBy === "Test"}
                    onChange={e => this.handleViewByChanged("Test")}/> <label htmlFor="viewby-test">Test</label>
                </div>
              </div>
            </div>
          }

          {enableChartTypeSelection &&
            <div className="summary-select">
              <div className="viewby-selection-container">
                <label className="label" htmlFor="viewby-filter">Chart Type</label>
                <div className="viewby-kind">
                  <input id="viewby-school" name="viewby-school" type="radio" 
                    value="Bar"
                    checked={this.props.filterSelections.chartType === "Bar"}
                    onChange={e => this.handleChartTypeChanged("Bar")}/> <label htmlFor="viewby-school">Bar</label>
                  
                  <input id="viewby-test" name="viewby-test" type="radio" 
                    value="Stacked"
                    checked={this.props.filterSelections.chartType === "Stacked"}
                    onChange={e => this.handleChartTypeChanged("Stacked")}/> <label htmlFor="viewby-test">Stacked</label>

                  <input id="viewby-test" name="viewby-test" type="radio" 
                    value="Line"
                    checked={this.props.filterSelections.chartType === "Line"}
                    onChange={e => this.handleChartTypeChanged("Line")}/> <label htmlFor="viewby-test">Line</label>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}
