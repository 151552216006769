import './WelcomeModalTwo.css';

import * as React from 'react';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';

interface Props { 
    onClose: () => void;
    show: boolean;
}

const WelcomeModalTwo: React.SFC<Props> = (props) => {
    return (
            <Modal onClose={props.onClose} className="welcome-two-modal" show={props.show}>
                <div className="welcome-two-modal-header">
                    <h2>Winward Academy Benefits</h2>
                </div>
                <div className="welcome-two-modal-body">
                    <div className="row welcome-two-info-section">
                        <div className="col-lg-12">
                            <h5>Students experience tremendous benefits when they're diligent about adjusting the lesson speed to fit their learning pace, taking notes, reviewing their mistakes, and writing themselves hints on missed questions.</h5>
                        </div>
                    </div>
                    <div className="row checklist-container">
                        <div className="checklist">
                            <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <p>
                                    <img 
                                        className="checkmark-image" 
                                        src="/assets/images/dashboard/modal-cards/greencheckmark.svg"
                                    />
                                <p>Real results</p>
                                </p>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <p>
                                    <img 
                                        className="checkmark-image" 
                                        src="/assets/images/dashboard/modal-cards/greencheckmark.svg"
                                    />
                                <p>Elevated peace of mind</p>
                                </p>
                            </div>
                            </div>
                            <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <p>
                                    <img 
                                        className="checkmark-image" 
                                        src="/assets/images/dashboard/modal-cards/greencheckmark.svg"
                                    />
                                <p>Improved study habits</p>
                                </p>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <p>
                                    <img 
                                        className="checkmark-image" 
                                        src="/assets/images/dashboard/modal-cards/greencheckmark.svg"
                                    />
                                <p>Passion for life-long learning</p>
                                </p>
                            </div>
                            </div>
                            <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <p>
                                    <img 
                                        className="checkmark-image" 
                                        src="/assets/images/dashboard/modal-cards/greencheckmark.svg"
                                    />
                                <p>Increased confidence</p>
                                </p>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <p>
                                    <img 
                                        className="checkmark-image" 
                                        src="/assets/images/dashboard/modal-cards/greencheckmark.svg"
                                    />
                                <p>Enhanced intellectual curiosity</p>
                                </p>
                            </div>
                            </div>
                            <div className="row button-container">
                                <div className="col-lg-12">
                                    <button 
                                        className="btn btn--text-white btn--bg-blue" 
                                        onClick={props.onClose}
                                    >
                                    NEXT!
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
    );
};

WelcomeModalTwo.displayName = 'WelcomeModalTwo';

export default WelcomeModalTwo;
