import './PriorityStar.css';

import * as React from 'react';

export interface Props {
    isPriority: boolean;
    onToggle: () => void;
}

const PriorityStar: React.SFC<Props> = ({ isPriority, onToggle }) => {
    const svgName = isPriority ? 'star-active' : 'star-hover';
    return (
        <button
            className="btn-div__inline btn-has-hover-img"
            title="Toggle lesson priority"
            onClick={() => {
                if (onToggle) {
                    onToggle();
                }
            }}>
            <img src={'/assets/images/icons/v2/ico-' + svgName + '.svg'} className="priority-icon non-hover-icon" />
            <img src="/assets/images/icons/v2/ico-star.svg" className="priority-icon hover-icon d-none" alt=""></img>
        </button>
    );
};

PriorityStar.defaultProps = {};
PriorityStar.displayName = 'PriorityStar';

export default PriorityStar;
