import './Navbar.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';
import { NavLink } from 'react-router-dom';
import FeedbackPopup from '../FeedbackPopup/FeedbackPopupContainer';

interface Props {
    /** Whether or not the navbar is collapsed (shows just navlink icons, no text) */
    collapsed: boolean;
    /** Used to dock/hide the navbar on smaller screens */
    docked?: boolean;
    /** Handle the collapse button click */
    onCollapse: (collapsed: boolean) => void;

    onDock: (docked: boolean) => void;

    lessonInProgress: boolean;
}

interface State {
    contactPopup: boolean;
}


export default class Navbar extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            // collapsed: props.collapsed || false
            contactPopup: false
        };
    }

    public componentDidMount() {
        const root = document.getElementById('root');
        if(root) {
            root.addEventListener('click', this.handleDock);
        }
    }

    public render() {
        const navClass = [
            this.props.collapsed ? 'navbar--collapsed' : '',
            this.props.docked ? '' : 'navbar--undocked'
        ].join(' ');
        const navLinkProps = this.cloneLinks();

        return (
            <div className={`navbar ${navClass}`}>
                <div className="navbar__scroll-container">
                    <div className="navbar__logo-container" title="Winward Home">
                        <NavLink to='/dashboard'>
                            <img
                                src="/assets/images/winward-academy-logo-white.svg"
                                alt="Winward Academy"
                                className={this.props.collapsed ? 'navbar-logo hide-image' : 'navbar-logo'}
                            />
                            <img
                                src="/assets/images/winward-study-guide-logo-white.svg"
                                alt="Winward Academy"
                                className={!this.props.collapsed ? 'navbar-logo--collapsed hide-image' : 'navbar-logo--collapsed'}
                            />
                        </NavLink>
                    </div>

                    <div className="navbar__links-container">
                        {navLinkProps}
                    </div>
                </div>

                {this.props.docked &&
                    <button
                        title="Comments"
                        className="navbar__comments-button"
                        onClick={this.handleCommentsClick}
                    >
                        <img
                            alt=""
                            src="/assets/images/navbar/WWA_Contact.svg"
                        />
                        {!this.props.collapsed && <span key="span" className="navbar-link__title">Comments</span>}
                    </button>
                }

                {this.props.docked &&
                    <button className="navbar__collapse-button noselect" title={this.props.collapsed ? 'Open Menu' : 'Collapse Menu'} onClick={this.handleCollapse}>
                        {this.props.collapsed ? '>>' : '<<'}
                    </button>}

                <FeedbackPopup
                        show={this.state.contactPopup}
                        onClose={this.handleContactModalClose}
                />
            </div>
        );
    }

    @boundMethod
    private handleCommentsClick() {
        if(this.props.lessonInProgress) {
            // window.open('mailto:info@winwardacademy.com', '_self');
            this.setState({ contactPopup: !this.state.contactPopup});
        } else {
            window.open('http://winwardacademy.com/contact/');
            return ;
        }
    }

    private cloneLinks() {
        return React.Children.map(this.props.children, child => {
            return React.cloneElement(child as React.ReactElement<any>, {
                collapsed: this.props.collapsed
            });
        });
    }

    @boundMethod
    private handleCollapse() {
        const collapsed = !this.props.collapsed;
        this.props.onCollapse(collapsed);
    }

    @boundMethod
    private handleDock() {
        // TODO refactor the whole docking/collapsing process
        const root = document.getElementById('root');
        if(root && !this.props.docked) {
            this.props.onDock(!this.props.docked);
        }
    }

    private handleNavigateHome() {
        window.open('http://winwardacademy.com', '_blank');
    }

    @boundMethod
    private handleContactModalClose() {
        this.setState({ contactPopup: false });
    }
}
