import './TableRow.css';

import * as React from 'react';

import { DragSource, DropTarget } from 'react-dnd';

import { CellContents } from '../../types/types';
import DragHandle from './components/DragHandle/DragHandle';
import TableCell from './components/TableCell/TableCell';
import { findDOMNode } from 'react-dom';

interface Props {
    index: number;
    data: CellContents[];
    dragReorder?: boolean;
    onRowDrag?: (oldIndex: number, newIndex: number) => void;
    summaryRowClassName?: string
    averageRowClassName?: string
    highlightRowClassName?: string
    totalRowClassName?: string
}

interface DragProps {
    connectDragSource?: any;
    connectDropTarget?: any;
    connectDragPreview?: any;
    isDragging?: boolean;
}

const sourceConnector = {
  beginDrag(props: Props) {
    return {
      id: props.index,
      index: props.index,
    };
  }
};

export default class TableRow extends React.Component<Props & DragProps, {}> {
    constructor(props: Props & DragProps) {
        super(props);
    }

    public render() {
        const { connectDragSource, connectDropTarget, connectDragPreview, isDragging, data, highlightRowClassName="" } = this.props;
        const style = isDragging ? { opacity: 0 } : {};
        const className = `table-row ${highlightRowClassName}`
        return (
            <div className={className} style={style}>
                {
                    data.map((cell, i, arr) => {
                        return <TableCell width={cell.width} content={cell.content} key={`table-cell${i}`} />;
                    })
                }
                {this.props.dragReorder &&
                    connectDragSource(<div><TableCell width={'auto'} content={() => <DragHandle />} /></div>)}
            </div>
        );
    }
}


export class TableSummaryRow extends React.Component<Props & DragProps, {}> {
    constructor(props: Props & DragProps) {
        super(props);
    }

    public render() {
        const { connectDragSource, connectDropTarget, connectDragPreview, isDragging, data, summaryRowClassName } = this.props;
        const style = isDragging ? { opacity: 0 } : {};
        const className = summaryRowClassName ? `table-summary-row ${summaryRowClassName}`: "table-summary-row"

        return (
            <div className={className} style={style}>
                {
                    data.map((cell, i, arr) => {
                        return <TableCell width={cell.width} content={cell.content} key={`table-cell${i}`} />;
                    })
                }
                {this.props.dragReorder &&
                    connectDragSource(<div><TableCell width={'auto'} content={() => <DragHandle />} /></div>)}
            </div>
        );
    }
}

export class TableAverageRow extends React.Component<Props & DragProps, {}> {
    constructor(props: Props & DragProps) {
        super(props)
    }

    public render() {
        const { data, averageRowClassName } = this.props
        const className = averageRowClassName ? `table-row table-average-row ${averageRowClassName}`: "table-row table-average-row"

        // Only one cell has the average info!
        let averageData = data.find(d => d.content && d.content != "")
        let averageInfo = averageData?.content || ""

        return (
            <div className={className}>
                <TableCell style={{textAlign: "center"}} width={"100%"} content={averageInfo} key={`table-cell-average`} />
            </div>
        )

    }
}

export class TableTotalRow extends React.Component<Props & DragProps, {}> {
    constructor(props: Props & DragProps) {
        super(props)
    }

    public render() {
        const { connectDragSource, totalRowClassName, isDragging, data } = this.props;
        const style = isDragging ? { opacity: 0 } : {};
        const className = totalRowClassName ? `table-total-row ${totalRowClassName}`: "table-total-row"

        return (
            <div className={className} style={style}>
                {
                    data.map((cell, i, arr) => {
                        return <TableCell width={cell.width} content={cell.content} key={`table-cell${i}`} />;
                    })
                }
                {this.props.dragReorder &&
                    connectDragSource(<div><TableCell width={'auto'} content={() => <DragHandle />} /></div>)}
            </div>
        );
    }
}


