import React, { Component } from 'react'
import "./index.css"
import { Link, RouteComponentProps } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { getTestDisplayName, getTestTypeBasedOnName } from '../../services/utils/practice-test-util'

interface Props {
  visible?: boolean
  style?: any
}

export interface IBreadcrumb {
  name: string
  path?: string
}

type AllProps = Props & RouteComponentProps<any>

interface State {  }

class Breadcrumb extends Component<AllProps, State> {
  constructor(props) {
    super(props)
  }
 
  buildBreadcrumbs = () => {
    let location = window.location
    let {href} = location
    let crumbs = generateBreadcrumbs(href)

    return (
      crumbs.map(crumb => {
        let {path, name} = crumb
        let liItem = path ? 
          <li key={path} className="breadcrumb-item">
            <Link to={path}>{name}</Link>
          </li>
          :
          <li key={path} className="breadcrumb-item active" aria-current="page">
            {name}
          </li>

        return liItem
      })
    )
  }

  render() {
    let {style, visible} = this.props || {}
    let crumbs = this.buildBreadcrumbs()

    return (
      visible ? 
        <div className="wa-breadcrumb-container" style={style}>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              {crumbs}
            </ol>
          </nav>
        </div>
        :
        <div></div>
    )
  }
}

const ConnectedBreadcrumb =  withRouter(Breadcrumb)

export default (props) => {
    return (
        <ConnectedBreadcrumb {...props} />
    )
}


//--- Helper Functions ---
function extractTestTypeForTopics(path) {
  let regex = /\/app\/practice-tests\/(?<testType>[^\/]+)\/growth-analytics\/topics/
  let match = regex.exec(path)
  let testTypeName = ""

  if (match && match.groups && match.groups.testType) {
    testTypeName = decodeURIComponent(match.groups.testType)
  }
  return testTypeName
}

function extractTestTypeDashboard(path) {
  let regex = /\/app\/practice-tests\?testType=([^&]+)/
  let testTypeName = "ACT"
  let match = path.match(regex)
  let testType = match ? match[1] : null

  if (testType) {
    testTypeName = decodeURIComponent(testType)
  }
  
  return testTypeName
}

function extractTestTypeForGrowthAnalytics(path) {
  let regex = /\/growth-analytics\?testType=([^&]+)/
  let testTypeName = ""
  let match = path.match(regex)
  let testType = match ? match[1] : null

  if (testType) {
    testTypeName = decodeURIComponent(testType)
  }
  
  return testTypeName
}

function extractTestNameForScoring(path) {
  let regex = /\/app\/practice-tests\/scoring\/(?<testName>[^\/]+)/
  let match = regex.exec(path)
  let testName = ""

  if (match && match.groups && match.groups.testName) {
    testName = decodeURIComponent(match.groups.testName)
  }
  
  return testName
}

function extractTestNameForInstructions(path) {
  let regex = /\/app\/practice-tests\/instructions\/(?<testName>[^\/]+)/
  let match = regex.exec(path)
  let testName = ""

  if (match && match.groups && match.groups.testName) {
    testName = decodeURIComponent(match.groups.testName)
  }
  
  return decodeURIComponent(testName)
}

function extractTestNameForReports(path) {
  let regex = /\/app\/practice-tests\/reports\/(?<testName>[^\/]+)/
  let match = regex.exec(path)
  let testName = ""

  if (match && match.groups && match.groups.testName) {
    testName = decodeURIComponent(match.groups.testName)
  }
  
  return testName
}

function generateBreadcrumbs(path): IBreadcrumb[] {
  let crumbs: IBreadcrumb[] = []
  
  if (path.includes('/app/practice-tests')) {    
    crumbs = getPracticeTestBreadcrumbs(path)
  }
  
  return crumbs
}

function getPracticeTestBreadcrumbs(fullPath): IBreadcrumb[] {
  let crumbs: IBreadcrumb[] = []

  if (fullPath.includes('growth-analytics/topics')) {
    // Topics Over Time (Growth Analytics Topics)
    let testType = extractTestTypeForTopics(fullPath)
    crumbs = [
      { name: 'Practice Tests', path: '/app/practice-tests' },
      { name: `${testType.toUpperCase()} Dashboard`, path: `/app/practice-tests?testType=${testType}` },
      { name: 'Topics Over Time' }
    ]
  }
  else if (fullPath.includes('growth-analytics')) {
    // Growth Analytics (for individual tests)
    let testType = extractTestTypeForGrowthAnalytics(fullPath)
    let testTypeQueryStr = testType.toLocaleLowerCase() 
    crumbs = [
      { name: 'Practice Tests', path: '/app/practice-tests' },
      { name: `${testType.toUpperCase()} Dashboard`, path: `/app/practice-tests?testType=${testTypeQueryStr}` },
      { name: 'Growth Analytics' }
    ]
  }
  else if (fullPath.includes('practice-tests/scoring')) {
    // Scoring
    let testName = extractTestNameForScoring(fullPath)
    let testDisplayName = getTestDisplayName(testName)
    let testType = getTestTypeBasedOnName(testName)
    let testTypeQueryStr = testType.toLocaleLowerCase() 
    crumbs = [
      { name: 'Practice Tests', path: '/app/practice-tests' },
      { name: `${testType.toUpperCase()} Dashboard`, path: `/app/practice-tests?testType=${testTypeQueryStr}` },
      { name: `${testDisplayName} Scoring` }
    ]
  }
  else if (fullPath.includes('practice-tests/instructions')) {
    // Instructions
    let testName = extractTestNameForInstructions(fullPath)
    let testDisplayName = getTestDisplayName(testName) 
    let testType = getTestTypeBasedOnName(testName)
    let testTypeQueryStr = testType.toLocaleLowerCase() 
    crumbs = [
      { name: 'Practice Tests', path: '/app/practice-tests' },
      { name: `${testType.toUpperCase()} Dashboard`, path: `/app/practice-tests?testType=${testTypeQueryStr}` },
      { name: `${testDisplayName} Scoring`, path: `/app/practice-tests/scoring/${testName}` },
      { name: `${testDisplayName} Instructions` }
    ]
  }
  else if (fullPath.includes('practice-tests/reports')) {
    // Reports (Analysis)
    let testName = extractTestNameForReports(fullPath)
    let testDisplayName = getTestDisplayName(testName)
    let testType = getTestTypeBasedOnName(testName)
    let testTypeQueryStr = testType.toLocaleLowerCase() 
    crumbs = [
      { name: 'Practice Tests', path: '/app/practice-tests' },
      { name: `${testType.toUpperCase()} Dashboard`, path: `/app/practice-tests?testType=${testTypeQueryStr}` },
      { name: `${testDisplayName} Scoring`, path: `/app/practice-tests/scoring/${testName}` },
      { name: `${testDisplayName} Analysis` }
    ]
  }
  else {
    // Dashboard
    let testType = extractTestTypeDashboard(fullPath)
    let testTypeDisplayName = testType.toUpperCase()
    crumbs = [
      { name: 'Practice Tests', path: '/app/practice-tests' },
      { name: `${testTypeDisplayName} Dashboard` }
    ]
  }

  return crumbs
}

