import * as React from 'react'
import { CATEGORY_ENGLISH, CATEGORY_MATH, CATEGORY_READING, CATEGORY_SCIENCE, CATEGORY_WRITING, NOT_STARTED, STATUS_LC_KEY_BASELINE, STATUS_LC_KEY_COMPLETED, STATUS_LC_KEY_NOT_STARTED, STATUS_LC_KEY_ON_VIDEO, STATUS_LC_KEY_QUESION, STATUS_LC_KEY_VIDEO } from '../../constants'
import './StatusProgressBar.css'

interface StatusBarProps {
  status: string
  category: string // AKA lesson section
  maxlen?: number
}

/**
 * Shows the status bar with progress
 * @param status info
 * @returns 
 */
 export default function StatusProgressBar({status, category, maxlen=35}: StatusBarProps) {
  let statusLC = status.toLowerCase()
  let started = statusLC !== STATUS_LC_KEY_NOT_STARTED  
  let completed = statusLC === STATUS_LC_KEY_COMPLETED
  let inBaselines = statusLC.indexOf(STATUS_LC_KEY_BASELINE) > -1
  let inVideos = statusLC.indexOf(STATUS_LC_KEY_VIDEO) > -1
  let inQuestions = statusLC.indexOf(STATUS_LC_KEY_QUESION) > -1
  let inProgress = started && !completed
  let completionPercentages = [0, 0, 0] // Not started state
  
  // Limit the status length
  status = status.substring(0, maxlen)

  // Progress of the baselines, videos and questions are
  // represented as {Baseline|Video|Question} n1/n2
  // n1: Progress
  // n2: Number of {Baselines|Videos|Questions}
  // If this information is available, which is available for
  // all new entries but not for the old ones, then
  // we will try to extract it and find out the corresponding
  // percentage information in order to display the progress.
  if (inProgress) {
    let n1 = 1, n2 = 1
    let regex = /(\d+)\/(\d+)/
    let found = statusLC.match(regex)
    if (found) {
      n1 = Number(found[1])
      n2 = Number(found[2])      
    }
    let percentage = Math.round(100.0*n1/n2)
    completionPercentages = 
      inBaselines ? [percentage, 0, 0]:
      inVideos ?    [100, percentage, 0]:
      inQuestions ? [100, 100, percentage]: [100, 100, 100]
  } 
  else if (completed) {
    completionPercentages = [100, 100, 100]
  }
  
  // New entries in the report table in DB will have the progress 
  // information like Baseline 1/5 or Video 2/7, etc.  
  // However, the old entries may have "On video: <lesson-name>::Intro" line 
  // and they are always stuct in the Intro because they weren't processed 
  // properly. The following part simplifies the long status entries
  // for the videos.
  if (statusLC.indexOf(STATUS_LC_KEY_ON_VIDEO) !== -1) status = "Video: Intro"

  return (
    <div>
      <div>
        {status}
      </div>
      <div className="status-bar">
        <div className="status-bar-sections">
          <div className="status-bar-baselines"><StatusBarCategoricalProgress category={category} completionPercentage={completionPercentages[0]}/></div>
          <div className="status-bar-videos"><StatusBarCategoricalProgress category={category} completionPercentage={completionPercentages[1]}/></div>
          <div className="status-bar-questions"><StatusBarCategoricalProgress category={category} completionPercentage={completionPercentages[2]}/></div>
        </div>
      </div>
    </div>
  )
}

interface StatusBarCategoricalProgressProps {
  completionPercentage: number
  category: string // AKA lesson section
}

function StatusBarCategoricalProgress(props: StatusBarCategoricalProgressProps) {
  let {completionPercentage, category} = props
  
  let categorizedClassName = "category-"
  switch (category) {
    case CATEGORY_ENGLISH:
      categorizedClassName += "english"
      break
    case CATEGORY_MATH:
      categorizedClassName += "math"
      break
    case CATEGORY_READING:
      categorizedClassName += "reading"
      break
    case CATEGORY_SCIENCE:
      categorizedClassName += "science"
      break
    case CATEGORY_WRITING:
      categorizedClassName += "writing"
      break
  }
  let className = `status-bar-progress ${categorizedClassName}`

  return (
    <div className={className} style={{width: completionPercentage + "%"}}>
      &nbsp;
    </div>
  )
}
