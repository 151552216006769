import './ScantronBubble.css';

import * as React from 'react';

interface Props {
    onClickHandler?: () => void;
    letter: string;
    userAnswer?: string;
    correctAnswer: string;
    disabled?: boolean;
}

interface State {
    attempt: boolean;
}

export class ScantronBubble extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            attempt: false, 
        };
      }

    render() {
        const lettersMatch = this.props.letter === this.props.userAnswer;
        const isCorrect = this.props.letter === this.props.correctAnswer;

        if (lettersMatch && isCorrect) {
            return (
                <button 
                    className={'scantron-answer-selection ' + (this.state.attempt && isCorrect) ? 'scantron-answered-correct' : ''}
                    onClick={this.props.onClickHandler}
                    disabled={this.props.disabled}
                >
                    <img src={`/assets/images/icons/v2/test/ico-test-` + this.props.letter.toLowerCase() + `-correct.svg`} className="" />
                </button>
            );
        } else if (lettersMatch) {
            return (
                <button 
                    className={'scantron-answer-selection ' + (this.state.attempt && !isCorrect) ? 'scantron-answered-incorrect' : ''}
                    onClick={this.props.onClickHandler}
                    disabled={this.props.disabled}
                >
                    <img src={`/assets/images/icons/v2/test/ico-test-` + this.props.letter.toLowerCase() + `-incorrect.svg`} className="" />
                </button>
            );
        } else {
            return (
                <button 
                    className={'scantron-answer-selection'}
                    onClick={this.props.onClickHandler}
                    disabled={this.props.disabled}
                >
                    <img src={`/assets/images/icons/v2/test/ico-test-` + this.props.letter.toLowerCase() + `.svg`} className="" />
                </button>
            );
        }
    }

}
