import * as React from 'react';
import * as _ from 'lodash';

import './LessonTopicGroup.css';

import { TestSection, getColor } from '../../../services/section-colors';
import GrowthIndicator from './GrowthIndicator';
import TopicRow from './TopicRow';
import TopicMetric from './TopicMetric';

interface Props {
    compareLastTwo: boolean;
    lessonId: string;
    lessonDisplayName: string;
    topics: Array<any>;
    sectionName: TestSection;
}

interface State {
    collapsed: boolean;
}

export default class LessonTopicGroup extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            collapsed: true
        };
    }

    render() {
        const { collapsed } = this.state;
        const { 
            lessonId, lessonDisplayName, topics, compareLastTwo, sectionName 
        } = this.props;

        // lots of treacherous skullduggery going on here, just watch...
        const testnames = _.uniq(_.flatMap(topics, topic => topic.tests.map(t => t.name)));
        const topicsByTest = testnames.map(testName => {
            const topic = topics
                .map(topic => topic.tests.find(test => testName == test.name));

            const firstTopic = _.first(topic);
            const correctByTopic = topic.reduce((acc, current) => current.correctCount + acc, 0);
            const totalByTopic = topic.reduce((acc, current) => current.total + acc, 0);
            return {
                testDisplayName: firstTopic.testDisplayName.toUpperCase(), 
                name: testName,
                correctCount: correctByTopic,
                total: totalByTopic
            };
        });

        let before = _.first(topicsByTest);
        if (compareLastTwo) {
            before = topicsByTest[topicsByTest.length - 2];
        }
        const after = topicsByTest[topicsByTest.length - 1];

        return (
            <table className="lesson-topic-group">
                <thead className={`ReportTableHeader`}>
                <tr>
                    <th>LESSON</th>
                    {topicsByTest.map(t =>
                        <th key={`th-${t.name}`}>
                            {t.testDisplayName === 'SAT DIAGNOSTIC' && <div>SAT DIAGNOSTIC</div>}
                            {t.testDisplayName.startsWith('PRE-TEST') &&
                                <div>
                                PRE-TEST<br />
                                {t.testDisplayName.substr(8, 12)}
                                </div>
                            }
                            {t.testDisplayName.startsWith('SAT TEST') && <div>SAT TEST {t.testDisplayName.substr(8)}</div>}
                            {t.testDisplayName === 'ACT DIAGNOSTIC' && <div>ACT DIAGNOSTIC</div>}
                            {t.testDisplayName.startsWith('ACT TEST') && <div>ACT TEST {t.testDisplayName.substr(8)}</div>}
                            {!t.testDisplayName.startsWith('PRE-TEST') && !t.testDisplayName.includes('SAT') && !t.testDisplayName.includes('ACT') &&
                            t.testDisplayName
                            }
                        </th>
                    )
                    }
                    <th>GROWTH</th>
                </tr>
                </thead>
                <tbody>
                    <tr className="lesson-row">
                        <td>
                            <div>
                            <div className="toggle-wrapper">
                                <button
                                    className={`lesson-group-toggle ${!this.state.collapsed ? 'expanded' : ''}`}
                                    onClick={() => this.setState({
                                    collapsed: !this.state.collapsed })
                                }>
                                    {this.state.collapsed ?
                                    <svg width="16px" height="9px" viewBox="0 0 16 9">
                                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#0E5682" d="M15.4,8.7c-0.4,0.4-1.2,0.4-1.6,0l-6-6l-6,6
                                        c-0.4,0.4-1.2,0.4-1.6,0c-0.4-0.4-0.4-1.2,0-1.6l6.8-6.8c0.4-0.4,1.2-0.4,1.6,0l6.7,6.8C15.9,7.5,15.9,8.2,15.4,8.7z"/>
                                    </svg> :
                                    <svg width="16px" height="9px" viewBox="0 0 16 9">
                                        <path fill-rule="evenodd" clip-rule="evenodd" fill="#0E5682" d="M0.3,0.3c0.4-0.4,1.2-0.4,1.6,0l6,6l6-6c0.4-0.4,1.2-0.4,1.6,0
                                        c0.4,0.4,0.4,1.2,0,1.6L8.7,8.7c-0.4,0.4-1.2,0.4-1.6,0L0.3,1.9C-0.1,1.5-0.1,0.8,0.3,0.3z"/>
                                    </svg>
                                    }
                                </button>
                                </div>
                                <a href="#" className="lesson-name-button"
                                    onClick={() =>
                                    this.setState({ collapsed: !this.state.collapsed})}
                                    >
                                    {lessonDisplayName}
                                </a>
                            </div>
                        </td>
                        {topicsByTest.map(t =>
                            <TopicMetric
                                key={`${t.name}-${lessonId}`}
                                correctCount={t.correctCount}
                                total={t.total}
                            />
                        )}
                        <GrowthIndicator
                            before={before}
                            after={after}
                        />
                    </tr>
                    {!collapsed &&
                    topics.map(
                        (topicOverTime: any) => {
                        const { key } = topicOverTime;
                        return (
                            <TopicRow
                                compareLastTwo={compareLastTwo}
                                topicId={key.topicId}
                                topicDisplayName={topicOverTime.topicDisplayName}
                                key={`${key.lessonId}-${key.topicId}`}
                                topicOverTimeList={topicOverTime.tests}
                            />
                            );
                        })
                    }
                </tbody>
            </table>
    );
    }
}
