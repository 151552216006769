import './MistakeLessonList.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import { Column, RowData } from '../../../../../../components/DataGrid/types/types';

import Button from '../../../../../../components/Button/Button';
import DataGrid from '../../../../../../components/DataGrid/DataGrid';
import { MistakeBankLessons } from '../../../../../../services/elearn/lesson-types';
import MistakeBankVideo from './components/MistakeBankVideo/MistakeBankVideo';
import MistakeLessonListTitle from './components/MistakeLessonListTitle/MistakeLessonListTitle';
import { RouteComponentProps } from 'react-router-dom';
import { getBaseUrl } from '../../../../../../services/routing/routing-utils';
import MistakeBankDialog from '../../../MistakeBankDialog/MistakeBankDialog';

export interface MistakeLessonListProps {
    mistakeLessons: MistakeBankLessons[];
}

const sortStr = (a, b) => {
    if(a < b) {
        return -1;
    }
    if(b < a) {
        return 1;
    }
    return 0;
};

const sortStatus = (a, b) => {
  if(a < b) {
    return 1;
  }
  if(b < a) {
      return -1;
  }
  return 0;
};

const mistakeBankColumns: Column[] = [
    {
        title: 'LESSONS',
        dataProperty: 'lesson',
        // width: '250px',
        width: '300px',
        sortMethod: sortStatus
    },
    {
        title: 'TOPIC',
        dataProperty: 'topic',
        width: '27%',
        sortMethod: sortStatus
    },
    {
        title: 'REVIEWED',
        dataProperty: 'review',
        sortMethod: sortStr,
        width: '12%'
    },
    {
        title: 'REWATCH',
        dataProperty: 'video',
        sortable: false,
        width: '12%'
    },
    {
        title: 'STATUS',
        dataProperty: 'status',
        sortMethod: sortStatus,
        width: '220px'
    },
];

type AllProps = MistakeLessonListProps & RouteComponentProps<any>;


interface State {
    showModalDialog: boolean
    currentLessonId?: string
    currentTopicId?: string
}

export default class MistakeLessonList extends React.Component<AllProps, State> {
    constructor(props) {
        super(props)
        this.state = {
            showModalDialog: false
        }
    }

    handleButtonLeft = () => {
        this.setState({showModalDialog: false})
    }

    handleButtonRight = () => {
        let {currentLessonId, currentTopicId} = this.state
        this.props.history.push(`${getBaseUrl()}app/mistake-bank/${currentLessonId}/${currentTopicId}`);
    }

    public render() {

        const data = this.createMistakeBankData;
        const lessonData = this.props.mistakeLessons.map(data);
        let {showModalDialog}= this.state
        return (
            <>
            {showModalDialog? 
                <MistakeBankDialog
                    show= {showModalDialog}
                    onButtonLeft={this.handleButtonLeft}
                    onButtonRight={this.handleButtonRight}
                />
                :
                <DataGrid
                    columns={mistakeBankColumns}
                    data={lessonData}
                    emptyText="No lessons to display"
                    className={`mistake-bank-lesson-list ` + this.props.mistakeLessons[0].categoryName.toLowerCase()}
                />
            }
            </>
        );
    }

    
    @boundMethod
    private createMistakeBankData(lesson: MistakeBankLessons, i: number): RowData {
        return {
            data: {
                lesson: {
                    content: this.createMistakeLessonTitle(lesson),
                    comparator: lesson.lessonName
                },
                topic: {
                    content: this.createMistakeTopic(lesson),
                    comparator: lesson.topicName
                },
                review: {
                    content: this.formatMistakeQuestions(lesson),
                    comparator: lesson.numQuestionsReviewed || 0
                },
                video: {
                    content: this.getVideoTopic(lesson),
                    comparator: lesson.topicVideoId
                },
                status: {
                    content: this.createReviewButton(lesson),
                    comparator: this.getReviewButtonData(lesson).text
                }
            },
            id: `lesson-data${i}`
        };
    }

    @boundMethod
    private createMistakeLessonTitle(lesson: MistakeBankLessons) {
        const iconTitle = this.getReviewButtonData(lesson).text;
        const icon = this.getLessonMistakeIcon(lesson).img;
        const iconAltText = this.getLessonMistakeIcon(lesson).alt;
        return () => (
            <MistakeLessonListTitle
                icon={icon}
                alt={iconAltText}
                title={iconTitle}
                lessonName={lesson.lessonName}
                onIconClick={() => this.handleTopicButtonClick(lesson.lessonId, lesson.topicId, '')}
            />
        );
    }

    private createMistakeTopic(lesson: MistakeBankLessons) {
        return () => (
            <span>{lesson.topicName}</span>
        );
    }

    private formatMistakeQuestions(lesson: MistakeBankLessons) {
        if(!lesson.numQuestionsMissed && (!lesson.numManualMistakeEntries || 0)) { return 'N/A'; }
        return `${lesson.numQuestionsReviewed} of ${lesson.numQuestionsMissed + (lesson.numManualMistakeEntries || 0)}`;
    }

    private createReviewButton(lesson: MistakeBankLessons) {
        const statusButtonData = this.getReviewButtonData(lesson);
        const style = {
            paddingLeft: '0',
            paddingRight: '0'
        };
        return () => (
            <Button
                textColor={'white'}
                bgColor={statusButtonData.color as any}
                text={statusButtonData.text}
                className="lesson-list__btn"
                onClick={() => this.handleTopicButtonClick(lesson.lessonId, lesson.topicId, statusButtonData.text)}
                style={style}
            />
        );
    }

    private getLessonMistakeIcon(lesson: MistakeBankLessons) {
        const status = lesson.topicReviewStatus;
        switch(status) {
            case 'REVIEW': return { img: '/assets/images/icons/v2/ico-review.svg', alt: 'Review' };
            case 'REVIEW AGAIN': return { img: '/assets/images/icons/v2/ico-review-again.svg', alt: 'Review again' };
            default: return { img: '/assets/images/icons/v2/ico-topic-mastered.svg', alt: 'Mastered' };
        }
    }

    private getReviewButtonData(lesson: MistakeBankLessons) {
        const status = lesson.topicReviewStatus;
        switch(status) {
            case 'REVIEW': return { text: 'REVIEW', color: 'blue' };
            case 'REVIEW AGAIN': return { text: 'REVIEW AGAIN', color: 'green' };
            default: return { text: 'TOPIC MASTERED', color: 'purple' };
        }
    }

    private getVideoTopic(lesson: MistakeBankLessons) {
        return () => (
            <MistakeBankVideo
                videoId={lesson.topicVideoId}
                lessonId={lesson.lessonId}
                categoryName={lesson.categoryName}
            />
        );
    }

    
    private handleTopicButtonClick(lessonId: string, topicId: string, lessonText: string) {
        if(lessonText.toLowerCase() == 'topic mastered') {
            // TRIGGER MODAL WINDOW 
            this.setState({
                showModalDialog: true, 
                currentLessonId: lessonId, 
                currentTopicId: topicId
            })
        } else {
            // ELSE default action:
            this.props.history.push(`${getBaseUrl()}app/mistake-bank/${lessonId}/${topicId}`);
        }
    }
}
