import './StaySharp.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';
import * as _ from 'lodash';

import { StaySharpView, getStaySharpViewEnum } from './components/StaySharpViewSwitch/StaySharpViewSwitch';

import { CURRICULUM_LIST, StaySharpProgress } from '../../services/elearn/lesson-types';
// import CalendarView from '../Lessons/components/CalendarView/CalendarViewContainer';
import ComingSoon from '../../components/ComingSoon/ComingSoon';
import CompletedView from '../Lessons/components/CompletedView/CompletedViewContainer';
import StaySharpTabs from './components/StaySharpTabs/StaySharpTabs';
import StaySharpInfoCard from './components/StaySharpInfoCard/StaySharpInfoCard';
import { PageContent } from '../../components/PageContent/PageContent';
import { RouteComponentProps } from 'react-router-dom';
import StaySharpSubjectView from './components/StaySharpSubjectView/StaySharpSubjectView';
import { Tab } from '../../components/Tabs/Tabs';
import { parse } from 'query-string';
import StaySharpModal from './components/StaySharpModal/StaySharpModal';
import { getDashboard } from '../../services/elearn/stay-sharp-rest-interface';
import Loading from '../../components/Loading/Loading';

interface Props extends RouteComponentProps<any> {

}

interface RouteProps {
    // Terrible...
    completion: any;
}

export interface State {
    selectedView: StaySharpView;
    showEnglishModal: boolean;
    showMathModal: boolean;
    onCompleteModal: boolean;
    // Also terrible...
    completion: any;
    topicText: string;
    subjects: StaySharpProgress[];
    renderComponent: boolean;
}

type AllProps = Props & RouteProps;


export class StaySharpWithoutRouting extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props);

        const search = parse(props.location.search);
        let topicText = '';
        if (!_.isEmpty(search)) {
            topicText = search.completion as string;
        }
        const searchView = search.view as string;
        this.state = {
            selectedView: getStaySharpViewEnum(searchView || 'subject'),
            showEnglishModal: false,
            showMathModal: false,
            onCompleteModal: !!search.completion || false,
            completion: search || '',
            topicText: topicText,
            subjects: [],
            renderComponent: false
        };
    }

    public componentDidMount() {
        let self = this;
        getDashboard().then(res => {
            self.setState({ subjects: res, renderComponent: true });
        });
    }

    public render() {
        const { onCompleteModal, topicText, renderComponent } = this.state;

        if (!renderComponent) {
            return <Loading />;
        }
        
        const tabs = CURRICULUM_LIST.map(this.createStaySharpTab);

        return (
            <PageContent>
                <h1 className="page-title">Stay Sharp</h1>
                <StaySharpInfoCard />
                <StaySharpTabs 
                    onViewChange={this.handleViewChange} 
                    selectedView={this.state.selectedView} 
                    openEnglishModal={this.openEnglishModal} 
                    openMathModal={this.openMathModal}
                >
                {tabs}
                </StaySharpTabs>
                <StaySharpModal 
                    onClose={this.closeCompleteModal} 
                    show={onCompleteModal} 
                    topicName={topicText} 
                    backToStaySharp={this.closeCompleteModal}
                />
            </PageContent>
        );
    }

    @boundMethod
    private openCompleteModal(topicName: string) {
        this.setState({ onCompleteModal: true, topicText: topicName });
    }

    @boundMethod
    private closeCompleteModal() {
        this.setState({ onCompleteModal: false });
    }
    private openEnglishModal() {
        this.setState({ showEnglishModal: true, selectedView: this.state.selectedView });
    }
    private openMathModal() {
        this.setState({ showMathModal: true, selectedView: this.state.selectedView });
    }

    @boundMethod
    private createStaySharpTab(title: string, index: number) {
        const isTestPrep = title === 'Test Prep';
        return (
            <Tab text={title} key={title}>
                {!isTestPrep && <ComingSoon />}
                {isTestPrep &&
                    this.state.selectedView === StaySharpView.Subject &&
                    <StaySharpSubjectView 
                        curriculum={title} 
                        showCompletionModal={this.openCompleteModal}
                        subjects={this.state.subjects} 
                        {...this.props} 
                    />
                }
                {isTestPrep &&
                    this.state.selectedView === StaySharpView.Completed && 
                    <CompletedView curriculum={title} />}
            </Tab>
        );
    }

    private handleViewChange(selectedView: StaySharpView) {
        this.setState({ selectedView });
    }

    private setViewToSubject() {
        this.setState({selectedView: StaySharpView.Subject});
    }
}
