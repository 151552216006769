import * as React from 'react';

import './ComparisonSelector.css';

interface State {
    compareLastTwo: boolean; // growth comparison mode
}

interface Props {
    onClick: (shouldCompareLastTwo: boolean) => void;   
}

export class ComparisonSelector extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { compareLastTwo: false };
    }

    doThing(shouldCompareLastTwo: boolean) {
        this.setState({ compareLastTwo: shouldCompareLastTwo });
        this.props.onClick(shouldCompareLastTwo);
    }

    render() {
        const { compareLastTwo } = this.state;
        return (
            <div className="topic-comparison-selector">
                <span className="d-block d-sm-inline">Compare:&nbsp;</span>
                <label htmlFor="compare-first-last-radio">
                    <input
                        type="radio"
                        name="compare-first-last-radio"
                        id="compare-first-last-radio"
                        value="first-last"
                        checked={!compareLastTwo}
                        onChange={() => this.doThing(false)}
                    />
                    <span className="checkmark" />
                    First and Last Tests
                </label>
                <label htmlFor="compare-last-two-radio">
                    <input
                        type="radio"
                        name="compare-last-two-radio"
                        id="compare-last-two-radio"
                        value="last-two"
                        checked={compareLastTwo}
                        onChange={() => this.doThing(true)}
                    />
                    <span className="checkmark" />
                    Last Two Tests
                </label>
            </div>
        );
    }
}

export default ComparisonSelector;
