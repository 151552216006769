import './Modal.css';

import * as React from 'react';
import * as autobind from 'autobind-decorator';

import Portal from '../Portal/Portal';

interface Props {
    className?: string;
    show?: boolean;
    onClose: () => void;
    width?: string;
    showXTopRight?: boolean;
    showXinside?: boolean;
}

interface State {

}



// TODO flesh this out so it is customizable
export default class Modal extends React.PureComponent<Props, State> {
    private lastFocusedElement: Element | null = null;
    // Create a ref for the modal element
    private modalRef = React.createRef<HTMLDivElement>();

    public static defaultProps: Partial<Props> = {
        width: '800px'
    };

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
        this.setFocusToFirstItem();
    }

    componentDidUpdate(prevProps: Props) {
        if (!prevProps.show && this.props.show) {
            // Store the current focus before modal opens
            this.lastFocusedElement = document.activeElement;
            this.setFocusToFirstItem();
        } else if (prevProps.show && !this.props.show) {
            // When the modal is closed, restore focus to the last focused element
            if (this.lastFocusedElement instanceof HTMLElement) {
                this.lastFocusedElement.focus();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    setFocusToFirstItem = () => {
        const focusableElements = this.modalRef.current?.querySelectorAll<HTMLElement>('.modal-body button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        if (focusableElements && focusableElements.length > 0) {
            setTimeout(() => focusableElements[0].focus(), 0);
        }
    };

    handleKeyDown = (event: KeyboardEvent) => {
        if (this.props.show && event.key === 'Tab') {
            // Implement focus trapping
            // ... focus trapping logic
        }
    };

    constructor(props: Props) {
        super(props);
    }

    // public componentDidUpdate(prevProps: Props) {
    //     const root = document.getElementById('root');
    //     if(!root) { return; }

    //     if(this.props.show) {
    //         const backdrops = document.getElementsByClassName('modal-backdrop');
    //         if(backdrops.length > 0) {
    //             return;
    //         }
    //         const backdrop = document.createElement('div');
    //         backdrop.classList.add('modal-backdrop', 'fade', 'show');
    //         root.appendChild(backdrop);
    //     } else {
    //         const backdrops = document.getElementsByClassName('modal-backdrop');
    //         for(let i = 0; i < backdrops.length; i++) {
    //             root.removeChild(backdrops[i]);
    //         }
    //     }
    // }

    // public componentWillUnmount() {
    //     const root = document.getElementById('root');
    //     if(!root) { return; }

    //     const backdrops = document.getElementsByClassName('modal-backdrop');
    //     for(let i = 0; i < backdrops.length; i++) {
    //         root.removeChild(backdrops[i]);
    //     }
    // }

    // public componentDidMount() {
    //     const root = document.getElementById('root');
    //     if(!root) { return; }

    //     if(this.props.show) {
    //         const backdrops = document.getElementsByClassName('modal-backdrop');
    //         if(backdrops.length > 0) {
    //             return;
    //         }
    //         const backdrop = document.createElement('div');
    //         backdrop.classList.add('modal-backdrop', 'fade', 'show');
    //         root.appendChild(backdrop);
    //     }
    // }

    public render() {
        const modalClass = this.props.className || '';
        const showClass = this.props.show ? 'show d-block' : '';
        // TODO set this depending on if we have a title so content can be centered
        const emptyHeader = 'modal--empty-header';
        const tabIval = this.props.show ? 1 : -1;
        return (
            <Portal>
                <div>
            <div
                ref={this.modalRef}
                className={`modal fade ${showClass}`}
                id="modal"
                onClick={this.props.onClose}
                tabIndex={tabIval}
                role="dialog"
                aria-labelledby="modalLabel"
                aria-hidden="true"
            >
            {this.props.showXTopRight &&
              <button className="closeBtn btn-simple" title="Close modal">
                <img
                    className="modal-icon"
                    src={`/assets/images/icons/v2/ico-modal-close-white.svg`}
                    width="24"
                    height="24"
                />
              </button>
            }

                  <div className="vertical-align-helper">
                    <div className="modal-dialog vertical-align-center modal-center" role="document">

                        <div
                            className={`modal-content ${modalClass}`}
                            onClick={this.preventClose}
                            style={{ maxWidth: this.props.width}}
                        >

                            {/* Header */}
                            <div className={`modal-header ${emptyHeader}`}>

                                <button type="button" className="close" title="Close Modal" aria-label="Close" onClick={this.props.onClose}>
                                    {/*<h5 className="modal-title" id="modalLabel">Modal Title</h5>*/}
                                    {this.props.showXinside ?
                                        <img className="modal-icon" src="/assets/images/icons/v2/ico-modal-close-white.svg" width="24" height="24" />
                                        :
                                        <span aria-hidden="true">&times;</span>
                                    }
                                </button>
                            </div>

                            {/* Body */}
                            <div className="modal-body">
                                {this.props.children}
                            </div>

                            {/* Footer */}
                            {/*<div className="modal-footer">

                            </div>*/}
                        </div>
                    </div>
                </div>
            </div>
            {this.props.show
                && <div className="modal-backdrop fade show"/>
            }
            </div>
            </Portal>
        );
    }

    
    private preventClose(e: any) {
        e.stopPropagation();
    }
}
