import "./MistakeBankInfoCard.css"

import * as React from "react"
import { Link } from "react-router-dom"
import { hasSchoolSafety } from "../../../../services/account/account-utils"

interface Props {
  userInfo: any
}

// tslint:disable:max-line-length
const MistakeBankInfoCard: React.SFC<Props> = (props) => {
  const link = `/print/study-guide/${props.userInfo.firstName}/${props.userInfo.lastName}`
  const PersonalizedStudyGuideLink = (
    <Link
      to={link}
      target="_blank"
      title="Generate A Personalized Study Guide"
      onClick={(event) => {
        event.preventDefault()
        window.open(link)
      }}
    >
      personalized study guide
    </Link>
  )
  return (
    <div className="mistake-bank-info-card">
      <div className="mistake-bank-info-card__mix-em-up d-flex">
        <img
          src={"/assets/images/icons/sections/mistake_bank_gradient-v2_white.svg"}
          alt=""
          width="176"
          height="176"
        />
      </div>
      {!hasSchoolSafety(props.userInfo) && (
        <div>
          <h6 className="info-card-title">Mistake Bank Overview</h6>
          <p>
            The Mistake Bank automatically keeps track of your missed multiple
            choice questions, so you stay organized and always have a place to
            go to redo mistakes and to improve your skills. After reviewing
            missed questions, you have the opportunity to categorize the topic
            as “review again” or “topic mastered” to help you keep track.
          </p>
          <p>
            You can also generate a {PersonalizedStudyGuideLink} summarizing the
            hints you wrote to yourself when reviewing questions. This guide is
            perfect to review leading up to an exam. Opportunities for more
            practice questions from real exams can be found in the&nbsp;
            <Link title="Stay Sharp" to={"stay-sharp"}>Stay Sharp</Link>&nbsp;feature.
          </p>
        </div>
      )}

      {hasSchoolSafety(props.userInfo) && (
        <div>
          <p>
            The Mistake Bank automatically keeps track of your missed multiple
            choice questions, so you stay organized and always have a place to
            go to redo mistakes and to improve your knowledge. After reviewing
            missed questions, you have the opportunity to categorize the topic
            as “review again” or “topic mastered” to help you keep track.
          </p>
          <p>
            You can also generate a {PersonalizedStudyGuideLink} summarizing the
            hints you wrote to yourself when reviewing questions. This guide is
            a perfect review tool to consolidate what you've learned.
          </p>
        </div>
      )}
    </div>
  )
}

MistakeBankInfoCard.defaultProps = {}
MistakeBankInfoCard.displayName = "MistakeBankInfoCard"

export default MistakeBankInfoCard
