import * as autobind from 'autobind-decorator';
import * as React from 'react';
import { ScantronBubble } from '../ScantronBubble';
import './PrintPracticeTable.css';

export interface Props {
    data: any;
    subject: string;
}

interface State {
}


export class PrintPracticeTable extends React.Component<Props, State> {
    constructor (props: Props) {
        super(props);
        this.state = {};
    }

    public render() {
        const correctAnswerCount = this.props.data.filter((a) => {
            return a.correctAnswer === a.userSubmittedAnswer;
        });
        return (
            <div className="practice-print clearfix">
                <div className="row">
                    <div className="col-lg-8">
                        <h3>{this.props.subject}</h3>
                    </div>
                    <div className="col-lg-4">
                        {correctAnswerCount.length}/{this.props.data.length} Correct
                    </div>
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Q</th>
                            <th>YOUR ANSWER</th>
                            <th>ACCURACY</th>
                            <th>CORRECT ANSWER</th>
                            <th>LESSON</th>
                            <th>TOPIC</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.data.map((object: any, i: any) => {
                        const image = (object.correctAnswer === object.userSubmittedAnswer) ? 
                        '/assets/images/icons/black_checkmark.png' : 
                        '/assets/images/icons/black_x_mark.png';
                        return (
                            <tr key={object.questionNumber}>
                            <td>{object.questionNumber}</td>
                            <td className="accuracy-column">
                                {object.mcLetters.map((letter: string, j: any) => {
                                return (
                                    <span key={letter + '-' + j}>
                                    <ScantronBubble
                                        letter={letter}
                                        userAnswer={object.userSubmittedAnswer}
                                        correctAnswer={object.correctAnswer}
                                    />
                                    </span>
                                );
                                })}
                            </td>
                            <td>
                                <img className="accuracy" src={image} />
                            </td>
                            <td>{object.correctAnswer}</td>
                            <td>{object.lessonName}</td>
                            <td>{object.topicName}</td>
                            </tr>
                        );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}
