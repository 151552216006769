import './ConversionModal.css';

import * as React from 'react';
import Modal from '../Modal/Modal';

interface Props {
    onClose: () => void;
    show: boolean;
}

const ConversionModal: React.SFC<Props> = (props) => {
    return (
      <Modal 
        className="conversion-modal modal" 
        show={props.show} 
        onClose={props.onClose} 
        width="900px"
      >
      <div className="row text-center">
          <div className="conversion-modal-header col-lg-12">
            <h2 className="conversion-modal-title mb-0">Score Conversion</h2>
            <h3 className="conversion-modal-subtitle">Please reference the table below to better understand how accuracy converts to ACT and SAT section scores.</h3>
            <button type="button" className="conversion-modal-close modal-close" title="Close Modal" aria-label="Close" onClick={props.onClose}>
                <img
                    className="modal-icon"
                    src={`/assets/images/icons/v2/ico-modal-close-white.svg`}
                    width="24"
                    height="24"
                />
            </button>
          </div>
          <div className="conversion-table-container container-fluid coverBottom-tc w-100">
            <div className="responsive-table-wrapper">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="no-border"></th>
                            <th colSpan={4} className="text-center act-header">ACT</th>
                            <th colSpan={2} className="text-center sat-header">SAT</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="grey">Accuracy</td>
                            <td className="act-subjects">English</td>
                            <td className="act-subjects">Math</td>
                            <td className="act-subjects">Reading</td>
                            <td className="act-subjects">Science</td>
                            <td className="sat-subjects">EBRW</td>
                            <td className="sat-subjects">Math</td>
                        </tr>
                        <tr>
                            <td className="grey">100%</td>
                            <td>36</td>
                            <td>36</td>
                            <td>36</td>
                            <td>36</td>
                            <td>800</td>
                            <td>800</td>
                        </tr>
                        <tr>
                            <td className="grey">90%</td>
                            <td>30-32</td>
                            <td>34-35</td>
                            <td>30-33</td>
                            <td>32-33</td>
                            <td>720-730</td>
                            <td>730-760</td>
                        </tr>
                        <tr>
                            <td className="grey">80%</td>
                            <td>25-27</td>
                            <td>29-30</td>
                            <td>26-30</td>
                            <td>28</td>
                            <td>650-670</td>
                            <td>690-720</td>
                        </tr>
                        <tr>
                            <td className="grey">70%</td>
                            <td>23-24</td>
                            <td>27</td>
                            <td>22-25</td>
                            <td>25</td>
                            <td>590-610</td>
                            <td>650-680</td>
                        </tr>
                        <tr>
                            <td className="grey">60%</td>
                            <td>20-21</td>
                            <td>24</td>
                            <td>20-22</td>
                            <td>23</td>
                            <td>540-560</td>
                            <td>590-620</td>
                        </tr>
                        <tr>
                            <td className="grey">50%</td>
                            <td>17-18</td>
                            <td>21</td>
                            <td>17-20</td>
                            <td>20-21</td>
                            <td>490-510</td>
                            <td>540-570</td>
                        </tr>
                        <tr>
                            <td className="grey">40%</td>
                            <td>14-15</td>
                            <td>18</td>
                            <td>15-17</td>
                            <td>18-19</td>
                            <td>450-470</td>
                            <td>490-510</td>
                        </tr>
                        <tr>
                            <td className="grey">30%</td>
                            <td>10-12</td>
                            <td>15-16</td>
                            <td>13-14</td>
                            <td>16</td>
                            <td>380-400</td>
                            <td>430-450</td>
                        </tr>
                        <tr>
                            <td className="grey">20%</td>
                            <td>8-9</td>
                            <td>14</td>
                            <td>9-10</td>
                            <td>11-12</td>
                            <td>320-330</td>
                            <td>360-370</td>
                        </tr>
                        <tr>
                            <td className="grey">10%</td>
                            <td>5-6</td>
                            <td>10-11</td>
                            <td>5-6</td>
                            <td>7-8</td>
                            <td>240-250</td>
                            <td>280-290</td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </div>
      </div>
      </Modal>
    );
};

ConversionModal.displayName = 'ConversionModal';

export default ConversionModal;
