import React, { Component } from 'react'
import { LessonSummaryByCategory, TimeOnPlatformLessonProcessor } from './top-lesson-processor'
import "./LessonByLessonSummary.css"
import { getSubjectIcon } from '../../../services/icon/icon-service'
import CollapsableCard, { CollapsableCardBody, CollapsableCardHeader } from '../../../components/CollapsableCard/CollapsableCard'
import ResourceListCardHeader from '../../../components/ResourceListCardHeader'
import DataGrid from '../../../components/DataGrid'
import { Column, RowData } from '../../../components/DataGrid/types/types'
import { getHumanTime } from '../../../services/utils/date-util'
import { CategorySummariesMap, LessonsSummariesMap, LessonsSummaryMap, Selections } from './types'
import Modal from '../../../components/Modal/Modal'
import LessonBreakdownView from './LessonBreakdownView'
import { COLLEGE_READY_COLOR_THRESHOLD } from '../../../constants'

interface Props {
  lessonSummariesByCategoryMap: LessonsSummariesMap
  selectedTestKind: string
  nofStudents: number
  classSummariesByCategoryMap: CategorySummariesMap
  selections: Selections
}

interface State {
  modalShowing: boolean
  lessonToBreakdown?: LessonSummaryByCategory
  lessonColor
 }

const sortMethod = (str1: string|number, str2: string|number) => {
  if (str1 > str2) return 1
  if (str1 < str2) return -1
  
  return 0    
}

let sortable = true
const resourceColumns: Column[] = [
  {
    title: 'LESSON',
    dataProperty: 'lesson',
    customTooltip: 'Indicates the name of the lesson',
    width: '320px',
    sortable,
    sortMethod
  },
  {
    title: 'LESSONS<br/>STARTED',
    dataProperty: 'started',
    customTooltip: 'Indicates the number of lessons started',
    width: '12%',
    sortable,
    sortMethod
  },
  {
    title: 'LESSONS<br/>COMPLETED',
    dataProperty: 'completed',
    customTooltip: 'Indicates the number of lessons finished',
    width: '12%',
    sortable,
    sortMethod
  },

  {
    title: 'PRE-LESSON<br/>ACCURACY',
    dataProperty: 'preLessonAccuracy',
    customTooltip: 'Calculated as the percent of baseline questions answered correctly before starting a lesson',
    width: '12%',
    sortable,
    sortMethod
  },
  {
    title: 'POST-LESSON<br/>ACCURACY',
    dataProperty: 'postLessonAccuracy',
    customTooltip: 'Calculated as the number of correct questions divided by the number of questions answered',
    width: '12%',
    sortable,
    sortMethod
  },
  {
    title: 'LESSON<br/>GROWTH',
    dataProperty: 'lessonGrowth',
    customTooltip: 'Calculated as the change in accuracy from pre-lesson accuracy to completed question accuracy',
    width: '12%',
    sortable,
    sortMethod
  },
  {
    title: 'HINTS ON<br/>INCORRECT',
    dataProperty: 'hintsOnIncorrect',
    customTooltip: 'Reflects what percent of missed questions students write themselves problem solving hints',
    width: '12%',
    sortable,
    sortMethod
  },
  {
    title: 'TIME ON<br/> QUESTIONS',
    dataProperty: 'timeOnQuestion',
    customTooltip: 'Indicates average and total time answering multiple choice questions',
    width: '12%',
    sortable,
    sortMethod
  },
  {
    title: 'TOTAL<br/>STUDY TIME',
    dataProperty: 'totalStudyTime',
    customTooltip: 'Indicates total time spent answering baseline questions, watching the video lesson, and answering multiple choice questions',
    width: '12%',
    sortable,
    sortMethod
  },
  {
    title: 'COLLEGE<br/>READY',
    dataProperty: 'collegeReady',
    customTooltip: 'Indicates the percent of students whose post-lesson accuracy meets the ACT/SAT threshold for "college ready"',
    width: '12%',
    sortable,
    sortMethod
  }
]

export default class LessonByLessonSummary extends Component<Props, State> {
  timeOnPlatformLessonProcessor = new TimeOnPlatformLessonProcessor()

  constructor(props) {
    super(props)
    this.state = {
      modalShowing: false,
      lessonToBreakdown: undefined,
      lessonColor: undefined
    }
  }

  
  prepLessonAccuracyInfo = (accuracy: number, testKind: string, scoreInfo: string) => {
    return () => (
      <div>
        {accuracy}%
        <div className="act-or-sat-score-info">
          {testKind} <span className="act-or-sat-score">{scoreInfo}</span>
        </div>
      </div>
    )
  }

  prepTimeOnQuestionInfo = (avgTime: number, totalTime: number) => {
    let NA = "N/A"
    let avgTimeInfo = avgTime > 0 ? getHumanTime(avgTime, true, true).text: NA
    let totalTimeInfo = totalTime > 0 ? getHumanTime(totalTime, false, true).text: NA

    return () => (
      <div className="time-on-question-info act-or-sat-score-info">
        {avgTimeInfo != NA ?
          <div>
            Avg <span dangerouslySetInnerHTML={{__html: avgTimeInfo}}/>
            <br/>
            Total <span dangerouslySetInnerHTML={{__html: totalTimeInfo}}/>
          </div>
          :
          <div className="not-applicable">{NA}</div>
        }
      </div>
    )
  }

  prepTotalStudyTimeInfo = (totalStudyTime: number) => {
    let NA = "N/A"
    let totalTimeInfo = totalStudyTime > 0 ? getHumanTime(totalStudyTime, false, true).text: NA

    return () => (
      <div className="time-on-question-info act-or-sat-score-info">
        {totalTimeInfo != NA ?
          <span dangerouslySetInnerHTML={{__html: totalTimeInfo}}/>
          :
          <div className="not-applicable">{NA}</div>
        }
      </div>
    )
  }

  private showLessonBreakdown = (lesson, category) => {
    let si = getSubjectIcon(category)
    let defaultColor = "#10a492"
    let color = si ? si.color: defaultColor
    this.setState({modalShowing: true, lessonToBreakdown: lesson, lessonColor: color})
  }

  private closeModal = () => {
    this.setState({modalShowing: false, lessonToBreakdown: undefined})
  }

  /**
   * Note that if the category Reading | Science, we need to show pre-lesson accuracy and growth to be N/A (per Jennifer) 
   */
  private createLessonByLessonData = (lessonSummary: LessonSummaryByCategory, index: number, category: string): RowData => {
    let id = `lesson-by-lesson-${lessonSummary.lessonName}-${index}`
    let { selectedTestKind } = this.props
    let {keepOnTopDuringSort=false} = lessonSummary
    let {lessonId, lessonName, nofStudentsStarted, nofStudentsCompleted, nofStudentsContinued, nofStudentsCollegeReadyACT, nofStudentsCollegeReadySAT} = lessonSummary
    let {accuracyPreLesson, accuracyPostLesson, lessonGrowth, hintsOnIncorrect, avgTimePerQuestion, totalTimeOnAnswered, totalTimeOnEnded} = lessonSummary
    let {preLessonConversionScoreACT, preLessonConversionScoreSAT} = lessonSummary
    let {postLessonConversionScoreACT, postLessonConversionScoreSAT} = lessonSummary
    let collegeReadyACT = Math.round(100*nofStudentsCollegeReadyACT / nofStudentsContinued)
    let collegeReadySAT = Math.round(100*nofStudentsCollegeReadySAT / nofStudentsContinued)
    let collegeReady = selectedTestKind === "ACT" ? collegeReadyACT: collegeReadySAT
    let collegeReadyInfoACT, collegeReadyInfoSAT, formattedLessonGrowth
    let collegeReadyColorThreshold = COLLEGE_READY_COLOR_THRESHOLD
    let postLessonsStarted = accuracyPostLesson == -1 ? false: true
    let NA = "N/A"

    let formattedLessonName, formattedNofStudentsStarted, formattedNofSudentsCompleted
    let formattedCollegeReady, formattedHintsOnIncorrect
    let isSummaryRow = false
    let isPreLessonShownAsNA = (category === "Reading" || category === "Science") ? true: false
    let isGrowthShownAsNA = isPreLessonShownAsNA

    if (keepOnTopDuringSort) {
      isSummaryRow = true
      id = "summary-row"
      formattedLessonName = <b>{lessonName}</b>
      collegeReadyColorThreshold = 101 // i.e. don't color format if summary row!
    }

    collegeReadyInfoACT = collegeReadyACT >= collegeReadyColorThreshold ? <span><span className="c-status-green">{collegeReadyACT}%</span><span className="act-or-sat-score-info">ACT</span></span> : <span>{collegeReadyACT}%<span className="act-or-sat-score-info">ACT</span></span>
    collegeReadyInfoSAT = collegeReadySAT >= collegeReadyColorThreshold ? <span><span className="c-status-green">{collegeReadySAT}%</span><span className="act-or-sat-score-info">SAT</span></span>  : <span>{collegeReadySAT}%<span className="act-or-sat-score-info">SAT</span></span>
    formattedLessonGrowth = lessonGrowth < 0 ? <span className="after--down-arrow">{lessonGrowth}%</span> : lessonGrowth + "%"
    if(lessonGrowth > 0) {
      formattedLessonGrowth = <span className="after--up-arrow">{lessonGrowth}%</span>
    } else if (lessonGrowth < 0) {
      formattedLessonGrowth = <span className="after--down-arrow">{lessonGrowth}%</span>
    } else {
      formattedLessonGrowth = 'N/A'
    }
    let collegeReadyInfo = selectedTestKind === "ACT" ? collegeReadyInfoACT: collegeReadyInfoSAT
    let preScoreInfo = selectedTestKind === "ACT" ? preLessonConversionScoreACT: preLessonConversionScoreSAT
    let postScoreInfo = selectedTestKind === "ACT" ? postLessonConversionScoreACT: postLessonConversionScoreSAT    
    let data = {
        lesson: {
          content: isSummaryRow ? formattedLessonName: <a href="#" className="link modal-link iconLink--left" onClick={e => this.showLessonBreakdown(lessonSummary, category)}>
            <img
              className="explanation-button-image"
              src={`/assets/images/icons/v2/ico-modal.svg`}
              width="18"
              height="18"
              alt=""
            />
            {lessonName}
          </a>,
          comparator: lessonName,
        }, 
        started: {
          content: nofStudentsStarted,
          comparator: nofStudentsStarted,
        },  
        completed: {
          content: nofStudentsCompleted,
          comparator: nofStudentsCompleted, 
        },
        collegeReady: {
          content: collegeReadyInfo,
          comparator: collegeReady,
        },
        preLessonAccuracy: {
          content: isPreLessonShownAsNA ? NA: this.prepLessonAccuracyInfo(accuracyPreLesson, selectedTestKind, preScoreInfo),
          comparator: accuracyPreLesson
        },
        postLessonAccuracy: {
          content: !postLessonsStarted ? NA: this.prepLessonAccuracyInfo(accuracyPostLesson, selectedTestKind, postScoreInfo),
          comparator: accuracyPostLesson,
        },
        lessonGrowth: {
          content: isGrowthShownAsNA ? NA: !postLessonsStarted ? NA : formattedLessonGrowth,
          comparator: lessonGrowth,
        },
        hintsOnIncorrect: {
          content: hintsOnIncorrect == -1 ? NA: hintsOnIncorrect + "%",
          comparator: hintsOnIncorrect,
        },
        timeOnQuestion: {
          content: this.prepTimeOnQuestionInfo(avgTimePerQuestion, totalTimeOnAnswered),
          comparator: avgTimePerQuestion,
        },
        totalStudyTime: {
          content: this.prepTotalStudyTimeInfo(totalTimeOnEnded),
          comparator: totalTimeOnEnded,
        },
        keepOnTopDuringSort: {
          content: keepOnTopDuringSort,
          comparator: 0
        }
    }

    return {
        id,
        data,
        isSummaryRow,
        summaryRowClassName: isSummaryRow ? "category-" + category: ""
    }
  }

  getCategoricalSummaryFromLessons = (lessonsSummaryMap: LessonsSummaryMap, category: string): LessonSummaryByCategory => {
    const avg = (sum, len) => len > 0 ? Math.round(sum/len): 0
    let {classSummariesByCategoryMap} = this.props
    let lessonNames = Object.keys(lessonsSummaryMap)
    let len = lessonNames.length
    let summary: LessonSummaryByCategory = {
      lessonId: "N/A",
      lessonName: "Summary",
      nofStudentsStarted: 0,
      nofStudentsContinued: 0,
      nofStudentsCompleted: 0,
      nofStudentsCollegeReadyACT: 0,
      nofStudentsCollegeReadySAT: 0,
      accuracyPostLesson: 0,
      accuracyPreLesson: 0,
      lessonGrowth: 0,
      hintsOnIncorrect: 0,
      totalTimeOnAnswered: 0,
      totalTimeOnEnded: 0,
      preLessonConversionScoreACT: "?",
      postLessonConversionScoreACT: "?",
      preLessonConversionScoreSAT: "?",
      postLessonConversionScoreSAT: "?",
      avgTimePerQuestion: 0,
      keepOnTopDuringSort: true // OT: Important and only used for summary info!
    }

    lessonNames.reduce((acc, cur) => {
      let lesson = lessonsSummaryMap[cur]
      acc.nofStudentsStarted += lesson.nofStudentsStarted
      acc.nofStudentsContinued += lesson.nofStudentsContinued
      acc.nofStudentsCompleted += lesson.nofStudentsCompleted
      acc.nofStudentsCollegeReadyACT += lesson.nofStudentsCollegeReadyACT
      acc.nofStudentsCollegeReadySAT += lesson.nofStudentsCollegeReadySAT
      return acc
    }, summary)
    
    // Use the class summary information directly here (this info used in the progress charts)!
    let categorySummary = classSummariesByCategoryMap[category]
    summary.accuracyPreLesson  = categorySummary.preLessonAccuracy
    summary.accuracyPostLesson = categorySummary.postLessonAccuracy
    summary.preLessonConversionScoreACT  = categorySummary.preLessonConversionScoreACT 
    summary.postLessonConversionScoreACT = categorySummary.postLessonConversionScoreACT 
    summary.preLessonConversionScoreSAT  = categorySummary.preLessonConversionScoreSAT 
    summary.postLessonConversionScoreSAT = categorySummary.postLessonConversionScoreSAT 
    summary.lessonGrowth = summary.accuracyPostLesson - summary.accuracyPreLesson
    summary.avgTimePerQuestion = categorySummary.avgTimePerQuestions
    summary.totalTimeOnAnswered = categorySummary.totalTimeOnAnswered
    summary.totalTimeOnEnded = categorySummary.totalTimeOnQuestions
    summary.hintsOnIncorrect = categorySummary.hintsOnIncorrects

    return summary
  }

  getCardBodyContent = (lessonsMap: LessonsSummaryMap, category) => {
    let nofLessons = Object.keys(lessonsMap).length    
    let extendedLessonsMap = lessonsMap
    
    if (nofLessons > 0) {
      let summary = this.getCategoricalSummaryFromLessons(lessonsMap, category)
      extendedLessonsMap = {summary, ...lessonsMap}
    }

    let keys = Object.keys(extendedLessonsMap) 
    let data = keys.map((key, index) => this.createLessonByLessonData(extendedLessonsMap[key], index, category))

    return (
        <DataGrid
            columns={resourceColumns}
            data={data}
            emptyText="No lessons have been started, continued or completed in this subject."
        />
    )
  }

  render() {
    let {lessonSummariesByCategoryMap, nofStudents, selections} = this.props
    let {modalShowing, lessonToBreakdown} = this.state    
    let studentInfo = <span>Showing Data for <b>{nofStudents}</b> {nofStudents == 1 ? "Student": "Students"}</span>
    let categoryKeys = Object.keys(lessonSummariesByCategoryMap)
    let defaultColor = "#10a492"
    let cssClassSubject = 'default'
    let cssClassColor = 'blue'
    switch(this.state.lessonColor) {
      case '#A256D7':
        cssClassSubject = 'english'
        cssClassColor = 'purple'
      break;
      case '#25B8D6':
        cssClassSubject = 'math'
        cssClassColor = 'blue'
      break;
      case '#F49100':
        cssClassSubject = 'reading'
        cssClassColor = 'orange'
      break;
      case '#8AC22D':
        cssClassSubject = 'science'
        cssClassColor = 'green'
      break;
    }
    return (
      <div className="lesson-by-lesson-summary">
        <div className="row align-items-center">
          <div className="col-5 lesson-summary-title h3">Lesson Summary</div>
          <div className="col-7 student-numbers-info">{studentInfo}</div>
        </div>
        <hr className="half" />
        <div className="">
          {categoryKeys.map(category => {
            let lessonSummariesMap = lessonSummariesByCategoryMap[category]
            let iconPath = `/assets/images/icons/subjects/ico-subject-${category.replace(/\s/g, '').toLowerCase()}.svg`
            let si = getSubjectIcon(category)
            let color = si ? si.color: defaultColor 
            return (
              <CollapsableCard key={category}>
                  <CollapsableCardHeader>
                      <ResourceListCardHeader
                          text={category}
                          color={color}
                          icon={iconPath}
                      />
                  </CollapsableCardHeader>
                  <CollapsableCardBody> 
                      {this.getCardBodyContent(lessonSummariesMap, category)}
                  </CollapsableCardBody>
              </CollapsableCard>
            )}
          )}
        </div>

        <Modal show={modalShowing} onClose={this.closeModal} className={`lesson-breakdown-container--modal ${cssClassColor}-header theme-modal theme-modal--${cssClassSubject}`}>
          <div className="lesson-breakdown-container--modal-header">
              <button type="button" className="modal-close" title="Close Modal" aria-label="Close" onClick={this.closeModal}>
                <img
                    className="modal-icon"
                    src={`/assets/images/icons/v2/ico-modal-close.svg`}
                    width="24"
                    height="24"
                    alt=""
                />
              </button>
            </div>
          { lessonToBreakdown && <LessonBreakdownView subjectColor={this.state.lessonColor} lesson={lessonToBreakdown} selections={selections} nofTotalStudents={nofStudents}/>}
        </Modal>
      </div>
    )
  }
}
