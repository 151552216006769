import React from "react"
import { IResilienceReminderWeeklyContent } from "./types"
import { CDN_PUB_URL } from "../../../constants"
import { ResilienceReminders } from "."

/**
 * Weekly content for a grade level which will display the "Skill of the Week":
 *  + Category (with icon and the textual info)
 *  + Title of the topic
 *  + An animated image (either we will use our CDN or directly from Giphy)
 *  + Summary (like an abstract)
 *  + Read Aloud Section
 *  + Questions Section
 *  + Copyright Section (each week there could be new copyright info based on the image used)
 * 
 * @param props weekly content
 * @returns 
 */
export default function ResilienceReminderWeeklyContentView(props: {content: IResilienceReminderWeeklyContent}) {
    let {content} = props
    let {name, category, subCategory, image, summary, readAloud, questions, copyright} = content
    let imageUrl = `${CDN_PUB_URL}/resilience-reminders/images/${image}`
    let readAloudHtml = simpleText2Html(readAloud)
    let questionsHtml = simpleText2Html(questions, "rr-question")

    // Function to process strings
    let processString = (str) => {
        return str
            .toLowerCase()
            .replace(/ /g, '-') // Replace spaces with hyphens
            .replace(/\//g, '-'); // Replace slashes with hyphens
    }

    // Processed category and subCategory strings
    let processedCategory = processString(category)
    let processedSubCategory = processString(subCategory)

    let handleModalClose = () => {
        ResilienceReminders.closeModal()
    }

    return (
        <div className={`rr-weekly-container rr-theme--` + processedCategory}>
            <div className="rr-modal-header rr-modal-header--">
                <div className="h2 mb-0">Skill of the Week</div>
                <button className="closeBtn btn-simple" title="Close modal" onClick={handleModalClose}>
                    <img
                        className="modal-icon"
                        src={`/assets/images/icons/v2/ico-modal-close.svg`}
                        width="24"
                        height="24"
                    />
                </button>
            </div>
            <div className="rr-modal-content">
                <span className="rr-weekly-category">
                    <div className="iconBlock">
                        <img src={`/assets/images/icons/v2/resilience/ico_rr_` + processedCategory + `.svg`} className="" alt="" width="auto" height="20" />
                    </div>
                     {category.toUpperCase()}
                </span>
                {subCategory &&
                    <span className={`rr-weekly-sub-category rr-theme--` + processedSubCategory}>
                        <div className="iconBlock">
                            <img src={`/assets/images/icons/v2/resilience/ico_rr_` + processedSubCategory + `.svg`} className="" alt="" width="auto" height="20" />
                        </div>
                        {subCategory.toUpperCase()}
                    </span>
                }
                <h3>{name}</h3>
                <div className="rr-weekly-banner mb-3">
                    <img src={imageUrl} width="100%" height="auto" alt="" />
                </div>
                <p className="rr-weekly-summary">
                    <i>{summary}</i>
                </p>
                <div className="rr-weekly-read-aloud">
                <div className="titleBlock h4"><img src={`/assets/images/icons/v2/ico-read.svg`} className="mr-2" alt="" width="39" height="36" /> Read Aloud</div>
                    <div className="rr-readaloud-items" dangerouslySetInnerHTML={{__html: readAloudHtml}} />
                </div>
                <div className="rr-weekly-questions">
                    <div className="titleBlock h4"><img src={`/assets/images/icons/v2/ico-questions.svg`} className="mr-2" alt="" width="51" height="39" /> Questions</div>
                    <div  className="rr-question-items" dangerouslySetInnerHTML={{__html: questionsHtml}} />
                </div>
                <div className="rr-weekly-copyright">
                    {copyright}
                </div>
            </div>
        </div>
    )
}


/**
 * Provides a standard html representation from text given that text is delimited with line feeds.
 * Uses className as the CSS class for the <p> tags (optional, might be empty)
 * @param text 
 * @param className 
 * @returns 
 *
 * Original:
function simpleText2Html(text: string, className="") {
    let lines = text.split(/[\n]+/)
    let html = lines
        .map(line => line.trim())
        .filter(line => line !== "" && line !== "\n")
        .map(line => {
            return `<p class=${className}>${line}</p>`
        })
        .join(" ")

    return html
}
*/

/* account for Lists in data */

function simpleText2Html(text, className = "") {
    let lines = text.split(/[\n]+/);
    let html = "";
    let isOrderedList = false;
    let isUnorderedList = false;
    let inListItem = false;

    lines.forEach((line) => {
        line = line.trim();

        // detect OL in line
        if (/^\d+\.\s/.test(line)) {
            if (!isOrderedList) {
                if (isUnorderedList) {
                    html += `</ul>`;
                    isUnorderedList = false;
                }
                html += `<ol>`;
                isOrderedList = true;
            }
            if (inListItem) {
                html += `</li>`;
                inListItem = false;
            }
            html += `<li>${line.replace(/^\d+\.\s/, '')}`;
            inListItem = true;
        }
        // detect UL in line
        else if (/^\*\s/.test(line)) {
            if (!isUnorderedList) {
                if (isOrderedList) {
                    html += `</ol>`;
                    isOrderedList = false;
                }
                html += `<ul>`;
                isUnorderedList = true;
            }
            if (inListItem) {
                html += `</li>`;
                inListItem = false;
            }
            html += `<li>${line.replace(/^\*\s/, '')}`;
            inListItem = true;
        }
        // normal P with List item
        else if (inListItem && line !== "") {
            html += `<p class="${className}">${line}</p>`;
        }
        // normal P outside List
        else {
            if (inListItem) {
                html += `</li>`;
                inListItem = false;
            }
            if (isOrderedList) {
                html += `</ol>`;
                isOrderedList = false;
            }
            if (isUnorderedList) {
                html += `</ul>`;
                isUnorderedList = false;
            }
            if (line !== "" && line !== "\n") {
                html += `<p class="${className}">${line}</p>`;
            }
        }
    });
    // ensure closure List
    if (inListItem) {
        html += `</li>`;
    }
    if (isOrderedList) {
        html += `</ol>`;
    }
    if (isUnorderedList) {
        html += `</ul>`;
    }
    return html;
}