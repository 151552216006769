import './ImageTest.css';

import * as React from 'react';

import { PageContent, PageTitle } from '../../components/PageContent/PageContent';

import { RouteComponentProps } from 'react-router-dom';
import { parse } from 'query-string';

interface Props {

}

type AllProps = Props & RouteComponentProps<any>;

interface Image {
    dir: string;
    images: string[];
}

const IMAGES: Image[] = [
    {
        dir: 'Experiment 1',
        images: ['Figures & Tables_1_1.png']
    },
    {
        dir: 'Experiment 2',
        images: ['Figures & Tables_2_1.png', 'Figures & Tables_2_2.png', 'Figures & Tables_2_3.png']
    },
    {
        dir: 'Experiment 3',
        images: ['Figures & Tables_3_1.png', 'Figures & Tables_3_2.png', 'Figures & Tables_3_3.png']
    },
    {
        dir: 'Experiment 4',
        images: ['Figures & Tables_4_1.png', 'Figures & Tables_4_2.png', 'Figures & Tables_4_3.png']
    },
    {
        dir: 'Experiment 5',
        images: ['Figures & Tables_5_1.png', 'Figures & Tables_5_2.png', 'Figures & Tables_5_3.png']
    },
    {
        dir: 'Passage 1',
        images: ['ACT & SAT Reading Passages Overview_1_1.png', 'ACT & SAT Reading Passages Overview_1_2.png']
    },
    {
        dir: 'Passage 2',
        images: ['ACT & SAT Reading Passages Overview_2_1.png']
    },
    {
        dir: 'Passage 3',
        images: ['ACT & SAT Reading Passages Overview_3_1.png']
    },
];

export default class ImageTest extends React.Component<AllProps, {}> {
    constructor(props: AllProps) {
        super(props);
    }

    public render() {
        const dir = parse(this.props.location.search);
        dir.dir = dir.dir || IMAGES[0].dir;
        const image = IMAGES.filter(img => img.dir === dir.dir)[0];
        if(!image) { return null; }

        const baseUrl = `/assets/images/test/`;
        return (
            <PageContent>
                <PageTitle title="Passage Image Test" />

                <div className="image-test">
                    <div className="image-test__container">
                        {
                            image.images.map(img =>
                                <img key={img} src={`${baseUrl}${image.dir}/${img}`} />)
                        }
                    </div>

                    <div className="image-test__questions">
                    </div>
                </div>
            </PageContent>
        );
    }

}
