import * as React from 'react'
import DataGrid from '../../components/DataGrid'
import { Column } from '../../components/DataGrid/types/types'
import { sortStr } from '../../services/utils/text-util'
import "./index.css"

export interface ITip {
    resource: string
    description: string
    link: string
}

interface Props {
    title: string
    subtitle?: string
    tips: ITip[]
}

interface State { }

const INFO_EMPTY = "No tips to display!"

const columns: Column[] = [
    {
        title: 'RESOURCE',
        dataProperty: 'resource',
        width: '30%',
        sortMethod: sortStr,
        customTooltip: 'Indicates the name of the resource'
    },
    {
        title: 'DESCRIPTION',
        dataProperty: 'description',
        width: '65%',
        sortMethod: sortStr,
        customTooltip: 'Indicates the description of the resource'
    },
    {
        title: 'LINK',
        dataProperty: 'link',
        sortable: false,
        customTooltip: 'Opens resource in new tab'
    },
]

/**
 * Tips view that shows the varios tips with links
 */
export class Tips extends React.Component<Props, State> {
    private createLink = (data: ITip) => {
        let { resource, link } = data

        return () => (
            <div className="d-flex h-100 align-items-center justify-content-center">
                <a
                    href={link}
                    className="download-icon--link vertical-center"
                    target="_blank"
                    title={`View the resource "${resource}"`}>
                    <img
                        src="/assets/images/icons/v2/ico-external-link.svg"
                        width="18"
                        height="18"
                    />
                </a>
            </div>
        )
    }

    private createTipsData = (data: ITip, idx: number) => {
        const ecd = {
            resource: {
                content: data.resource,
                comparator: data.resource,
                subComparator: ""
            },
            description: {
                content: data.description,
                comparator: data.description,
                subComparator: ""
            },
            link: {
                content: this.createLink(data),
                comparator: data.link,
                subComparator: ""
            }
        }
        return {
            data: ecd,
            id: `tips-${idx}`
        }
    }

    render() {
        let { title, subtitle, tips } = this.props
        let data = tips.map(this.createTipsData)

        return (
            <div className="tips-container">
                <div className="tips-info-container">
                    <div className="tips-info">
                        <DataGrid
                            columns={columns}
                            className={"tips-table"}
                            data={data}
                            emptyText={INFO_EMPTY}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
