import './DiagnosticMaterials.css';

import * as React from 'react';

import { Link, RouteComponentProps } from 'react-router-dom';

import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import BrightcoveVideo from '../../../../components/BrightcoveVideo/BrightcoveVideo';
import { PageContent } from '../../../../components/PageContent/PageContent';
import Loading from '../../../../components/Loading/Loading';
import DiagnosticTimingRow from '../DiagnosticTiming/DiagnosticTimingRow/DiagnosticTimingRow';

interface Props {
    testName: string;
}

interface State {
    showConfirmationModal: boolean;
    link: string;
    test: string;
    isSAT: boolean;
    isDiagnostic: boolean;
    videoId: string;
    showLoading: boolean;
    showVideoInModal:boolean;
 }

type AllProps = Props & RouteComponentProps<any>;

export class DiagnosticMaterials extends React.Component<AllProps, State> {

    videoRef = React.createRef<BrightcoveVideo>()

    showVideo = (videoId) => {
        this.setState({showVideoInModal: true, videoId})

        // OT: Loading the video causes flashing, so I first cover it...
        // and after a short time I reveal the video in order to prevent flashing!
        setTimeout(() => {
            this.setState({showLoading: false})
        }, 500)
    }

    handleOnModalClose = () => {
        this.setState({showVideoInModal: false, showLoading: true})
        this.videoRef.current?.pause()
    }

    constructor(props: AllProps) {
        super(props);
        const currentUrl = window.location.href;
        const endingPath = currentUrl.substr(currentUrl.lastIndexOf('/') + 1);
        this.state = {
            showConfirmationModal: false,
            link: props.testName === 'diagnostic' ?
            `/app/practice-tests/instructions/timing/${endingPath}` :
            `/app/practice-tests/instructions/timing/${endingPath}`,
            test: window.location.href.substr(window.location.href.lastIndexOf('/') + 1),
            isSAT: false,
            isDiagnostic: (window.location.href.includes('diagnostic')) ? true : false,
            videoId:'',
            showLoading: false,
            showVideoInModal: false
        };
    }

    public render() {
        const urlHash = window.location.hash;
        const fullUrl = window.location.href;
        let currentUrl;
        urlHash ? currentUrl = fullUrl.replace(urlHash, '') : currentUrl = fullUrl;
        let currentTestName = currentUrl.substr(currentUrl.lastIndexOf('/') + 1);
        const imageBasePath = `/assets/images/icons/diagnostic`;
        let type = 'ACT';
        let isSAT = false;
        let isDiagnostic = false;
        let selectedVideoId = this.state.videoId
        let desiredWidth = "80%"
        let bigPlayerStyle = {
            width: '100%',
            maxWidth: '100%',
            height: 'auto'
        }

        if( currentTestName !== 'Diagnostic' 
        && currentTestName !== 'ACT1'
        && currentTestName !== 'ACT2'
        && currentTestName !== 'ACT3'
        && currentTestName !== 'ACT4'
        && currentTestName !== 'ACT5'
        && currentTestName !== 'ACT6'
        && currentTestName !== 'ACT7'
        ) {
            type = 'SAT';
            isSAT = true;
        }
        const endingPath = currentUrl.substr(currentUrl.lastIndexOf('/') + 1);
        let link = `/app/practice-tests/scoring/${endingPath}`;

        if(currentUrl.toLowerCase().includes('diagnostic')) {
            isDiagnostic = true;
            link = `/app/practice-tests/scoring/Diagnostic`;
        }

        if(this.state.test === 'DiagnosticSAT') {
            link = `/app/practice-tests/scoring/DiagnosticSAT`;
        }

        let testDisplayName = '';
        let testNumber;

        if(currentTestName === 'Diagnostic') {
            testDisplayName = 'the ACT Diagnostic';
        } else if(currentTestName === 'DiagnosticSAT') {
            testDisplayName = 'the SAT Diagnostic';
        } else {
            testNumber = currentTestName.slice(-1);
            testDisplayName = currentTestName.substring(0, currentTestName.length - 1) + ' Test ' + testNumber;
        }

        let ctaButtonClass = 'btn btn--text-white btn--bg-blue mx-md-1 mt-2'
        let ctaButtonClassAlt = 'btn btn--text-white btn--bg-green mx-md-1 mt-2'
        let ctaRegularSuffix = 'REGULAR TIME'
        let ctaExtendedSuffix = '1.5 EXTENDED TIME'
        const [wyneeds,setWyn] = new Array([
            {name: "No 2 pencils", img: "pencil.svg"},
            {name: "Calculator", img: "calculator.svg"},
            {name: "Timer or stopwatch", img: "timer.svg"},
            {name: "Printed exam", img: "exam.svg"},
            {name: "Printed scantron", img: "scantron.svg"},
            {name: "Quiet space", img: "lamp.svg"}
        ]);
        return (
                <PageContent>
                    <div className="combined-instructions">
                        <div className="row mb-4">
                            <div className="col-12">
                                <div className="diagnostic-instructions inst-block-wrapper row no-gutters">
                                    <div className="col-lg-4 order-lg-2">
                                    {
                                        type === 'ACT' &&
                                        <div className="act-logo-container">
                                            <p className="act-logo-holder">
                                                <img src="/assets/images/badge-act.png" alt="ACT Logo" />
                                            </p>
                                        </div>
                                    }
                                    {
                                        type === 'SAT' &&
                                        <div className="act-logo-container">
                                            <p className="act-logo-holder">
                                                <img src="/assets/images/badge-sat.png" alt="SAT Logo" />
                                            </p>
                                        </div>
                                    }
                                    </div>
                                    <div className="col-lg-8">
                                        <h2 className="fw-700 text-center text-md-left">Steps to Get Ready for {testDisplayName}</h2>
                                        <ul className="instruction-list">
                                            {type === 'ACT' && currentTestName !== 'Diagnostic' &&
                                                <li className="list-check">Print the scantron and open the ACT Official Guide to the appropriate test. <a target="_blank" href="https://www.winwardacademy.com/contact/" title="Contact Us">Contact us</a> if you have trouble locating the ACT practice exam</li>
                                            }
                                            {type === 'ACT' && currentTestName === 'Diagnostic' &&
                                                <li className="list-check">Print the exam and scantron by clicking the buttons below</li>
                                            }
                                            {type === 'SAT' &&
                                                <li className="list-check">Print the exam and scantron by clicking the buttons below</li>
                                            }
                                            <li className="list-check">Find a quiet space to emulate real testing conditions</li>
                                            <li className="list-check">Self-proctor by following along with the video guides or section times provided below</li>
                                            <li className="list-check">Put a star next to any question on which you guess, so you remember to go back and review</li>
                                        </ul>
                                        { type === 'SAT' && isDiagnostic &&
                                            <div className="text-center text-md-left">
                                                <a className="btn btn--text-white btn--bg-blue mr-sm-2 mx-md-1 mt-2" href={`/assets/sat/SATDiagnostic.pdf`} target="_blank">PRINT THE EXAM</a>
                                                <a className="btn btn--text-white btn--bg-green mx-md-1 mt-2" href={`/assets/sat/SATScantron.pdf`} target="_blank">DOWNLOAD SCANTRON</a>
                                            </div>
                                        }
                                        { type === 'SAT' && !isDiagnostic &&
                                            <div className="text-center text-md-left">
                                            <a className="btn btn--text-white btn--bg-blue mr-sm-2 mx-md-1 mt-2" href={`/assets/sat/${currentTestName}.pdf`} target="_blank">PRINT THE EXAM</a>
                                            <a className="btn btn--text-white btn--bg-green mx-md-1 mt-2" href={`/assets/sat/SATScantron.pdf`} target="_blank">DOWNLOAD SCANTRON</a>
                                            </div>
                                        }
                                        { type === 'ACT' && isDiagnostic &&
                                            <div className="text-center text-md-left">
                                                <a className="btn btn--text-white btn--bg-blue mr-sm-2 mx-md-1 mt-2" href={`/assets/home-pdfs/ACT2018Diagnostic.pdf`} target="_blank">PRINT THE EXAM</a>
                                                <a className="btn btn--text-white btn--bg-green mx-md-1 mt-2" href={`/assets/home-pdfs/ACTScantron.pdf`} target="_blank">DOWNLOAD SCANTRON</a>
                                            </div>
                                        }
                                        { type === 'ACT' && !isDiagnostic &&
                                            <div className="text-center text-md-left">
                                                <a href="/assets/home-pdfs/ACTScantron.pdf" target="_blank" className="btn btn--text-white btn--bg-blue mx-1">DOWNLOAD SCANTRON</a>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-4">
                        <div className="col-xl-4 col-tools mb-4 mb-xl-0">
                                <div className="diagnostic-instructions inst-block-wrapper">
                                    <div className="row h-100 no-gutters">
                                    <h2 className="text-center fw-700 mb-2 mb-md-4 w-100">What You'll <br className="d-block d-md-none" />Need</h2>
                                        {wyneeds.map( (wyn,index)=> (
                                            <div className="col-sm-6 col-lg-4 col-xl-12 mb-4 material-card">
                                                <div className="tool-icons">
                                                    <div className="tool-icons--iconWrapper wa-blue-background">
                                                        <img src={`/assets/images/icons/diagnostic/` + wyn.img} className="diagnostic-icon" />
                                                    </div>
                                                    <p className="tool-icons--label pr-md-3 pr-xl-0">{wyn.name}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-guides mb-3 mb-xl-0">
                                <div className="diagnostic-instructions inst-block-wrapper">
                                    {type !== "SAT" &&
                                    <div className="text-center text-xl-left">
                                        <h2 className="fw-700 py-0">Proctoring Video Guides</h2>
                                        <p className="mb-4">If you're looking to follow along with a proctoring video that times each section, gives 5 minute warnings, and provides tips along the way, use the regular or 1.5x extended time guides:</p>
                                    </div>
                                    }

                                    <div className="mb-5 text-center text-xl-left">
                                        { type === 'ACT' && isDiagnostic &&
                                            <div>
                                                <button className={ctaButtonClass} onClick={() => this.showVideo("6268507970001")}>ACT {ctaRegularSuffix}</button>
                                                <button className={ctaButtonClassAlt} onClick={() => this.showVideo("6268505297001")}>ACT {ctaExtendedSuffix}</button>
                                            </div>
                                        }
                                        { type === 'ACT' && !isDiagnostic &&
                                            <div>
                                                <button className={ctaButtonClass} onClick={() => this.showVideo("6268507970001")}>ACT {ctaRegularSuffix}</button>
                                                <button className={ctaButtonClassAlt} onClick={() => this.showVideo("6268505297001")}>ACT {ctaExtendedSuffix}</button>
                                            </div>
                                        }
                                    </div>

                                    <div>
                                    <h2 className="fw-700">Guide Timelines</h2>
                                        { type === 'SAT' &&
                                            <div className="row no-gutters">
                                                <div className="col-12">
                                                    <div className="timing-table  mb-3 mb-xl-5">
                                                        <div className="timing-table--inner wide">
                                                            <div className="row black no-gutters timing-table--header">
                                                                <div className="col-first" style={{borderBottom: "2px solid #C7CACA"}}>
                                                                    <p className="fw-700 mb-none section-header">EXAM TIMING</p>
                                                                </div>
                                                                <div className="col" style={{borderBottom: "2px solid #f29000"}}>
                                                                    <p className="mb-none section-header">R&W Module 1</p>
                                                                </div>
                                                                <div className="col" style={{borderBottom: "2px solid #9d59cf"}}>
                                                                    <p className="mb-none section-header">R&W Module 2</p>
                                                                </div>
                                                                <div className="col col-break" style={{borderBottom: "2px solid #0E5682"}}>
                                                                    <p className="mb-none section-header">BREAK</p>
                                                                </div>
                                                                <div className="col" style={{borderBottom: "2px solid #31b8d4"}}>
                                                                    <p className="mb-none section-header">Math Module 1</p>
                                                                </div>
                                                                <div className="col" style={{borderBottom: "2px solid #31b8d4"}}>
                                                                    <p className="mb-none section-header">Math Module 2</p>
                                                                </div>
                                                            </div>
                                                            <div className="row no-gutters flex-no-wrap timing-table--row">
                                                                <div className="col-first">
                                                                    <p className="timing-table--label">Regular Time</p>
                                                                </div>
                                                                <div className="col">
                                                                    <p className="timing-table--value">39min</p>
                                                                </div>                                                               
                                                                <div className="col">
                                                                    <p className="timing-table--value">39min</p>
                                                                </div>
                                                                <div className="col col-break">
                                                                    <p className="timing-table--value">10min</p>
                                                                </div>
                                                                <div className="col">
                                                                    <p className="timing-table--value">43min</p>
                                                                </div>
                                                                <div className="col">
                                                                    <p className="timing-table--value">43min</p>
                                                                </div>
                                                            </div>
                                                            <div className="row no-gutters flex-no-wrap timing-table--row last">
                                                                <div className="col-first">
                                                                <p className="timing-table--label">1.5 Extended Time</p>
                                                                </div>
                                                                <div className="col">
                                                                    <p className="timing-table--value">59min</p>
                                                                </div>
                                                                <div className="col">
                                                                    <p className="timing-table--value">59min</p>
                                                                </div>
                                                                <div className="col col-break">
                                                                    <p className="timing-table--value">15min</p>
                                                                </div>
                                                                <div className="col">
                                                                    <p className="timing-table--value">65min</p>
                                                                </div>                                                                
                                                                <div className="col">
                                                                    <p className="timing-table--value">65min</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row no-gutters">
                                                        <div className="">
                                                            <p className="">
                                                                Use your break wisely to recharge. Get a snack, use the restroom, wash your face with cold water, do jumping jacks, whatever you need to stay focused.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        { type === 'ACT' &&
                                            <div className="row no-gutters">
                                                <div className="col-12">
                                                    <div className="timing-table mb-3 mb-xl-5">
                                                        <div className="timing-table--inner">
                                                            <div className="row black no-gutters timing-table--header">
                                                                <div className="col-first" style={{borderBottom: "2px solid #C7CACA"}}>
                                                                    <p className="mb-none section-header">EXAM TIMING</p>
                                                                </div>
                                                                <div className="col" style={{borderBottom: "2px solid #9d59cf"}}>
                                                                    <p className="mb-none section-header">ENGLISH</p>
                                                                </div>
                                                                <div className="col" style={{borderBottom: "2px solid #31b8d4"}}>
                                                                    <p className="mb-none section-header">MATH</p>
                                                                </div>
                                                                <div className="col" style={{borderBottom: "2px solid #0E5682"}}>
                                                                    <p className="mb-none section-header">BREAK</p>
                                                                </div>
                                                                <div className="col" style={{borderBottom: "2px solid #f29000"}}>
                                                                    <p className="mb-none section-header">READING</p>
                                                                </div>
                                                                <div className="col" style={{borderBottom: "2px solid #78b821"}}>
                                                                    <p className="mb-none section-header">SCIENCE</p>
                                                                </div>
                                                            </div>
                                                            <div className="row no-gutters flex-no-wrap timing-table--row">
                                                                <div className="col-first">
                                                                    <p className="timing-table--label">Regular Time</p>
                                                                </div>
                                                                <div className="col">
                                                                    <p className="timing-table--value">45min</p>
                                                                </div>
                                                                <div className="col">
                                                                    <p className="timing-table--value">60min</p>
                                                                </div>
                                                                <div className="col col-break">
                                                                    <p className="timing-table--value">10min</p>
                                                                </div>
                                                                <div className="col">
                                                                    <p className="timing-table--value">35min</p>
                                                                </div>
                                                                <div className="col">
                                                                    <p className="timing-table--value">35min</p>
                                                                </div>
                                                            </div>
                                                            <div className="row no-gutters flex-no-wrap timing-table--row last">
                                                                <div className="col-first">
                                                                <p className="timing-table--label">1.5 Extended Time</p>
                                                                </div>
                                                                <div className="col">
                                                                    <p className="timing-table--value">70min</p>
                                                                </div>
                                                                <div className="col">
                                                                    <p className="timing-table--value">90min</p>
                                                                </div>
                                                                <div className="col col-break">
                                                                    <p className="timing-table--value">15min</p>
                                                                </div>
                                                                <div className="col">
                                                                    <p className="timing-table--value">55min</p>
                                                                </div>
                                                                <div className="col">
                                                                    <p className="timing-table--value">55min</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row no-gutters">
                                                        <div className="">
                                                            <p className="">
                                                                <b>You only have one break</b>, so use it wisely to recharge and focus on the-back-to-back reading and science sections. Get a snack, use the restroom, wash your face with cold water, do jumping jacks, whatever you need to recharge and stay focused for the second half.
                                                            </p>
                                                            <p className="mb-0">
                                                                If you're taking the ACT without Writing, you're done after Science. If you're taking the ACT with Writing, then you would complete the 40 minute essay (60 minutes for 1.5x extended time).
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="diagnostic-instructions inst-block-wrapper">
                                    <div className="diagnostic-button-container">
                                        <h2 className="text-center fw-700">Good luck on your practice exam!</h2>
                                        <p>When you're done taking the exam, you're ready to enter your answers.  You'll get immediate feedback on whether your answer is correct (green) or incorrect (red) with a running total of your accuracy and score. Every question is broken down by lesson and topic with a step-by-step explanation video.</p>
                                        <Button text={`READY TO ENTER ANSWERS`} onClick={e => this.popConfirmationModal(`${link}`)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal
                        className="heading-banner-tc modal diagnostic-confirmation-modal"
                        show={this.state.showConfirmationModal}
                        onClose={this.closeConfirmationModal}
                    >
                        <div className="row">
                        <div className="col-lg-12 text-center">
                                <h2 className="cushion-element-tc">Quick Check</h2>
                            </div>
                            <div className="body-tc container-fluid coverBottom-tc text-center">
                                <p>Have you completed the exam and filled out your printed scantron with your answers?</p>
                                <div>
                                    <Button className="btn btn-info no-wrap" text="NO, GO BACK" onClick={this.closeConfirmationModal} />
                                    <Button className="btn btn--bg-green btn--text-white continue-btn no-wrap" text="YES, CONTINUE" bgColor="green" onClick={e => this.closeAndNext(link)} />
                                </div>

                            </div>
                        </div>
                    </Modal>
                    <Modal
                        show={this.state.showVideoInModal}
                        width={desiredWidth}
                        showXTopRight={true}
                        onClose={() => this.handleOnModalClose()}>
                            <div style={{position:"relative"}}>
                                <BrightcoveVideo 
                                    ref = {this.videoRef}
                                    id={`video-in-modal-${selectedVideoId}`}
                                    videoId={selectedVideoId}
                                    style={bigPlayerStyle}
                                />
                                {this.state.showLoading &&
                                    <div style={{position:"absolute",
                                        height: "100%",
                                        width: "100%",
                                        top: 0,
                                        zIndex: 1,
                                        backgroundColor: "white"}}>
                                        <div style={{position: "absolute", top: "42%", left: "50%"}}>
                                            <div style={{position: "relative", left: "-50%", top: "-50%"}}>
                                                <Loading />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                    </Modal>
                </PageContent>
            );
    }

    closeAndNext = (link) => {
        this.setState({
            showConfirmationModal: false
        }, () => {
            this.props.history.push(link)
        })
    }

    popConfirmationModal = (link: string) => {
    //   if (this.props.testName === 'diagnostic' || this.props.testName === 'DiagnosticSAT') {
        this.setState({
          showConfirmationModal: true
        });
    //   } else {
    //     this.props.history.push(link);
    //   }
    }

    closeConfirmationModal = () => {
        this.setState({
            showConfirmationModal: false
        });
    }

    routeTo = (path: string) => {
        let isSAT = path === 'diagnostic' ? false : true;
        let currUrl = window.location.href;
        let isDiagnostic = currUrl.includes('diagnostic') ? true : false;
        this.setState({
            isSAT: isSAT,
            isDiagnostic: isDiagnostic
        }, () => {
            this.props.history.push(path);
        });
    }
}
