import './Lessons.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import { LessonView, getLessonViewEnum } from './components/LessonViewSwitch/LessonViewSwitch';

import { CURRICULUM_LIST } from '../../services/elearn/lesson-types';
import ComingSoon from '../../components/ComingSoon/ComingSoon';
import CompletedView from './components/CompletedView/CompletedViewContainer';
import LessonTabs from './components/LessonTabs/LessonTabs';
import Modal from '../../components/Modal/Modal';
import { PageContent, PageTitle } from '../../components/PageContent/PageContent';
import { RouteComponentProps } from 'react-router-dom';
import SubjectView from './components/SubjectView/SubjectViewContainer';
import { Tab } from '../../components/Tabs/Tabs';
import ConversionModal from '../../components/ConversionModal/ConversionModal';
import { updateLessonView, UserInfo } from '../../services/account/account-rest-interface';
import { hasSchoolSafety } from '../../services/account/account-utils';
import PriorityLessonsView from './PriorityLessonsView';

export interface Props extends RouteComponentProps<any> {
    isTeacher: boolean;
    lessonDashView: string;
}

export interface DispatchProps {
    setUserInfo: (userInfo: Partial<UserInfo>) => void;
    userInfo: UserInfo;
}

export interface State {
    selectedView: LessonView;
    showEnglishModal: boolean;
    showMathModal: boolean;
    showReadingModal: boolean;
    showScienceModal: boolean;
    frequency: string;
    showEmailNotificationModal: boolean;
    showConversionModal: boolean;
    showPriorityLessons: boolean;
}

type AllProps = Props & DispatchProps;


export class LessonsWithoutRouting extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props);

        // if (this.props.lessonDashView === 'SAT') {
        //     this.updateLocalFrequency('SAT');
        // }
        this.state = {
            selectedView: getLessonViewEnum('subject'),
            showEnglishModal: false,
            showMathModal: false,
            showReadingModal: false,
            showScienceModal: false,
            frequency: this.props.lessonDashView || 'ACT',
            showEmailNotificationModal: false,
            showConversionModal: false,
            showPriorityLessons: false
        };
    }

    public shouldComponentUpdate(nextProps: Props, nextState: State) {
        return nextState.selectedView !== this.state.selectedView ||
        nextState.showEnglishModal !== this.state.showEnglishModal ||
        nextState.showMathModal !== this.state.showMathModal ||
        nextState.showReadingModal !== this.state.showReadingModal ||
        nextState.showScienceModal !== this.state.showScienceModal ||
        nextState.showConversionModal !== this.state.showConversionModal ||
        nextState.showEmailNotificationModal !== this.state.showEmailNotificationModal ||
        nextState.showPriorityLessons !== this.state.showPriorityLessons;
    }

    public render() {
        const tabs = CURRICULUM_LIST
            .map(this.createLessonTab);

        const englishMathReadingActSatCopy = ` We analyzed 85 real ACT and SAT tests, categorized every question by lesson and topic, and identified the most frequently tested concepts. Please prioritize your studying based on the frequencies below.`;
        const scienceActSatCopy = ` We analyzed 65 real ACT tests, categorized every question by lesson and topic, and identified the most frequently tested concepts in the ACT Science section. Now you'll know what types of questions to expect and how frequently they appear.`;        
        const {showPriorityLessons} = this.state
        const {isTeacher} = this.props

        return (
            <PageContent className="lessons-container">
                <PageTitle title="My Lessons">
                        <div className="navigation-button-container">
                            {!hasSchoolSafety(this.props.userInfo) && 
                                <>
                                    {!isTeacher && 
                                        <button
                                            className="btn btn--text-white btn--bg-blue title-navigation-buttons"
                                            onClick={this.showPriotityLessons}
                                            >
                                            VIEW PRIORITIZED LESSONS
                                        </button>
                                    }
                                    &nbsp;
                                    <button
                                        className={`btn btn--text-white title-navigation-buttons btn--bg-${isTeacher ? 'blue' : 'green'}`}
                                        onClick={this.showConversionPopup}
                                        >
                                        CONVERSION TABLE
                                    </button>
                                </>
                            }      
                        </div>
                </PageTitle>

                <ConversionModal show={this.state.showConversionModal} onClose={this.closeConversionPopup} />
                
                <Modal show={showPriorityLessons} className="PriorityLessonsView--modal" onClose={this.closePriorityLessons} width="100%">
                    <div className="lesson-breakdown-container--modal-header">
                        <button type="button" className="modal-close" title="Close Modal" aria-label="Close" onClick={this.closePriorityLessons}>
                            <img
                                className="modal-icon"
                                src={`/assets/images/icons/v2/ico-modal-close.svg`}
                                width="24"
                                height="24"
                            />
                        </button>
                        </div>
                    { showPriorityLessons && <PriorityLessonsView {...this.props}/> }
                </Modal>

                <Modal onClose={this.closeModal} show={this.state.showEmailNotificationModal}>
                    <div className="row text-center">
                        <div className="col-12">
                            <h3 className="frequency-modal__header">Email Sent!</h3>
                            <p className="frequency-modal__info-text">The full lesson report for your students is on its way and will arrive via email in the next 2-3 minutes.</p>
                        </div>
                    </div>
                </Modal>

                <LessonTabs
                    onViewChange={this.handleViewChange}
                    selectedView={this.state.selectedView}
                    openEnglishModal={this.openEnglishModal}
                    openMathModal={this.openMathModal}
                    openReadingModal={this.openReadingModal}
                    openScienceModal={this.openScienceModal}
                    changeFrequency={this.changeFrequency}
                    isTeacher={this.props.isTeacher}
                    selectedFrequency={this.state.frequency}
                    isSSS={hasSchoolSafety(this.props.userInfo)}
                >
                    {tabs}
                </LessonTabs>
                <Modal
                    className="heading-banner-lessons modal english"
                    show={this.state.showEnglishModal}
                    onClose={this.closeEnglishModal}
                    width={'90%'}
                >
                    <div className="row" onClick={this.closeEnglishModal}>
                        <button type="button" className="modal-close">
                            <img
                                className="modal-icon"
                                src={`/assets/images/icons/v2/ico-modal-close.svg`}
                                width="24"
                                height="24"
                            />
                        </button>
                        <div className="col-lg-12">
                            <h2 className="cushion-element-lessons">English Statistics</h2>
                        </div>
                        <div className="body-lessons container-lessonsuid coverBottom-lessons text-center">
                            <p className="pad-p">
                                {englishMathReadingActSatCopy}
                            </p>
                            <p className="pad-images">
                                <div className="row m-0">
                                    <div className="col-md-6 mb-3 mb-md-0">
                                        <img
                                            className="w-100"
                                            height="auto"
                                            width="100%"
                                            src="/assets/images/chartsv2/WWA_ACT_English_Lesson-Frequency-Chart@4x.png"
                                            alt="English Statistics ACT"
                                        />
                                    </div>
                                    <div className="col-md-6 mb-3 mb-md-0">
                                        <img
                                            className="w-100"
                                            height="auto"
                                            width="100%"
                                            src="/assets/images/chartsv2/WWA_SAT_English_Lesson-Frequency-Chart@4x.png"
                                            alt="English Statistics SAT"
                                        />
                                    </div>
                                </div>
                            </p>
                        </div>
                    </div>
                </Modal>
                <Modal
                    width={'90%'}
                    className="heading-banner-lessons modal math"
                    show={this.state.showMathModal}
                    onClose={this.closeMathModal}
                >
                        <div className="row" onClick={this.closeMathModal}>
                          <button type="button" className="modal-close">
                            <img
                                className="modal-icon"
                                src={`/assets/images/icons/v2/ico-modal-close.svg`}
                                width="24"
                                height="24"
                            />
                          </button>
                            <div className="col-lg-12">
                                <h2 className="cushion-element-lessons">Math Statistics</h2>
                            </div>
                            <div className="body-lessons container-lessonsuid coverBottom-lessons text-center">
                                <p className="pad-p">
                                    {englishMathReadingActSatCopy}
                                </p>
                                <p className="pad-images">
                                    <div className="row m-0">
                                        <div className="col-md-6 mb-3 mb-md-0">
                                            <img
                                                className="w-100"
                                                height="auto"
                                                width="100%"
                                                src="/assets/images/chartsv2/WWA_ACT_Math_Lesson-Frequency-Chart@4x.png"
                                                alt="Math Statistics ACT"
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3 mb-md-0">
                                            <img
                                                className="w-100"
                                                height="auto"
                                                width="100%"
                                                src="/assets/images/chartsv2/WWA_SAT_Math_Lesson-Frequency-Chart@4x.png"
                                                alt="Math Statistics SAT"
                                            />
                                        </div>
                                    </div>
                                </p>

                            </div>
                        </div>
                </Modal>
                <Modal
                    className="heading-banner-lessons modal reading"
                    show={this.state.showReadingModal}
                    onClose={this.closeReadingModal}
                    width={'80%'}
                >
                        <div className="row" onClick={this.closeReadingModal}>
                          <button type="button" className="modal-close">
                            <img
                                className="modal-icon"
                                src={`/assets/images/icons/v2/ico-modal-close.svg`}
                                width="24"
                                height="24"
                            />
                          </button>
                            <div className="col-lg-12">
                                <h2 className="cushion-element-lessons">Reading Statistics</h2>
                            </div>
                            <div className="body-lessons container-lessonsuid coverBottom-lessons text-center">
                                <p className="pad-p">
                                    {englishMathReadingActSatCopy}
                                </p>
                                <p className="pad-images">
                                    <div className="row m-0">
                                        <div className="col-md-6 mb-3 mb-md-0">
                                            <img
                                                className="w-100"
                                                height="auto"
                                                width="100%"
                                                src="/assets/images/chartsv2/WWA_ACT_Reading_Lesson-Frequency-Chart@4x.png"
                                                alt="Reading Statistics ACT"
                                            />
                                        </div>
                                        <div className="col-md-6 mb-3 mb-md-0">
                                            <img
                                                className="w-100"
                                                height="auto"
                                                width="100%"
                                                src="/assets/images/chartsv2/WWA_SAT_Reading_Lesson-Frequency-Chart@4x.png"
                                                alt="Reading Statistics SAT"
                                            />
                                        </div>
                                    </div>
                                </p>
                            </div>
                        </div>
                </Modal>
                <Modal
                    className="heading-banner-lessons modal science"
                    show={this.state.showScienceModal}
                    onClose={this.closeScienceModal}
                    width={'60%'}
                >
                        <div className="row" onClick={this.closeScienceModal}>
                          <button type="button" className="modal-close">
                            <img
                                className="modal-icon"
                                src={`/assets/images/icons/v2/ico-modal-close.svg`}
                                width="24"
                                height="24"
                            />
                          </button>
                            <div className="col-lg-12">
                                <h2 className="cushion-element-lessons">Science Statistics</h2>
                            </div>
                            <div className="body-lessons container-lessonsuid coverBottom-lessons text-center">
                                <p className="pad-p">
                                    {scienceActSatCopy}
                                </p>
                                <div className="col-10 offset-1 col-lg-12 offset-lg-0">
                                    <p className="pad-images">
                                        <img
                                            className="img-fluid resize-whats-tested"
                                            src="/assets/images/chartsv2/WWA_ACT_Science_Lesson-Frequency-Chart@4x.png"
                                            alt="Math Statistics"
                                            width="640"
                                            height="481"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                </Modal>               
            </PageContent>
        );
    }
    updateLocalFrequency = (testType: string) => {
        this.setState({
            frequency: testType
          }, () => {
            this.forceUpdate();
        });
    }

    @boundMethod
    private showConversionPopup() {
        this.setState({
            showConversionModal: true
        });
    }

    @boundMethod
    private closeConversionPopup() {
        this.setState({
            showConversionModal: false
        });
    }

    private closeModal() {
        this.setState({
            showEmailNotificationModal: false
        });
    }

    @boundMethod
    private openEnglishModal() {
        this.setState({ showEnglishModal: true });
    }

    @boundMethod
    private openMathModal() {
        this.setState({ showMathModal: true });
    }

    @boundMethod
    private openReadingModal() {
        this.setState({ showReadingModal: true });
    }

    @boundMethod
    private openScienceModal() {
        this.setState({ showScienceModal: true });
    }

    @boundMethod
    private closeEnglishModal() {
        this.setState({ showEnglishModal: false });
    }

    @boundMethod
    private closeMathModal() {
        this.setState({ showMathModal: false });
    }

    @boundMethod
    private closeReadingModal() {
        this.setState({ showReadingModal: false });
    }

    @boundMethod
    private closeScienceModal() {
        this.setState({ showScienceModal: false });
    }

    private closePriorityLessons = () => {
        this.setState({ showPriorityLessons: false })
    }

    @boundMethod
    private changeFrequency(testType: string) {
        const lessonViewObject = {
            lessonDashView: testType.toUpperCase()
        };
        updateLessonView(lessonViewObject).then(() => {
            this.props.setUserInfo(lessonViewObject);
            this.updateLocalFrequency(testType.toUpperCase());
        });
    }
    
    @boundMethod
    private createLessonTab(title: string, index: number) {
        // TODO clean this up
        const isTestPrep = title === 'Test Prep';
        return (
            <Tab text={title} key={title}>
                {!isTestPrep && <ComingSoon />}
                {isTestPrep &&
                    this.state.selectedView === LessonView.Subject &&
                        <SubjectView curriculum={title} mistakeBank={false} frequency={this.state.frequency} />}
                {isTestPrep &&
                    this.state.selectedView === LessonView.Completed && 
                        <CompletedView curriculum={title} frequency={this.state.frequency} />}
            </Tab>
        );
    }

    @boundMethod
    private handleViewChange(selectedView: LessonView) {
        this.setState({ selectedView });
    }

    @boundMethod
    private setViewToSubject() {
        this.setState({selectedView: LessonView.Subject});
    }

    private showPriotityLessons = () => {
        this.setState({showPriorityLessons: true});
    }   
}
