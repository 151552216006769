import * as React from 'react'
import { boundMethod } from 'autobind-decorator';
import { LessonProgress, MistakeBankLessons } from '../../../../services/elearn/lesson-types'
import Loading from '../../../../components/Loading/Loading'
import { PageContent } from '../../../../components/PageContent/PageContent'
import { Link, RouteComponentProps } from 'react-router-dom'
import { UserInfo } from '../../../../services/account/account-rest-interface'
import { getBaseUrl } from '../../../../services/routing/routing-utils'
import { welcomeUser } from '../../../../services/account/account-rest-interface'
import WelcomeModalOne from '../../components/WelcomeModalOne/WelcomeModalOne'
import WelcomeModalTwo from '../../components/WelcomeModalTwo/WelcomeModalTwo'
import WelcomeModalThree from '../../components/WelcomeModalThree/WelcomeModalThree'
import { StepByStepModal } from '../../components/StepByStepModal/StepByStepModal'
import LessonStepByStepModal from '../../components/LessonStepByStepModal/LessonStepByStepModal'
import { getDashboardOverviewCached } from '../../../../services/user/user-lesson-rest-interface'
import Button from '../../../../components/Button/Button'
import InspirationalQuote from '../InspirationalQuote'
import CurrentLesson from '../Progress/Current-Lesson/Current-Lesson'
import { getSubjectIconList } from '../../../../services/icon/icon-service'
import ProgressBar from '../../../../components/ProgressBar/ProgressBar'
import { ProgressCircle } from '../ProgressCircle/ProgressCircle'
import { getStudentTonPInfoCached } from '../../../../services/time-on-platform'
import PracticeTestResults from '../../../Reports/StudentReports/PracticeTestResults'
import { IPracticeTestScores, PracticeTestScoreEx } from '../../../../services/elearn/practice-test-types'
import { getAllTestScoresCached, getCurrentIAScores, getScores } from '../../../../services/practice-test-scoring/practice-test-service'
import { getStudentDashboardInfo } from './helpers'
import { IStudentDashboardInfo, IStudentDashboardProps } from './types'
import { SENTINEL } from '../../../../constants'
import './StudentDashboard.css'

export interface Props {

}

export interface DispatchProps {
    setUserInfo: (info: Partial<UserInfo>) => void
}

export interface StoreProps {
    userInfo: UserInfo
    numLessonsUnstarted: number
    numLessonsCompleted: number
    numLessonsStarted: number
    currentLessonId: string
    currentLesson: LessonProgress | undefined
    mistake: MistakeBankLessons[]
    allLessons: LessonProgress[]
}

interface State {
    showFirstModal: boolean
    showSecondModal: boolean
    showThirdModal: boolean
    showStepByStep: boolean
    showLearnStepModal: boolean
    isLoaded: boolean
    myLessonData: any
    myMistakeBankData: any
    tonp: any
    testScores: IPracticeTestScores
}

export type AllProps = Props & StoreProps & DispatchProps & RouteComponentProps<any>;

export class StudentDashboard extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props)
        this.state = {
            showFirstModal: this.props.userInfo.postWelcome === 'true' ? false : true,
            showSecondModal: false,
            showThirdModal: false,
            showStepByStep: false,
            showLearnStepModal: false,
            isLoaded: false,
            myLessonData: {},
            myMistakeBankData: {},
            tonp: {},
            testScores: {
                scoresACT: [],
                scoresSAT: [],
                scoresIA: [],
            },
        }
    }

    public async componentDidMount() {
        getStudentTonPInfoCached().then(tonp => this.setState({ tonp }))
        getAllTestScoresCached().then(testScores => this.setState({ testScores }))
        getDashboardOverviewCached().then(res => {
            this.setState({
                isLoaded: true,
                myLessonData: {
                    numLessonsCompleted: res.numLessonsCompleted,
                    numLessonsStarted: res.numLessonsStarted,
                    numLessonsUnstarted: res.numLessonsUnstarted
                },
                myMistakeBankData: {
                    mbMastered: res.mbMastered,
                    mbReview: res.mbReview,
                    mbReviewAgain: res.mbReviewAgain
                },
            })
        })
    }

    public render() {
        let { isLoaded, tonp, testScores } = this.state
        let { userInfo, allLessons, mistake, currentLessonId, currentLesson } = this.props

        if (!isLoaded || typeof (currentLesson) === 'undefined') {
            return <Loading />
        }

        let studentDashboardInfo = getStudentDashboardInfo(userInfo, currentLesson, allLessons, mistake, testScores, tonp)

        return (
            <PageContent className="home-container student-dashboard">
                <div className="row dashboard-section">
                    <div className="col-12">
                        <WelcomeUserSection info={studentDashboardInfo} />
                    </div>
                </div>

                <div className="row dashboard-section">
                    <div className="col-12">
                        <CurrentLessonSection info={studentDashboardInfo} onLessonClick={this.onProgressLessonClick} />
                    </div>
                </div>

                <div className="row dashboard-section">
                    <div className="col-xl-8 mb-3 mb-xl-0">
                        <InspirationalQuoteSection />
                    </div>
                    <div className="col-xl-4 pl-xl-0">
                        <HoursOfStudySection info={studentDashboardInfo} />
                    </div>
                </div>

                <div className="row dashboard-section">
                    <div className="col-12">
                        <LessonMetricsSection info={studentDashboardInfo} />
                    </div>
                </div>

                <div className="row dashboard-section">
                    <div className="col-12">
                        <MistakeBankMetricsSection info={studentDashboardInfo} />
                    </div>
                </div>

                <div className="row dashboard-section">
                    <div className="col-12">
                        <PracticeTestsOverviewSection info={studentDashboardInfo} />
                    </div>
                </div>

                <div className="row dashboard-section">
                    <div className="col-12">
                        <AchievementsSection info={studentDashboardInfo} />
                    </div>
                </div>

                {/* --- Various Modals --- */}
                <WelcomeModalOne
                    show={this.state.showFirstModal}
                    onClose={this.toggleSecondModal}
                />

                <WelcomeModalTwo
                    show={this.state.showSecondModal}
                    onClose={this.toggleThirdModal}
                />

                <WelcomeModalThree
                    show={this.state.showThirdModal}
                    onClose={this.sendPostWelcomed}
                />

                <StepByStepModal
                    show={this.state.showStepByStep}
                    onClose={this.closeStepByStep}
                    onModalClose={this.cancelStepModal}
                />

                <LessonStepByStepModal
                    show={this.state.showLearnStepModal}
                    onClose={this.closeLearnStepModal}
                />
            </PageContent>
        )
    }

    @boundMethod
    private goToDiagnostic() {
        this.props.history.push(`${getBaseUrl()}app/practice-tests`)
    }

    @boundMethod
    private toggleSecondModal() {
        this.setState({ showFirstModal: false })
        this.setState({ showSecondModal: true })
    }

    @boundMethod
    private toggleThirdModal() {
        this.setState({ showSecondModal: false })
        this.setState({ showThirdModal: true })
    }

    @boundMethod
    private openStepByStep() {
        this.setState({ showStepByStep: true })
    }

    @boundMethod
    private closeStepByStep() {
        this.setState({
            showStepByStep: false,
            showLearnStepModal: true
        })
    }

    @boundMethod
    private cancelStepModal() {
        this.setState({
            showStepByStep: false,
            showLearnStepModal: false
        });
    }

    @boundMethod
    private closeLearnStepModal() {
        this.setState({
            showLearnStepModal: false
        })
    }

    @boundMethod
    private onProgressLessonClick(lessonId: string) {
        this.props.history.push(`${getBaseUrl()}app/lessons/${lessonId}`)
    }

    @boundMethod
    private sendPostWelcomed() {
        welcomeUser(this.props.userInfo.id).then(this.updateInitialLogIn)
        this.setState({ showFirstModal: false, showSecondModal: false, showThirdModal: false })
    }

    @boundMethod
    private updateInitialLogIn() {
        this.props.userInfo.postWelcome = 'true'
    }
}

//--- Helpers: Sections & Subsections ---

function WelcomeUserSection(props: IStudentDashboardProps) {
    let { userInfo } = props.info
    let { firstName, lastName } = userInfo //props.userInfo
    let progressReportLink = `/print/student-progress/${firstName}/${lastName}`

    return (
        <div className="row dashboard-section">
            <div className="col-6">
                {firstName && <h1 className="page-title mb-0">Welcome, {firstName}</h1>}
            </div>
            <div className="col-md-6 text-right">
                <Button
                    text={"PROGRESS REPORT"}
                    textColor="white"
                    bgColor="green"
                    onClick={(event) => {
                        event.preventDefault()
                        window.open(progressReportLink)
                    }}
                />
            </div>
        </div>
    )
}

function CurrentLessonSection(props: { info: IStudentDashboardInfo, onLessonClick: (lessonId: string) => void }) {
    let { info, onLessonClick } = props
    let { currentLesson: lesson } = info
    let subjectIcons = getSubjectIconList("@0,5x")
    let subjectIcon = lesson && lesson.category ? subjectIcons.filter(icon => icon.title === lesson.category.categoryName)[0] : { icon: "" }
    let minutes = lesson ? lesson.aggregateVideoDuration.substring(0, lesson.aggregateVideoDuration.indexOf(':')) : "0"
    let seconds = lesson ? lesson.aggregateVideoDuration.substring(lesson.aggregateVideoDuration.indexOf(":") + 1) : "0"
    let timeText = minutes + " minutes"

    if (minutes === '0') {
        timeText = seconds + " seconds"
    }

    return (
        <div className="section-current-lesson">
            <CurrentLesson
                title="Up Next:"
                category={lesson.category.categoryName}
                lesson={lesson.lessonName}
                img={subjectIcon.icon}
                length={timeText}
                onLessonClick={() => onLessonClick(lesson.lessonId)}
                currentLessonContent={lesson.currentLessonContent}
            />
        </div>
    )
}

function InspirationalQuoteSection(props) {
    return (
        <div className="section-inspirational-quote dashboard-block">
            <InspirationalQuote />
        </div>
    )
}

function HoursOfStudySection(props: IStudentDashboardProps) {
    let { info } = props
    let { studyTime, milestones } = info.summary
    let { hours } = studyTime.humanTime
    let awardLevelStudyTime = milestones.awardLevels.studyTime
    let awardLevelStudyTimeNext = milestones.awardLevels.studyTimeNext

    return (
        <div className="section-hours-of-study dashboard-block">
            <div className="dashboard-block-title">
                <img className="mr-2" src="/assets/images/icons/v2/ico-time-red.svg" width="37" height="37" alt="" />
                Hours Studying
            </div>
            <div className="hours-of-study-info dashboard-number-lrg mb-3">
                {hours}
            </div>
            <div className="trophyCase hours-of-study-trophy pt-1">
                <div className="trophyCase--inner">
                    {awardLevelStudyTime > SENTINEL &&
                        <div className="trophyCase--trophy">
                            <div className="trophyCase--wrap">
                                <img className="trophyCase--img" src="/assets/images/icons/v2/awards/houraward-1.svg" width="76" height="94" />
                            </div>
                            <span className="trophyCase--val">{awardLevelStudyTime}</span>
                            <span className="trophyCase--title">CURRENT TROPHY</span>
                        </div>
                    }
                    {awardLevelStudyTimeNext > SENTINEL &&
                        <div className="trophyCase--trophy disabled">
                            <div className="trophyCase--wrap">
                                <img className="trophyCase--img" src="/assets/images/icons/v2/awards/houraward-1.svg" width="76" height="94" />
                            </div>
                            <span className="trophyCase--val">{awardLevelStudyTimeNext}</span>
                            <span className="trophyCase--title">NEXT TROPHY</span>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

function LessonMetricsSection(props: IStudentDashboardProps) {
    let { info } = props
    let { lessons, questions, milestones } = info.summary
    let { total, completed, leftToStart, inProgress } = lessons
    let { total: totalQuestions, answered: questionsAnswered } = questions
    let awardLevelLessonsCompleted = milestones.awardLevels.lessonsCompleted
    let awardLevelLessonsCompletedNext = milestones.awardLevels.lessonsCompletedNext
    let awardLevelQuestionsAnswered = milestones.awardLevels.questionsAnswered
    let awardLevelQuestionsAnsweredNext = milestones.awardLevels.questionsAnsweredNext

    return (
        <div className="section-lesson-metrics dashboard-block wide">
            <div className="row">
                <div className="col-md-12">
                    <h2 className="student-dashboard-generic-title">
                        <img src="/assets/images/icons/v2/ico-metrics.svg" width="42" height="29" className="mr-2" />
                        Lesson Metrics
                    </h2>
                </div>
            </div>

            <div className="row mx-0">
                <div className="col-md-8 px-0">
                    <div className="dashboard-theme-block">
                        <div className="dtb-inner purple">
                            <div className="row mx-0">
                                <div className="col-xl-6 pl-0 mb-3 mb-xl-0">
                                    <div className="dashboard-block-title">
                                    <img className="mr-2" src="/assets/images/icons/v2/ico-summary.svg" width="37" height="37" alt="" />
                                    Lessons Completed
                                </div>
                                <div className="dashboard-number-lrg">
                                    {completed} <span className="subtext">/ {total}</span>
                                </div>
                                </div>
                                <div className="col-xl-6 px-0">
                                    <div className="trophyCase lessons-completed-trophy ">
                                        <div className="trophyCase--inner">
                                            {awardLevelLessonsCompleted > SENTINEL &&
                                                <div className="trophyCase--trophy ml-xl-auto">
                                                    <div className="trophyCase--wrap">
                                                        <img className="trophyCase--img" src="/assets/images/icons/v2/awards/lesson-award-1.svg" width="76" height="94" />
                                                    </div>
                                                    <span className="trophyCase--val">{awardLevelLessonsCompleted}</span>
                                                    <span className="trophyCase--title">CURRENT TROPHY</span>
                                                </div>
                                            }
                                            {awardLevelLessonsCompletedNext > SENTINEL &&
                                                <div className="trophyCase--trophy disabled">
                                                    <div className="trophyCase--wrap">
                                                        <img className="trophyCase--img" src="/assets/images/icons/v2/awards/lesson-award-1.svg" width="76" height="94" />
                                                    </div>
                                                    <span className="trophyCase--val">{awardLevelLessonsCompletedNext}</span>
                                                    <span className="trophyCase--title">NEXT TROPHY</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 px-0">
                    <div className="dashboard-theme-block">
                        <div className="dtb-inner blue">
                            <div className="dashboard-block-title">
                                <img className="mr-2" src="/assets/images/icons/v2/ico-start.svg" width="37" height="37" alt="" />
                                Lessons to Start
                            </div>
                            <div className="dashboard-number-lrg">
                                {leftToStart}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 px-0">
                    <div className="dashboard-theme-block">
                        <div className="dtb-inner green">
                            <div className="dashboard-block-title">
                                <img className="mr-2" src="/assets/images/icons/v2/ico-resume.svg" width="37" height="37" alt="" />
                                Lessons In Progress
                            </div>
                            <div className="dashboard-number-lrg">
                                {inProgress}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-8 px-0">
                    <div className="dashboard-theme-block">
                        <div className="dtb-inner pink">
                            <div className="row mx-0">
                                <div className="col-xl-6 pl-0 mb-3 mb-xl-0">
                                    <div className="dashboard-block-title">
                                        <img className="mr-2" src="/assets/images/icons/v2/ico-question-purple.svg" width="37" height="37" alt="" />
                                        Questions Answered
                                    </div>
                                    <div className="dashboard-number-lrg">
                                        {questionsAnswered}
                                    </div>
                                </div>
                                <div className="col-xl-6 px-0">
                                    <div className="trophyCase questions-completed-trophy ">
                                        <div className="trophyCase--inner">
                                            {awardLevelQuestionsAnswered > SENTINEL &&
                                                <div className="trophyCase--trophy ml-xl-auto">
                                                    <div className="trophyCase--wrap">
                                                        <img className="trophyCase--img" src="/assets/images/icons/v2/awards/question-award-1.svg" width="76" height="94" />
                                                    </div>
                                                    <span className="trophyCase--val">{awardLevelQuestionsAnswered}</span>
                                                    <span className="trophyCase--title">CURRENT TROPHY</span>
                                                </div>
                                            }
                                            {awardLevelQuestionsAnsweredNext > SENTINEL &&
                                                <div className="trophyCase--trophy disabled">
                                                    <div className="trophyCase--wrap">
                                                        <img className="trophyCase--img" src="/assets/images/icons/v2/awards/question-award-1.svg" width="76" height="94" />
                                                    </div>
                                                    <span className="trophyCase--val">{awardLevelQuestionsAnsweredNext}</span>
                                                    <span className="trophyCase--title">NEXT TROPHY</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function MistakeBankMetricsSection(props: IStudentDashboardProps) {
    let { info } = props
    let { topics } = info.summary
    let { mastered, review, revisit } = topics

    return (
        <div className="section-mistakebank-metrics dashboard-block wide">
            <div className="row">
                <div className="col-12">
                    <h2 className="student-dashboard-generic-title">
                        <img src="/assets/images/icons/v2/ico-mistake-bank.svg" width="60" height="44" className="mr-2" />
                        Mistake Bank Metrics
                    </h2>
                </div>
            </div>

            <div className="row mx-0">
                <div className="col-md-4 px-0">
                    <div className="dashboard-theme-block">
                        <div className="dtb-inner purple">
                            <div className="dashboard-block-title">
                                <img className="mr-2" src="/assets/images/icons/v2/vis-ico-topic-mastered-filled.svg" width="37" height="37" alt="" />
                                Topics Mastered
                            </div>
                            <div className="dashboard-number-lrg">
                                {mastered}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 px-0">
                    <div className="dashboard-theme-block">
                        <div className="dtb-inner blue">
                            <div className="dashboard-block-title">
                            <img className="mr-2" src="/assets/images/icons/v2/vis-ico-review-filled.svg" width="37" height="37" alt="" />
                                Topics to Review
                            </div>
                            <div className="dashboard-number-lrg">
                                {review}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 px-0">
                    <div className="dashboard-theme-block">
                        <div className="dtb-inner green">
                            <div className="dashboard-block-title">
                                <img className="mr-2" src="/assets/images/icons/v2/vis-ico-review-again-filled.svg" width="37" height="37" alt="" />
                                Topics to Revisit
                            </div>
                            <div className="dashboard-number-lrg">
                                {revisit}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function PracticeTestsOverviewSection(props: IStudentDashboardProps) {
    let { info } = props
    let { studentTestScores } = info
    let { scoresACT: actScores, scoresSAT: satScores, scoresIA: iaScores } = studentTestScores
    let totalLen = actScores.length + satScores.length + iaScores.length    
    let imagePath = '/assets/images/icons/v2';
    let iconAboveAvg = `${imagePath}/ico-target-above.svg`
    let iconCloseAvg = `${imagePath}/ico-target-at.svg`
    let iconBelowAvg = `${imagePath}/ico-target-below.svg`
    
    // Check if we have any relevant data here, if not then just return without any rendering!
    if (totalLen === 0) return null

    return (
        <div className="section-practice-tests-overview dashboard-block wide">
            <div className="row">
                <div className="col-12">
                    <h2 className="student-dashboard-generic-title">
                        <img src="/assets/images/icons/v2/ico-document.svg" width="32" height="42" className="mr-2" />
                            Practice Tests Overview
                        </h2>
                </div>
            </div>

            <div className="row mx-0 mb-2">
                <div className="col-12 px-0">
                <div className="dashboard-theme-block">
                        <div className="dtb-inner gradient">
                            <div className="row mx-0 pto-row">
                                {/* <div className="pto-row--img col-sm-6 col-lg-2 mb-3 mb-lg-0 px-0 pr-sm-2"> */}
                                <div className="pto-row--col--img">
                                    <div className="logo-block logo-act-sat">
                                        <img className="img-fluid" src="/assets/images/icons/tests/act_logo.svg" width="167" height="" alt="ACT" />
                                    </div>
                                </div>
                                {/* <div className="pto-row--img col-sm-6 col-lg-2 mb-3 mb-lg-0 px-0 pr-lg-2"> */}
                                <div className="pto-row--col--img">
                                    <div className="logo-block logo-act-sat">
                                        <img className="img-fluid" src="/assets/images/icons/tests/sat_logo.svg" width="114" height="" alt="SAT" />
                                    </div>
                                </div>
                                {/* <div className="col-12 col-lg-8 d-flex justify-content-center flex-column"> */}
                                <div className="pto-row--col--txt d-flex justify-content-center flex-column">
                                    <div className="dashboard-block-title">Assess and Enhance Your Skills</div>
                                    <div className="practice-tests-intro pb-4 pb-lg-0">
                                        <p>Welcome to your <b><i>Practice Test Overview!</i></b>
                                        Here, you can view your previous overall diagnostic and practice test results below.
                                        Additionally, revisit the Practice Tests section to review your answers
                                        from previous tests and take additional practice tests to further enhance your skills.</p>
                                        <Link className="btn btn--text-white btn--bg-white title-navigation-buttons" to="practice-tests" title="Access Practice Tests">PRACTICE TESTS</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mx-0">
                <div className="col-12 px-0">
                    <div className="dashboard-theme-block">
                        <div className="table-legend-center">
                            <div className="practice-test-help-icons">
                                <img src={iconAboveAvg} alt="" width="20" height="20" className="help-icons--icon" /> Above college ready score
                            </div>
                            <div className="practice-test-help-icons">
                                <img src={iconCloseAvg} alt="" width="20" height="20" className="help-icons--icon" /> Close to college ready score
                            </div>
                            <div className="practice-test-help-icons">
                                <img src={iconBelowAvg} alt="" width="20" height="20" className="help-icons--icon" /> Below college ready score
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row pt-3">
                <div className="col-md-12">
                    <PracticeTestResults
                        title={''}
                        classes="dashboard-preview-table"
                        legend={false}
                        scores={{ actScores, satScores, iaScores }}
                        showTopScores={false}
                    />
                </div>
            </div>
        </div>
    )
}

function AchievementsSection(props: IStudentDashboardProps) {
    let { info } = props
    let { summary } = info
    let { completedLessons } = summary.milestones

    return (
        <div className="section-achievements dashboard-block wide">
            <div className="row">
                <div className="col-md-12">
                    <h2 className="student-dashboard-generic-title">
                        <img src="/assets/images/icons/v2/ico-trophy.svg" width="42" height="42" className="mr-2" />
                        Achievements
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="dashboard-theme-block">
                        <div className="achievements-wrapper">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="achievements-info-title">Subject Awards</div>
                                    <div className="achievements-info">
                                        Complete all lessons in a subject area, and earn a <b><i>Subject Master</i></b> award for each.
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-lg-4 mb-3 mb-xl-0 col-xl">
                                    <div className={`achievements-subject-award english ${completedLessons.english ? "" : "disabled"}`}>
                                        <img className="mx-auto mb-1" width="114" height="140" alt="" src="/assets/images/icons/v2/awards/grandgrammarian.svg" />
                                        <div className="achievements-subject-award-title">Grand Grammarian</div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4 mb-3 mb-xl-0 col-xl">
                                    <div className={`achievements-subject-award math ${completedLessons.math ? "" : "disabled"}`}>
                                        <img className="mx-auto mb-1" width="114" height="140" alt="" src="/assets/images/icons/v2/awards/mathematicalmaven.svg" />
                                        <div className="achievements-subject-award-title">Mathematical Maven</div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4 mb-3 mb-xl-0 col-xl">
                                    <div className={`achievements-subject-award reading ${completedLessons.reading ? "" : "disabled"}`}>
                                        <img className="mx-auto mb-1" width="114" height="140" alt="" src="/assets/images/icons/v2/awards/resolute-reader.svg" />
                                        <div className="achievements-subject-award-title">Resolute Reader</div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4 mb-3 mb-xl-0 col-xl">
                                    <div className={`achievements-subject-award science ${completedLessons.science ? "" : "disabled"}`}>
                                        <img className="mx-auto mb-1" width="114" height="140" alt="" src="/assets/images/icons/v2/awards/stellar-scientist.svg" />
                                        <div className="achievements-subject-award-title">Stellar Scientist</div>
                                    </div>                                    
                                </div>
                                <div className="col-sm-6 col-lg-4 mb-3 mb-xl-0 col-xl">
                                <div className={`achievements-subject-award writing ${completedLessons.writing ? "" : "disabled"}`}>
                                        <img className="mx-auto mb-1" width="114" height="140" alt="" src="/assets/images/icons/v2/awards/writing-wonder.svg" />
                                        <div className="achievements-subject-award-title">Writing Wonder</div>                                        
                                    </div>
                                </div>                                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    )
}

function StudentProgressProgressbar(props: { title: string, total: number, current: number }) {
    let { title, total, current } = props
    let info = `${current}/${total}`

    return (
        <div className="student-progress-progressbar-container">
            <div className="row">
                <div className="col-md-6 student-progress-title">{title}</div>
                <div className="col-md-6 student-progress-info">{info}</div>
            </div>
            <ProgressBar barCurrentColor='darkPurple' total={total} current={current} />
        </div>
    )
}

function StudentProgressCircle(props: { title: string, progress: number, img: string }) {
    let { title, progress, img } = props

    return (
        <div className="student-progress-circle-container">
            <ProgressCircle
                title={title}
                progress={progress}
                img={img}
            />
        </div>
    )
}
