import * as React from 'react';

interface Props {
    text: string;
    logoPath?: string;
}

const SignUpHeader: React.SFC<Props> = ({ text, logoPath }) => (
    <div className="row sign-up-header">
        <div className="col-12">
            <h1 style={{ 
                color: 'white', 
                fontWeight: 200
            }}>
                { logoPath !== undefined && 
                    <span className="">
                        <img 
                            className="img-fluid" 
                            style={{ 
                                height: 'auto',
                                width: '30%',
                                marginRight: '1em'
                            }}
                            src={logoPath} 
                        /> 
                    </span>
                }
                <span>{text}</span>
            </h1>
        </div>
    </div>
);

SignUpHeader.displayName = 'SignUpHeader';

export default SignUpHeader;
