import React, { Component } from 'react'
import { getHumanTime } from '../../../services/utils/date-util'
import { FilterSelections, prepareSelectionsInfo, TestKind, TimeFrame } from '../SuperintendentFilters'
import { getSubjectIcon } from '../../../services/icon/icon-service'
import ConversionModal from '../../../components/ConversionModal/ConversionModal'
import CollapsableCard, { CollapsableCardBody, CollapsableCardHeader } from '../../../components/CollapsableCard/CollapsableCard'
import ResourceListCardHeader from '../../../components/ResourceListCardHeader'
import { convertToACTandSAT, shortEquivalentInfoForPreLesson, shortEquivalentInfoForPostLesson } from '../../../services/utils/act-sat-util'
import { round, sum, weightedMean } from '../../../services/utils/math-util'
import { MiniCaret } from '../../../components/MiniCaret'
import Modal from '../../../components/Modal/Modal'
import SchoolSummary from '../SchoolSummary'
import { localeNumber } from '../../../services/utils/text-util'
import { COLLEGE_READY_COLOR_THRESHOLD, NA } from '../../../constants'
import { isCategoryReadingOrScience } from '../../../services/utils/lesson-util'
import './index.css'

interface Props {
  data: any
  allData: any
  filterSelections: FilterSelections
}

interface State {
  showSchoolSummary: boolean
  selectedSchool: undefined | any
  showScoreConversion: boolean
}

function formatTableHeader(first: string, second: string, third: string) {
  return (
    <div>
      <div className="th-tooltip">
        <div>{first}</div>
        <div>{second} </div>
        <span className="th-tooltip-text">{third}</span>
        </div>
    </div>
  )
}

const usageAndTimeSummaryHeaders = [
  formatTableHeader('SCHOOL', '', 'Indicates the name of the school'),
  formatTableHeader('LESSONS', 'STARTED', 'Indicates the number of lessons started'),
  formatTableHeader('LESSONS', 'COMPLETED', 'Indicates the number of lessons completed'),
  formatTableHeader('QUESTIONS', 'ANSWERED', 'Indicates the number of questions answered'),
  formatTableHeader('TIME ON', 'QUESTIONS', 'Indicates total time answering multiple choice questions'),
  formatTableHeader('TOTAL', 'STUDY TIME', 'Indicates total time spent answering baseline questions, watching the video lesson, and answering multiple choice questions'),
]

const schoolDetailHeaders = [
  formatTableHeader('SCHOOL', '', 'Indicates the name of the school'),
  formatTableHeader('STUDENTS', '', 'Indicates the number of students participated'),
  formatTableHeader('COLLEGE', 'READY', 'Indicates the percent of students whose post-lesson accuracy meets the ACT/SAT threshold for "college ready"'),
  formatTableHeader('PRE-LESSON', 'ACCURACY', 'Calculated as the percent of baseline questions answered correctly before starting a lesson'),
  formatTableHeader('POST-LESSON', 'ACCURACY', 'Calculated as the number of correct questions divided by the number of questions answered'),
  formatTableHeader('LESSON', 'GROWTH', 'Calculated as the change in accuracy from pre-lesson accuracy to completed question accuracy'),
  formatTableHeader('HINTS ON', 'INCORRECT', 'Reflects what percent of missed questions students write themselves problem solving hints'),
  formatTableHeader('TIME ON', 'QUESTIONS', 'Indicates average and total time answering multiple choice questions'),
  formatTableHeader('TOTAL', 'STUDY TIME', 'Indicates total time spent answering baseline questions, watching the video lesson, and answering multiple choice questions'),
]

export default class SchoolStats extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      showSchoolSummary: false,
      selectedSchool: undefined,
      showScoreConversion: false
    }
  }

  getGraduationYearData(schoolsInfo, schoolName: string, category: string, graduationYear: string, timeFrame: TimeFrame) {
    let school = schoolsInfo.find(s => s.name === schoolName)
    if (!school) return
    let graduationYearInfo = school.rawdata.graduationYears.find(gy => gy.year === graduationYear)
    if (!graduationYearInfo) return
    return graduationYearInfo[timeFrame].lessons.categories[category]
  }

  getCategoricalData(schoolsInfo, categories: string[]) {
    let {graduationYears, schools, timeFrame} = this.props.filterSelections
    let selectedSchools = schools.filter(s => s.selected).map(s => s.name)
    let selectedGraduationYears = graduationYears.filter(gy => gy.selected).map(gy => gy.name)
    let categoryInfo = categories.map(category => {
      let selectedSchoolsInfo = selectedSchools.filter(n => n !== "all").map(schoolName => {
        let graduationYearsInfo = selectedGraduationYears.filter(gy => gy !== "all").map(graduationYear => {
          let data = this.getGraduationYearData(schoolsInfo, schoolName, category, graduationYear, timeFrame)
          if (data === undefined) return undefined
          return {year: graduationYear, ...data}
        }).filter(data => data && Object.keys(data).length !== 0)
        let selectedSchoolInfo = schoolsInfo.filter(school => school.name === schoolName)
        let rawdata = selectedSchoolInfo && selectedSchoolInfo.length > 0 ? selectedSchoolInfo[0].rawdata: undefined

        return {
          name: schoolName,
          graduationYears: graduationYearsInfo,
          rawdata
        }
      }).filter(d => d !== undefined)

      return {
        category,
        schools: selectedSchoolsInfo
      }
    })

    return categoryInfo
  }

  prepTimeOnQuestionInfo = (avgTime: number, totalTime: number) => {
    let avgTimeInfo = avgTime > 0 ? getHumanTime(avgTime, true, true).html: NA
    let totalTimeInfo = totalTime > 0 ? getHumanTime(totalTime, false, true).html: NA

    return () => (
      <div className="time-on-question-info act-or-sat-score-info time-on-question-info">
        {avgTimeInfo != NA ?
          <div>
            Avg <span dangerouslySetInnerHTML={{__html: avgTimeInfo}}/>
            <br/>
            Total <span dangerouslySetInnerHTML={{__html: totalTimeInfo}}/>
          </div>
          :
          <div className="not-applicable">{NA}</div>
        }
      </div>
    )
  }

  getCardBodyContent = (schools: ISchool[], category: string, testKind: TestKind) => {
    let graduationYears = schools.flatMap(s => s.graduationYears)
    let summary = getGraduationYearsSummary(graduationYears)
    let infoCollegeReady = `${testKind === TestKind.ACT ? summary.collegeReadyACT: summary.collegeReadySAT}%`
    let equivalentScoreInfo = convertToACTandSAT(category, summary.preLessonAccuracy, summary.postLessonAccuracy)
    let equivalentInfoPreScore  = shortEquivalentInfoForPreLesson(equivalentScoreInfo, testKind)
    let equivalentInfoPostScore = summary.postLessonAccuracy != -1 ? shortEquivalentInfoForPostLesson(equivalentScoreInfo, testKind): ""
    let infoPreLessonAccuracy = `${summary.preLessonAccuracy}%`
    let infoPostLessonAccuracy = summary.postLessonAccuracy != -1 ? `${summary.postLessonAccuracy}%`: NA
    let infoLessonGrowth = summary.postLessonAccuracy != -1 ? `${summary.lessonGrowth}%`: NA
    let infoHintsOnIncorrects = summary.postLessonAccuracy != -1 ? `${summary.hintsOnIncorrects}%`: NA
    let averageTimeOnQuestions = summary.postLessonAccuracy != -1 ? "Average " + getHumanTime(summary.avgTimePerQuestions, true, true).html: NA
    let timeOnQuestions = summary.postLessonAccuracy != -1 ? getHumanTime(summary.totalTimeOnAnswered, false, true).html: ""
    let totalStudyTime = getHumanTime(summary.studyTime.total, false, true).html
    let summaryRowClassName = `lesson-summary-row-category-${category}`
    let arrowClass = ""
    let participationInfo = `${summary.nofStudentsParticipated}`

    if (summary.lessonGrowth > 0 ) {
      arrowClass = 'after--up-arrow'
    } 
    else if (summary.lessonGrowth  < 0) {
      arrowClass = 'after--down-arrow'
    }

    // For Reading & Science pre-lesson accuracy & growth are N/A
    let isPreLessonAndGrowthNA = isCategoryReadingOrScience(category)
    let isPreLessonNA = isPreLessonAndGrowthNA
    let isPostLessonNA = summary.postLessonAccuracy <= 0
    let isLessonGrowthNA = isPostLessonNA || isPreLessonAndGrowthNA
    let isCollegeReadyNA = isPostLessonNA
    let isHintsOnIncorrectsNA = isPostLessonNA
    let isTimeOnQuestionsNA = isPostLessonNA
    infoPreLessonAccuracy = !isPreLessonAndGrowthNA ? infoPreLessonAccuracy: NA
    equivalentInfoPreScore = !isPreLessonAndGrowthNA ? equivalentInfoPreScore: ""
    infoLessonGrowth = !isPreLessonAndGrowthNA ? infoLessonGrowth: NA
    
    // Level: Summary for schools (top level)
    return (
      <div className="fixedHeaderTable">
        <table className="lesson-summary-table usage-time-summary-table support-cols--center">
          <thead>
            <tr>
              {schoolDetailHeaders.map((headerName, index) =>
                <th className={`label-header ${(index < 2) ? "label-type1": "label-type2"}`} key={`th-${headerName}-${index}`}>
                  {headerName}
                </th>
              )}
            </tr>
          </thead>
          <tbody className="school-detail-body">
              <tr className={summaryRowClassName}>
                <td className="info-si-category-summary">SUMMARY</td>
                <td>{participationInfo}</td>
                {summary.nofStudentsParticipated > 0 ?
                  <>
                    <td>
                      {isCollegeReadyNA ?
                        NA
                        :
                        infoCollegeReady
                      }
                    </td>
                    <td className="ignore-bold">
                      {isPreLessonNA ?
                        NA
                        :
                        <>
                          <div className="info-line" dangerouslySetInnerHTML={{__html: infoPreLessonAccuracy}}/>
                          <div className="info-line info-second-line act-or-sat-score-info" dangerouslySetInnerHTML={{__html: equivalentInfoPreScore}} />
                        </>
                      }
                    </td>
                    <td>
                      {isPostLessonNA ?
                        NA
                        :
                        <>
                          <div className="info-line" dangerouslySetInnerHTML={{__html: infoPostLessonAccuracy}}/>
                          <div className="info-line act-or-sat-score-info" dangerouslySetInnerHTML={{__html: equivalentInfoPostScore}} />
                        </>
                      }
                    </td>
                    <td>
                      {isLessonGrowthNA ?
                        NA
                        :
                        <span className={arrowClass}>{infoLessonGrowth}</span>
                      }
                    </td>
                    
                    <td>
                      {isHintsOnIncorrectsNA ?
                        NA
                        :
                        infoHintsOnIncorrects
                      }
                    </td>
                    <td className="ignore-bold">
                      {isTimeOnQuestionsNA ?
                        NA
                        :
                        <>
                          <div className="info-line act-or-sat-score-info time-on-question-info" dangerouslySetInnerHTML={{__html: averageTimeOnQuestions}} />
                          <div className="info-line act-or-sat-score-info time-on-question-info">Total <span className="info-second-line" dangerouslySetInnerHTML={{__html: timeOnQuestions}}/></div>
                        </>
                      }
                    </td> 
                    <td className="ignore-bold">
                      <div className="total-study-time" dangerouslySetInnerHTML={{__html: totalStudyTime}}/>
                    </td>
                  </>
                  :
                  <>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </>
                }
              </tr>
            {schools.map((school, index) => <SchoolDetail ind={index} school={school} category={category} testKind={testKind}/>)}
          </tbody>
        </table>
      </div>
    )
  }

  showSchoolDetails = (school) => {
    this.setState({showSchoolSummary: true, selectedSchool: school})
  }

  render() {
    let {data, allData} = this.props
    let {schools, summary} = data
    if (!schools) return <div>...</div>
    let categories = ["English", "Math", "Reading", "Science"]
    let categoricalData = this.getCategoricalData(schools, categories)
    let defaultColor = "#10a492"
    let {filterSelections} = this.props
    let {testScoreKind} = filterSelections
    let {showSchoolSummary} = this.state
    let summaryHeadline = prepareSelectionsInfo(filterSelections).selectionInfoForLessons

    return (
      <div style={{paddingTop: '30px'}}>
        <div>
          <div>
            <h3 className="h3">Usage and Time Summary</h3>
            <hr className="half mb-2"/>
          </div>
          <div className="summary-headline" dangerouslySetInnerHTML={{ __html: summaryHeadline }} />
          <div className="lesson-summary-table-wrapper usage-time-summary-wrapper">
            <div className="fixedHeaderTable">
              <table className="lesson-summary-table usage-time-summary-table support-cols--center tbl-sml">
                <thead>
                  <tr>
                    {usageAndTimeSummaryHeaders.map((headerName, index) =>
                      <th className={`label-header ${(index < 2) ? "label-type1": "label-type2"}`} key={`th-${headerName}-${index}`}>{headerName}</th>
                    )}
                  </tr>
                </thead>
                <tbody className="usage-time-summary-table-body">
                  {
                    schools.map(school => {
                      let totalTimeOnQuestions = getHumanTime(school.totalTimeOnQuestions, false, true)
                      let totalStudyTime = getHumanTime(school.totalStudyTime, false, true)
                      let localeLessonsStarted = localeNumber(school.lessonsStarted)
                      let localeLessonsCompleted = localeNumber(school.lessonsCompleted)
                      let localeQuestionsAnswered = localeNumber(school.questionsAnswered)

                      return (
                        <tr key={school.name}>
                          <td>
                            <a href="#" onClick={() => this.showSchoolDetails(school)} className="link modal-link iconLink--left">
                              <img
                                className="modal-icon"
                                src={`/assets/images/icons/v2/ico-modal.svg`}
                                width="18"
                                height="18"
                              />
                              {school.name}
                            </a>
                          </td>
                          <td>{localeLessonsStarted}</td>
                          <td>{localeLessonsCompleted}</td>
                          <td>{localeQuestionsAnswered}</td>
                          <td className="ignore-bold" dangerouslySetInnerHTML={{__html: totalTimeOnQuestions.html}}></td>
                          <td className="ignore-bold" dangerouslySetInnerHTML={{__html: totalStudyTime.html}}></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="">
          <div className="individual-data-section--2col siview-data-title">
            <h3 className="individual-data-title">Subject Performance Summary</h3>
            <div className="title-sidebar">
              <a title="View Conversion Table" href="#" className="iconLink--left img-rel" onClick={() => this.setState({ showScoreConversion: true})}>
                <img src="/assets/images/icons/v2/ico-modal.svg" width="11" height="11" alt="" />
                <span className="label def">View Conversion Table</span>
              </a>
            </div>
          </div>
          <hr className="half mb-2" />
          <div className="summary-headline" dangerouslySetInnerHTML={{ __html: summaryHeadline }} />
          <div className="school-stats-summary">
            {categories.map(category => {
              let schools = categoricalData.find(cd => cd.category === category)?.schools || []
              let iconPath = `/assets/images/icons/subjects/ico-subject-${category.replace(/\s/g, '').toLowerCase()}.svg`
              let si = getSubjectIcon(category)
              let color = si ? si.color: defaultColor 

              return (
                  <CollapsableCard key={category}>
                      <CollapsableCardHeader>
                          <ResourceListCardHeader
                              text={category}
                              color={color}
                              icon={iconPath}
                              nofResources={schools.length}
                          />
                      </CollapsableCardHeader>
                      <CollapsableCardBody> 
                          {this.getCardBodyContent(schools, category, testScoreKind)}
                      </CollapsableCardBody>
                  </CollapsableCard>
              )}
            )}
          </div>
        </div>

        <ConversionModal 
            show={this.state.showScoreConversion}
            onClose={() => this.setState({ showScoreConversion: false})}/>

        {showSchoolSummary && 
          <Modal
              className="school-details-modal "
              show={showSchoolSummary}
              width="auto"
              onClose={() => {this.setState({showSchoolSummary: false})}}>
              <SchoolSummary
                allData={this.props.allData}
                selectedSchool={this.state.selectedSchool}
                summary={summary}
                testScoreKind={testScoreKind}
                onClose={() => {this.setState({showSchoolSummary: false})}}/>
          </Modal>
        }     
      </div>
    )
  }
}

interface SchoolDetailProps {
  ind: any
  school: ISchool
  category: string
  testKind: TestKind
}

interface SchoolDetailState {
  isDetailsShowing: boolean
}

interface IGraduationYear {
  year: string
  avgTimePerQuestions: number
  collegeReadyACT: number
  collegeReadySAT: number
  hintsOnIncorrects: number
  lessonGrowth: number
  nofLessonsCompleted: number
  nofLessonsContinued: number
  nofLessonsStarted: number
  nofQuestionsAnswered: number
  nofStudentsCollegeReadyACT: number
  nofStudentsCollegeReadySAT: number
  nofStudentsParticipated: number
  postLessonAccuracy: number
  postLessonConversionScoreACT: string
  postLessonConversionScoreSAT: string
  preLessonAccuracy: number
  preLessonConversionScoreACT: string
  preLessonConversionScoreSAT: string
  totalTimeOnAnswered: number
  totalTimeOnQuestions: number
  studyTime: {
    total: number,
    baselines: number,
    videos: number,
    questions: number
  }
}

interface ISchool {
  name: string
  graduationYears: IGraduationYear[]
  rawdata: any
}

/**
 * Obtain the summary from the graduation years.
 * @param graduationYears 
 * @returns summary information
 */
const getGraduationYearsSummary = (graduationYears: IGraduationYear[]) => {
  let getSum = (attrName: string) => sum(graduationYears.filter(gy => gy.nofLessonsContinued > 0).map(gy => gy[attrName]))
  let getStudyTimeSum = (attrName: string) => sum(graduationYears.filter(gy => gy.nofLessonsContinued > 0).map(gy => gy["studyTime"][attrName]))
  let getFilteredValues = (attrName: string, filterPredicate?: (data: IGraduationYear) => boolean) =>
        graduationYears
          .filter(gy => gy.nofLessonsContinued > 0)
          .filter(gy => filterPredicate ? filterPredicate(gy): true)
          .map(gy => {
            return {
              weight: gy.nofLessonsContinued,
              value: gy[attrName]
            }
          })
  let getAveragePre = (attrName: string) => round(weightedMean(getFilteredValues(attrName)))
  let getAveragePost = (attrName: string) => round(weightedMean(getFilteredValues(attrName, gy => gy.nofQuestionsAnswered > 0)))
  let preLessonAccuracy = getAveragePre("preLessonAccuracy")
  let postLessonAccuracy = getAveragePost("postLessonAccuracy")
  let hintsOnIncorrects = getAveragePost("hintsOnIncorrects")
  let collegeReadyACT = getAveragePost("collegeReadyACT")
  let collegeReadySAT = getAveragePost("collegeReadySAT")
  let avgTimePerQuestions = getAveragePost("avgTimePerQuestions")
  let summary = {
    preLessonAccuracy,
    postLessonAccuracy,
    lessonGrowth: postLessonAccuracy - preLessonAccuracy,
    
    // Averages
    hintsOnIncorrects,
    collegeReadyACT,
    collegeReadySAT,
    avgTimePerQuestions,
    
    // Sums
    totalTimeOnAnswered:     getSum("totalTimeOnAnswered"),  // Time on Questions (answered)
    totalTimeOnQuestions:    getSum("totalTimeOnQuestions"), // Time on Questions (ended), i.e. total study time
    totalLessonsContinued:   getSum("nofLessonsContinued"),
    nofStudentsParticipated: getSum("nofStudentsParticipated"),
    
    studyTime: {
      baselines: getStudyTimeSum("baselines"),
      videos:    getStudyTimeSum("videos"),
      questions: getStudyTimeSum("questions"),
      total:     getStudyTimeSum("total"),
    }
  }

  return summary
}

class SchoolDetail extends Component<SchoolDetailProps, SchoolDetailState> {
  constructor(props) {
    super(props)
    this.state = {
      isDetailsShowing: false
    }
  }

  private toggleDetails = () => {
    this.setState({isDetailsShowing: !this.state.isDetailsShowing})
  }

  // Level: School
  render() {
    let getPercentageInfo = (data: number) => data !== -1 ? `${data}%`: NA
    let getLessonGrowthInfo = (data: number) => data !== 0 ? `${data}%`: NA
    let {school, category, testKind, ind} = this.props
    let {name, graduationYears, rawdata} = school
    let graduationYearsSummary = getGraduationYearsSummary(graduationYears)
    let {collegeReadyACT, collegeReadySAT, preLessonAccuracy, postLessonAccuracy, lessonGrowth} = graduationYearsSummary
    let {hintsOnIncorrects, totalTimeOnAnswered, totalTimeOnQuestions, avgTimePerQuestions, totalLessonsContinued} = graduationYearsSummary
    let {isDetailsShowing} = this.state
    let nofGraduationYears = graduationYears.length

    // Prepare the info for summary
    let collegeReadyColorThreshold = COLLEGE_READY_COLOR_THRESHOLD
    let collegeReadyInfo = nofGraduationYears > 0 ? `${testKind === TestKind.ACT ? collegeReadyACT: collegeReadySAT}`: ""
    let collegeReadyInfoFormated

    if (nofGraduationYears > 0) {
      collegeReadyInfoFormated = parseInt(collegeReadyInfo) >= collegeReadyColorThreshold ? <span className="c-status-green"><b>{collegeReadyInfo}%</b></span> : collegeReadyInfo + "%"
    } 
    else {
      collegeReadyInfoFormated = ""
    }

    let preLessonAccuracyInfo = getPercentageInfo(preLessonAccuracy)
    let postLessonAccuracyInfo = getPercentageInfo(postLessonAccuracy)
    let lessonGrowthInfo = getLessonGrowthInfo(lessonGrowth)
    let hintsOnIncorrectsInfo = getPercentageInfo(hintsOnIncorrects)
    let averageTimeOnQuestions = (nofGraduationYears > 0 && avgTimePerQuestions !== -1) ? "Average " + getHumanTime(avgTimePerQuestions, true, true).html: ""
    let timeOnQuestions = nofGraduationYears > 0 ?  getHumanTime(totalTimeOnAnswered, false, true).html : ""
    let totalStudyTime = nofGraduationYears > 0 ?  getHumanTime(totalTimeOnQuestions, false, true).html : ""
    let equivalentScores = convertToACTandSAT(category, preLessonAccuracy, postLessonAccuracy)
    let preLessonEquivalentScore = shortEquivalentInfoForPreLesson(equivalentScores, testKind)
    let postLessonEquivalentScore = shortEquivalentInfoForPostLesson(equivalentScores, testKind)
    let arrowClass = ''

    if (lessonGrowth > 0) {
      arrowClass = 'after--up-arrow'
    } 
    else if (lessonGrowth < 0) {
      arrowClass = 'after--down-arrow'
    }

    // For Reading & Science pre-lesson accuracy & growth are N/A
    let isPreLessonAndGrowthNA = isCategoryReadingOrScience(category)
    let isPreLessonNA = isPreLessonAndGrowthNA
    let isPostLessonNA = postLessonAccuracy <= 0 
    let isLessonGrowthNA = isPostLessonNA || isPreLessonAndGrowthNA
    let isCollegeReadyNA = isPostLessonNA
    let isHintsOnIncorrectsNA = isPostLessonNA
    let isTimeOnQuestionsNA = isPostLessonNA
    let participationInfo = graduationYearsSummary.nofStudentsParticipated

    return (
      <>
      {nofGraduationYears > 0 &&
       <>
        <tr className={`subject-performance-summary ${(ind % 2 == 0) ? 'odd' : 'even'}`} onClick={this.toggleDetails}>
          <td className=" school-cell ">
            {nofGraduationYears > 0 && <MiniCaret up={!isDetailsShowing}/>}
            <span className="school-name">{name}</span>
          </td>

          <td>{participationInfo}</td>

          {totalLessonsContinued > 0 && nofGraduationYears > 0 ?
            <>
              <td>
                {isCollegeReadyNA ?
                  NA
                  :
                  <>
                    {collegeReadyInfoFormated}
                  </>
                }
              </td>
              <td>
                {isPreLessonNA ? 
                  NA
                  :
                  <>
                    {preLessonAccuracyInfo}
                    <br/>
                    <div><span className="info-second-line act-or-sat-score-info">{preLessonEquivalentScore}</span></div>
                  </>
                }
              </td>
              <td>
                {isPostLessonNA ? 
                  NA
                  :
                  <>
                    {postLessonAccuracyInfo}
                    <br/>
                    <div><span className="info-second-line act-or-sat-score-info">{postLessonEquivalentScore}</span></div>
                  </>
                }
              </td>
              
              <td>
                {isLessonGrowthNA ?
                  NA
                  :
                  <span className={arrowClass}>{lessonGrowthInfo}</span>
                }
              </td>

              <td>
                {isHintsOnIncorrectsNA ?
                  NA
                  :
                  <>
                    {hintsOnIncorrectsInfo}
                  </>
                }
              </td>
              <td className="ignore-bold">
                {isTimeOnQuestionsNA ?
                  NA
                  :
                  <>
                    <div className="info-line act-or-sat-score-info time-on-question-info time-on-question-info" dangerouslySetInnerHTML={{__html: averageTimeOnQuestions}} />
                    <div className="info-line act-or-sat-score-info time-on-question-info time-on-question-info">Total <span className="info-second-line" dangerouslySetInnerHTML={{__html: timeOnQuestions}}/></div>
                  </>
                }
              </td> 
              <td className="ignore-bold">
                <div className="" dangerouslySetInnerHTML={{__html: totalStudyTime}}/>
              </td>
            </>
            :
            <>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </>
          }
        </tr>

        {isDetailsShowing && graduationYears.length > 0 && graduationYears.map(graduationYearInfo => {
          let {year, collegeReadyACT, collegeReadySAT} = graduationYearInfo
          let {preLessonAccuracy, postLessonAccuracy, lessonGrowth, hintsOnIncorrects, totalTimeOnAnswered, totalTimeOnQuestions, avgTimePerQuestions} = graduationYearInfo
          let {preLessonConversionScoreACT, postLessonConversionScoreACT } = graduationYearInfo
          let {preLessonConversionScoreSAT, postLessonConversionScoreSAT } = graduationYearInfo
          let {nofLessonsContinued, nofStudentsParticipated, nofQuestionsAnswered} = graduationYearInfo
          let collegeReadyColorThreshold = COLLEGE_READY_COLOR_THRESHOLD

          // Prepare the info for detail for school based on the graduation year
          let collegeReadyInfo = testKind === TestKind.ACT ? collegeReadyACT: collegeReadySAT
          let collegeReadyInfo_fmt = collegeReadyInfo >= collegeReadyColorThreshold ? <span className="c-status-green"><b>{collegeReadyInfo}%</b></span> : collegeReadyInfo + "%"
          let preLessonAccuracyInfo = getPercentageInfo(preLessonAccuracy)
          let postLessonAccuracyInfo = getPercentageInfo(postLessonAccuracy)

          // Inner table
          let lessonGrowthInfo = getPercentageInfo(lessonGrowth)
          let hintsOnIncorrectsInfo = getPercentageInfo(hintsOnIncorrects)
          let averageTimeOnQuestions = avgTimePerQuestions !== -1 ? "Average " + getHumanTime(avgTimePerQuestions, true, true).html: NA
          let timeOnQuestions = getHumanTime(totalTimeOnAnswered, false, true).html
          let totalStudyTime = getHumanTime(totalTimeOnQuestions, false, true).html
          let prePostPrefix = testKind === TestKind.ACT ? "ACT" : "SAT";
          let preLessonEquivalentScore = `${prePostPrefix} ${testKind === TestKind.ACT ? preLessonConversionScoreACT : preLessonConversionScoreSAT}`
          let postLessonEquivalentScore = `${prePostPrefix} ${testKind === TestKind.ACT ? postLessonConversionScoreACT : postLessonConversionScoreSAT}`
          let showAsNA = nofLessonsContinued === 0
          let arrowClass = ""
          let participationInfo = `${nofStudentsParticipated}`
          let isPreLessonAndGrowthNA = isCategoryReadingOrScience(category)
          let isPreLessonNA = isPreLessonAndGrowthNA
          let isPostLessonNA = postLessonAccuracy <= 0
          let isCollegeReadyNA = isPostLessonNA
          let isLessonGrowthNA = isPostLessonNA || isPreLessonAndGrowthNA
          let isHintsOnIncorrectsNA = isPostLessonNA
          let isTimeOnQuestionsNA = isPostLessonNA

          if (lessonGrowth > 0) {
            arrowClass = 'after--up-arrow'
          }
          else if (lessonGrowth < 0) {
            arrowClass = 'after--down-arrow'
          }

          // Level: Class of 20xx
          return (
            <tr className={`subject-performance-detail ${(ind % 2 == 0) ? 'odd' : 'even'} ${showAsNA ? 'disabled' : ''}`}>
              <td className="graduation-year-info">Class of {year}</td>
              <td>{participationInfo}</td>
              {!showAsNA ?
                <>
                  <td>
                    {isCollegeReadyNA ?
                      NA
                      :
                      collegeReadyInfo_fmt
                    }
                  </td>
                  <td>
                    {isPreLessonNA ?
                      NA
                      :
                      <>
                        {preLessonAccuracyInfo}
                        <br/>
                        <span className="info-second-line act-or-sat-score-info">{preLessonEquivalentScore}</span>
                      </>
                    }
                  </td>
                  <td>
                    {isPostLessonNA ?
                      NA
                      :
                      <>
                        <div className="info-line">{postLessonAccuracyInfo}</div>
                        <div className="info-line act-or-sat-score-info"><span className="info-second-line">{postLessonEquivalentScore}</span></div>
                    </>
                    }
                  </td>
                  <td>
                    {isLessonGrowthNA ?
                      NA
                      :
                      <span className={arrowClass}>{lessonGrowthInfo}</span>
                    }
                  </td>
                  <td>
                    {isHintsOnIncorrectsNA ?
                      NA
                      :
                      <span>{hintsOnIncorrectsInfo}</span>
                    }
                  </td>
                  <td className="ignore-bold">
                    {isTimeOnQuestionsNA ? 
                      NA
                      :
                      <>
                      <div className="info-line act-or-sat-score-info time-on-question-info" dangerouslySetInnerHTML={{__html: averageTimeOnQuestions}} />
                      <div className="info-line act-or-sat-score-info time-on-question-info">
                        Total <span className="info-second-line" dangerouslySetInnerHTML={{__html: timeOnQuestions}}/>
                      </div>
                    </>
                    }
                  </td> 
                  <td>
                    <div className="total-study-time" dangerouslySetInnerHTML={{__html: totalStudyTime}}/>
                  </td>
                </>
                :
                <>
                  <td>{NA}</td>
                  <td>{NA}</td>
                  <td>{NA}</td>
                  <td>{NA}</td>
                  <td>{NA}</td>
                  <td>{NA}</td>
                  <td>{NA}</td>
                </>
              }
            </tr>
          )
        })} 
        </>
      }
      </>
    )
  }
}
