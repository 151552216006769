import './TableHeader.css';

import * as React from 'react';

import { Column } from '../../types/types';
import HeaderCell from './components/HeaderCell/HeaderCell';

interface Props {
    data: Column[];
    sortedCol?: string;
    reversed?: boolean;
    onSort: (dataProperty: string) => void;
}

const TableHeader: React.SFC<Props> = (props) => {
    const sortedCol = props.sortedCol;
    const reversed = props.reversed;
    
    return (
        <div className="lesson-table-header">
            {props.data.map(data =>
                <HeaderCell
                    title={data.title}
                    sortable={typeof data.sortable === 'undefined' ? true : data.sortable}
                    sorted={!reversed && data.dataProperty === sortedCol}
                    width={data.width}
                    customToolTip={data.customTooltip}
                    key={`header-cell-${data.title}`}
                    onClick={() => props.onSort(data.dataProperty)}
                />)}
        </div>
    );
};

TableHeader.displayName = 'TableHeader';

export default TableHeader;
