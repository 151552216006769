import _ from "lodash"

export const HOURS_IN_DAY = 24      // Hours in a day
export const MINS_IN_DAY = 1440     // Minutes in a day
export const MINS_IN_HOUR = 60      // Minutes in an hour
export const SECS_IN_DAY = 86_400   // Seconds in a day
export const SECS_IN_HOUR = 3600    // Seconds in an hour
export const SECS_IN_MIN = 60       // Seconds in a min
export const MS_IN_DAY = 86_400_000 // Milliseconds in a day
export const MS_IN_HOUR = 3_600_000 // Milliseconds in an hour
export const MS_IN_MIN = 60_000     // Milliseconds in a minute
export const MS_IN_SEC = 1000       // Milliseconds in a second

export const TONP_EPOCH_DATE = new Date("2022-02-22")

/**
 * Get time in milliseconds for the provided date
 * 
 * @param date 
 */
export function getTimeInMs(date: Date | string) {
    return new Date(date).getTime()
}

/**
 * Gets the time difference between two dates.
 * @param date1 earlier date
 * @param date2 later date
 * @returns time difference in milliseconds
 */
export function timeDiffInMs(date1: Date | string, date2: Date | string) {
    let t1 = getTimeInMs(date1)
    let t2 = getTimeInMs(date2)
    return t2 - t1
}

export function timeDiffInSecs(date1: Date | string, date2: Date | string) {
    return timeDiffInMs(date1, date2) / MS_IN_SEC
}

export function timeDiffInMins(date1: Date | string, date2: Date | string) {
    return timeDiffInMs(date1, date2) / MS_IN_MIN
}

export function timeDiffInHours(date1: Date | string, date2: Date | string) {
    return timeDiffInMs(date1, date2) / MS_IN_HOUR
}

export function timeDiffInDays(date1: Date | string, date2: Date | string) {
    return timeDiffInMs(date1, date2) / MS_IN_DAY
}

/**
 * Number of days between start and end dates
 * @param startDate 
 * @param endDate 
 * @returns 
 */
export function dateDiff(startDate: Date | string, endDate: Date | string) {
    return Math.round(timeDiffInDays(startDate, endDate))
}

/**
 * Find out if the provided date (or timestamp) within the start and end dates.
 * All dates use the ISO 8601 date format (e.g. 2021-12-15, etc.)
 * 
 * @param dateOrTimestamp 
 * @param dateStart 
 * @param dateEnd 
 * @returns 
 */
export function isWithinDateRange(dateOrTimestamp: string | number, dateStart: Date | string, dateEnd: Date | string, acceptNoStartDate = true) {
    if (!dateOrTimestamp) return false

    // Special case for old entries with no timestamp in lessons!
    // Their lastUpdatedTimestamp value is -1 (lastUpdatedTimestamp fieled added later on in DB)
    // New entries should have the correct timestamp
    if (acceptNoStartDate && dateOrTimestamp === -1) return true

    try {
        let dd, ds, de
        let dStart = new Date(dateStart)
        let dEnd = new Date(dateEnd)
        dStart.setUTCHours(0, 0, 0, 0)
        dEnd.setUTCHours(23, 59, 59, 999)
        ds = dStart.getTime()
        de = dEnd.getTime()

        if (typeof (dateOrTimestamp) == "number") {
            dd = dateOrTimestamp * 1000 // Convert to milliseconds
        }
        else {
            // Some of the earlier dates marked as "No start date", we can either accept or reject them here
            if (dateOrTimestamp == "No start date") return acceptNoStartDate ? true : false
            dd = new Date(dateOrTimestamp.substring(0, 10)).getTime()
        }

        if (dd >= ds && dd <= de) {
            return true
        }
    }
    catch (ex) {
        console.error(`Error in isWithinDateRange. date: ${dateOrTimestamp} start: ${dateStart} end: ${dateEnd}`, ex)
    }

    return false
}


export interface TimeInfo {
    days: number
    hours: number
    minutes: number
    seconds: number
}

export interface HumanTime extends TimeInfo {
    html: string
    text: string
}

export interface MilitaryTime extends TimeInfo {
    text: string
}


export function getTimeInfo(totalSeconds: number): TimeInfo {
    let secondsInDay = SECS_IN_DAY
    let secondsInHour = SECS_IN_HOUR
    let secondsInMinute = SECS_IN_MIN
    let days = Math.floor(totalSeconds / secondsInDay)
    let hours = Math.floor((totalSeconds - days * secondsInDay) / secondsInHour)
    let minutes = Math.floor((totalSeconds - days * secondsInDay - hours * secondsInHour) / secondsInMinute)
    let usedSeconds = days * secondsInDay + hours * secondsInHour + minutes * secondsInMinute
    let seconds = Math.floor(totalSeconds - usedSeconds)

    return { days, hours, minutes, seconds }
}

/**
 * Get the human understandable information from the total seconds provided.
 * Sometimes we don't want to show the dates but just hours (for example working hours)
 * This is the case for our application, and Jennifer just wants to display days as hours! 
 * Therefore the "showDays" is set to false by default!
 * 
 * @param totalSeconds 
 * @param showSeconds
 * @param showCondensed
 * @param showDays
 * @returns 
 */
export function getHumanTime(totalSeconds: number, showSeconds = false, showCondensed = false, showDays = false): HumanTime {
    let { days, hours, minutes, seconds } = getTimeInfo(totalSeconds)

    // If days not shown then it will be added to the hours!
    if (!showDays) {
        hours += 24 * days
        days = 0
    }

    if (!showSeconds && seconds > 30) {
        // Round up minutes if not showing seconds!
        minutes++
        seconds = 0
        if (minutes >= 60) {
            minutes = 0
            hours++
        }
    }

    let formatNumber = (num: number) => num.toLocaleString()

    let daysInfo = days > 0 ?
        !showCondensed ? (days === 1 ? "<b>1</b> day" : `<b>${formatNumber(days)}</b> days`) : `<b>${formatNumber(days)}</b>d`
        : ""

    let hoursInfo = hours > 0 ?
        !showCondensed ? (hours === 1 ? "<b>1</b> hr" : `<b>${formatNumber(hours)}</b> hrs`) : `<b>${formatNumber(hours)}</b>h`
        : ""

    let minutesInfo = minutes > 0 ?
        !showCondensed ? (minutes === 1 ? "<b>1</b> min" : `<b>${formatNumber(minutes)}</b> mins`) : `<b>${formatNumber(minutes)}</b>m`
        : ""

    let secondsInfo = seconds > 0 ?
        !showCondensed ? (seconds === 1 ? "<b>1</b> sec" : `<b>${formatNumber(seconds)}</b> secs`) : `<b>${formatNumber(seconds)}</b>s`
        : ""

    let html = `${daysInfo} ${hoursInfo} ${minutesInfo} ${showSeconds ? secondsInfo : ""}`.trim()
    html = html !== "" ? html : "0m"
    let clear = (str: string, tag: string) => str.split(tag).join("")
    let text = ["<b>", "</b>"].reduce(clear, html) // Simplified representation (no tags), just text

    return { days, hours, minutes, seconds, html, text }
}


/**
 * In the military time text only hours:minutes:seconds represented
 * Note that hours part is optional
 * 
 * @param totalSeconds seconds to be translated to days, hours, etc.
 * @param showHours shows or hides hours information
 * @returns HH:MM:SS
 */
export function getMilitaryTime(totalSeconds: number, showHours = true): MilitaryTime {
    let { days, hours, minutes, seconds } = getTimeInfo(totalSeconds)
    let hoursInfo = hours < 10 ? `0${hours}` : `${hours}`
    let minsInfo = minutes < 10 ? `0${minutes}` : `${minutes}`
    let secsInfo = seconds < 10 ? `0${seconds}` : `${seconds}`
    let text = `${showHours ? hoursInfo + ":" : ""}${minsInfo}:${secsInfo}`

    return { days, hours, minutes, seconds, text }
}


/**
 * Gets the last n days from the start date going backward
 * 
 * @param startDate Either Date or ISO date string (like 2022-01-22)
 * @param n number of days 
 * @param earlierFirst sorting for the array
 * @returns 
 */
export function getLastNDays(startDate: Date, n: number, earlierFirst = true) {
    let date = new Date(startDate)
    let days = _.range(n).reduce((acc, _cur) => {
        let d = new Date(acc[acc.length - 1])
        d.setDate(d.getDate() - 1)
        acc.push(d)
        return acc
    }, [date]).map(d => dateToISODate(d))

    return earlierFirst ? days.reverse() : days
}


/**
 * Gets the relative date information in human terms
 * Examples: Last Week, Last Month, etc.
 * 
 * @param date 
 * @returns human understandable relative date information
 */
export function getRelativeHumanDateInfo(date: Date | string): string {
    let numbers = {
        0: "Zero", 1: "One", 2: "Two", 3: "Three", 4: "Four",
        5: "Five", 6: "Six", 7: "Seven", 8: "Eight", 9: "Nine",
        10: "Ten", 11: "Eleven", 12: "Twelve"
    }
    let diff = dateDiff(date, new Date())
    let info

    if (diff < 0) info = Math.abs(diff) + " days later"
    else if (diff < 7) info = "This week"
    else if (diff < 14) info = "Last week"
    else if (diff < 30) info = "This month"
    else if (diff < 60) info = "Last month"
    else if (diff < 365) {
        let month = Math.floor(diff / 30)
        let numStr = numbers[month]
        info = numStr + " months ago"
    } else {
        info = diff + " days ago"
    }

    return info
}

/**
 * Get relative week info like 1 week ago, 2 weeks ago, etc.
 * Examples: 1 week ago, 2 weeks ago, etc.
 * 
 * @param date 
 * @returns human understandable relative date information
 */
export function getRelativeWeekInfo(date: Date | string): string {
    let diff = dateDiff(date, new Date())
    let weeks = Math.round(Math.abs(diff) / 7)
    let agoOrLater = diff > 0 ? "ago" : "later"
    let info = weeks == 0 ? "This week" : `${weeks} week${weeks == 1 ? "" : "s"} ${agoOrLater}`

    return info
}


/**
 * Given a date it returns ISO-8601 date format (like 2022-02-01)
 * 
 * @param date 
 * @returns ISO formated date string
 */
export function dateToISODate(date: Date): string {
    let d = new Date(date)
    let year = d.getFullYear()
    let month = d.getMonth() + 1 // Note that months are zero based!
    let day = d.getDate()        // Gets the day of the month

    return `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}`
}

/**
 * Get the date string in the format of M/D/YYYY
 * 
 * @param date 
 * @returns 
 */
export function dateToDateStr(date: Date): string {
    let dateStr = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    return dateStr
}

/**
 * Get the date string in the format of M/D/YY
 * 
 * @param date 
 * @returns 
 */
export function dateToDateShortStr(date: Date): string {
    let shortYear = `${date.getFullYear()}`.substring(2)
    let dateStr = `${date.getMonth() + 1}/${date.getDate()}/${shortYear}`
    return dateStr
}

/**
 * Gets the nominal graduation years based on the current date.
 * Nominal graduation years are the ones starting with the current academic year + 1 and the next 3 years
 * i.e. if school starting and current year is 2022 then the nominal graduation years 
 * would be ["2023", "2024", "2025", "2026"]
 * 
 * @param now 
 * @param academicYearStartMonth default is 8 (i.e August)
 * @returns nominal graduation years in array
 */
export function getNomimalGraduationYears(now = new Date(), academicYearStartMonth = 8): string[] {
    if (academicYearStartMonth < 1 || academicYearStartMonth > 12) {
        throw new Error("Academic year start month must be between 1 and 12")
    }

    let nowYear = now.getFullYear()
    let nowMonth = now.getMonth() + 1  // Note that JS months are zero based!
    let startMonth = academicYearStartMonth - 1 // Again note that JS date months are zero based!
    let academicYearStartDate = new Date(nowYear, startMonth)
    let diff = dateDiff(now, academicYearStartDate)
    let graduationYears = (startYear: number) => [1, 2, 3, 4].map(n => `${startYear + n}`)

    if (diff == 0) {
        // Just starting the academic year (i.e. very first day!)
        return graduationYears(academicYearStartDate.getFullYear())
    }

    if (nowMonth >= academicYearStartMonth && nowMonth <= 12) {
        // First half
        // Same academicYearStartDate, no change needed!
    }
    else {
        // Second half (change in the academicYearStartDate needed!)
        academicYearStartDate = new Date(nowYear - 1, startMonth)
    }

    return graduationYears(academicYearStartDate.getFullYear())
}

/**
 * Finds if the provided date is before TonP epoch date
 * TonP started collecting data for lessons on 2022-02-22
 * 
 * @param date 
 * @returns 
 */
export function isBeforeTonPEpochDate(date: Date | string): boolean {
    let diff = dateDiff(TONP_EPOCH_DATE, new Date(date))

    if (diff < 0) return true

    return false
}


export enum TimeFrame {
    ALL_TIME = "all-time",
    LAST_MONTH = "last-month",
    LAST_WEEK = "last-week"
}

export function getStartDateBasedOnTimeFrame(timeFrame: TimeFrame): Date {
    let dateStart = new Date()
    switch (timeFrame) {
        case "all-time":
            dateStart = new Date("2000-01-01")
            break
        case "last-week":
            dateStart.setDate(dateStart.getDate() - 7)
            break
        case "last-month":
            dateStart.setDate(dateStart.getDate() - 30)
            break
    }
    return dateStart
}

/**
 * Pads a number with leading zeros.
 * 
 * @param num 
 * @param size 
 * @returns padded number
 */
export function pad(num: number, size = 2) {
    let s = num + ""
    while (s.length < size) s = "0" + s
    return s
}

/**
 * Get the start and end dates of the each bin in backward fashion (for days) since.
 * 
 * @param sinceDay
 * @param nofDays 
 * @returns bins
 */
export function getDailyBinsBackwardFrom(sinceDay: string | Date, nofDays: number) {
    let bins = [] as any[]
    let currentDate = new Date(sinceDay)
    let startDate, endDate

    for (let i = 0; i < nofDays; i++) {
        endDate = new Date(currentDate)
        endDate.setDate(currentDate.getDate() - i)
        startDate = new Date(endDate)

        // Find out the days
        let startYear = startDate.getFullYear()
        let startMonth = startDate.getMonth() + 1
        let startDay = startDate.getDate()
        let date = `${startYear}-${pad(startMonth)}-${pad(startDay)}`

        bins.unshift({
            displayDate: dateToDateShortStr(startDate),
            date
        })
    }

    return bins
}

/**
 * Get the start and end dates of the each bin in backward fashion (for weeks) since.
 * 
 * @param sinceDay
 * @param nofWeeks 
 * @returns bins
 */
export function getWeeklyBinsBackwardFrom(sinceDay: string | Date, nofWeeks: number) {
    let bins = [] as any[]
    let currentDate = new Date(sinceDay)
    let startDate, endDate

    for (let i = 0; i < nofWeeks; i++) {
        endDate = new Date(currentDate)
        endDate.setDate(currentDate.getDate() - i * 7)
        startDate = new Date(endDate)
        startDate.setDate(endDate.getDate() - 6)

        // Find out the week start & end dates
        let startYear = startDate.getFullYear()
        let startMonth = startDate.getMonth() + 1
        let startDay = startDate.getDate()
        let endYear = endDate.getFullYear()
        let endMonth = endDate.getMonth() + 1
        let endDay = endDate.getDate()
        let weekStartDate = `${startYear}-${pad(startMonth)}-${pad(startDay)}`
        let weekEndDate = `${endYear}-${pad(endMonth)}-${pad(endDay)}`

        bins.unshift({
            displayStartDate: dateToDateShortStr(startDate),
            displayEndDate: dateToDateShortStr(endDate),
            startDate: weekStartDate,
            endDate: weekEndDate
        })
    }

    return bins
}


/**
 * Get the start and end dates of the each bin in backward fashion (for months) since.
 * 
 * @param sinceDay
 * @param nofMonths 
 * @returns bins
 */
export function getMonthlyBinsBackwardFrom(sinceDay: string | Date, nofMonths: number) {
    let bins = [] as any[]
    let currentDate = new Date(sinceDay)
    let startDate, endDate

    for (let i = 0; i < nofMonths; i++) {
        endDate = new Date(currentDate)
        endDate.setDate(currentDate.getDate() - i * 30)
        startDate = new Date(endDate)
        startDate.setDate(endDate.getDate() - 29)

        // Find out the month start & end dates
        let startYear = startDate.getFullYear()
        let startMonth = startDate.getMonth() + 1
        let startDay = startDate.getDate()
        let endYear = endDate.getFullYear()
        let endMonth = endDate.getMonth() + 1
        let endDay = endDate.getDate()
        let monthStartDate = `${startYear}-${pad(startMonth)}-${pad(startDay)}`
        let monthEndDate = `${endYear}-${pad(endMonth)}-${pad(endDay)}`

        bins.unshift({
            displayStartDate: dateToDateShortStr(startDate),
            displayEndDate: dateToDateShortStr(endDate),
            startDate: monthStartDate,
            endDate: monthEndDate
        })
    }

    return bins
}

/**
 * Gets the yesterday's date
 * 
 * @returns yesterday's date
 */
export function getYesterday(): Date {
    let yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    return yesterday
}

/**
 * Get the start and end dates of the each bin in backward fashion (for days) since.
 * 
 * @param sinceDay
 * @param nofDays 
 * @returns bins
 */
export function getDailyBinsBackwardFromYesterday(nofDays: number) {
    let yesterday = getYesterday()
    return getDailyBinsBackwardFrom(yesterday, nofDays)
}

/**
 * Get the start and end dates of the each bin in backward fashion (for weeks) since.
 * 
 * @param sinceDay
 * @param nofWeeks
 * @returns bins
 */
export function getWeeklyBinsBackwardFromYesterday(nofWeeks: number) {
    let yesterday = getYesterday()
    return getWeeklyBinsBackwardFrom(yesterday, nofWeeks)
}


/**
 * Get the start and end dates of the each bin in backward fashion (for months) since.
 * 
 * @param sinceDay
 * @param nofMonths
 * @returns bins
 */
export function getMonthlyBinsBackwardFromYesterday(nofMonths: number) {
    let yesterday = getYesterday()
    return getMonthlyBinsBackwardFrom(yesterday, nofMonths)
}

/**
 * Get the start and end dates of the each bin in backward fashion (for days).
 * 
 * @param nofDays 
 * @returns bins
 */
export function getDailyBinsBackwardFromCurrentDay(nofDays: number) {
    let today = new Date()
    return getDailyBinsBackwardFrom(today, nofDays)
}

/**
 * Get the start and end dates of the each bin in backward fashion (for weeks).
 * 
 * @param nofWeeks 
 * @returns bins
 */
export function getWeeklyBinsBackwardFromCurrentDay(nofWeeks: number) {
    let today = new Date()
    return getWeeklyBinsBackwardFrom(today, nofWeeks)
}


/**
 * Get the start and end dates of the each bin in backward fashion (for months).
 * 
 * @param nofMonths 
 * @returns bins
 */
export function getMonthlyBinsBackwardFromCurrentDay(nofMonths: number) {
    let today = new Date()
    return getMonthlyBinsBackwardFrom(today, nofMonths)
}

/**
 * Gets the beginning of the week of the given date
 * @param startDate 
 * @returns 
 */
export function getBeginningOfWeek(startDate: Date) {
    let dayOfWeek = startDate.getDay()
    let daysToMonday = (dayOfWeek + 6) % 7
    let beginningOfWeek = new Date(startDate)
    beginningOfWeek.setDate(startDate.getDate() - daysToMonday)
    return beginningOfWeek
}

export interface IWeekInfo {
    weekNo: number
    weekStartDate: Date
}

export type SpecialWeek = "Thanksgiving" | "Christmas" | "NewYear"

/**
 * Gets the Thanksgiving (US) date for the given year.
 * In US, it is always the 4th Thursday
 * 
 * @param year 
 * @returns 
 */
export function getThanksgivingDate(year: number = new Date().getFullYear()) {
    let november = 10 // Month is 0 based
    let firstDayOfNovember = new Date(year, november, 1)
    let firstDay = firstDayOfNovember.getDay()
    let offset = firstDay <= 4 ? 4 - firstDay : 11 - firstDay
    let firstThursday = offset + 1
    let thanksgivingDate = firstThursday + 21
    return new Date(year, november, thanksgivingDate)
}

/**
 * Gets the beginning week of the Thanksgiving week for the given year.
 * @param year 
 * @returns 
 */
export function getBeginningOfWeekForThanksgiving(year: number = new Date().getFullYear()) {
    let date = getThanksgivingDate(year)
    let beginningOfWeek = getBeginningOfWeek(date)
    return beginningOfWeek
}


/**
 * Gets the Christmas date for the given year.
 * @param year 
 * @returns 
 */
export function getChristmasDate(year: number = new Date().getFullYear()) {
    let december = 11 // Month is 0 based
    let christmasDay = 25
    return new Date(year, december, christmasDay)
}

/**
 * Gets the beginning week of the Christmas week for the given year.
 * @param year 
 * @returns 
 */
export function getBeginningOfWeekForChrismas(year: number = new Date().getFullYear()) {
    let date = getChristmasDate(year)
    let beginningOfWeek = getBeginningOfWeek(date)
    return beginningOfWeek
}

/**
 * Gets the beginning week of the New Year date for the given year.
 * Optionally, you can get the beginning week for the next year.
 * @param year 
 * @param nextYear if true then get the beginning week for the next year
 * @returns 
 */
export function getBeginningOfWeekForNewYear(year: number = new Date().getFullYear(), nextYear: boolean = false): Date {
    let targetYear = nextYear ? year + 1 : year
    let date = new Date(targetYear, 0, 1)
    let beginningOfWeek = getBeginningOfWeek(date)
    return beginningOfWeek
}

/**
 * Finds if the given date is within the start and end date.
 * @param startDate 
 * @param endDate 
 * @param date 
 * @returns 
 */
export function isWithinStartAndEndDate(startDate: Date, endDate: Date, date: Date): boolean {
    return date >= startDate && date <= endDate
}

/**
 * Gets the next date from the given date with offset.
 * @param date 
 * @param offsetInDays 
 * @returns 
 */
export function nextDate(date: Date, offsetInDays = 1) {
    let next = new Date(date)
    next.setDate(next.getDate() + offsetInDays)
    return next
}


/**
 * Gets the weeks from the start date
 * 
 * @param dateStart 
 * @returns 
 */
export function getWeeksSinceStartDate(dateStart: string | Date, specialWeeksToSkip?: SpecialWeek[]): IWeekInfo[] { 
    let today = new Date()
    let startDate = new Date(dateStart)
    let beginningOfWeek = getBeginningOfWeek(startDate)
    let weeks: IWeekInfo[] = []
    let weekNo = 1
    let cursor = new Date(beginningOfWeek)

    while (cursor < today) {
        let year = cursor.getFullYear()
        let thanksgivingWeekStart = getBeginningOfWeekForThanksgiving(year)
        let thanksgivingWeekEnd = nextDate(thanksgivingWeekStart, 7)
        let xmasWeekStart = getBeginningOfWeekForChrismas(year)
        let xmasWeekEnd = nextDate(xmasWeekStart, 7)
        let newYearWeekStart = getBeginningOfWeekForNewYear(year, true)
        let newYearWeekEnd = nextDate(newYearWeekStart, 7)
    
        weeks.push({
            weekNo,
            weekStartDate: new Date(cursor),
        })
        
        cursor.setDate(cursor.getDate() + 7)
        
        if (specialWeeksToSkip) {
            specialWeeksToSkip.forEach(specialWeek => {
                let skip = 
                    (specialWeek === "Thanksgiving" && isWithinStartAndEndDate(thanksgivingWeekStart, thanksgivingWeekEnd, cursor)) || 
                    (specialWeek === "Christmas" && isWithinStartAndEndDate(xmasWeekStart, xmasWeekEnd, cursor)) || 
                    (specialWeek === "NewYear" && isWithinStartAndEndDate(newYearWeekStart, newYearWeekEnd, cursor))

                if (skip) {
                    cursor.setDate(cursor.getDate() + 7)
                }            
            })
        }
        
        weekNo++
    }

    return weeks
}

