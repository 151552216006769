import './Login.css';

import React, { createRef } from 'react';

import { boundMethod } from 'autobind-decorator';

import BlueGeometricBackground from '../../components/BlueGeometricBackground/BlueGeometricBackground';
import Button from '../../components/Button/Button';
import { Link } from 'react-router-dom';
import Modal from '../../components/Modal/Modal';
import { RouteComponentProps } from 'react-router-dom';
import TextInput from '../../components/TextInput/TextInput';
import { UserInfo } from '../../services/account/account-rest-interface';
import VerificationError from './components/VerificationError/VerificationError';
import { setSessionStorageItem, deleteSessionStorageByID } from '../../services/utils/session-storage-utility';
import CleverLoginButton from './components/CleverLoginButton';
import ClassLinkLoginButton from './components/ClassLinkLoginButton';

export interface StoreProps {
    requestingLogin: boolean;
}
export interface DispatchProps {
    loginUser: (username: string, password: string) => Promise<UserInfo>;
}

interface State {
    username: string;
    password: string;
    isLoading: boolean;
    hasError: boolean;
    errorText: string;
    accountNotVerified: boolean;
    showModal: boolean;
    isCapsLockOn: boolean;
}

export type AllProps = StoreProps & DispatchProps & RouteComponentProps<any>;

export class LoginWithoutRouting extends React.Component<AllProps, State> {
    private emailRef = createRef<HTMLInputElement>();

    constructor(props: AllProps) {
        super(props);
        this.state = {
            username: '',
            password: '',
            isLoading: false,
            hasError: false,
            errorText: '',
            accountNotVerified: false,
            showModal: this.detectBrowser() === 0 ? true : false,
            isCapsLockOn: false
        };
    }

    public componentDidMount() {
        const currentNode = this.emailRef.current;
        if(currentNode) {
            currentNode.focus();
        }
    }

    public render() {
        const { requestingLogin } = this.props;
        const commonProps = {
            onSubmit: this.login,
            size: 'lg' as 'lg'
        };
        let buttonColor = 'blue';
        let textColor = 'white';
        let widthBtn = 'login-modal-btn';

        return (
            <BlueGeometricBackground className="login-container">
                {/* Header text */}
                <div className="row login__header">
                    <div className="col-12">
                        <a href="https://www.winwardacademy.com">
                            <img 
                                alt="Winward Academy Logo"
                                src={'/assets/images/winward-academy-logo-white.svg'} 
                                className="wa-logo"
                            />
                        </a>
                    </div>
                    <div className="col-12">
                        <h1>Log In</h1>
                    </div>
                    <div className="col-12">
                        <h3>Please provide your email and password.</h3>
                    </div>
                </div>

                {/* Form */}
                <div className="row">
                    <div className="col-12 text-center">
                        <div className="form">
                            {/* Email */}
                            <TextInput
                                id="login-email"
                                invalid={this.state.hasError}
                                labelText="Email Address"
                                value={this.state.username}
                                onChange={this.onUsernameChange}
                                inputType="email"
                                inputRef={this.emailRef}

                                {...commonProps}
                            />

                            {/* Password */}
                            <TextInput
                                id="login-password"
                                invalid={this.state.hasError}
                                labelText="Password"
                                value={this.state.password}
                                onChange={this.onPasswordChange}
                                onKeyUp={this.onPasswordKeyUp}
                                inputType="password"
                                {...commonProps}
                            />

                            {this.state.isCapsLockOn &&
                              <div className="row">
                                <div className="col-12 text-center">
                                  <p className="caps-lock-message">Did you mean for Caps Lock to be on?</p>
                                </div>
                              </div>
                            }

                            {/* Login Button */}
                            <Button
                                text={requestingLogin ? 'LOADING...' : 'LOGIN'}
                                className="login__button"
                                onClick={this.login}
                                bgColor="green"
                                disabled={requestingLogin}
                            />

                            {/* Error text */}
                            {this.state.hasError &&
                                <div style={{textAlign: "center"}}>
                                    <span className="login__error-text">
                                        {this.state.accountNotVerified ?
                                            <VerificationError /> :
                                            this.state.errorText || 'Invalid email/password combination'}
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 login__forgot-password">
                        <Link to={`${process.env.REACT_APP_BASE_URL}reset-password`}>Forgot Password?</Link>
                    </div>
                </div>
                <div className="loginButtonWrap">
                    <CleverLoginButton/>
                    <ClassLinkLoginButton/>
                </div>
                <div className="row">
                    <div className="col-12 login__sign-up-prompt">
                        <p>Don't have an account? <a href="https://www.winwardacademy.com/pricing">Sign Up</a></p>
                        <p>It's quick and easy.</p>
                    </div>
                </div>
                <Modal className="heading-banner-login modal" show={this.state.showModal} onClose={this.toggleModal}>
                    <div className="row text-center">
                        <div className="col-lg-12">
                            <h1 className="cushion-element-login">Welcome to Winward Academy!</h1>
                        </div>
                        <div className="body-login coverBottom-login">
                            <div className="cushion-heading-login">
                                <h3 className="dark-blue-text-login text-center">We’re thrilled you’re here!</h3>
                                <h3 className="dark-blue-text-login text-center">
                                    Please note that this site only works in up-to-date browsers.
                                    Come back with a recent version of Chrome, Safari, Edge, or Firefox.</h3>
                            </div>
                            <Button
                                text="I UNDERSTAND"
                                textColor={textColor as any}
                                bgColor={buttonColor as any}
                                className={widthBtn as any}
                                onClick={this.toggleModal}
                            />
                        </div>
                    </div>
                </Modal>
            </BlueGeometricBackground>

        );
    }

    private toggleModal() {
        this.setState({ showModal: !this.state.showModal });
        window.location.href = 'https://winwardacademy.com';
    }

    @boundMethod
    private onUsernameChange(e: any) {
        const username = e.target.value;
        this.setState({ username });
    }

    @boundMethod
    private onPasswordKeyUp(e: any) {
      if (e.getModifierState) {
        if (e.getModifierState('CapsLock')) {
          this.setState({ isCapsLockOn: true });
        } else {
          this.setState({ isCapsLockOn: false });
        }
      }
    }

    @boundMethod
    private onPasswordChange(e: any) {
      const password = e.target.value;
      this.setState({ password });
    }

    @boundMethod
    private login() {
        setSessionStorageItem('x-win-user', this.state.username);
        deleteSessionStorageByID('x-win-token');
        if(!this.state.username) {
            this.setState({ accountNotVerified: false, hasError: true, errorText: 'Email cannot be empty' });
            return;
        }
        if(!this.state.password) {
            this.setState({ accountNotVerified: false, hasError: true, errorText: 'Password cannot be empty' });
            return;
        }
        this.props.loginUser(this.state.username, this.state.password)
            .then(this.onLoginSuccess)
            .catch(this.onLoginError);
    }

    @boundMethod
    private onLoginSuccess(response: UserInfo) {
        this.setState({
            isLoading: false,
            hasError: false,
            errorText: '',
            accountNotVerified: false
        }, this.routeToHome);
    }

    @boundMethod
    private onLoginError(e: any) {
        if (e.body && e.status === 401) {
            e.text().then(output =>  {
                let accountNotVerified = false;
                if (output === 'The account has not been verified.') {
                    accountNotVerified = true;
                }
                this.setState({ 
                    isLoading: false, 
                    hasError: true, 
                    errorText: output, 
                    accountNotVerified: accountNotVerified 
                });
            }).catch(err => {
                console.log({ jsonParseErr: err });
            });
        } else {
            const accountNotVerified = e.status === 403;
            this.setState({ isLoading: false, hasError: true, errorText: '', accountNotVerified });
        }
    }

    private routeToHome() {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        this.props.history.push(baseUrl + 'app');
    }

    // OT: This has to be fixed at some day!
    private detectBrowser() {
        const ua = navigator.userAgent;
        let browser = '';
        let version = 0;

        if (/Firefox[\/\s](\d+\.\d+)/.test(ua)) {
            browser = 'firefox';
            version = Number(RegExp.$1);
        } else if (ua.lastIndexOf('Chrome/') > 0) {
            version = Number(ua.substr(ua.lastIndexOf('Chrome/') + 7, 5));
            browser = 'chrome';
        } else if (ua.lastIndexOf('Safari/') > 0) {
            version = Number(ua.substr(ua.lastIndexOf('Safari/') + 7, 5));
            browser = 'safari';
        } else {
            if(ua.lastIndexOf('MSIE ') > 0) {
                version = 10;
                browser = 'ie';
            } else if(ua.lastIndexOf('Trident/') > 0) {
                version = 11;
                browser = 'ie';
            } else if(ua.lastIndexOf('Edge/') > 0) {
                version = 12;
                browser = 'edge';
            } else if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(ua)) {
                browser = 'mobile';
            }
        }

        switch(browser) {
            case 'firefox':
                if( version < parseInt(process.env.REACT_APP_MIN_FIREFOX_VERSION || '0') ) {
                    return 0;
                }
                return 1;
            case 'chrome':
                if( version < parseInt(process.env.REACT_APP_MIN_CHROME_VERSION || '0')) {
                    return 0;
                }
                return 1;
            case 'safari':
                if( version < parseInt(process.env.REACT_APP_MIN_SAFARI_VERSION || '0')) {
                    return 0;
                }
                return 1;
            case 'ie':
                return 0;
            case 'mobile':
                return 1;
            case 'edge':
                return 1;
            default:
                return 0;
        }
    }
}

export default LoginWithoutRouting;
