import './MathCheatsheetLink.css';

import * as React from 'react';

const MathCheatsheetLink: React.SFC = () => {
    const onClick = () => 
        window.open('/assets/handouts/Math_Cheat_Sheet.pdf', '_blank');

    return (
        <p className="math-cheatsheet-link">
            <img src="/assets/images/icons/v2/icon-info.svg" width="20" height="20" alt="" /> Download the <a href="#" onClick={onClick}>math formula sheet</a> to make sure you know all the equations.
        </p>
    );
};

MathCheatsheetLink.displayName = 'MathCheatsheetLink';

export default MathCheatsheetLink;
