import React from 'react'

const ICON_BASE_PATH = "/assets/images/icons/v2"
const ICON_NAME = "ico-reteach"

export interface Props {
    isSelected: boolean
    onClick: () => void
}

/**
 * Reteach button
 * Icons used:
 *  + ico-reteach.svg
 *  + ico-reteach-active.svg
 *  + ico-reteach-hover.svg
 * 
 * @param props 
 * @returns 
 */
export function ReteachPlus(props: Props) {
    let { isSelected, onClick } = props
    let img =  `${ICON_BASE_PATH}/${isSelected ? ICON_NAME + "-active" : ICON_NAME}.svg`
    let imgHover = `${ICON_BASE_PATH}/${ICON_NAME}-hover.svg`

    return (
        <button className="btn-div__inline btn-has-hover-img" title="Toggle reteach" onClick={onClick}>
            <img src={img} className="non-hover-icon" alt=""/>
            <img src={imgHover} className="hover-icon d-none" alt=""></img>
        </button>
    )
}
