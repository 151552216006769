import * as React from 'react'
import './LessonSummaryOverview.css'
import ReportLoadingMessage from './ReportLoadingMessage'
import Modal from '../../components/Modal/ModalEmail'
import { sendStudentCKReport } from '../../services/elearn/lesson-rest-interface'
import { CKLessonReportSummary } from '../../services/elearn/lesson-types'
import SummaryFilters from './SummaryFilters'
import { TeacherStore, ITeacherStoreData } from '../../stores/teacher.store'
import { GraduationYearDescriptor } from './types'
import CKLessonSummary from '../../components/CollegeKnowledgeLessonSummary'
import { NO_STUDENT_INFO_FOUND } from './info'

export interface Props { }

interface State {
  selectedSchoolName?: string
  selectedGraduationYear?: string
  data: CKLessonReportSummary[]
  schools: string[]
  graduationYears: GraduationYearDescriptor[]
  showEmailNotificationModal: boolean
  selectedTimeRange?: string
  loaded: boolean
}

export default class CKLessonSummaryOverview extends React.Component<Props, State> {
  unsubscribe
  
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedSchoolName: undefined,
      data: [],
      schools: [],
      graduationYears: [],
      showEmailNotificationModal: false,
      loaded: false
    };
  }

  public componentDidMount() {
    this.loadCKLessonSummaries()
    this.unsubscribe = TeacherStore.subscribe(d => {
      this.setMainData(d)
    })
    this.setMainData(TeacherStore.data)
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  setMainData(data: ITeacherStoreData) {
    let { ckLessonSummaries } = data
    this.setState({ 
      data: ckLessonSummaries,
      loaded: true
    })    
  }

  sendLessonReport() {
    sendStudentCKReport();
    this.setState({
      showEmailNotificationModal: true
    });
  }

  loadCKLessonSummaries() {
    const { selectedSchoolName, selectedGraduationYear, selectedTimeRange } = this.state;
    const schoolParam = selectedSchoolName !== undefined ? selectedSchoolName : undefined;
    const gradYearParam = selectedGraduationYear !== undefined ? selectedGraduationYear : undefined;
    TeacherStore.loadCKLessonSummaries(schoolParam, gradYearParam, selectedTimeRange)
  }

  onNewSchoolSelected(newSelectedSchool: string) {
    this.setState({ data: [], loaded: false });
    this.setState({
        selectedSchoolName: newSelectedSchool === 'All' ? 
          undefined : newSelectedSchool 
      }, () => this.loadCKLessonSummaries()
    );
  }

  onTimeRangeSelected(newlySelected: string) {
    this.setState({ data: [], loaded: false });
    this.setState({
      selectedTimeRange: newlySelected }, 
      () => this.loadCKLessonSummaries()
    );
  }

  onGraduationYearSelected(selectedYear: string) {
    this.setState({ data: [], loaded: false });
    this.setState(
      { selectedGraduationYear: selectedYear === 'All' ?  
          undefined : selectedYear
      }, 
      () => this.loadCKLessonSummaries()
    );
  }

  public render() {
    const { data, loaded } = this.state;

    return (
        <div className="lesson-overview">
          <SummaryFilters 
            enableTimeRangeSelection={true}
            onNewGraduationYearSelected={(gradYear) => this.onGraduationYearSelected(gradYear)}
            onNewSchoolSelected={(schoolSel) => this.onNewSchoolSelected(schoolSel)}
            onNewTimeRangeSelected={(timeRange) => this.onTimeRangeSelected(timeRange)}
          />
          <div className="individual-data-section--2col">
            <h3 className="individual-data-title">College Knowledge Summary</h3>
            <div className="title-sidebar">
              <a title="Email Report" className="iconLink--left img-rel" href="#" onClick={() => this.sendLessonReport()}>
                <img src="/assets/images/icons/v2/ico-email.svg" width="14" height="11" alt="" />
                <span className="label def">Email Report</span>
              </a>
            </div>
          </div>
          <hr className="half" />
          {/* <p>
            You can <a href="#" onClick={() => this.sendLessonReport()}> 
            email the full college knowledge lesson report 
            </a> or view summary information below.
          </p> */}

          {!loaded && <ReportLoadingMessage />}

          {data.map(d => {
            return (
              <CKLessonSummary 
                key={'ck-lesson-summary-' + d.categoryName}
                summaryData={d} 
              />
              )
            })
          }
          
          {loaded && data.length == 0 && NO_STUDENT_INFO_FOUND}

          <Modal 
            show={this.state.showEmailNotificationModal}
            onClose={() => this.setState({ showEmailNotificationModal: false })}
          >
            <div className="lesson-summary-email-notification-modal">
              <h3>College Knowledge Report Is On Its Way</h3>
              <p>
              The full lesson report for your students is on its 
              way and will arrive via email in the next 2-3 minutes.
              </p>
            </div>
          </Modal>
        </div>
    );
  }
}
