import './StaySharpTopicTables.css';

import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { TopicSummary } from '../../../../../../../services/elearn/lesson-types';
import DataGrid from '../../../../../../../components/DataGrid';
import { Column } from '../../../../../../../components/DataGrid/types/types';
import { RowData } from '../../../../../../../components/DataGrid/types/types';
import LessonListTitle from '../../../../../../../components/LessonList/components/LessonListTitle/LessonListTitle';
import Button from '../../../../../../../components/Button/Button';
import { setSSCurrentLesson } from '../../../../../../../services/user/user-lesson-rest-interface';

interface Props {
    topicData: TopicSummary[];
    showCompletionModal: (topicName: string) => void;
}

type AllProps = Props & RouteComponentProps<any>;

const StaySharpTopicTables: React.SFC<AllProps> = (props: AllProps) => {

    enum TopicStatus {
        NOT_STARTED,
        STARTED,
        COMPLETED
    }

    const topicColumns: Column[] = [
        {
            title: 'TOPIC',
            dataProperty: 'topic',
            width: '70%',
        },
        {
            title: 'QUESTIONS',
            dataProperty: 'questions',
            width: '20%'
        },
        {
            title: 'STATUS',
            dataProperty: 'status',
            width: '220px'
        },
    ];

    function createLessonData(topic: TopicSummary, i: number): RowData {
        return {
            data: {
                topic: {
                    content: createLessonTitle(topic),
                    comparator: topic.topicName
                },
                questions: {
                    content: formatQuestions(topic),
                    comparator: topic.completedQuestions || 0
                },
                status: {
                    content: createStatusButton(topic),
                    comparator: topic.status
                }
            },
            id: `topic-data-${i}`
        };
    }

    function createLessonTitle(topic: TopicSummary) {
        const lessonStatusInfo = getLessonTitleIcon(topic);
        const icon = '/assets/images/icons/v2/' + lessonStatusInfo.img;
        // const title = getStatusButtonData(topic).text;
        // no click event here
        return () => (
            <LessonListTitle
                enabled={true}
                icon={icon}
                alt={lessonStatusInfo.alt}
                // title={title}
                lessonName={topic.topicName}
            />
        );
    }

    function getLessonTitleIcon(topic: TopicSummary) {
        switch(topic.status) {
            case 'Keep Going': return { img: 'ico-resume.svg', alt: 'Keep Going' };
            case 'All Done': return { img: 'ico-summary.svg', alt: 'All Done' };
            default: return { img: 'ico-start.svg', alt: 'Try More' };
        }
    }

    function formatQuestions(topic: TopicSummary) {
        if(!topic.totalQuestions) { return 'N/A'; }
        return `${topic.completedQuestions}/${topic.totalQuestions}`;
    }

    function createStatusButton(topic: TopicSummary) {
        const statusButtonData = getStatusButtonData(topic);
        return () => (
            <Button
                textColor={'white'}
                bgColor={statusButtonData.color as any}
                text={statusButtonData.text}
                className="lesson-list__btn"
                onClick={() => goToStaySharp(topic)}
            />
        );
    }

    function getStatusButtonData(topic: TopicSummary) {
        const status = determineTopicStatus(topic);
        switch(status) {
            case TopicStatus.STARTED: return { text: 'KEEP GOING', color: 'green' };
            case TopicStatus.COMPLETED: return { text: 'ALL DONE', color: 'purple' };
            default: return { text: 'TRY MORE', color: 'blue' };
        }
    }

    function determineTopicStatus(topic: TopicSummary) {
        if(topic.status === 'All Done') {
            return TopicStatus.COMPLETED;
        }
        if(topic.status === 'Keep Going') {
            return TopicStatus.STARTED;
        }
        return TopicStatus.NOT_STARTED;
    }

    const data = createLessonData;
    const topicData = props.topicData.map(data);

    return (
        <div className="stay-sharp-dashboard-container">
            <DataGrid
                columns={topicColumns}
                data={topicData}
                emptyText="No lessons to display"
                className={`lesson-header-theme--` + props.topicData[0].subject.toLowerCase()}
            />
        </div>
    );

    function goToStaySharp(topicSummary: TopicSummary) {
        if (topicSummary.status === 'All Done') {
            // TODO: POP MODAL HERE
            props.showCompletionModal(topicSummary.topicName);
        } else {
            setSSCurrentLesson(topicSummary.lessonId, topicSummary.topicId).then(() => {
                props.history.push(`stay-sharp/${topicSummary.lessonId}/${topicSummary.topicId}`);
            });
        }
        
    }
};

StaySharpTopicTables.displayName = 'StaySharpTopicTables';

export default StaySharpTopicTables;
