import './TestAnalysis.css';

import * as React from 'react';

import { RouteComponentProps, Link } from 'react-router-dom';
import { PageContent, PageTitle } from '../PageContent/PageContent';
import Loading from '../Loading/Loading';
import { getPTAnalysis, getIAAnalysis } from '../../services/practice-test-scoring/practice-test-service';
import TestCard from './components/TestCard/TestCardContainer';
import { SimpleTimer } from '../../services/utils/simple-timer';
import { Context, Resource, UserActivityEx, sendUserActivity } from '../../services/liveview/liveview-service';
import { SAT2024 } from "../../services/utils/practice-test-util";

export interface Props {
}

export interface StoreProps {
}

interface State {
    isLoaded: boolean;
    testData: any;
    testName: string;
    testTitle: string;
}

export type AllProps = Props & StoreProps & RouteComponentProps<any>;

export class TestAnalysis extends React.Component<AllProps, State> {
  simpleTimer = new SimpleTimer()

  constructor(props: AllProps) {
    super(props);
    this.state = {
        isLoaded: false,
        testData: [],
        testName: props.match.params.testName,
        testTitle: '',

    };
  }

  public componentDidMount() {
    const testName = this.props.match.params.testName;
    if (testName.startsWith('ACT') ||
    testName === 'Diagnostic' ||
    testName.startsWith('SAT') ||
    testName === 'DiagnosticSAT') {
        const testId = this.props.match.params.testName;
        getPTAnalysis(testId).then((res) => {
            this.setState({
                testData: res,
                isLoaded: true,
                testTitle: testId
            });
        });    
    } else {
        getIAAnalysis(this.props.match.params.testName).then((res) => {
            // console.log('IA', res);
            // console.log(this.props.match.params.testName);
            const iaDisplayName = this.getIADisplayName(
                this.props.match.params.testName
            );
            this.setState({
                testData: res,
                isLoaded: true,
                testTitle: this.getIADisplayName(
                    this.props.match.params.testName
                )
            });
        });
    }
  }

  componentWillUnmount() {
    this.sendStats()
  }

  /**
   * In the Practice tests a user can visit the analysis view multiple times
   * so we nee to allow multiple entries for this resource, hence the 
   * sendUseractivity has a boolean flag set to true.
   */
  sendStats() {
    let resource = Resource.ANALYSIS
    let {testName} = this.state
    let seconds = this.simpleTimer.diffInSeconds()
    let ended = seconds
    let context = Context.TEST
    let contextId = testName
    let category = "Analysis"
    let resourceId = `${testName}`
    let userActivityEx: UserActivityEx = {
        context, contextId, 
        resource, resourceId, 
        category,
        ended
    }
    sendUserActivity(userActivityEx, true)
    this.simpleTimer.reset()
  }
 
  public render() {
    const { testData, isLoaded, testTitle } = this.state;
    const titleAllCaps = testTitle.toUpperCase();
    if (!isLoaded) {
        return <Loading />;
    }
    const testName = this.props.match.params.testName;
    const link = '/app/practice-tests/scoring/' + testName;
    const testType = getTestTypeBasedOnName(testName)
    const summaryLink = `/print/practice-tests/summary/${testType}/${testName}`
    let type = 'ACT';

    if (testName.startsWith('SAT') || testName === 'DiagnosticSAT' ) {
        type = 'SAT';
    }

    const sortByTotal = (a, b) => b.total - a.total;
    // TODO: This logic should be extracted consolidated on client 
    // OR be received from server
    if (type === 'ACT') {
        let newTestTitle = testName;
        let newTitleAllCaps = titleAllCaps;
        if(testName === 'Diagnostic') {
            newTestTitle = 'ACT Diagnostic';
            newTitleAllCaps = 'ACT DIAGNOSTIC';
        } else if (testName.startsWith('ACT')) {
            // WORST HACKs now happening
            const baseName = testName.slice(0, 3);
            const testNumber = testName.slice(3, 4);
            newTestTitle = `${baseName} Test ${testNumber}`;
            newTitleAllCaps = newTestTitle.toUpperCase();
        } else {
            // It's an IA Test
            newTestTitle = this.getIADisplayName(testName);
            newTitleAllCaps = newTestTitle.toUpperCase();
        }

        const readingLessonOrder = [
            'Prose Fiction', 'Social Science', 'Humanities', 'Natural Science'
        ];
        const readingAnalysisFixedOrder = readingLessonOrder.map( lessonText => 
            testData.readingAnalysis.find(r => r.lessonId.includes(lessonText))
        );

        testData.englishAnalysis.sort(sortByTotal);
        testData.mathAnalysis.sort(sortByTotal);
        testData.scienceAnalysis.sort(sortByTotal);

        return (
            <PageContent className="analysis-container">
            <PageTitle title={`${newTestTitle} Analysis`}>
                <div className="navigation-button-container">
                    <div className="pr-2">
                        <SummaryLink link={summaryLink}/>
                    </div>
                    <div>
                        <Link className="title-navigation-buttons btn btn--text-white btn--bg-green" to={link}>
                            BACK TO {newTitleAllCaps} SCORING
                        </Link>
                    </div>
                </div>
            </PageTitle>
                <div className="test-card-container">
                    <div className="d-flex flex-direction-row align-items-center mt-3 margin-bottom-20">
                        <div className="lesson-list-card-header col-12 purple">
                            <span className="ribbon purple">
                            <img alt="" src="/assets/images/icons/subjects/ico-subject-english.svg" width="28" height="27" />
                            </span>
                            <span>English</span>
                        </div>
                    </div>
                    
                    <TestCard
                        backgroundColor="purple-header"
                        testData={testData.englishAnalysis}
                        subject="English"
                    />
                </div>

                <div className="test-card-container">
                    <div className="d-flex flex-direction-row align-items-center mt-3 margin-bottom-20">
                        <div className="lesson-list-card-header col-12 blue">
                            <span className="ribbon blue">
                            <img alt="" src="/assets/images/icons/subjects/ico-subject-math.svg" width="28" height="27" />
                            </span>
                            <span>Math</span>
                        </div>
                    </div>
                    <TestCard
                        backgroundColor="blue-header"
                        testData={testData.mathAnalysis}
                        subject="Math"
                    />
                </div>

                <div className="test-card-container">
                    <div className="d-flex flex-direction-row align-items-center mt-3 margin-bottom-20">
                        <div className="lesson-list-card-header col-12 orange">
                            <span className="ribbon orange">
                            <img alt="" src="/assets/images/icons/subjects/ico-subject-reading.svg" width="28" height="27" />
                            </span>
                            <span>Reading</span>
                        </div>
                    </div>
                    <TestCard
                        backgroundColor="orange-header"
                        testData={readingAnalysisFixedOrder}
                        subject="Reading"
                    />
                </div>

                <div className="test-card-container">
                    <div className="d-flex flex-direction-row align-items-center mt-3 margin-bottom-20">
                        <div className="lesson-list-card-header col-12 green">
                            <span className="ribbon green">
                            <img alt="" src="/assets/images/icons/subjects/ico-subject-science.svg" width="28" height="27" />
                            </span>
                            <span>Science</span>
                        </div>
                    </div>
                    <TestCard
                        backgroundColor="green-header"
                        testData={testData.scienceAnalysis}
                        subject="Science"
                    />
                </div>
            </PageContent>
        );
    } else {
        let newTestTitle = testName;
        let newTitleAllCaps = titleAllCaps;
        if(testName === 'DiagnosticSAT') {
            newTestTitle = 'SAT Diagnostic';
            newTitleAllCaps = 'SAT DIAGNOSTIC';
        } else {
            const baseName = testName.slice(0, 3);
            const testNumber = testName.slice(3, 4);
            newTestTitle = `${baseName} Test ${testNumber}`;
            newTitleAllCaps = newTestTitle.toUpperCase();
        }

        testData.readingAnalysis.sort(sortByTotal);
        testData.writingAndLanguageAnalysis.sort(sortByTotal);
        testData.mathCalculatorAnalysis.sort(sortByTotal);
        testData.mathNoCalculatorAnalysis.sort(sortByTotal);

        return (
            <PageContent className="analysis-container">
            <PageTitle title={`${newTestTitle} Analysis`}>
                    <div className="navigation-button-container">
                        <div className="pr-2">
                            <SummaryLink link={summaryLink}/>
                        </div>
                        <div>
                            <Link className="title-navigation-buttons btn btn--text-white btn--bg-green" to={link}>
                                BACK TO {newTitleAllCaps} SCORING
                            </Link>
                        </div>
                    </div>
            </PageTitle>
                <div className="test-card-container">
                    <div className="d-flex flex-direction-row align-items-center mt-3 margin-left-20 margin-bottom-20">
                        <img
                            src={`/assets/images/WWA_Reading.svg`}
                            width="30"
                            height="30"
                        />
                        <h2 className="subject-title d-flex reading-orange margin-left-10">{SAT2024.DISPLAYNAME_RW_MODULE1}</h2>
                    </div>

                    <TestCard
                        backgroundColor="orange-header"
                        testData={testData.readingAnalysis}
                        subject={SAT2024.SECTIONNAME_RW_MODULE1}
                    />
                </div>

                <div className="test-card-container">
                    <div className="d-flex flex-direction-row align-items-center mt-3 margin-left-20 margin-bottom-20">
                        <img
                            src={`/assets/images/WWA_English.svg`}
                            width="30"
                            height="30"
                        />
                        <h2 className="subject-title d-flex english-purple margin-left-10">{SAT2024.DISPLAYNAME_RW_MODULE2}</h2>
                    </div>

                     <TestCard
                        backgroundColor="purple-header"
                        testData={testData.writingAndLanguageAnalysis}
                        subject={SAT2024.SECTIONNAME_RW_MODULE2}
                    />
                </div>

                <div className="test-card-container">
                    <div className="d-flex flex-direction-row align-items-center mt-3 margin-left-20 margin-bottom-20">
                        <img
                            src={`/assets/images/WWA_Math.svg`}
                            width="30"
                            height="30"
                        />
                        <h2 className="subject-title d-flex math-blue margin-left-10">{SAT2024.DISPLAYNAME_MATH_MODULE1}</h2>
                    </div>

                    <TestCard
                        backgroundColor="blue-header"
                        testData={testData.mathNoCalculatorAnalysis}
                        subject={SAT2024.SECTIONNAME_MATH_MODULE1}
                    />
                </div>

                <div className="test-card-container">
                    <div className="d-flex flex-direction-row align-items-center mt-3 margin-left-20 margin-bottom-20">
                        <img
                            src={`/assets/images/WWA_Math.svg`}
                            width="30"
                            height="30"
                        />
                        <h2 className="subject-title d-flex math-blue margin-left-10">{SAT2024.DISPLAYNAME_MATH_MODULE2}</h2>
                    </div>
                    <TestCard
                        backgroundColor="blue-header"
                        testData={testData.mathCalculatorAnalysis}
                        subject="Math (Calculator)"
                    />
                </div>
            </PageContent>
        );
    }
  }

    private getIADisplayName(testName: string) {
        switch(testName) {
            case 'Pre-Test': 
                return 'Pre-Test 2018';
            // case 'Post-Test': 
            // case 'Practice 1': 
            // case 'Practice 2': 
            // case 'Practice 3':
            case 'Pre-Test 2019': 
            case 'Mid-Test': 
            case 'IA 1': 
            case 'IA 2':
            case 'IA 3':
                return testName;
            default: {
                // console.log('default? shold not haap');
                return testName;
            }
        }
    }
}

// OT: Based on Rustam's hack :)
function getTestTypeBasedOnName(testName: string) {
    let testType = "?"

    if(testName === "Diagnostic" || testName.startsWith("ACT") ) {
        testType = "ACT"
    } else if (testName === "DiagnosticSAT" || testName.startsWith("SAT")) {
        testType = "SAT"
    } else {
        // It's an IA Test
        testType = "IA"
    }

    return testType
}

function SummaryLink(props) {
    let {link} = props

    return (
        <Link to={link} target="blank">
            <button className="title-navigation-buttons btn btn--bg-blue mb-2">
                VIEW REPORT
            </button>
        </Link>
    )
}
