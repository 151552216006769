import './Logout.css';

import * as React from 'react';

import BlueGeometricBackground from '../../components/BlueGeometricBackground/BlueGeometricBackground';
import Button from '../../components/Button/Button';

const Logout: React.SFC<{}> = (props) => {
    // const close = () => {
    //     window.open('', '_self').close();
    // };

    return (
        <BlueGeometricBackground className="logout">
            <div className="logout__message-container">
                <h1>Logout Successful</h1>
                <p>We look forward to your next time studying with us!</p>

                <div className="logout__nav-btn-container">
                    {/*<div className="logout__nav-btn">
                        <Button text="CLOSE WINDOW" textColor="white" bgColor="blue" onClick={close} />
                    </div>*/}
                    <div className="logout__nav-btn">
                        <Button
                            text="GO TO HOME PAGE"
                            textColor="white"
                            bgColor="blue"
                            link="https://www.winwardacademy.com/"
                        />
                        <Button
                            text="LOGIN AGAIN"
                            textColor="default"
                            bgColor="green"
                            link="/login"
                        />
                    </div>
                </div>
            </div>
        </BlueGeometricBackground>
    );
};

Logout.displayName = 'Logout';

export default Logout;
