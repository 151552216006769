import React, { Component } from 'react'
import { FilterSelections, prepareSelectionsInfo, TestKind, TimeFrame } from '../SuperintendentFilters'
import { getSubjectIcon } from '../../../services/icon/icon-service'
import ConversionModal from '../../../components/ConversionModal/ConversionModal'
import CollapsableCard, { CollapsableCardBody, CollapsableCardHeader } from '../../../components/CollapsableCard/CollapsableCard'
import ResourceListCardHeader from '../../../components/ResourceListCardHeader'
import { CATEGORY_ENGLISH, CATEGORY_MATH, CATEGORY_READING, CATEGORY_SCIENCE, COLLEGE_READY_COLOR_THRESHOLD, NA } from '../../../constants'
import { LessonPerformance } from './LessonPerformance'
import { ICategoricalLessonsPerformances, IGroupedLessonsMap } from '../../../stores/superintendent-store'

const defaultColor = "#10a492"

function formatTableHeader(first: string, second: string, third: string) {
  return (
    <div>
      <div className="th-tooltip">
        <div>{first}</div>
        <div>{second} </div>
        <span className="th-tooltip-text">{third}</span>
        </div>
    </div>
  )
}

interface Props {
  data: ICategoricalLessonsPerformances
  allData: any
  filterSelections: FilterSelections
}

interface State {
  showSchoolSummary: boolean
  selectedSchool: undefined | any
  showScoreConversion: boolean
}


const lessonPerformanceDetailHeaders = [
  formatTableHeader('LESSON', '', 'Indicates the lesson name'),
  formatTableHeader('STUDENTS', '', 'Indicates the number of students participated'),
  formatTableHeader('COLLEGE', 'READY', 'Indicates the percent of students whose post-lesson accuracy meets the ACT/SAT threshold for "college ready"'),
  formatTableHeader('PRE-LESSON', 'ACCURACY', 'Calculated as the percent of baseline questions answered correctly before starting a lesson'),
  formatTableHeader('POST-LESSON', 'ACCURACY', 'Calculated as the number of correct questions divided by the number of questions answered'),
  formatTableHeader('LESSON', 'GROWTH', 'Calculated as the change in accuracy from pre-lesson accuracy to completed question accuracy'),
  formatTableHeader('HINTS ON', 'INCORRECT', 'Reflects what percent of missed questions students write themselves problem solving hints'),
  formatTableHeader('TIME ON', 'QUESTIONS', 'Indicates average and total time answering multiple choice questions'),
  formatTableHeader('TOTAL', 'STUDY TIME', 'Indicates total time spent answering baseline questions, watching the video lesson, and answering multiple choice questions'),
]

export default class LessonsPerformanceStats extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      showSchoolSummary: false,
      selectedSchool: undefined,
      showScoreConversion: false
    }
  }

  getCardBodyContentForLessonsPerformances(groupedLessonsMap: IGroupedLessonsMap, category: string, testKind: TestKind, colorName?: string) {
    let lessonIds = Object.keys(groupedLessonsMap) ||  []
    
    return (
      <div className="fixedHeaderTable">
        <table className={`lesson-summary-table usage-time-summary-table support-cols--center ${colorName}-header`}>
          <thead className="theme-thead">
            <tr>
              {lessonPerformanceDetailHeaders.map((headerName, index) =>
                <th className={`label-header ${(index < 2) ? "label-type1": "label-type2"}`} key={`th-${headerName}-${index}`}>
                  {headerName}
                </th>
              )}
            </tr>
          </thead>
          <tbody className="school-detail-body">
            {lessonIds.map((lessonId, index) => {
              let groupedLesson = groupedLessonsMap[lessonId]
              return <LessonPerformance key={lessonId} idx={index} category={category} groupedLesson={groupedLesson} testKind={testKind}/>
            })} 
          </tbody>
        </table>
      </div>
    )
  }

  showSchoolDetails = (school) => {
    this.setState({showSchoolSummary: true, selectedSchool: school})
  }

  render() {
    let {data, filterSelections} = this.props
    let categories = [CATEGORY_ENGLISH, CATEGORY_MATH, CATEGORY_READING, CATEGORY_SCIENCE]
    let summaryHeadline = prepareSelectionsInfo(filterSelections).selectionInfoForLessons
    let {testScoreKind} = filterSelections

    return (
      <div style={{paddingTop: '30px'}}>
        <div className="">
          <div className="individual-data-section--2col siview-data-title">
            <h3 className="individual-data-title">Lesson Performance Summary</h3>
            <div className="title-sidebar">
              <a title="View Conversion Table" href="#" className="iconLink--left img-rel" onClick={() => this.setState({ showScoreConversion: true})}>
                <img src="/assets/images/icons/v2/ico-modal.svg" width="11" height="11" alt="" />
                <span className="label def">View Conversion Table</span>
              </a>
            </div>
          </div>
          <hr className="half mb-2" />
          <div className="summary-headline" dangerouslySetInnerHTML={{ __html: summaryHeadline }} />
          <div className="school-stats-summary">
            {categories.map(category => {
              let iconPath = `/assets/images/icons/subjects/ico-subject-${category.replace(/\s/g, '').toLowerCase()}.svg`
              let si = getSubjectIcon(category)
              let color = si ? si.color: defaultColor 
              let colorName = si ? si.cssColor : ''
              let categorialLessonsPerformances: IGroupedLessonsMap = data[category]

              return (
                <CollapsableCard key={category}>
                    <CollapsableCardHeader>
                        <ResourceListCardHeader
                            text={category}
                            color={color}
                            icon={iconPath}
                        />
                    </CollapsableCardHeader>

                    <CollapsableCardBody> 
                      {categorialLessonsPerformances && this.getCardBodyContentForLessonsPerformances(categorialLessonsPerformances, category, testScoreKind, colorName)}
                    </CollapsableCardBody>
                </CollapsableCard>
              )}
            )}
          </div>
        </div>

        <ConversionModal 
            show={this.state.showScoreConversion}
            onClose={() => this.setState({ showScoreConversion: false})}/>
        
      </div>
    )
  }
}
