import React from "react"
import { capitilizeFirstLetter } from "../../../../services/utils/text-util"
import PriorityStar from "../../../Practice/components/PriorityStar/PriorityStar"
import { getUserLessons } from "../../../../services/user/user-lesson-rest-interface"
import { updatePriorityStarOfLesson } from "../../../../services/elearn/teacher/priority-star"
import { LessonProgress } from "../../../../services/elearn/lesson-types"

interface TestInsightDetailsbySectionViewProps {
  sectionName: string
  details: any
}

interface TestInsightDetailsbySectionViewPropsState {
  progressiveAccuracyChecked: boolean
  lessons: LessonProgress[]
}

export class TestInsightDetailsbySectionView extends React.Component<TestInsightDetailsbySectionViewProps, TestInsightDetailsbySectionViewPropsState> {
  constructor(props) {
    super(props)
    this.state = {
      progressiveAccuracyChecked: false,
      lessons: []
    }
  }

  async componentDidMount() {
    // Get teacher's lessons (which shows the star status)
    let lessons = (await getUserLessons()).userLessonOverviews
    this.setState({lessons })
  }

  toggleProgressiveAccuracy = () => {
    this.setState((prevState) => ({
      progressiveAccuracyChecked: !prevState.progressiveAccuracyChecked,
    }))
  }

  /**
   * Update the priority of the lessons (i.e. star or unstar them accordingly)
   * This will update both teacher's and his/her students' priority star
   * 
   * @param lessonProgresses 
   * @param starred 
   * @returns 
   */
  handlePriorityStarForLessons = async (lessonProgresses: LessonProgress[], starred: boolean) => {
    if (!lessonProgresses) return

    lessonProgresses.forEach(lessonProgress => {
      let {lessonId} = lessonProgress
      updatePriorityStarOfLesson(lessonId, starred)
      lessonProgress.star = starred
    })
    this.setState({}) // Force refresh!
  }

  /**
   * Gets the corresponding topic lessons' progresses
   * @param topic 
   * @returns 
   */
  getTopicLessonsProgresses = (topic) => {
    let {lessons: lessonsAndStars} = this.state
    let {lessons: topicLessonsMap} = topic
    let topicLessons: LessonProgress[] = []

    Object.values(topicLessonsMap).forEach((topicLesson: any) => {
      let lesson = lessonsAndStars.find(las => las.lessonId === topicLesson.lessonId)
      if (lesson) {
        topicLessons.push(lesson)
      }
    })

    return topicLessons
  }

  /**
   * Finds out if the topic is priority starred or not based on the topic lessons' progress
   * If any of the topic lessons is starred then mark is as starred
   * @param topicLessonProgresses 
   * @returns true if at least one starred lesson progress found (i.e. selected as priority)
   */
  isTopicPriorityStarred = (topicLessonProgresses: LessonProgress[]) => {
    if (!topicLessonProgresses) return false
    let isStarred = false

    topicLessonProgresses.forEach((topicLessonProgress: LessonProgress) => {
      if (topicLessonProgress && topicLessonProgress.star) {
        isStarred = true
      }
    })

    return isStarred
  }

  render () {
    let {details} = this.props
    let {progressiveAccuracyChecked} = this.state;
    let {topics, highestFrequencies, lowestAccuracies} = details
    
    if (!topics) return null
    
    let isHighestFrequency = (topicName) => highestFrequencies.includes(topicName)
    let isLowestAccuracy = (topicName) => lowestAccuracies.includes(topicName)
    let topicNames = Object.keys(topics)

    topicNames.sort()

    return (
      <div className="test-details-by-section">
        <div className="progressive-tabs">
          <div className="progressive-tabs--inner">
            <label
              className={`progressive-checkbox ${
                !progressiveAccuracyChecked ? 'selected' : ''
              }`}
            >
              <button className="progressive-tabs--tab" onClick={this.toggleProgressiveAccuracy}>
                <span className="progressive-checkbox-innerlabel">ALL QUESTIONS</span>
              </button>
            </label>
            <label
              className={`progressive-checkbox ${
                progressiveAccuracyChecked ? 'selected' : ''
              }`}
            >
              <button className="progressive-tabs--tab" onClick={this.toggleProgressiveAccuracy}>
                <span className="progressive-checkbox-innerlabel">ANSWERED QUESTIONS</span>
              </button>
            </label>
          </div>
        </div>
        <table className="lesson-summary-table progressive-summary-table">
          <thead>
            <th className="section-detail-topic-title">LESSONS AND TOPICS</th>
            <th>FREQUENCY</th>
            <th>ACCURACY</th>
            <th>PRIORITY</th>
          </thead>
          <tbody>
            {topicNames.map(topicName => {
              let highestFrequencyFound = isHighestFrequency(topicName)
              let lowestAccuracyFound = isLowestAccuracy(topicName)
              let topic = topics[topicName]
              let {nofTopicQuestions, overallAccurracy, accuracy: progressiveAccuracy, lessons} = topic
              let lessonNames = Object.keys(lessons)
              let accuracyInfo = `${Math.round(progressiveAccuracyChecked ? progressiveAccuracy: overallAccurracy)}%`
              let topicLessonsProgresses = this.getTopicLessonsProgresses(topic)            
              let isPriority = this.isTopicPriorityStarred(topicLessonsProgresses)
              
              lessonNames.sort()

              return (
                <>
                  <tr className={`section-detail-topic-row ${highestFrequencyFound ? "highlight-highest-frequency": ""} ${lowestAccuracyFound ? "emphasize-lowest-accuracy": ""}`}>
                    <td className={`topic-detail-first-item  ${lowestAccuracyFound ? "emphasize-lowest-accuracy": ""}`}>{topicName} {highestFrequencyFound ? <span className="bubble_hf">HIGH FREQUENCY</span> : ""}</td>
                    <td>{nofTopicQuestions}</td>
                    <td className={`${lowestAccuracyFound ? "lowest-accuracy-number": ""}`}>
                      {lowestAccuracyFound ? 
                        <span className="">
                          {accuracyInfo}
                          <img
                            className="ico-not-active"
                            src={`/assets/images/icons/v2/liveView/ico-not-active.svg`}
                            width="20"
                            height="19" 
                            alt=""
                            />
                        </span>
                        :
                        <>
                          {accuracyInfo}
                        </>
                      }
                      </td>
                      <td>
                        <PriorityStar isPriority={isPriority} onToggle={() => this.handlePriorityStarForLessons(topicLessonsProgresses, !isPriority)}/>
                      </td>
                  </tr>
                  {lessonNames.map(lessonName => {
                    let lesson = lessons[lessonName]
                    let {accuracy: progressiveAccuracy, overallAccurracy, uniqueLessonQuestions: nofLessonQuestions} = lesson
                    let accuracyInfo = `${Math.round(progressiveAccuracyChecked ? progressiveAccuracy: overallAccurracy)}%`
                    return (
                      <tr className={`section-detail-lesson-row ${highestFrequencyFound ? "highlight-highest-frequency": ""}`}>
                        <td className="lesson-detail-first-item">{lessonName}</td>          
                        <td>{nofLessonQuestions}</td>
                        <td colSpan={2}>{accuracyInfo}</td>
                      </tr>
                    )
                  })
                }
                </>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}
