import './ModalCard.css';

import * as React from 'react';

interface Props { 
    image: string;
    children: JSX.Element;
}

const ModalCard: React.SFC<Props> = (props) => {
    return (
        <div className="modal-card card h-100">
            <img className="modal-card-image" src={'/assets/images/dashboard/modal-cards/' + props.image} />
            <p className="modal-card-text">{props.children}</p>
        </div>  
    );
};

ModalCard.displayName = 'ModalCard';

export default ModalCard;
