import React from "react"
import SuperintendentFilters, { FilterSelections, SelectableItem, TestKind, TimeFrame, ViewBy } from "../SuperintendentFilters"
import { TestsSummary, getStudentsTestInsightDetailsBySchoolAndTestName, getStudentsTestInsightsBySchoolAndTestName, getStudentsTimeOnPlatformInfo } from "../../../stores/superintendent-store"
import { ViewBySchool } from "./ViewBySchool"
import { ViewByTest } from "./ViewByTest"
import Modal from "../../../components/Modal/Modal"
import { TestType, getTestDisplayName, getTestTypeBasedOnName } from "../../../services/utils/practice-test-util"
import { ITestInsightACT, ITestInsightSAT, getPracticeTestSectionDetails } from "../../../services/elearn/teacher/practice-tests-insights"
import TestInsightsForACT from "../../TeacherPortal/TestOverview/SummaryTable/TestInsightsForACT"
import HeadingBanner from "../../../components/HeadingBanner"
import Loading from "../../../components/Loading/Loading"
import TestInsightsForSAT from "../../TeacherPortal/TestOverview/SummaryTable/TestInsightsForSAT"
import "./index.css"
import { TestInsightDetailsbySectionView } from "../../TeacherPortal/TestOverview/SummaryTable/TestInsightDetailsSectionView"
import { getSubjectIcon } from '../../../services/icon/icon-service'

export interface IPracticeTestsViewProps {
  summary: TestsSummary
  filterSelections: FilterSelections
  onSchoolsChanged: (schools: SelectableItem[]) => void
  onGraduationYearsChanged: (graduationYears: SelectableItem[]) => void
  onTestKindChanged: (testKind: TestKind) => void
  onViewByChanged: (viewBy: ViewBy) => void
}

interface IPracticeTestsViewState {
  showInsights: boolean
  showInsightDetails: boolean
  insights: any[]
  insightDetails: any[]
  timeOnPlatformInfo: any[]
  testName: string
  testType: TestType
  loading: boolean
  errorMsg: string
  category: string
}

export class PracticeTestsView extends React.Component<IPracticeTestsViewProps, IPracticeTestsViewState> {
  constructor(props) {
    super(props)

    this.state = {
      showInsights: false,
      showInsightDetails: false,
      insights: [],
      insightDetails: [],
      timeOnPlatformInfo: [],
      testName: "",
      testType: TestType.ACT,
      loading: true,
      errorMsg: "",
      category: "",
    }
  }

  /**
   * Shows the Practice Tests insights for SI in a modal view (similar to the teacher) for students based on 
   * the school, test name and optional graduation year (if not provided then all students' insights will be shown).
   * This filters the graduations years for school based on the filter selections in UI.
   * Additionaly, it further filters the results if the user (SI) clicks on the hyperlink (Class of 2024, etc.)
   * 
   * @param schoolName 
   * @param testName 
   * @param graduationYear 
   */
  showStudentsTestsInsights = async (schoolName: string, testName: string, graduationYear?: string) => {
    let testType = getTestTypeBasedOnName(testName)
    let {filterSelections} = this.props
    let {graduationYears} = filterSelections
    let selectedGraduationYears = graduationYears.filter(gy => gy.selected).map(gy => gy.name)

    // Show modal first...
    this.setState({showInsights: true, loading: true, testName, testType})

    setTimeout(async () => {
      // Now, get the information (this may take sometime for SI!)
      let rawInsights = await getStudentsTestInsightsBySchoolAndTestName(schoolName, testName)
      let selectedInsights = rawInsights
        .filter(ri => selectedGraduationYears.includes(ri.graduationYear))
        .filter(i => !graduationYear ? true: graduationYear && i.graduationYear === graduationYear)
      let insights = selectedInsights
      
      let timeOnPlatformInfo = await getStudentsTimeOnPlatformInfo(schoolName)
      
      // Sort it by lastName & firstName
      insights.sort((i1, i2) => {
        let k1 = `${i1.lastName} ${i1.firstName}`
        let k2 = `${i2.lastName} ${i2.firstName}`
        return k1 < k2 ? -1 : 1
      })
      
      // Now, we are ready to display information within the modal view
      this.setState({insights, timeOnPlatformInfo, loading: false})
    }, 500)
  }

  showStudentsTestsDetailsByCategory = async (category: string, schoolName: string, testName: string, graduationYear?: string) => {
    let testType = getTestTypeBasedOnName(testName)
    let {filterSelections} = this.props
    let {graduationYears} = filterSelections
    let selectedGraduationYears = graduationYears.filter(gy => gy.selected).map(gy => gy.name)

    // Show modal first...
    this.setState({showInsightDetails: true, category, loading: true, testName, testType})

    setTimeout(async () => {
      // Now, get the information (this may take sometime for SI!)
      let rawData = await getStudentsTestInsightDetailsBySchoolAndTestName(schoolName, testName)
      
      let selectedData = rawData
        .filter(d => d)
        .filter(d => selectedGraduationYears.includes(d.graduationYear))
        .filter(d => !graduationYear ? true: graduationYear && d.graduationYear === graduationYear)

      let insightDetails = await getPracticeTestSectionDetails(category, testType, selectedData)

      // Now, we are ready to display information within the modal view
      this.setState({insightDetails, loading: false})
    }, 500)
  }

  closeInsights = () => {
    this.setState({showInsights: false})
  }

  closeInsightDetails = () => {
    this.setState({showInsightDetails: false})
  }

  render() {
    let {summary, filterSelections} = this.props
    let {viewBy} = filterSelections
    let {onSchoolsChanged, onGraduationYearsChanged, onTestKindChanged, onViewByChanged} = this.props
    let {showInsights, showInsightDetails, category, insights, insightDetails, testName, testType, loading, errorMsg, timeOnPlatformInfo} = this.state
    let displayName = getTestDisplayName(testName)
    
    return (
      <>
        <SuperintendentFilters
          filterSelections={filterSelections}
          onSchoolsChanged={onSchoolsChanged}
          onGraduationYearsChanged={onGraduationYearsChanged}
          onTestKindChanged={onTestKindChanged}
          onViewByChanged={onViewByChanged}
          enableTestTypeSelection={true}
          enableViewBySelection={true}
        />
        { viewBy === ViewBy.School ?
          <ViewBySchool
            summary={summary}
            filterSelections={filterSelections}
            onShowStudentsTestsInsights={this.showStudentsTestsInsights}
            onShowStudentsTestsDetailsByCategory={this.showStudentsTestsDetailsByCategory}
          /> 
          : 
          <ViewByTest
            summary={summary}
            filterSelections={filterSelections}
            onShowStudentsTestsInsights={this.showStudentsTestsInsights}
            onShowStudentsTestsDetailsByCategory={this.showStudentsTestsDetailsByCategory}
          /> 
        }

        {showInsights && 
          <InsightsModal 
            showInsights={showInsights} 
            displayName={displayName} 
            loading={loading} 
            errorMsg={errorMsg} 
            testType={testType} 
            insights={insights} 
            timeOnPlatformInfo={timeOnPlatformInfo}
            onClose={this.closeInsights}
          />
        }

        {showInsightDetails && 
          <InsightDetailsModal 
            showInsightDetails={showInsightDetails}
            category={category}
            displayName={displayName} 
            loading={loading} 
            errorMsg={errorMsg} 
            testType={testType} 
            insights={insights}
            insightDetails={insightDetails}
            timeOnPlatformInfo={timeOnPlatformInfo}
            onClose={this.closeInsightDetails}
          />
        }
      </>
    )
  }
}

//--- Helpers ---
const InsightsModal = ({showInsights, displayName, loading, errorMsg, testType, insights, timeOnPlatformInfo, onClose}) => {
  return (
    <Modal className="tests-insights-container--modal" show={showInsights} width="90%" onClose={onClose} >
      <div className="tests-insights-container--modal-header">
        <button type="button" className="modal-close" title="Close Modal" aria-label="Close" onClick={onClose}>
          <img
            className="modal-icon"
            src={`/assets/images/icons/v2/ico-modal-close-white.svg`}
            width="24"
            height="24"
          />
        </button>
      </div>
      <div className="tests-insights-container">
        <HeadingBanner title={displayName} />
        <ErrorOrLoadingInfo errorMsg={errorMsg} loading={loading}/>
        {!loading &&              
          <div className="tests-insights-content">
            {(testType === TestType.ACT || testType === TestType.IA) ?
              <TestInsightsForACT insights={insights as ITestInsightACT[]} timeOnPlatformInfo={timeOnPlatformInfo}/>
              :
              <TestInsightsForSAT insights={insights as ITestInsightSAT[]} timeOnPlatformInfo={timeOnPlatformInfo}/>
            }
          </div>
        }
      </div>
    </Modal>
  )
}

const InsightDetailsModal = ({showInsightDetails, category, displayName, loading, errorMsg, testType, insights, insightDetails, timeOnPlatformInfo, onClose}) => {

  let si = getSubjectIcon(category)
  let cssTitle = si ? si.cssTitle : 'default'

  return (
    <Modal className={`tests-insights-container--modal theme-modal theme-modal--${cssTitle}`} show={showInsightDetails} width="90%" onClose={onClose} >
      <div className="tests-insights-container--modal-header">
        <button type="button" className="modal-close" title="Close Modal" aria-label="Close" onClick={onClose}>
          <img
            className="modal-icon"
            src={`/assets/images/icons/v2/ico-modal-close.svg`}
            width="24"
            height="24"
          />
        </button>
      </div>
      <div className="tests-insights-container">
        <HeadingBanner title={`${displayName}: ${category}`} />
        <ErrorOrLoadingInfo errorMsg={errorMsg} loading={loading}/>
        {!loading &&
          <div className="tests-insights-content">
            <TestInsightDetailsbySectionView details={insightDetails} sectionName={category}/>
          </div>
        }
      </div>
    </Modal>
  )
}

const LoadingMessage = () => {
  return (
    <div className="tests-insights-loading-wrapper">
      <Loading />
      <p>
        We are calculating the practice test insights in real time. Please be patient as this process can take up to 60 seconds.
      </p>
    </div>
  )  
}

const ErrorOrLoadingInfo = ({loading, errorMsg}) => {
  return (
    errorMsg ?
      <div className="tests-insights-error">{errorMsg}</div>
      :
      loading && <LoadingMessage />
  )
}
