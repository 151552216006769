import './MCAnswerRadioButton.css';

import * as React from 'react';

import MathJaxText from '../../../../../../components/MathJaxText/MathJaxText';
import { QuestionOption } from '../../../../../../services/elearn/lesson-types';

interface Props {
    id: string | number;
    option: QuestionOption;
    selected: boolean;
    onAnswerSelection: () => void;
    useMathFont: boolean;
    showAnswer?: boolean;
    disabled?: boolean;
    visible: boolean;
}

const MCAnswerRadioButton: React.SFC<Props> = props => {
    const { 
      option, id, selected, onAnswerSelection, showAnswer, 
      disabled, useMathFont, visible 
    } = props;
    const inputId = `mc-answer-radio-input.${id}`;

    return (
        <div
            className={
                'noselect ' +
                'mc-answer-radio-button ' +
                `${showAnswer ? 'mc-answer-radio-button--show-answer ' : ''}` +
                `mc-answer-radio-button--${option.isCorrect ? 'correct' : 'incorrect'}`
            }
        >
            <input
                type="radio"
                name={`mc-answer-radio-input.${id}`}
                id={inputId}
                value={id}
                key={id}
                checked={selected}
                onChange={onAnswerSelection}
                disabled={disabled}
            />
            <label htmlFor={inputId}>
                <MathJaxText 
                  id={id + ''} 
                  text={option.answerHtml} 
                  useMathFont={useMathFont} 
                  visible={visible}
                />
            </label>
        </div>
    );
};

MCAnswerRadioButton.defaultProps = {};
MCAnswerRadioButton.displayName = 'MCAnswerRadioButton';

export default MCAnswerRadioButton;
