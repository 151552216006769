import * as React from 'react';
import { boundMethod } from 'autobind-decorator';
import * as _ from 'lodash';
import { PageContent } from '../../components/PageContent/PageContent';
import { RouteComponentProps, Link } from 'react-router-dom';
import Tabs, { Tab, CustomHeaderContent } from '../../components/Tabs/Tabs';
import CollapsableCard, { CollapsableCardHeader, CollapsableCardBody } from '../CollapsableCard/CollapsableCard';
import LessonListCardHeader from '../LessonListCardHeader/LessonListCardHeader';
import Loading from '../Loading/Loading';
import { Column } from '../DataGrid/types/types';
import DataGrid from '../DataGrid';
import { RowData } from '../DataGrid/types/types';
import LessonListTitle from '../LessonList/components/LessonListTitle/LessonListTitle';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import BrightcoveVideo from '../BrightcoveVideo/BrightcoveVideo';
import { UserInfo } from '../../services/account/account-rest-interface';
import { hasLessons, hasSchoolSafety, hasCollegeKnowledge, hasResilienceReminders } from '../../services/account/account-utils';
import { PageTitle } from '../PageContent/PageContent';
import ExpirationDialog from '../../scenes/Home/components/ExpirationDialog';
import { EnrichmentState } from '../../stores/enrichment.store';
import './EnrichmentLessons.css';
import { Navigation, updateActivityInfo } from '../../WinwardTracker';

export interface Props extends RouteComponentProps<any> {
}

export interface State {
    enrichmentData: any;
    videoLink: string;
    lessonId: string;
    toggleExplanationVideoPopup: boolean;
    showUnlockModal: boolean;    
    pdfLink: string;
    togglePDFPopup: boolean;
}

interface StoreProps {
  userInfo: UserInfo;
  enrichment: EnrichmentState;
}

interface DispatchProps {
    updateUserEnrichment: (lessonId: string, status: string) => Promise<any>;
}

declare const videojs: any;
type AllProps = Props & StoreProps & DispatchProps;


export class EnrichmentLessons extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            enrichmentData: null,
            videoLink: '',
            lessonId: '',
            toggleExplanationVideoPopup: false,
            showUnlockModal: false,
            pdfLink: '',
            togglePDFPopup: false
        };
    }

    public render() {
        const enrichmentColumns: Column[] = [
            {
                title: 'LESSONS',
                dataProperty: 'lesson',
                width: 'calc(60%)',
                sortable: false
            },
            {
                title: 'VIDEO/PDF',
                dataProperty: 'video',
                width: '20%',
                sortable: false
            },
            {
                title: 'STATUS',
                dataProperty: 'status',
                width: '210px',
                sortable: false
            },
        ];

        let keyNames;
        let data;
        const enrichmentData = _.groupBy(this.props.enrichment.enrichment, 'categoryName');
        if (enrichmentData) {
            keyNames = Object.keys(enrichmentData);
            data = this.createEnrichmentData;
        }

        if (!enrichmentData) {
            return (
                <Loading />
            );
        }

        let {userInfo} = this.props
        let {isTeacher=false} = userInfo

        return (
            <PageContent className="enrichment-list view-change-buttons-container">
                <PageTitle title="My Lessons" />
                    <div className="row blue-tabs-container">
                        <div className="col-12 text-left subject-buttons">
                            {hasLessons(this.props.userInfo) && 
                                <Link to="/app/lessons"
                                    className={'btn title-button btn-not-bold btn-default white margin-right-10'}
                                    role="button">
                                    <div>{this.props.userInfo.bundleName}</div>
                                </Link>
                            }
                            {hasCollegeKnowledge(this.props.userInfo) && 
                                <Link to="/app/lessons/dashboard/ck"
                                    className={'btn title-button btn-not-bold btn-default white margin-right-10'}
                                    role="button">
                                    <div>College Knowledge</div>
                                </Link>
                            }
                            {hasSchoolSafety(this.props.userInfo) && 
                                <Link to="/app/school-safety"
                                    className={'btn title-button btn-not-bold btn-default white'}
                                    role="button">
                                    <div>School Safety</div>
                                </Link>
                            }
                            <button
                                className={'btn title-button btn-not-bold btn-default white activeSelection arrow_box margin-right-10 ck-button'}
                                type="button">
                                <div>Enrichment</div>
                            </button>
                            {isTeacher && hasResilienceReminders(userInfo) && 
                                <Link to="/app/lessons/resilience-reminders"
                                    className={'btn title-button btn-not-bold btn-default white'}
                                    role="button"
                                    title="Mindset Moments"
                                    style={{width: 210}}
                                    >
                                    <div>Mindset Moments</div>
                                </Link>
                            }
                        </div>
                    </div>
                {keyNames.map(function(name: string, index: number) {
                    const numCompleted = enrichmentData[name].filter(function(item: any) {
                        let status = item.status;
                        return status === 'rewatch' || status === "reread";
                    });

                    let newData, subKeyNames;
                    let sortedCK = enrichmentData[name].sort((ck1: any, ck2: any) => {
                      if (ck1 !== undefined && ck2 !== undefined) {
                        return ck1.playOrder - ck2.playOrder;
                      } else {
                        return 0;
                      }
                    });
                    newData = _.groupBy(enrichmentData[name], 'subCategoryName');
                    subKeyNames = Object.keys(newData);

                    sortedCK = sortedCK.map(data);
                    const iconPath = `/assets/images/enrichment/${name.replace(/\s/g, '').toLowerCase()}-white.svg`;
                    return (
                        <CollapsableCard key={index}>
                            <CollapsableCardHeader>
                                <LessonListCardHeader
                                    text={name}
                                    color={'#279ed7'}
                                    icon={iconPath}
                                    numQuestionsCompleted={numCompleted.length}
                                    numQuestionsTotal={enrichmentData[name].length}
                                >
                                </LessonListCardHeader>
                            </CollapsableCardHeader>
                            <CollapsableCardBody>
                                {
                                  subKeyNames.map(d => {
                                  for (let i = 0; i < d.length; i++) {
                                    sortedCK = newData[d].map(data);
                                    // It's supposed to be RowData, but...
                                    //const anyRowData = sortedCK.map(x => x as any); 
                                    const anyRowData = sortedCK.map(x => {
                                        return x as any;
                                    });
                                    return (
                                      <div className="subcategory-lesson-list" key={`subcategory-lesson-list-${d}`}>
                                          {d.toUpperCase() !== 'NULL' &&
                                              <h6 className="subcategory-title" key={d}>
                                                  {d.toUpperCase()}
                                              </h6>
                                          }
                                              <DataGrid
                                                  columns={enrichmentColumns}
                                                  data={anyRowData}
                                                  emptyText="No lessons to display"
                                              />
                                      </div>
                                    );
                                  }
                                  return (
                                    <div key={d.lessonId}></div>
                                  );
                                })
                              }
                            </CollapsableCardBody>
                        </CollapsableCard>
                    );
                })}
                <Modal
                    className="ck-video ck-video-modal"
                    show={this.state.toggleExplanationVideoPopup}
                    onClose={this.closeModal}
                    showXTopRight={true}>
                    <BrightcoveVideo
                        id="ck-video"
                        videoId={this.state.videoLink}
                    >
                    </BrightcoveVideo>
                </Modal>
                
                <Modal
                    className="heading-banner-home unlock-modal expired-account-dialog"
                    show={this.state.showUnlockModal}
                    onClose={this.closeUnlockModal}
                    width="auto">
                    <ExpirationDialog {...this.props} unlockAccount closeModal={this.closeUnlockModal} />
                </Modal>
            </PageContent>
        );
    }

    
    @boundMethod
    private createEnrichmentData(enrichment: any, i: number): RowData {
        const studentData = {
            lesson: {
                content: this.createLessonTitle(enrichment),
                comparator: enrichment.lessonName,
                subComparator: enrichment.lessonOrder
            },
            video: {
                content: enrichment.duration || "PDF",
                comparator: enrichment.duration,
                subComparator: enrichment.lessonOrder
            },
            status: {
                content: this.createStatusButton(enrichment),
                comparator: this.getStatusButtonData(enrichment).order,
                subComparator: enrichment.lessonOrder
            },
            isOwned: {
                content: enrichment.isOwned,
                comparator: enrichment.isOwned,
            }
        };

        return {
            data: studentData,
            id: `lesson-data${i}`
        };
    }

    private popUnlockModal = () => {
        this.setState({
            showUnlockModal: true
        });
    }

    private closeUnlockModal = () => {
        this.setState({
            showUnlockModal: false
        });
    }

    private createLessonTitle(lesson: any) {
        let lessonStatus = this.getStatusButtonData(lesson).text;
        const icon = '/assets/images/icons/v2/' + this.getLessonTitleIcon(lesson).img;
        const isVideo = lesson.videoId !== "" ? true: false;
        if (!isVideo) {
            lessonStatus = lessonStatus === "START" ? "READ" : lessonStatus;
        }
        return () => (
            <LessonListTitle
                enabled={true}
                icon={icon}
                title={lessonStatus}
                lessonName={lesson.lessonName}
                onIconClick={() =>
                    lesson.isOwned === 'false' ?
                    this.popUnlockModal()
                :
                isVideo ?
                    this.openExplanationVideo(lesson)
                    : 
                    this.openPDF(lesson)
                }

            />
        );
    }

    private getLessonTitleIcon(lesson: any) {
        switch(lesson.status) {
            case 'resume': return { img: 'ico-resume.svg', alt: 'In Progress' };
            case 'rewatch': return { img: 'ico-summary.svg', alt: 'Completed' };
            case 'reread': return { img: 'ico-summary.svg', alt: 'Completed' };
            default: return { img: 'ico-start.svg', alt: 'To Start' };
        }
    }

    private createStatusButton(lesson: any) {
        const statusButtonData = this.getStatusButtonData(lesson);
        const isVideo = lesson.videoId !== "" ? true: false;
        let buttonText = statusButtonData.text
        if (!isVideo) {
            buttonText = buttonText === "START" ? "READ": buttonText;
        }
        return () => (
            <Button

                textColor={'white'}
                bgColor={statusButtonData.color as any}
                text={buttonText}
                className="lesson-list__btn"
                onClick={() => 
                    lesson.isOwned === 'false' ? 
                        this.popUnlockModal() 
                        :
                        isVideo ?
                            this.openExplanationVideo(lesson)
                            : 
                            this.openPDF(lesson)
                }
            />
        );
    }

    private getStatusButtonData(lesson: any) {
        if (lesson.isOwned === 'false') {
            switch(lesson.status) {
                case 'resume': return { text: 'RESUME', color: 'gray', order: 2 };
                case 'rewatch': return { text: 'REWATCH', color: 'gray', order: 3 };
                case 'reread': return { text: 'REREAD', color: 'gray', order: 3 };
                default: return { text: 'START', color: 'gray', order: 1 };
            }    
        } else {
            switch(lesson.status) {
                case 'resume': return { text: 'RESUME', color: 'green', order: 2 };
                case 'rewatch': return { text: 'REWATCH', color: 'purple', order: 3 };
                case 'reread': return { text: 'REREAD', color: 'purple', order: 3 };
                default: return { text: 'START', color: 'blue', order: 1 };
            }
        }
    }

    private openExplanationVideo(lesson: any) {
        let link = lesson.videoId
        let lessonId = lesson.lessonId

        updateActivityInfo(Navigation.Lessons, "Enrichment (Video): " + lesson.lessonName)

        let self = this;
        this.setState({
          videoLink: link,
          lessonId
        }, function() {
            const player = videojs('ck-video');
            player.catalog.getVideo(Number(link), (error, playerVid) => {
                const response = !!error && JSON.parse(error.response);
                if(response && response[0] && response[0].error_code) {
                    console.error(response);
                    alert(response[0].error_code);
                } else {
                    self.props.updateUserEnrichment(lessonId, 'resume');
                    player.catalog.load(playerVid);
                    self.setState({
                        toggleExplanationVideoPopup: !self.state.toggleExplanationVideoPopup,
                    });
                    player.on('ended', function() {
                        self.props.updateUserEnrichment(self.state.lessonId, 'rewatch');
                        updateActivityInfo(Navigation.Lessons, "Enrichment (Video - finished): " + lesson.lessonName)
                    });
                }
            });
        });
    }

    private openPDF(lesson:any) {
        let link = lesson.pdfId
        let lessonId = lesson.lessonId
        let self = this;
        
        updateActivityInfo(Navigation.Lessons, "Enrichment (PDF): " + lesson.lessonName)

        this.setState({
          pdfLink: link,
          lessonId
        }, () => {
            self.props.updateUserEnrichment(lessonId, 'reread');
            let url = `/assets/enrichment-pdfs/${link}`
            let win: any = window.open(url, '_blank');
            win.focus();
        });
    }

    @boundMethod
    private closeModal() {
        const myplayer = videojs('ck-video');
        const { lessonId } = this.state;
        myplayer.pause();
        this.setState({
            toggleExplanationVideoPopup: !this.state.toggleExplanationVideoPopup,
        }, this.componentDidMount);

    }

    @boundMethod
    private closePDFModal() {
        this.setState({
            togglePDFPopup: !this.state.togglePDFPopup,
        }, this.componentDidMount);

    }

    private doNothing() {
        return true;
    }
}
