import * as React from 'react';
import * as _ from 'lodash';
import { boundMethod } from 'autobind-decorator';

import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { UserInfo, getLoginStatus } from './services/account/account-rest-interface';
import {
    getDashboardOverview,
    getMistakeBankOverview,
    getUserLessonsOverview,
    setCurrentLesson
} from './stores/lesson.store';

import NewRelic from './NewRelic';
import { HashLink } from 'react-router-hash-link';
import './components/SkipLink/SkipLink.css';
import SessionTimeoutModal from './components/SessionTimeoutModal/SessionTimeoutModal';
import AccountSettings from './scenes/AccountSettings/AccountSettings';
import AdminDashboard from './scenes/AdminDashboard';
import TeacherPortal from './scenes/TeacherPortal';
import { AppState } from './store';
import Authenticated from './components/Authenticated/Authenticated';
import Home from './scenes/Home/HomeContainer';
import ImageTest from './scenes/ImageTest/ImageTest';
import Lesson from './scenes/Lesson/LessonContainer';
import LessonComplete from './scenes/LessonComplete/LessonCompleteContainer';
import { LessonProgress } from './services/elearn/lesson-types';
import Lessons from './scenes/Lessons/LessonsContainer';
import Loading from './components/Loading/Loading';
import MistakeBank from './scenes/MistakeBank/MistakeBankContainer';
import MistakeBankTopic from './scenes/MistakeBank/components/MistakeBankTopic/MistakeBankTopicContainer';
import StaySharp from './scenes/StaySharp/StaySharpContainer';
import StaySharpQuestions from './scenes/StaySharpQuestions/StaySharpQuestionsContainer';
import Navbar from './components/Navbar/Navbar';
import NavbarLink from './components/NavbarLink/NavbarLink';
import NotFound from './scenes/NotFound/NotFound';
import TestDashboard from './scenes/Practice/TestDashboardContainer';
import PracticeInstructions from './scenes/Practice/PracticeInstructions';
import PracticeScoring from './scenes/Practice/PracticeTestScoreSheetContainer';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import SearchBar from './components/SearchBar/SearchBar';
import SupHeader from './components/SuperIntendent/components/SupHeader/SupHeader';
import { addBetaBundle } from './services/elearn/bundle-rest-interface';
import { connect } from 'react-redux';
import { getUserInfo } from './stores/account.store';
import { startHeartbeat } from './services/account/login-heartbeat';
import { stringify } from 'query-string';
import UnderMaintenance from './scenes/UnderMaintenance/UnderMaintenance';
import { IAGrowthAnalytics } from './scenes/InterimAssessment/IAGrowthAnalytics';
import { PTGrowthAnalytics } from './scenes/Practice/PTGrowthAnalytics';
import PracticePrintContainer from './scenes/Practice/PracticePrintContainer';
import TeacherDashboardContainer from './scenes/Home/components/TeacherDashboard/TeacherDashboardContainer';
import CollegeKnowledgeLessonsContainer from './components/CollegeKnowledgeLessons/CollegeKnowledgeLessonsContainer';
import { getCollegeKnowledge } from './stores/ck.store';
import { getEnrichment } from './stores/enrichment.store';
import EnrichmentLessonsContainer from './components/EnrichmentLessons/EnrichmentLessonsContainer';
import SchoolSafetyLessonsContainer from './components/SchoolSafety/SchoolSafetyLessonsContainer';
import Modal from './components/Modal/Modal';
import ExpirationDialog from './scenes/Home/components/ExpirationDialog';
import moment from 'moment';
import ExpiredAccountDialog from './scenes/Home/components/ExpiredAccountDialog/ExpiredAccountDialog';
import { TestAnalysis } from './components/TestAnalysis/TestAnalysis';
import { TopicsOverTime } from './scenes/TopicsOverTime';
import { DiagnosticTiming } from './scenes/Practice/components/DiagnosticTiming/DiagnosticTiming';
import { hasSchoolSafety } from './services/account/account-utils';
import WinwardTracker from './WinwardTracker';
import { isDevelopment } from './services/utils/env-helper';
import { TeacherStore } from './stores/teacher.store';
import { TeacherResources } from './scenes/TeacherPortal/TeacherResources';
import ReactGA from 'react-ga'
import SuperintendentDashboard from './scenes/SuperintendentDashboard'
import Sitemap from './scenes/Sitemap'
import { StudentResources } from './scenes/StudentResources'
import { Reteach } from './scenes/Reteach'
import ResilienceRemindersContainer from './scenes/TeacherPortal/ResilienceReminders/ResilienceRemindersContainer';

const HEARTHBEAT_INTERVAL = isDevelopment() ? 600_000: 15_000 // In milliseconds

// Init Google Analytics
ReactGA.initialize("UA-101736118-3", {
    gaOptions: {
        siteSpeedSampleRate: 100
    }
})

interface Props {

}

interface StoreProps {
    authenticated: boolean;
    lessonOpen: boolean;
    userInfo: UserInfo;
    lessonList: Partial<LessonProgress>[];
    currentLessonId: string;
    numLessonUnstarted: number;
}

interface DispatchProps {
    getUserInfo: () => Promise<any>;
    getDashboardOverview: (userInfo: any) => Promise<any>;
    getUserLessonsOverview: (userInfo: any) => Promise<any>;
    getMistakeBankOverview: (userInfo: any) => Promise<any>;
    getCollegeKnowledge: () => Promise<any>;
    getEnrichment: () => Promise<any>;
    setCurrentLesson: (currentLessonId: string, userInfo: any) => void;
}

export enum NavbarState {
    OPEN = "OPEN",
    CLOSE = "CLOSE"
}

export const SkipNavLink = ({ id, children = 'Skip to content', ...props }) => {
    return (
        <HashLink to="#main-content" className="skip-nav-link">
            Skip to Content
        </HashLink>
    );
};

interface State {
    navbarCollapsed: boolean;
    navbarStateByUser?: NavbarState
    navbarDocked: boolean;
    isLoaded: boolean;
    showSessionTimeoutModal: boolean;
    showPurchaseModal: boolean;
    portraitOrientation: boolean;
}

export const BASE_PATH = process.env.REACT_APP_BASE_URL + 'app';
const imagePath = '/assets/images/navbar';


/**
 * Hardcoded admin emails to determine if someone is an admin or not!
 * 
 * @param emailAddress 
 * @returns 
 */
function isAdmin(emailAddress: string) {
    // OT: We need to find a better approach but for now
    // I will follow what Rustam did earlier, i.e. checking
    // the current user's email against the hardcoded ones!
    let adminEmails = [
        'jennifer@winwardacademy.com',
        'william@winwardacademy.com',
        // 'rustam@winwardacademy.com',
        'ogun@winwardacademy.com',
        'andy@winwardacademy.com',
        'thomas@winwardacademy.com',
        'gaby@winwardacademy.com', 
    ]

    return adminEmails.some(adminEmail => adminEmail === emailAddress)
}

const renderHome = () => {
    return <Redirect to={`${BASE_PATH}/dashboard`} />;
};

const authenticatedRoutes = [    
    {
        path: `${BASE_PATH}/dashboard`,
        component: Home,
        exact: true
    },
    {
        path: `${BASE_PATH}/dashboard/superintendent`,
        component: SuperintendentDashboard,
        exact: true
    },
    {
        path: `${BASE_PATH}/sitemap`,
        component: Sitemap,
        exact: true
    },
    {
        path: `${BASE_PATH}/lessons/dashboard/ck`,
        component: CollegeKnowledgeLessonsContainer,
        exact: true
    },
    {
        path: `${BASE_PATH}/lessons/dashboard/enrichment`,
        component: EnrichmentLessonsContainer,
        exact: true
    },
    {
        path: `${BASE_PATH}/lessons/resilience-reminders`,
        component: ResilienceRemindersContainer,
        exact: true
    },
    {
        path: `${BASE_PATH}/school-safety`,
        component: SchoolSafetyLessonsContainer,
        exact: true
    },
    {
        path: `${BASE_PATH}`,
        render: renderHome,
        exact: true
    },
    {
        path: `${BASE_PATH}/lessons`,
        component: Lessons,
        exact: true
    },
    {
        path: `${BASE_PATH}/lessons/:lessonId/report`,
        component: LessonComplete
    },
    {
        path: `${BASE_PATH}/practice-tests/reports/:testName`,
        component: TestAnalysis
    },
    {
        path: `${BASE_PATH}/diagnostic/reports/:testName`,
        component: TestAnalysis
    },
    {
        path: `${BASE_PATH}/lessons/:lessonId`,
        component: Lesson
    },
    {
        path: `${BASE_PATH}/mistake-bank`,
        component: MistakeBank,
        exact: true
    },
    {
        path: `${BASE_PATH}/mistake-bank/:lessonId/:topicId`,
        component: MistakeBankTopic,
        exact: true
    },
    {
        path: `${BASE_PATH}/stay-sharp`,
        component: StaySharp,
        exact: true
    },
    {
        path: `${BASE_PATH}/stay-sharp/:lessonId/:topicId`,
        component: StaySharpQuestions,
        exact: true
    },
    {
        path: `${BASE_PATH}/diagnostic`,
        component: TestDashboard,
        exact: true
    },
    {
        path: `${BASE_PATH}/diagnostic/instructions/:practiceId`,
        component: PracticeInstructions,
        exact: true
    },
    {
        path: `${BASE_PATH}/diagnostic/scoring/:practiceId`,
        component: PracticeScoring,
        exact: true
    },
    {
        path: `${BASE_PATH}/practice-tests`,
        component: TestDashboard,
        exact: true
    },
    {
        path: `${BASE_PATH}/practice-tests/ia/reports/:testName`,
        component: TestAnalysis
    },
    {
        path: `${BASE_PATH}/practice-tests/instructions/timing/:practiceId`,
        component: DiagnosticTiming,
        exact: true
    },
    {
        path: `${BASE_PATH}/diagnostic/instructions/Diagnostic/timing/:practiceId`,
        component: DiagnosticTiming,
        exact: true
    },
    {
        path: `${BASE_PATH}/practice-tests/instructions/:practiceId`,
        component: PracticeInstructions,
        exact: true
    },
    {
      path: `${BASE_PATH}/practice-tests/scoring/:practiceId`,
      component: PracticeScoring,
      exact: true
    },
    {
      path: `${BASE_PATH}/practice-tests/growth-analytics`,
      component: PTGrowthAnalytics,
      exact: true
    },
    {
        path: `${BASE_PATH}/practice-tests/:testType/growth-analytics/topics`,
        component: TopicsOverTime,
        exact: true
    },
    {
        path: `${BASE_PATH}/settings`,
        component: AccountSettings,
        exact: true
    },
    {
        path: `${BASE_PATH}/diagnostic/instructions/Diagnostic/timing`,
        component: DiagnosticTiming,
        exact: true
    },
    {
        path: `${BASE_PATH}/practice-tests?testType=sat`,
        component: PracticeScoring,
        exact: true
    },
    {
        path: `${BASE_PATH}/practice-tests/ia/growth-analytics`,
        component: IAGrowthAnalytics,
        exact: true
    },
    {
        path: `${BASE_PATH}/student-resources`,
        component: StudentResources,
        exact: true
    }, 
];

type AllProps = StoreProps & Props & DispatchProps & RouteComponentProps<any>;

// 
export class App extends React.Component<AllProps, State> {
    static instance: App

    constructor(props: AllProps) {
        super(props);
        this.state = {
            navbarCollapsed: props.lessonOpen || false,
            navbarDocked: true,
            isLoaded: false,
            showSessionTimeoutModal: false,
            showPurchaseModal: false,
            portraitOrientation: true
        };
        App.instance = this
    }

    public componentDidMount() {
        if(!this.props.authenticated) {
            this.props.history.push(`${process.env.REACT_APP_BASE_URL}login`);
            return;
        }

        // Make sure user is authenticated
        const { history } = this.props;
        getLoginStatus()
            .then(() => {

            this.props.getUserInfo().then((res) => {
                const { bundleId } = res.userInfo;
                const isPracticeTestsOnly = bundleId === 'practiceTestsOnly';
                if (isPracticeTestsOnly) {
                  this.setState(
                    { isLoaded: true }, 
                    () => history.push(`${BASE_PATH}/practice-tests`)
                  );
                  return;
                }

                Promise.all([
                    // this.props.getUserInfo().catch(() => Promise.resolve([])),
                    this.props.getDashboardOverview(res.userInfo).catch(() => Promise.resolve([])),
                    this.props.getUserLessonsOverview(res.userInfo).catch(() => Promise.resolve([])),
                    this.props.getMistakeBankOverview(res.userInfo).catch(() => Promise.resolve([])),
                    this.props.getCollegeKnowledge().catch(() => Promise.resolve([])),
                    this.props.getEnrichment().catch(() => Promise.resolve([]))                    
                ]).then(() => this.setState({ isLoaded: true }));

                if (res.userInfo && res.userInfo.isTeacher) {
                    TeacherStore.prefetchData()
                }
            });

            // Start session status heartbeat
            const allowMultipleSessions = process.env.REACT_APP_ALLOW_MULTIPLE_SESSIONS == "true";
            const heartbeatIntervalInMilliseconds = HEARTHBEAT_INTERVAL 
            startHeartbeat(heartbeatIntervalInMilliseconds)
                .catch(() => {
                    if (!allowMultipleSessions) {
                        this.setState({ showSessionTimeoutModal: true });
                    }
                });

        })
        .catch(response => {
            console.log('login status failed', response);
            this.props.history.push(`${process.env.REACT_APP_BASE_URL}login`);
        });
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Props & StoreProps) {
        if(!this.props.lessonOpen && nextProps.lessonOpen) {
            this.setState({ navbarCollapsed: true });
        } else if(this.props.lessonOpen && !nextProps.lessonOpen) {
            this.setState({ navbarCollapsed: false });
        }
    }

    public componentDidUpdate() {
        if(!this.props.currentLessonId && this.props.lessonList.length > 0
            && this.props.numLessonUnstarted === this.props.lessonList.length) {
            this.props.setCurrentLesson(this.props.lessonList[0].lessonId || '', this.props.userInfo);
        }
    }

    public render() {
        const { navbarCollapsed, isLoaded } = this.state;
        const { userInfo } = this.props;
        // console.log("Is maia student?", userInfo.isMaiaStudent);

        let freeTrial = false;
        const initials = userInfo.firstName ? userInfo.firstName[0] + userInfo.lastName[0] : '';
        let pageWrapperClass = navbarCollapsed ? 'page-wrapper page-wrapper--navbar-collapsed' : 'page-wrapper';
        if (userInfo.isMaiaStudent) {
            pageWrapperClass = 'page-wrapper page-wrapper--is-maia-student';
        }
        let showExpireModal = false;
        if (userInfo.expirationDate) {
            showExpireModal = moment(userInfo.expirationDate).isBefore(moment());
        }

        if (showExpireModal) {
            freeTrial = userInfo.bundleId === 'freeTrial';
        }
        let displayRoutes = this.getNavRoutes();
        
        const { emailAddress } = userInfo;
        const showAdmin = isAdmin(emailAddress)

        if (showAdmin) {
          authenticatedRoutes.push({
            path: `${BASE_PATH}/pinback`,
            component: AdminDashboard,
            exact: true
          });

          authenticatedRoutes.push({
            path: `${BASE_PATH}/pinback/dev-only`,
            component: AdminDashboard,
            exact: true
          });
        }

        const showNewTeacherPortal = !userInfo.isMaiaStudent;
        if (showNewTeacherPortal) {
          authenticatedRoutes.push({
            path: `${BASE_PATH}/teacher-portal`,
            component: TeacherPortal,
            exact: true
          })

          authenticatedRoutes.push({
            path: `${BASE_PATH}/teacher-resources`,
            component: TeacherResources,
            exact: true
          })
        }

        let pageClass;
        let isSuperintendent = this.props.location.pathname.includes('superintendent')
        isSuperintendent ? pageClass = ' fullscreen' : pageClass = ''; 
        if (userInfo.animationDisabled) pageClass += ' wcag-animate-off'
        return (
            <div className={"winward-academy-container" + pageClass}>
                <NewRelic />
                <SkipNavLink id="main-content">
                    Skip to Content
                </SkipNavLink>
                <ScrollToTop />
                <WinwardTracker userInfo={userInfo}/>


                { !userInfo.isMaiaStudent && !isSuperintendent &&
                <Navbar
                    collapsed={navbarCollapsed}
                    docked={this.state.navbarDocked}
                    onCollapse={this.onNavbarCollapse}
                    onDock={this.onSearchbarNavToggle}
                    lessonInProgress={this.props.lessonOpen}
                >
                    {displayRoutes.map((props) => {
                        // HACK: This is a hack because the practice-test 
                        // navigation scheme was not well understood when 
                        // first developed. This hack is in support of 
                        // hiding ACT/SAT depending on the test specific 
                        // bundles (eg satCrunchTime). RAK MAR-03-2020.
                        const satOnly = userInfo.bundleId === 'satCrunchTime';
                        const practiceTestLink = props.nav === '/app/practice-tests';
                        const newProps = {
                            ...props,
                            nav : (satOnly && practiceTestLink ?
                                '/app/practice-tests?testType=sat' : props.nav)
                        };
                        return (
                            <NavbarLink key={props.title} {...newProps} />
                        );
                    })}
                </Navbar>
                }

                <div className={pageWrapperClass}>

                    {!this.props.lessonOpen && !userInfo.isMaiaStudent && !isSuperintendent &&
                        <SearchBar
                            initials={initials.toUpperCase()}
                            avatarColor={userInfo.profileColor}
                            userInfo={userInfo}
                            onNavbarToggleClick={this.onSearchbarNavToggle}
                            popPurchaseModal={this.popPurchaseModal}
                        />}
                    {isSuperintendent &&
                        <SupHeader
                            initials={initials.toUpperCase()}
                            avatarColor={userInfo.profileColor}
                            userInfo={userInfo}
                            onNavbarToggleClick={this.onSearchbarNavToggle}
                            popPurchaseModal={this.popPurchaseModal}
                        />}
                    <div className="page">
                        {isLoaded ? <Switch>

                            {authenticatedRoutes.map((props) => (
                                <Authenticated key={props.path} {...props} {...this.props} />
                            ))}

                            <Route path={`${BASE_PATH}/image-test`} component={ImageTest} {...this.props} />
                            <Route component={NotFound} />
                        </Switch> :
                        <Loading />}
                    </div>
                </div>

                <Modal
                    className="heading-banner-home expired-account-dialog"
                    show={showExpireModal}
                    onClose={() => {return; }}
                    width="auto"
                >
                    <ExpiredAccountDialog {...this.props} freeTrial={freeTrial} />
                </Modal>

                <Modal
                    className="heading-banner-home unlock-modal expired-account-dialog"
                    show={this.state.showPurchaseModal}
                    onClose={this.closePurchaseModal}
                    width="auto"
                >
                    <ExpirationDialog
                        {...this.props}
                        unlockAccount
                        closeModal={this.closePurchaseModal}
                        freeTrial={freeTrial}
                    />
                </Modal>

                <SessionTimeoutModal
                    show={this.state.showSessionTimeoutModal}
                />

            </div>
        );
        // }
    }

    @boundMethod
    private popPurchaseModal() {
        this.setState({
            showPurchaseModal: true
        });
    }

    @boundMethod
    private closePurchaseModal() {
        this.setState({
            showPurchaseModal: false
        });
    }

    private getNavRoutes() {
      const { userInfo } = this.props;
      const { bundleId } = userInfo;
      const showTeacherPortal: boolean = !userInfo.isMaiaStudent;
      const isPracticeTestsOnly = bundleId === 'practiceTestsOnly';

      let navRoutes;
      let isSSS = bundleId === "sss" || bundleId === "schoolSafetySecurity"

      if (!_.isEmpty(this.props.userInfo)) {
          if (this.props.userInfo.isSuperintendent) {
            navRoutes = []
            navRoutes.push({
              img: `${imagePath}/WWA_Dashboard.svg`,
              nav: `${BASE_PATH}/dashboard/superintendent`,
              title: 'Dashboard'
            })
            return navRoutes;
          }

          if (this.props.userInfo.bundleId === 'collegeKnowledge') {
            navRoutes = [
                {
                    img: `${imagePath}/WWA_Dashboard.svg`,
                    nav: `${BASE_PATH}/dashboard`,
                    title: 'Dashboard'
                },
                {
                    img: `${imagePath}/WWA_Lessons.svg`,
                    nav: `${BASE_PATH}/lessons/dashboard/ck`,
                    title: 'Lessons'
                }
            ];
          } else if (this.props.userInfo.isTeacher && hasSchoolSafety(this.props.userInfo)) {
                navRoutes = [
                    {
                        img: `${imagePath}/WWA_Dashboard.svg`,
                        nav: `${BASE_PATH}/dashboard`,
                        title: 'Dashboard'
                    },
                    {
                        img: `${imagePath}/WWA_Lessons.svg`,
                        nav: `${BASE_PATH}/lessons`,
                        title: 'Lessons'
                    }
                ];
          } else if (isPracticeTestsOnly) {
                navRoutes = [
                {
                    img: `${imagePath}/WWA_Practice-Test-v2.svg`,
                    nav: `${BASE_PATH}/practice-tests`,
                    title: 'Practice Tests',
                }
                ];
          } else if (isSSS) {
                navRoutes = [
                    {
                        img: `${imagePath}/WWA_Lessons.svg`,
                        nav: `${BASE_PATH}/lessons`,
                        title: 'Lessons'
                    },
                    {
                        img: `${imagePath}/WWA_Mistake-Bank.svg`,
                        nav: `${BASE_PATH}/mistake-bank`,
                        title: 'Mistake Bank'
                    }
                ];
          } else if (this.props.userInfo.isTeacher) {
            navRoutes = []
            navRoutes.push({
              img: `${imagePath}/WWA_Dashboard.svg`,
              nav: `${BASE_PATH}/dashboard`,
              title: 'Dashboard'
            })
            if (showTeacherPortal) {
              navRoutes.push({
                img: `${imagePath}/WWA_Data.svg`,
                nav: `${BASE_PATH}/teacher-portal`,
                title: 'Data Reports'
              })
            }
            navRoutes.push({
              img: `${imagePath}/WWA_Lessons.svg`,
              nav: `${BASE_PATH}/lessons`,
              title: 'Lessons'
            })

            navRoutes.push({
                img: `${imagePath}/WWA_Practice-Test-v2.svg`,
                nav: `${BASE_PATH}/practice-tests`,
                title: 'Practice Tests',
            })

            navRoutes.push({
                img: `${imagePath}/resources-icon-white.svg`,
                nav: `${BASE_PATH}/teacher-resources`,
                title: 'Resources'
            })

          } else {
                navRoutes = [
                    {
                        img: `${imagePath}/WWA_Dashboard.svg`,
                        nav: `${BASE_PATH}/dashboard`,
                        title: 'Dashboard'
                    },
                    // {
                    //     img: `${imagePath}/WWA_Diagnostic.svg`,
                    //     nav: `${BASE_PATH}/diagnostic`,
                    //     title: 'Diagnostic'
                    // },
                    {
                        img: `${imagePath}/WWA_Lessons.svg`,
                        nav: `${BASE_PATH}/lessons`,
                        title: 'Lessons'
                    },
                    {
                        img: `${imagePath}/WWA_Mistake-Bank.svg`,
                        nav: `${BASE_PATH}/mistake-bank`,
                        title: 'Mistake Bank'
                    },
                    {
                        img: `${imagePath}/WWA_Stay_Sharp.svg`,
                        nav: `${BASE_PATH}/stay-sharp`,
                        title: 'Stay Sharp'
                    },
                    {
                        img: `${imagePath}/WWA_Practice-Test-v2.svg`,
                        nav: `${BASE_PATH}/practice-tests`,
                        title: 'Practice Tests',
                    },
                    {
                        img: `${imagePath}/resources-icon-white.svg`,
                        nav: `${BASE_PATH}/student-resources`,
                        title: 'Resources',
                    }
                ]
          }
      } else {
            navRoutes = [];
      }

      const { emailAddress } = this.props.userInfo;

      const showAdmin = isAdmin(emailAddress)

      if (showAdmin) {
        navRoutes.push({
          img: `${imagePath}/WWA_Data.svg`,
          nav: `${BASE_PATH}/pinback`,
          title: 'Admin'
        });
      }

      return navRoutes;
    }

    @boundMethod
    private onNavbarCollapse(collapsed: boolean, userAction=true) {
        if (userAction) {
            this.setState({ 
                navbarCollapsed: collapsed, 
                navbarStateByUser: collapsed ? NavbarState.CLOSE: NavbarState.OPEN
            })
        }
        else {
            this.setState({ navbarCollapsed: collapsed})
        }
    }

    @boundMethod
    private onSearchbarNavToggle() {
        this.setState({ navbarDocked: !this.state.navbarDocked });
    }

    private onOrientationChange(orientation: any, type: any, angle: any) {
        let isPortrait: boolean = (orientation === 'landscape') ? false : true;

        this.setState({
            portraitOrientation: isPortrait
        });
    }

    /**
     * Allow navigation bar to collapse
     */
    public static collapseNavbar(collapse=true) {
        if (App.instance) {
            App.instance.onNavbarCollapse(collapse, false)
        }
    }
    
    public static knownNavbarStateByUser(): NavbarState | undefined {
        if (App.instance) {
            return App.instance.state.navbarStateByUser
        } 
    }

    public static isNavbarCollapsed(): boolean {
        if (App.instance) {
            return App.instance.state.navbarCollapsed
        }
        return false
    }

    public static getUserInfo(): UserInfo {
        return App.instance.props.userInfo
    }

    public static getUserInfoOrUndefined(): UserInfo|undefined {
        if (App.instance) {
            return App.instance.props.userInfo
        }
    }
}

const mapStateToProps = (state: AppState): StoreProps => ({
    authenticated: state.account.isAuthenticated,
    lessonOpen : state.lesson.lessonInProgress,
    userInfo: state.account.userInfo as UserInfo,
    lessonList: state.lesson.userLessons.allLessons,
    currentLessonId: state.lesson.currentLessonId,
    numLessonUnstarted: state.lesson.numLessonsUnstarted
});

const mapDispatchToProps = {
    getUserInfo,
    getDashboardOverview,
    getUserLessonsOverview,
    setCurrentLesson,
    getMistakeBankOverview,
    getCollegeKnowledge,
    getEnrichment
};

const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
export default (props) => <ConnectedApp {...props} />;
