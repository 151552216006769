import './Welcome.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import BlueGeometricBackground from '../../components/BlueGeometricBackground/BlueGeometricBackground';
import WelcomeHelpMessage from './WelcomeHelpMessage';
import Button from '../../components/Button/Button';
import Loading from '../../components/Loading/Loading';
import { RouteComponentProps } from 'react-router';
import TextInput from '../../components/TextInput/TextInput';
import { parse } from 'query-string';
import { resendVerificationEmail } from '../../services/account/account-rest-interface';
import ButtonLoading from '../../components/ButtonLoading/ButtonLoading';

interface Props extends RouteComponentProps<any> {

}

interface State {
    email: string;
    showEmailForm: boolean;
    error: string;
    isLoading: boolean;
}


export default class Welcome extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        const search = parse(props.location.search);
        const emailFromSearch = search.email as string;
        // console.log(emailFromSearch);
        this.state = {
            showEmailForm: search.resendVerificationEmail === 'true',
            email: emailFromSearch || '',
            error: '',
            isLoading: false
        };
    }

    public render() {
        const { showEmailForm, email, error, isLoading } = this.state;

        return (
            <BlueGeometricBackground className="welcome-page">
                <div className="d-flex justify-content-center">
                    <div className="welcome-page__content">

                        <img 
                            className="logo-size-down verification-logo" 
                            src={`/assets/images/winward-academy-logo-white.svg`} 
                        />

                        {showEmailForm ?
                            <div className="welcome-page__input-container" style={{ height: '216px' }}>
                                <p className="text-center">
                                    Enter your email below and a new verification email will be sent to you.
                                </p>
                                <TextInput
                                    id="resend-email"
                                    labelText="Email Address"
                                    value={email}
                                    size="lg"
                                    inputType="email"
                                    onChange={this.onEmailChange}
                                    onSubmit={this.resendEmail}
                                    formGroupClass="welcome-page__resend-email-input"
                                    invalid={!!error}
                                    feedbackText={error}
                                />
                            </div> :
                            <div>
                                <h1 className="font-weight-700">Did you receive your verification email?</h1>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-sm-4 flex">
                                            <img src="/assets/images/icons/email_icon.png" className="email-icon" />
                                        </div>
                                        <div className="col-sm-8 text-left">
                                            <table className="welcome-verification-table">
                                                <tbody>
                                                    <tr>
                                                        <td className="number-title">1</td>
                                                        <td className="verification-table-content">
                                                            <h2>Check your email account</h2>
                                                            <p>School emails are sometimes blocked by school servers. 
                                                                Make sure to check your junk and spam folders.</p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="number-title">2</td>
                                                        <td className="verification-table-content">
                                                            <h2>Verify your account</h2>
                                                            <p>Once your email is verified, you are all set.</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <WelcomeHelpMessage />
                        <div className="welcome-page__btn-container">
                            <div className={`welcome-page__btn ${showEmailForm ? 'welcome-page__btn--full' : ''}`}>
                                <Button
                                    text={isLoading ? <ButtonLoading className="welcome-page__loading" /> :
                                        'RESEND VERIFICATION EMAIL'}
                                    bgColor="blue"
                                    textColor="white"
                                    onClick={this.resendEmail}
                                    disabled={isLoading}
                                />
                            </div>
                            {!showEmailForm && <div className="welcome-page__btn">
                                <Button
                                    text="RETURN TO HOME PAGE"
                                    bgColor="green"
                                    textColor="white"
                                    link="http://www.winwardacademy.com/"
                                />
                            </div>}
                        </div>
                    </div>
                </div>
            </BlueGeometricBackground>
        );
    }

    @boundMethod
    private resendEmail() {
        const { email, showEmailForm } = this.state;
        if(!showEmailForm) {
            this.setState({ showEmailForm: true, error: '' });
        } else if(email === '') {
            this.setState({ error: 'Email cannot be empty' });
        } else {
            this.setState({ isLoading: true, error: '' }, () => {
                resendVerificationEmail(email)
                    .then(() => this.setState({ isLoading: false, showEmailForm: false }))
                    .catch(response => {
                        const error = response.status === 404 ?
                            'Account does not exist' :
                            'Could not resend verification email';
                        this.setState({ isLoading: false, error });
                    });
            });
        }
    }

    @boundMethod
    private onEmailChange(e: any) {
        const email = e.target.value;
        this.setState({ email });
    }
}
