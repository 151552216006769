import './LessonListTitle.css';
import * as React from 'react';

interface Props {
    icon?: string;
    alt?: string;
    title?: string;
    lessonName: string;
    onIconClick?: () => void;
    enabled: boolean;
}

const LessonListTitle: React.SFC<Props> = (props) => {
    return (
        <div className="lesson-list__title">
            <img 
                className={props.enabled && props.onIconClick ? 'enabled' : 'disabled'}
                src={props.icon}
                title={props.title ? props.title : ''}
                alt={props.alt ? props.alt : ''}
                onClick={props.onIconClick} 
            />
            <span>{props.lessonName}</span>
        </div>
    );
};

LessonListTitle.displayName = 'LessonListTitle';

export default LessonListTitle;
