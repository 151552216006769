import './StockCard.css';

import * as React from 'react';

import Button from '../../../../components/Button/Button';
import { NavLink } from 'react-router-dom';
import Card from '../../../../components/Card/Card';

interface Props {
    topText?: string;
    bottomText?: string;
    pText?: string;
    btnText: string;
    btnLink: string;
    topStyle?: string;
    bottomStyle?: string;
    containerClass?: string;
    btnInternal?: string;
}

const StockCard: React.SFC<Props> = (props: Props) => {

    const btnStyle = {width: '100%'};
    const topText = props.topText ? props.topText : '';
    const topStyle = props.topStyle ? props.topStyle : '';
    const bottomText = props.bottomText ? props.bottomText : '';
    const pText = props.pText ? props.pText : '';
    const bottomStyle = props.bottomStyle ? props.bottomStyle : '';
    const btnInternal = props.btnInternal ? 'internal' : '';
    return (
        <div className={'stock__wrapper ' + props.containerClass}>
            <Card title="" titleColor="white" link="" classTitle="">
                <div className="stock-container">
                    <div>
                        {topText && <p className={'first-text-container ' + topStyle}>{topText}</p>}
                        <p className={'second-text-container ' + bottomStyle}>{bottomText}</p>
                    </div>
                    <div className="card-divider alt"></div>
                    { pText != '' ? (
                        <div className="mb-4">
                            <p className="text p-0 px-xl-4">
                                { props.pText }
                            </p>
                        </div>
                    ) : (
                        ''
                    )}
                    {btnInternal == 'internal' ? (
                         <NavLink to={props.btnLink} title={props.btnText} className="w-100 btn btn--text-default btn--bg-blue">
                            {props.btnText}
                         </NavLink>
                    ) : (
                        <Button
                            text={props.btnText}
                            titleAttr={props.btnText}
                            textColor="default"
                            bgColor="blue"
                            style={btnStyle}
                            onClick={() => window.open(props.btnLink)}
                        />
                    )}
                </div>
            </Card>
        </div>
    );
};

StockCard.displayName = 'StockCard';

export default StockCard;
