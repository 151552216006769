import './LessonListCardHeader.css';

import * as React from 'react';

import ProgressBar from '../../components/ProgressBar/ProgressBar';

interface Props {
    icon?: string;
    text: string;
    color: string;
    numQuestionsCompleted: number;
    numQuestionsTotal: number;
    mistakeBank?: boolean;
}

const LessonListCardHeader: React.SFC<Props> = (props) => {
    let barColor = '';
    switch (props.text) {
        case 'English': {
            barColor = 'purple';
            break;
        }
        case 'Math': {
            barColor = 'blue';
            break;
        }
        case 'Reading': {
            barColor = 'orange';
            break;
        }
        case 'Science': {
            barColor = 'green';
            break;
        }
        case 'Writing': {
            barColor = 'pink';
            break;
        }
        case 'Master the Application': {
          barColor = 'ck-green';
          break;
        }
        case 'Master the Writing': {
            barColor = 'ck-green';
            break;
        }
        case 'Master the Resume': {
            barColor = 'ck-green';
            break;
        }
        case 'Master the Interview': {
            barColor = 'ck-green';
            break;
        }
        case 'Break Barriers': {
            barColor = 'ck-green';
            break;
        }
        case 'School Safety & Security': {
            barColor = 'primary-wa-blue';
            break;
        }
        case 'Success Looks Like Me':
        case 'Young Women In Bio':
        case 'NCAA': {
            barColor = 'enrich';
            break;
        }
        default: {
            barColor = 'purple';
        }
    }
    let completeOrMastered = props.mistakeBank ? 'Mastered' : 'Complete';
    return (
        <div className="row">
            {/* Subject icon + title */}
            <div className={`lesson-list-card-header col-12 ` + barColor}>
                <span className={`ribbon ` + barColor}>
                <img alt="" src={props.icon} />
                </span>
                <span>{props.text}</span>
            </div>

            {/* Progress bar
            <div className="col-6 lesson-list-card-header__completed">
                <span>
                    {props.numQuestionsCompleted}/{props.numQuestionsTotal} {completeOrMastered}
                </span>
                <div className="lesson-list-card-header__progress-bar">
                    <ProgressBar
                        current={props.numQuestionsCompleted}
                        total={props.numQuestionsTotal}
                        barCurrentColor={barColor}
                        barTotalColor="gray"
                        size="small"
                    />
                </div>
            </div> */}
        </div>
    );
};

LessonListCardHeader.displayName = 'LessonListCardHeader';

export default LessonListCardHeader;
