import './StaySharpInfoCard.css';

import * as React from 'react';

interface Props {
    
}

const StaySharpInfoCard: React.SFC<Props> = props => {

    return (
        <div className="stay-sharp-info-card">
            <div className="stay-sharp-info-card__mix-em-up d-flex">
                <img src={`${process.env.PUBLIC_URL}/assets/images/icons/sections/stay-sharp-gradient-icon-v2_white.svg`} />
            </div>
            <div>
                <h6 className="info-card-title">Stay Sharp Overview</h6>
                <p>
                    When you're feeling like you've mastered a topic after reviewing 
                    mistakes in your Mistake Bank, then go ahead and launch more Stay Sharp questions to test 
                    your knowledge or refresh on a concept. Everything is organized by topic, so you can practice 
                    exactly what you need.
                </p>
                <p>
                    As always, all of your mistakes are saved in your personal Mistake Bank. Remember
                    that topics with <span className="italics">more</span> questions are tested more frequently, 
                    and topics with <span className="italics">fewer</span> questions are tested less frequently.
                </p>
            </div>
        </div>
    );
};

StaySharpInfoCard.defaultProps = {};
StaySharpInfoCard.displayName = 'StaySharpInfoCard';

export default StaySharpInfoCard;
