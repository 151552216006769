import './TableCell.css';

import * as React from 'react';

import { Content } from '../../../../types/types';

interface Props {
    content: Content;
    width: any;
    style?: React.CSSProperties;
}

const TableCell: React.SFC<Props> = (props) => {
    let style: React.CSSProperties = { ...props.style, flexBasis: props.width };
    if(!props.width) {
        style = { flex: '1 1 0' };
    }
    return (
        <div className="table-cell" style={style}>
            {typeof props.content === 'function' ? props.content() :
                props.content === 'N/A' ?
                    <div className="not-applicable">
                        N/A
                    </div>
                    :
                    <>
                        {props.content}
                    </>
            }
        </div>
    );
};

TableCell.displayName = 'TableCell';
TableCell.defaultProps = {
    style: {}
};

export default TableCell;
