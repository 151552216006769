import * as React from 'react'
import { LessonInfo } from '../../../services/elearn/lesson-types'
import "./LessonSummary.css"

interface Props {
  lessons: LessonInfo[]
}

interface State {}

type SummarySectionType = "English" | "Math" | "Reading" | "Science"

// Get the summary icons suitable for PDF export (svg graphics are not processed well!)
function getSummaryStyle(section: SummarySectionType, element: string) {
  let resUrl = "/assets/images/icons/reports/"
  let resColor = '#000000'
  switch (section) {
    case "English":
      resUrl += "icon-subject-english.svg"
      resColor = '#a256d7'
      break
    case "Math":
      resUrl += "icon-subject-math.svg"
      resColor = '#3FC3D2'
      break
    case
      "Reading":
      resUrl += "icon-subject-reading.svg"
      resColor = '#f29000'
      break
    case "Science":
      resUrl += "icon-subject-science.svg"
      resColor = '#8ac22d'
      break
    default: // This shouldn't happen!
      resUrl += "black_x_mark.png"
  }
  if(element == 'color') {
    return resColor
  } else {
    return resUrl
  }
}

export default class LessonSummary extends React.Component <Props, State> {
  render() {
    let { lessons } = this.props
    let lessonsMap = { 
      English: { lessons: [] },
      Math:    { lessons: [] },
      Reading: { lessons: [] },
      Science: { lessons: [] }
    }
    let prepMap = () => {
      lessons.forEach(lesson => {
        let key = lesson.category
        let item = lessonsMap[key]

        if (item) {
          item.lessons.push(lesson)
        }
      })
    }

    prepMap()  

    let mapKeys = Object.keys(lessonsMap)
    const imagePath = '/assets/images/icons/reports';
    return (
      <div className="lesson-summary">
        <div className="student-reports-header">
          <h2><img width="78" height="78" className="mb-2 mr-2" src={imagePath + '/report-header-icon--summary.svg'} /> Lessons</h2>
          <hr/>
        </div>

        <div className="report-content">
          {
            mapKeys.map(key => {
              let lessons = lessonsMap[key].lessons
              let sectionName = key
              let section = key as SummarySectionType

              return (
                <div key={key}>
                  {lessons.length > 0 ?
                    <div>
                      <span className="report-lesson-summary-title">
                        <span className="summaryIconRibbon">
                          <img src={getSummaryStyle(section, 'icon')} height="27" width="auto" />
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="71px" height="45px" viewBox="0 0 71 45" enable-background="new 0 0 71 45">
                            <path fill={getSummaryStyle(section, 'color')} d="M0,0h71L60,22.5L71,45H0V0z"/>
                          </svg>
                        </span>
                        {sectionName}
                      </span>
                      <Lessons lessons={lessons}/>
                    </div>
                    :
                    // <div style={{marginBottom: 25}}>No lesson entries found!</div>
                    <div></div>
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}

interface LessonsProps {
  lessons: LessonInfo[]
}
interface LessonsState {}

function renderFormatStatus(message) {

  let delCamelCase = message.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  let delDoubleCol = delCamelCase.replace('::', ' ');
  let delSingleCol = delDoubleCol.replace(' : ', ': ');
  let delAmpersand = delSingleCol.replace(/([A-Za-z0-9])(&)([A-Z])/g, '$1 $2 $3');
  message = delAmpersand;
  return message;
}

class Lessons extends React.Component<LessonsProps, LessonsState> {

  renderTableHeader = () => {
    return (
      <thead>
        <tr key={"report-table-header"}>
          <th className="lst-th1 label">Lesson</th>
          <th className="lst-th2 label">Status</th>
          <th className="lst-th3 label">Pre-Lesson Accuracy</th>
          <th className="lst-th3 label">Completed Accuracy</th>
          <th className="lst-th3 label">Total<br/>Accuracy</th>
          <th className="lst-th3 label">Lesson<br/>Growth</th>
          <th className="lst-th3 label">Hints on<br/>Incorrect</th>
        </tr>
      </thead>
    )
  }

  renderTableBody = (lessons: LessonInfo[]) => {
    return (
      <tbody>
        {lessons.map(lesson => this.renderTableRow(lesson))}
      </tbody>
    )
  }

  renderTableRow(lesson: LessonInfo) {
    let NA = "N/A" // Not applicable
    let preLessonAccuracyInfo, completedQuestionAccuracyInfo
    let lessonGrowthInfo, totalQuestionAccuracyInfo, hintsOnIncorrectInfo
    let { lessonName, currentLessonContent } = lesson
    let { baselineAccuracy: preLessonAccuracy, totalBaselineQuestions } = lesson
    let { totalQuestions, totalCompletedQuestions, totalCorrectPostLessonQuestions, questionCompletionProgress } = lesson
    let { totalHintsWrittenOutOfIncorrectQuestions } = lesson
    let completedQuestionAccuracy = totalCompletedQuestions > 0 ? Math.round(100.0*totalCorrectPostLessonQuestions/totalCompletedQuestions): -1
    let totalCompletedQuestionsAccuracy = Math.round(100.0*totalCorrectPostLessonQuestions/totalQuestions)
    let lessonGrowth = completedQuestionAccuracy - preLessonAccuracy
    let status = renderFormatStatus(currentLessonContent)
    
    preLessonAccuracyInfo = `${preLessonAccuracy}%`
    completedQuestionAccuracyInfo = `${completedQuestionAccuracy}%`
    lessonGrowthInfo = `${lessonGrowth}%`
    totalQuestionAccuracyInfo = `${totalCompletedQuestionsAccuracy}%`
    hintsOnIncorrectInfo = `${totalHintsWrittenOutOfIncorrectQuestions}%`
    
    if (questionCompletionProgress == 0) {
      completedQuestionAccuracyInfo = NA
      lessonGrowthInfo = NA
      totalQuestionAccuracyInfo = NA
      hintsOnIncorrectInfo = NA
    }
    else if (questionCompletionProgress == 100) {
      status = currentLessonContent;
    }
    else {
      status = `${questionCompletionProgress}%`
    }

    if (totalBaselineQuestions == 0) {
      preLessonAccuracyInfo = NA
    }

    if(lessonGrowthInfo.includes('-')) {
      lessonGrowthInfo = '<span class="after--down-arrow">' + lessonGrowthInfo + '</span>'
    } else if(lessonGrowthInfo != 'N/A' && lessonGrowthInfo != '0%') {
      lessonGrowthInfo = '<span class="after--up-arrow">' + lessonGrowthInfo + '</span>'
    } else if (lessonGrowthInfo == '0%' || lessonGrowthInfo === 'N/A') {
      lessonGrowthInfo = '<div class="not-applicable">N/A</div>'
    }
    return(
      <tr key={lesson.lessonId} >
        <td className="lst-td1">{lessonName}</td>
        <td className="lst-td2">{status}</td>
        <td className="lst-td3">
          {preLessonAccuracyInfo === 'N/A' ?
            <span className="not-applicable">N/A</span>
            :
            preLessonAccuracyInfo
          }
        </td>
        <td className="lst-td3">
          {completedQuestionAccuracyInfo === 'N/A' ?
            <span className="not-applicable">N/A</span>
            :
            completedQuestionAccuracyInfo
          }
        </td>
        <td className="lst-td3">
          {totalQuestionAccuracyInfo === 'N/A' ?
            <span className="not-applicable">N/A</span>
            :
            totalQuestionAccuracyInfo
          }
        </td>
        <td className="lst-td3" dangerouslySetInnerHTML={{ __html: lessonGrowthInfo}} />
        <td className="lst-td3">
          {hintsOnIncorrectInfo === 'N/A' ?
            <span className="not-applicable">N/A</span>
            :
            hintsOnIncorrectInfo
          }
        </td>
      </tr>
    )
  }

  render() {
    let {lessons} = this.props
    let catogoryClass = 'theme-v2--' + lessons[0].category.toLowerCase()
    // OT: In the table below I added an empty last column otherwise PDF export cutting off the info!
    // Needs to be inspected, probably a better solution can be found.
    return (
      <div className="responsive-table-wrapper">
        <table className={`report-lesson-summary-table ` + catogoryClass}>
          {this.renderTableHeader()}
          {this.renderTableBody(lessons)}
        </table>
      </div>
    )
  }
}

