import jp from "jsonpath"
import { sum } from "./math-util"

export const MAX_SCORE_ACT = 36
export const MAX_SCORE_SAT = 1600

export enum TestType {
  ACT = "ACT",
  IA  = "IA",
  SAT = "SAT",
}

export const ALL_ACT_TESTS_MAP = {
  Diagnostic: "ACT Diagnostic",
  ACT1: "ACT Test 1",
  ACT2: "ACT Test 2",
  ACT3: "ACT Test 3",
  ACT4: "ACT Test 4",
  ACT5: "ACT Test 5",
  ACT6: "ACT Test 6",
  ACT7: "ACT Test 7",
}

export const ALL_SAT_TESTS_MAP = {
  DiagnosticSAT: "SAT Diagnostic",
  SAT1: "SAT Test 1",
  SAT2: "SAT Test 2",
  SAT3: "SAT Test 3",
  SAT4: "SAT Test 4",
  SAT5: "SAT Test 5",
  // SAT6: "SAT Test 6",
  // SAT7: "SAT Test 7",
}

export const ALL_IA_TESTS_MAP = {
  "Pre-Test 2019": "EOY ACT 8th", // 1
  "Practice 2": "BOY ACT 9th",    // 2
  "Pre-Test": "MOY ACT 9th",      // 3  Pre-Test -> MOY ACT 9th (old Pre-Test 2018)
  "Mid-Test": "EOY ACT 9th",      // 4
  "IA 1": "BOY ACT 10th",         // 5
  "Practice 1": "MOY ACT 10th",   // 6
  "IA 3": "EOY ACT 10th",         // 7
  "IA 2": "BOY ACT 11th",         // 8
  "MOY ACT 11th": "MOY ACT 11th", // 9
}

export const ACTIVE_TESTS = {
  ACT: Object.keys(ALL_ACT_TESTS_MAP),
  SAT: Object.keys(ALL_SAT_TESTS_MAP),
  IA:  Object.keys(ALL_IA_TESTS_MAP),
}

export const ALL_TESTS_MAPS = {...ALL_ACT_TESTS_MAP, ...ALL_SAT_TESTS_MAP, ...ALL_IA_TESTS_MAP}

export function getTestTypeDisplayName(testType: string) {
  let testTypeDisplayName = '';
  if (testType === 'act') {
    return 'ACT TESTS';
  } else if (testType === 'sat') {
    return 'SAT TESTS';
  } else if (testType === 'ia') {
    return 'INTERIM ASSESSMENTS';
  } else {
    return '__default';
  }
}

export function isActTest(testName: string) {
  let keys = Object.keys(ALL_ACT_TESTS_MAP)
  return keys.indexOf(testName) > -1
}

export function isInterimAssessment(testName: string) {
  let keys = Object.keys(ALL_IA_TESTS_MAP)
  return keys.indexOf(testName) > -1
}

export function isSatTest(testName: string) {
  let keys = Object.keys(ALL_SAT_TESTS_MAP)
  return keys.indexOf(testName) > -1
}

/**
 * Gets the test names in a special order.
 * First, diagnostic tests should be displayed at the top for ACT and SAT tests.
 * Second, with the changing display names in the IA tests, ordering of the 
 * testNames should be handled in a special way since the display names now include
 * grade levels. Therefore we need to order them by considering the grade levels too.
 * IA ordering: Grade & {BOY, MOY, EOY} 
 * BOY, MOY, EOY: Beginning, Middle, End of School Year
 * 
 * Note that testNames are used like practice test IDs throughout the application!
 * 
 * Examples: EOY ACT 8th, BOY ACT 10th, MOY 11th, etc.
 * Example of ordered IA tests:
 *  EOY ACT 8th
 *  BOY ACT 10th   
 *  EOY ACT 10th
 *  BOY ACT 11th
 *  MOY ACT 11th
 *  EOY ACT 11th
 * 
 * @param tests 
 * @param testKind 
 * @returns testNames
 */
export function getTestNamesInSpecialOrder(originalTestNames: string[], testKind: "ACT" | "IA" | "SAT") {
  let testNames = [...originalTestNames] // Clone

  testNames = testNames.sort((n1, n2) => {
    let key = "diagnostic" // Keep the Diagnostic at top
    n1 = n1.toLowerCase()
    n2 = n2.toLowerCase()
    if (n1.includes(key)) return -1
    if (n2.includes(key)) return 1
    if (n1 > n2) return 1
    if (n1 < n2) return -1
    return 0
  }) || []

  if (testKind === "IA") {
    // Force the names into a specific lexicographical order
    let replacersMap = {
      "BOY": "1", // Beginnig of the school year
      "MOY": "2", // Middle of the school year
      "EOY": "3", // End of the school year
    }
    let replace = (testDisplayName: string) => Object.keys(replacersMap).reduce((acc, cur) => acc.replace(cur, `${replacersMap[cur]} ${cur}`), testDisplayName)

    testNames.sort((tn1, tn2) => {
      let tdn1 = replace(getTestDisplayName(tn1))
      let tdn2 = replace(getTestDisplayName(tn2))
      let regex = /\b(\d{1,2})(?:st|nd|rd|th)\b/
      let m1 = tdn1.match(regex)
      let m2 = tdn2.match(regex)      
      let ns1 = m1 ? m1[1].padStart(4, "0"): "" // i.e. 8 -> 0008 for lexicographical ordering
      let ns2 = m2 ? m2[1].padStart(4, "0"): ""      
      let k1 = `${ns1}-${tdn1}`
      let k2 = `${ns2}-${tdn2}`
      if (k1 > k2) return 1
      if (k1 < k2) return -1
      return 0
    })
  }

  return testNames
}


/**
 * Gets the test display name from the testName
 * Note that testNames are used as IDs throughout the application.
 * @param testName 
 * @returns 
 */
export function getTestDisplayName(testName: string) {
  // These names are identical to the names in Java backend in TestService.java
  let displayName = ALL_TESTS_MAPS[testName] || testName
  
  return displayName
}

/**
 * Gets the test type based on the test name.
 * TestName is unique for the PT tests.
 * 
 * @param testName 
 * @returns 
 */
export function getTestTypeBasedOnName(testName: string) {
  if (isActTest(testName)) return TestType.ACT
  if (isSatTest(testName)) return TestType.SAT
  return TestType.IA
}

/**
 * There are special sections in the practice tests in SAT
 * like Writing and Language, Math (Calculator) orMath (No Calculator)
 * so we are just getting the short names for those sections 
 * as categorical names
 * @param practiceTestSection
 * @param isSAT 
 * @returns short categories that corresponds to those sections.
 */
export function getCategoryName(practiceTestSection: string, isSAT: boolean) {
  const categoryMap = {
    "Math (Calculator)": "Math-C",
    "Math (No Calculator)": "Math-NC",
    "Writing and Language": "EBRW-WL",
    "Reading": "EBRW-R"
  }

  if (!isSAT) {
    return practiceTestSection
  }

  return categoryMap[practiceTestSection] ? categoryMap[practiceTestSection]: practiceTestSection
}

export function lookupSection(section: string) {
  let sectionMap = {} as any
  sectionMap.English = 'englishAnswers'
  sectionMap.Math = 'mathAnswers'
  sectionMap.Reading = 'readingAnswers'
  sectionMap.Science = 'scienceAnswers'
  sectionMap['Writing and Language'] = 'writingAndLanguageAnswers'
  sectionMap['Math (No Calculator)'] = 'mathNoCalculatorAnswers'
  sectionMap['Math (Calculator)'] = 'mathCalculatorAnswers'

  // SAT 2024 update
  sectionMap['R&W Module 1'] = 'readingAnswers'
  sectionMap['R&W Module 2'] = 'writingAndLanguageAnswers'
  sectionMap['Math Module 1'] = 'mathNoCalculatorAnswers'
  sectionMap['Math Module 2'] = 'mathCalculatorAnswers'

  return sectionMap[section]
}


// Names for SAT 2024 Update:
// * Reading -> R&W Module 1
// * Writing and Language -> R&W Module 2
// * Math (No Calculator) -> Math Module 1
// * Math (Calculator) -> Math Module 2
export const SAT2024 = {
  DISPLAYNAME_RW_MODULE1: "R&W Module 1",
  DISPLAYNAME_RW_MODULE2: "R&W Module 2",
  DISPLAYNAME_MATH_MODULE1: "Math Module 1",
  DISPLAYNAME_MATH_MODULE2: "Math Module 2",

  // OT: Keep the original sections names (needed throughout the app)!
  SECTIONNAME_RW_MODULE1: "Reading",
  SECTIONNAME_RW_MODULE2: "Writing and Language",
  SECTIONNAME_MATH_MODULE1: "Math (No Calculator)",
  SECTIONNAME_MATH_MODULE2: "Math (Calculator)",

  SECTIONNAME_CONDENSED_RW_MODULE1: "Reading",
  SECTIONNAME_CONDENSED_RW_MODULE2: "WritingAndLanguage",
  SECTIONNAME_CONDENSED_MATH_MODULE1: "MathNoCalculator",
  SECTIONNAME_CONDENSED_MATH_MODULE2: "MathCalculator",

}

export function getSATSectionDisplayName(sectionName) {
  let displayName = sectionName

  switch (sectionName) {
    case SAT2024.SECTIONNAME_RW_MODULE1:
    case SAT2024.SECTIONNAME_CONDENSED_RW_MODULE1:
      displayName = SAT2024.DISPLAYNAME_RW_MODULE1
      break

    case SAT2024.SECTIONNAME_RW_MODULE2:
    case SAT2024.SECTIONNAME_CONDENSED_RW_MODULE2:
      displayName = SAT2024.DISPLAYNAME_RW_MODULE2
      break

    case SAT2024.SECTIONNAME_MATH_MODULE1:
    case SAT2024.SECTIONNAME_CONDENSED_MATH_MODULE1:
      displayName = SAT2024.DISPLAYNAME_MATH_MODULE1
      break

    case SAT2024.SECTIONNAME_MATH_MODULE2:
    case SAT2024.SECTIONNAME_CONDENSED_MATH_MODULE2:
      displayName = SAT2024.DISPLAYNAME_MATH_MODULE2
      break
  }

  return displayName
}
