import * as React from 'react';

import './HintSubjectHeader.css';

import { getSubjectIconList } from '../../../../services/icon/icon-service';

interface Props {
  name: string;
}

// Get the summary icons suitable for PDF export (svg graphics are not processed well!)
function getSummaryStyle(section: string, element: string) {
    let resUrl = "/assets/images/icons/reports/"
    let resColor = '#000000'
    switch (section) {
        case "English":
        resUrl += "icon-subject-english.svg"
        resColor = '#a256d7'
        break
        case "Math":
        resUrl += "icon-subject-math.svg"
        resColor = '#3FC3D2'
        break
        case "Reading":
        resUrl += "icon-subject-reading.svg"
        resColor = '#f29000'
        break
        case "Science":
        resUrl += "icon-subject-science.svg"
        resColor = '#8ac22d'
        break
        case "practice":
        resUrl += 'icon-subject-practice-tests.svg'
        resColor = '#ffc957'
        break
        case "School Safety & Security":
        resUrl += 'lock-white.svg'
        resColor = '#0e5682'
        break
        default: // This shouldn't happen!
        resUrl += "black_x_mark.png"
    }
    if(element == 'color') {
        return resColor
    } else {
        return resUrl
    }
}

class HintSubjectHeader extends React.Component<Props> {
    constructor (props: Props) {
        super(props);
        this.state = { };
    }

    public render() {
        const subjectIcons = getSubjectIconList('@0,5x');
        const subjectIcon = subjectIcons.find(subj => subj.title === this.props.name);
        const standardSubjectStyle = { 
          backgroundColor: subjectIcon ? subjectIcon.color : 'gray'
        };

        const { name } = this.props;
        const isPracticeTest = name.startsWith('Practice Test');
        const practiceTestStyle = {
            backgroundColor: '#ffc957',
        };

        return (
            (isPracticeTest ?
                <span className="report-lesson-summary-title">
                    <span className="summaryIconRibbon">
                        <img src={getSummaryStyle('practice', 'icon')} height="27" />
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="71px" height="45px" viewBox="0 0 71 45" enable-background="new 0 0 71 45">
                        <path fill={getSummaryStyle('practice', 'color')} d="M0,0h71L60,22.5L71,45H0V0z"/>
                        </svg>
                    </span>
                    {name}
                </span>
            :
            <>
                <span className="report-lesson-summary-title">
                    <span className="summaryIconRibbon">
                        <img src={getSummaryStyle(name, 'icon')} height="27" />
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="71px" height="45px" viewBox="0 0 71 45" enable-background="new 0 0 71 45">
                        <path fill={getSummaryStyle(name, 'color')} d="M0,0h71L60,22.5L71,45H0V0z"/>
                        </svg>
                    </span>
                {name}</span>
            </>
            )
        );
    }
}

export default HintSubjectHeader;
