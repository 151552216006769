import * as React from 'react'
import DataGrid from '../../../components/DataGrid'
import { Column } from '../../../components/DataGrid/types/types'
import { IAssessments, LinkType } from './types'
import { assesmentsData } from "./assessments.data"
import { sortStr } from '../../../services/utils/text-util'

interface Props {}

interface State {}

const INFO_EMPTY = "No assessment info to display!"

const columns: Column[] = [
    {
        title: 'RESOURCE',
        dataProperty: 'resource',
        width: '30%',
        sortMethod: sortStr,
        customTooltip: 'Indicates the name of the resource'
    },
    {
        title: 'DESCRIPTION',
        dataProperty: 'description',
        width: '65%',
        sortMethod: sortStr,
        customTooltip: 'Indicates the description of the resource'
    },
    {
        title: 'LINK',
        dataProperty: 'link',
        sortable: false,
        customTooltip: 'Opens resource in new tab'
    },
]


export class Assessments extends React.Component<Props, State> {
    state = {

    }

    constructor(props) {
        super(props)
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private createLink = (data: IAssessments) => {
        let {resource, link, linkType} = data
        // OT: Jennifer wants one icon for all types
        // let iconPath = "/assets/images/icons/home/"
        // let iconSrc = `${iconPath}${
        //         linkType === LinkType.PDF ? "pdf-icon.svg":
        //         linkType === LinkType.PRESENTATION ? "presentation.svg": 
        //         linkType === LinkType.SHEET ? "sheet.svg": "document.svg"}`

        return () => (
            <div className="d-flex h-100 align-items-center justify-content-center">
                <a
                    href={link}
                    className="download-icon--link"
                    target="_blank"
                    title={`View the resource "${resource}"`}>
                    <img
                        alt=""
                        src={`/assets/images/icons/v2/ico-external-link.svg`}
                        width="18"
                        height="18"
                    />
                </a>
            </div>
        )
    }

    private createAssessmentsData = (data: IAssessments, idx: number) => {
        const ecd = {
            resource: {
                content: data.resource,
                comparator: data.resource,
                subComparator: ""
            },
            description: {
                content: data.description,
                comparator: data.description,
                subComparator: ""
            },
            link: {
                content: this.createLink(data),
                comparator: data.linkType + data.description,
                subComparator: ""
            }
        }
        return {
            data: ecd,
            id: `engagement-culture-${idx}`
        }
    }

    render() {
        const category1 = assesmentsData.category1
        const category2 = assesmentsData.category2
        const category3 = assesmentsData.category3
        const category4 = assesmentsData.category4
        const dataCategory1 = category1.data.map(this.createAssessmentsData)
        const dataCategory2 = category2.data.map(this.createAssessmentsData)
        const dataCategory3 = category3.data.map(this.createAssessmentsData)
        const dataCategory4 = category4.data.map(this.createAssessmentsData)

        return (
            <div>
                <div className="assessments-container">
                    <div className="assessments-info">
                        <AssessmentCategory title={category1.name}>
                            <DataGrid
                                columns={columns}
                                className={"assessment-table"}
                                data={dataCategory1}
                                emptyText={INFO_EMPTY}
                            />
                        </AssessmentCategory>

                        <AssessmentCategory title={category2.name}>
                            <DataGrid
                                columns={columns}
                                className={"assessment-table"}
                                data={dataCategory2}
                                emptyText={INFO_EMPTY}
                            />
                        </AssessmentCategory>

                        <AssessmentCategory title={category3.name}>
                            <DataGrid
                                columns={columns}
                                className={"assessment-table"}
                                data={dataCategory3}
                                emptyText={INFO_EMPTY}
                            />
                        </AssessmentCategory>

                        <AssessmentCategory title={category4.name}>
                            <DataGrid
                                columns={columns}
                                className={"assessment-table"}
                                data={dataCategory4}
                                emptyText={INFO_EMPTY}
                            />
                        </AssessmentCategory>
                    </div>           
                </div>
            </div>

        )
    }
}

//--- Helpers ---

interface AssessmentCategoryProps {title: string}
interface AssessmentCategoryState {}

class AssessmentCategory extends React.Component<AssessmentCategoryProps, AssessmentCategoryState> {

    render() {
        let {title, children} = this.props

        return (
            <div className="assessment-category-container">
                <div className="assessment-category-title">
                    {title}
                </div>
                <div>
                    {children}
                </div>
            </div>
        )
    }
}
 