import './CollapsableCard.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

interface Props {
    initiallyCollapsed?: boolean;
}

interface State {
    collapsed: boolean;
}


export default class CollapsableCard extends React.PureComponent<Props, State> {
    public static defaultProps: Partial<Props> = {
        initiallyCollapsed: true
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            collapsed: !!props.initiallyCollapsed
        };
    }

    public render() {
        const header = React.Children.toArray(this.props.children)
            .filter(this.isHeader)
            .map(this.cloneHeader);
        const body = React.Children.toArray(this.props.children)
            .filter(this.isBody)
            .map(this.cloneBody);

        return (
            <div className="collapsable-card">
                {header}
                {body}
            </div>
        );
    }

    private isHeader(el: any) {
        return CollapsableCardHeader.prototype === (el.type as any).prototype;
    }

    private isBody(el: any) {
        return CollapsableCardBody.prototype === (el.type as any).prototype;
    }

    @boundMethod
    private cloneHeader(el: any) {
        return React.cloneElement(el, {
            collapsed: this.state.collapsed,
            onCollapseClick: this.handleCollapseClick
        });
    }

    @boundMethod
    private cloneBody(el: any) {
        return React.cloneElement(el, {
            collapsed: this.state.collapsed
        });
    }

    @boundMethod
    private handleCollapseClick() {
        const collapsed = !this.state.collapsed;
        this.setState({ collapsed });
    }
}

interface HeaderProps {
    collapsed?: boolean;
    onCollapseClick?: () => void;
}

export const CollapsableCardHeader: React.SFC<HeaderProps> = (props) => {
    const collapsedClass = props.collapsed ? 'collapsed' : '';
    const cardHeaderTitle = props.collapsed ? 'Expand' : 'Collapse';
    return (
        <div className={`collapsable-card__header ${collapsedClass}`}>
            <button title={cardHeaderTitle} className="btn-div" onClick={props.onCollapseClick}>
                {props.children}
                <div className="collapsable-card__collapse-btn" onClick={props.onCollapseClick} />
            </button>
        </div>
    );
};

CollapsableCardHeader.displayName = 'CollapsableCardHeader';
CollapsableCardHeader.defaultProps = { onCollapseClick: () => {/**/} };

interface BodyProps {
    collapsed?: boolean;
}

interface BodyState {
    height?: number;
    collapsed: boolean;
}
export class CollapsableCardBody extends React.Component<BodyProps, BodyState> {
    private body?: HTMLDivElement | null;
    private height?: number;

    constructor(props: BodyProps) {
        super(props);
        this.state = {
            collapsed: false
        };
    }

    public componentDidMount() {
        this.height = this.body ? this.body.clientHeight : 0;
        this.setState({ collapsed: !!this.props.collapsed });
    }

    public UNSAFE_componentWillReceiveProps(nextProps: BodyProps) {
        if(nextProps.collapsed !== this.state.collapsed) {
            this.setState({ collapsed: !!nextProps.collapsed });
        }
    }

    public render() {
        const collapsedClass = this.state.collapsed ? 'collapsed' : '';
        const style = !this.state.collapsed ? {} : {};
        return (
            <div className={`collapsable-card__body ${collapsedClass}`} ref={el => this.body = el} style={style}>
                {this.props.children}
            </div>
        );
    }
}
