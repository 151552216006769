import React, { Component } from 'react'
import jp from "jsonpath"
import { getMCQuestionsForRegularLessons } from '../../../services/elearn/lesson-rest-interface'
import { TeacherStore } from '../../../stores/teacher.store'
import { Selections } from './types'
import DataGrid from '../../../components/DataGrid'
import { Column } from '../../../components/DataGrid/types/types'
import Loading from '../../../components/Loading/Loading'
import StatusProgressBar from '../../../components/StatusProgressBar'
import { EMPTY, NA, NO, YES } from '../../../constants'
import { getHumanTime } from '../../../services/utils/date-util'
import { avg, sum } from '../../../services/utils/math-util'
import { collegeReadyLookup } from '../../../services/utils/college-ready-util'
import { determineNAs, getStatus } from '../../../rules/business'
import { alphanumericCompare } from '../../../services/utils/sort-util'
import { ReteachPlus } from '../../../components/ReteachPlus'
import Button from '../../../components/Button/Button'
import { getReteachQuestions, updateQuestionReteachStatus } from '../../../services/elearn/teacher/reteach'
import { IReteachQuestion } from '../types'
import { persistUserInfo } from '../../../persistance/local'
import "./LessonBreakdownView.css"

interface ILessonPartial {
  lessonId: string
  lessonName: string
}

interface IScoredAnswer {
  lessonId: string
  questionNumber: number
  questionId: string
  isSelected: boolean
  nofStudents: number
  nofCorrectAnswers: number
  accuracy: number
  correctAnswer: string // A | B | C | D
  answerA: number,
  answerB: number,
  answerC: number,
  answerD: number,
  timeToAnswer: number,
  timedStudents: number  
}

interface Props {
  lesson: ILessonPartial
  selections: Selections
  nofTotalStudents: number,
  subjectColor?:string
}

interface State {
  scoredAnswers: IScoredAnswer[]
  studentsAnswersForLesson: any[] // TODO
  nofTotalQuestions: number
  loading: boolean
  errorMsg?: string
}

let sortMethod = alphanumericCompare
let minSortVal = Number.NEGATIVE_INFINITY
let sortable = true
let columnsForQuestions: Column[] = [
  {
    title: '#',
    dataProperty: 'questionNumber',
    customTooltip: 'Indicates the question number',
    width: '8%',
    sortable,
    sortMethod
  },
  {
    title: 'NUMBER OF<br/>STUDENTS',
    dataProperty: 'nofStudents',
    customTooltip: 'Indicates the number of students',
    width: '12%',
    sortable,
    sortMethod
  },
  {
    title: 'QUESTION<br/>ACCURACY',
    dataProperty: 'accuracy',
    customTooltip: 'Indicates the question accuracy',
    width: '12%',
    sortable,
    sortMethod
  },

  {
    title: 'QUESTION<br/>AVG. TIME',
    dataProperty: 'timeToAnswer',
    customTooltip: 'Calculated as the average time to answer the question',
    width: '12%',
    sortable,
    sortMethod
  },
  {
    title: 'ANSWER A',
    dataProperty: 'answerA',
    customTooltip: 'Answer A',
    width: '12%',
    sortable,
    sortMethod
  },
  {
    title: 'ANSWER B',
    dataProperty: 'answerB',
    customTooltip: 'Answer B',
    width: '12%',
    sortable,
    sortMethod
  },
  {
    title: 'ANSWER C',
    dataProperty: 'answerC',
    customTooltip: 'Answer C',
    width: '12%',
    sortable,
    sortMethod
  },
  {
    title: 'ANSWER D',
    dataProperty: 'answerD',
    customTooltip: 'Answer D',
    width: '12%',
    sortable,
    sortMethod
  },
  {
    title: 'RETEACH',
    dataProperty: 'reteach',
    customTooltip: 'Mark questions to reteach',
    width: '8%',
    sortable,
    sortMethod
  },
]

let columnsForStudents: Column[] = [
  {
    title: 'FIRST NAME',
    dataProperty: 'studentNameFirst',
    customTooltip: 'Indicates the first name of the student',
    width: '15%',
    sortable,
    sortMethod
  },
  {
    title: 'LAST NAME',
    dataProperty: 'studentNameLast',
    customTooltip: 'Indicates the last name of the student',
    width: '15%',
    sortable,
    sortMethod
  },
  {
    title: 'STATUS',
    dataProperty: 'status',
    customTooltip: 'Indicates the status of student',
    width: '220px',
    sortable,
    sortMethod
  },
  {
    title: 'PRE-LESSON<br/>ACCURACY',
    dataProperty: 'accuracyPre',
    customTooltip: 'Indicates the pre-lesson accuracy',
    width: '14%',
    sortable,
    sortMethod
  },
  {
    title: 'POST-LESSON<br/>ACCURACY',
    dataProperty: 'accuracyPost',
    customTooltip: 'Indicates the post-lesson accuracy',
    width: '14%',
    sortable,
    sortMethod
  },
  {
    title: 'LESSON<br/>GROWTH',
    dataProperty: 'growth',
    customTooltip: 'Indicates the lesson growth',
    width: '12%',
    sortable,
    sortMethod
  },
  {
    title: 'HINTS ON<br/>INCORRECT',
    dataProperty: 'hintsOnIncorrect',
    customTooltip: 'Indicates the hints on incorrect',
    width: '12%',
    sortable,
    sortMethod
  },
  {
    title: 'TIME ON<br/>QUESTIONS',
    dataProperty: 'timeOnQuestion',
    customTooltip: 'Indicates the time on questions',
    width: '12%',
    sortable,
    sortMethod
  },
  {
    title: 'TOTAL<br/>STUDY TIME',
    dataProperty: 'studyTime',
    customTooltip: 'Indicates the total study time on lesson',
    width: '12%',
    sortable,
    sortMethod
  },
  {
    title: 'COLLEGE<br/>READY',
    dataProperty: 'collegeReady',
    customTooltip: 'Indicates the college readiness',
    width: '12%',
    sortable,
    sortMethod
  },
]

export default class LessonBreakdownView extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      scoredAnswers: [],
      studentsAnswersForLesson: [],
      nofTotalQuestions: 0,
      loading: true
    }    
    persistUserInfo()
  }

  componentDidMount() {
    let {lesson, selections, subjectColor} = this.props

    if (lesson) {
      let {lessonId} = lesson
      this.prepareLessonBreakdown(lessonId, selections)
    }
  }

  private prepareLessonBreakdown = async (lessonId: string, selections: Selections) => {
    let {lesson, nofTotalStudents} = this.props
    let {questions, ok} = await getMCQuestionsForRegularLessons(lessonId)

    if (!ok) {
      this.setState({errorMsg: `Error occured while fetching the lesson "${lesson.lessonName}". Please try again.`})
      return
    }

    let reteachQuestions: IReteachQuestion[] = await getReteachQuestions(lesson.lessonId)
    let nofTotalQuestions = questions.length
    let students = TeacherStore.getAllStudents()
    let lessonsForAllStudents = TeacherStore.lessonInfoForAllStudents()
    let tonpForAllStudents = TeacherStore.timeOnPlaformInfoForAllStudents()
    let allLessonsForStudents = lessonsForAllStudents.map(lessonsForStudent => {
      let studentEmail = lessonsForStudent.emailAddress
      let allTonPForStudent = tonpForAllStudents.filter(t => t.email === studentEmail)
      let allAnsweredQuestionsJQ = jp.query(allTonPForStudent, "$..questions.items")
      let allAnsweredQuestions = allAnsweredQuestionsJQ.reduce((acc, cur) => {
        acc.push(...Object.keys(cur).map(key => ({ questionId: key, ...cur[key] })));
        return acc
      }, [])
      let lessons = lessonsForStudent.lessons.map(lesson => {
        let lessonAnswers = lesson.answers
        let answers = lessonAnswers.map((answer, idx) => {
          let tonp = allAnsweredQuestions.find(q => q.questionId === answer.questionId)

          return {
            ...answer,
            tonp
          }
        })
        answers.sort((a1, a2) => (a1.questionNo > a2.questionNo ? 1 : -1))
        return {
          ...lesson,
          lessonId: lesson.lessonId,
          answers,
        }
      })

      return {
        userId: lessonsForStudent.userId,
        email: studentEmail,
        lessons
      }
    })           

    let questionIds = questions.map(q => q.questionId)
    let studentsAnswersForLesson = allLessonsForStudents
      .map(student => {        
        let lesson = student.lessons.find(l => l.lessonId === lessonId)
        if (!lesson) return null

        lesson.status = getStatus(lesson) 

        let studentInfo = students.find(s => s.emailAddress === student.email)
        return {
          email: student.email,
          ...lesson,
          ...studentInfo,          
          answers: lesson.answers
        }
      })
      .filter(data => data != null)
      .map(data => ({lessonId, ...data}))

    studentsAnswersForLesson.sort((lesson1, lesson2) => {
      let k1 = `key-${lesson1.lastName}-${lesson1.firstName}`
      let k2 = `key-${lesson2.lastName}-${lesson2.firstName}`
      return k1.localeCompare(k2)
    })      

    let allScoredAnswers = [] as IScoredAnswer[]
    questionIds.forEach((questionId, index) => {
      let question = questions?.find(q => q.questionId == questionId)
      let correctAnswer = question?.questionOptions.find(o => o.isCorrect)?.mcLetter
      let isSelected = reteachQuestions.find(rq => rq.questionId == question?.questionId)?.selected
      let initialVals = {
        lessonId: lesson.lessonId,
        questionNumber: question?.questionNumber || -1,
        questionId,
        isSelected,
        nofStudents: 0,
        correctAnswer,
        nofCorrectAnswers: 0,
        accuracy: 0,
        answerA: 0,
        answerB: 0,
        answerC: 0,
        answerD: 0,
        timeToAnswer: 0,
        timedStudents: 0
      }
      let scoredAnswers = studentsAnswersForLesson.reduce((acc, cur) => {
        let studentAnswers = cur.answers
        let studentAnswer = studentAnswers?.find(a => a.questionId === questionId)

        if (studentAnswer) {
          let tonp = studentAnswer.tonp
          acc.nofStudents++
          acc.nofCorrectAnswers += studentAnswer?.isCorrect ? 1 : 0
          acc.answerA += studentAnswer?.mcLetter === "A" ? 1 : 0
          acc.answerB += studentAnswer?.mcLetter === "B" ? 1 : 0
          acc.answerC += studentAnswer?.mcLetter === "C" ? 1 : 0
          acc.answerD += studentAnswer?.mcLetter === "D" ? 1 : 0

          if (tonp) {  
            acc.timeToAnswer += tonp.answered
            acc.timedStudents++
          }
        }

        return acc
      }, initialVals)

      scoredAnswers.accuracy = Math.round(100*(scoredAnswers.nofCorrectAnswers / scoredAnswers.nofStudents))
      
      if (scoredAnswers.timedStudents > 0) {
        scoredAnswers.timeToAnswer = Math.round(scoredAnswers.timeToAnswer / scoredAnswers.timedStudents)
      }
      else {
        scoredAnswers.timeToAnswer = -1
      }

      allScoredAnswers.push(scoredAnswers)
    })
    this.setState({scoredAnswers: allScoredAnswers, nofTotalQuestions, studentsAnswersForLesson})
    setTimeout(() => this.setState({loading: false}), 700)
  }

  private handleReteachClick = (scoredAnswer: IScoredAnswer, selected: boolean) => {
    let {lessonId, questionId} = scoredAnswer
    scoredAnswer.isSelected = selected    
    updateQuestionReteachStatus(lessonId, questionId, selected)
    this.setState({}) // Force refresh!
  }

  private createContentForQuestion = (scoredAnswer: IScoredAnswer, index: number) => {
    let id = `lesson-breakdown-question-${index}`
    let {lessonId, questionId, questionNumber, isSelected, nofStudents, accuracy, timeToAnswer, nofCorrectAnswers, correctAnswer, answerA, answerB, answerC, answerD} = scoredAnswer
    let {nofTotalStudents} = this.props
    let getReteachContent = (questionId) => {
      return () => (
        <ReteachPlus isSelected={isSelected} onClick={() => this.handleReteachClick(scoredAnswer, !isSelected)}/>
      )
    }
    let getConditionalContent = (val, postfix="") => { return nofStudents > 0 ? `${val}${postfix}`: EMPTY }
    let getConditionalContentForAnswer = (answer, nofAnswers) => {
      if (nofStudents === 0) return EMPTY
      let percentage = Math.round(100*nofAnswers/nofStudents)
      return () => (
        <div className="conditional-answer">
          {correctAnswer === answer ?
            <div className="answer-correct">
              <div className="th-tooltip">
                <div>
                  {nofAnswers}
                  &nbsp;
                  ({percentage}%)
                </div>
                <span className="th-tooltip-text">Correct Answer</span>
              </div>
            </div>
            :
            nofAnswers > nofCorrectAnswers ?             
              <div className="answer-incorrect">
                <div className="th-tooltip">
                  <div>
                      {nofAnswers}
                    &nbsp;
                    ({percentage}%)
                  </div>
                  <span className="th-tooltip-text">Distractor Answer (i.e., picked more than the correct answer)</span>
                </div>
              </div>
              :
              <>
                {nofAnswers}
                &nbsp;
                ({percentage}%)
              </>
          }
        </div>
      )
    }
    let getContentForTime = (val=0) => {
      let ht = getHumanTime(val, true, true)
      return () => <span dangerouslySetInnerHTML={{__html: ht.text}}/>
    }
    let noStudentFound = nofStudents === 0
    let nofStudentsInfo = noStudentFound ? NA: `${nofStudents} (${Math.min(100, Math.round(100*nofStudents/nofTotalStudents))}%)`
    let timeToAnswerInfo = noStudentFound ? EMPTY:  timeToAnswer > 0 ? getContentForTime(timeToAnswer): NA    
    let reteachComparator = `${isSelected ? "A": "Z"}-${questionNumber.toString().padStart(3, "0")}` // Make it lexicographically sortable!    
    let data = {
      questionNumber: {
        content: questionNumber,
        comparator: questionNumber,
        className: "question-number"
      }, 
      nofStudents: {
        content: nofStudentsInfo,
        comparator: nofStudents,
      },
      accuracy: {
        content: getConditionalContent(accuracy, "%"),
        comparator: noStudentFound ? minSortVal: accuracy,
      },
      timeToAnswer: {
        content: timeToAnswerInfo,
        comparator: noStudentFound ? minSortVal: timeToAnswer,
      },
      answerA: {
        content: getConditionalContentForAnswer("A", answerA),
        comparator: noStudentFound ? minSortVal: answerA,
      },
      answerB: {
        content: getConditionalContentForAnswer("B", answerB),
        comparator: noStudentFound ? minSortVal: answerB,
      },
      answerC: {
        content: getConditionalContentForAnswer("C", answerC),
        comparator: noStudentFound ? minSortVal: answerC,
      },
      answerD: {
        content: getConditionalContentForAnswer("D", answerD),
        comparator: noStudentFound ? minSortVal: answerD,
      },
      reteach: {
        content: getReteachContent(questionId),
        comparator: reteachComparator,
      }
    }

    return {
      id,
      data
    }
  }

  private createContentForStudent = (lesson: any, index: number) => {
    let id = `lesson-breakdown-student-${index}`
    let {selections, subjectColor} = this.props
    let testKind = selections.selectedTestKind || "ACT"
    let {firstName, lastName, email, section: category, completionPercentage, status, totalCompletedQuestions, preLessonAccuracy, postLessonAccuracy, lessonGrowth, hintsOnIncorrect} = lesson
    let {studentsAnswersForLesson} = this.state
    let studentTonPInfo = lesson.answers ? lesson.answers.map(a => a.tonp).filter(t => t): []
    let onQuestionTimeAvg = Math.round(avg(studentTonPInfo.map(t => t.answered)))
    let onQuestionTimeTotal = sum(studentTonPInfo.map(t => t.answered))
    let totalStudyTime = sum(studentTonPInfo.map(t => t.ended))
    let nofStudents = studentsAnswersForLesson.length      
    let getStatusProgress = (status, category) => () => <StatusProgressBar status={status} category={category} />
    let getConditionalContent = (val, postfix="") => { return nofStudents > 0 ? `${val}${postfix}`: EMPTY }
    let getFormattedGrowth = (growth) => {
      let arrowClass = growth < 0 ? "after--down-arrow" : growth > 0 ? "after--up-arrow" : ""
      return () => (
        <span className={arrowClass}>{growth}%</span>
      )
    }
    let getTimeInfo = (studyTimeInSeconds: number) => {
      return () => {
        if (studyTimeInSeconds > 0) {
          let ht = getHumanTime(studyTimeInSeconds, true, true)
          return <span dangerouslySetInnerHTML={{__html: ht.text}}/>
        }
        return <span className="not-applicable">{NA}</span>
      }
    }
    let getTimeOnQuestionInfo = (avgTime: number, totalTime: number) => {
      let avgTimeInfo = avgTime > 0 ? getHumanTime(avgTime, true, true).text: NA
      let totalTimeInfo = totalTime > 0 ? getHumanTime(totalTime, true, true).text: NA
      return () => (
        <div className="time-on-question-info act-or-sat-score-info">
          {avgTimeInfo != NA ?
            <div>
              Avg <span dangerouslySetInnerHTML={{__html: avgTimeInfo}}/>
              <br/>
              Total <span dangerouslySetInnerHTML={{__html: totalTimeInfo}}/>
            </div>
            :
            <div className="not-applicable">{NA}</div>
          }
        </div>
      )
    }
    let {isAccuracyPreNA, isAccuracyPostNA, isGrowthNA, isHintsOnIncorrectNA, isCollegeReadyNA} = determineNAs(category, lesson)
    let studyTime = getTimeInfo(totalStudyTime)
    let timeOnQuestion = getTimeOnQuestionInfo(onQuestionTimeAvg, onQuestionTimeTotal)
    let collegeReadyValue = collegeReadyLookup(category, testKind)
    let collegeReady = postLessonAccuracy >= collegeReadyValue ? YES: NO
    let getCollegeReady = (collegeReady) => {
      return () => (
        <div>
          { collegeReady === YES ?
            <img className="enabled" title="" alt="College Ready" src="/assets/images/icons/v2/ico-check-green.svg" width="20" height="20" />
            :
            <img className="enabled" title="" alt="Not College Ready" src="/assets/images/icons/v2/ico-x-red.svg" width="20" height="20" />
          }
        </div>
      )
    }
    let data = {
      studentNameFirst: {
        content: firstName,
        comparator: firstName,
      }, 
      studentNameLast: {
        content: lastName,
        comparator: lastName,
      },
      status: {
        content: getStatusProgress(status, category),
        comparator: status,
      },
      accuracyPre: {
        content: isAccuracyPreNA ? NA: getConditionalContent(preLessonAccuracy, "%"),
        comparator: isAccuracyPreNA ? minSortVal: preLessonAccuracy,
      },
      accuracyPost: {
        content: isAccuracyPostNA ? NA: getConditionalContent(postLessonAccuracy, "%"),
        comparator: isAccuracyPostNA ? minSortVal: postLessonAccuracy,
      },
      growth: {
        content: isGrowthNA ? NA: getFormattedGrowth(lessonGrowth),
        comparator: isGrowthNA ? minSortVal: lessonGrowth,
      },
      hintsOnIncorrect: {
        content: isHintsOnIncorrectNA ? NA: getConditionalContent(hintsOnIncorrect, "%"),
        comparator: isHintsOnIncorrectNA ? minSortVal: hintsOnIncorrect,
      },
      timeOnQuestion: {
        content: timeOnQuestion,
        comparator: onQuestionTimeTotal,
      },
      studyTime: {
        content: studyTime,
        comparator: totalStudyTime,
      },
      collegeReady: {
        content: isCollegeReadyNA ? NA: getCollegeReady(collegeReady),
        comparator: isCollegeReadyNA ? minSortVal: collegeReady === YES ? 1: -1
      },
    }

    return {
      id,
      data
    }
  }

  private getCardBodyContentForQuestions = (scoredAnswers: IScoredAnswer[]) => {
    let data = scoredAnswers.map((sa, index) => this.createContentForQuestion(sa, index))

    return (
      <DataGrid
        columns={columnsForQuestions}
        data={data}
        specialSort={true}
        emptyText="No questions answered for this lesson."
      />
    )
  }

  private getCardBodyContentForStudents = (lessons: any[]) => {
    let data = lessons.map((lesson, index) => this.createContentForStudent(lesson, index))

    return (
      <DataGrid
        columns={columnsForStudents}
        data={data}
        emptyText="No students started this lesson."
      />
    )
  }

  render() {
    let {subjectColor} = this.props
    let {lessonId, lessonName} = this.props.lesson
    let {scoredAnswers, studentsAnswersForLesson, nofTotalQuestions, loading, errorMsg} = this.state
    let questionsDetails = this.getCardBodyContentForQuestions(scoredAnswers)
    let studentsDetails = this.getCardBodyContentForStudents(studentsAnswersForLesson)
    let nofParticipatedStudents = studentsAnswersForLesson.length
    return (
      <div className="lesson-breakdown-container">
        <HeadingBanner title={`Lesson Metrics: ` + lessonName} />
        <ErrorOrLoadingInfo errorMsg={errorMsg} loading={loading}/>                
        <LessonBreakdownContent
          lessonName={lessonName}
          lessonId={lessonId}
          nofParticipatedStudents={nofParticipatedStudents}
          errorMsg={errorMsg} 
          loading={loading}
          subjectColor={subjectColor}
          nofTotalQuestions={nofTotalQuestions}
          scoredAnswers={scoredAnswers}
          questionsDetails={questionsDetails}
          studentsDetails={studentsDetails}
        />
      </div>
    )
  }
}

//--- Helpers ---
const LessonBreakdownLoadingMessage = () => {
  return (
    <div className="lesson-breakdown-loading-wrapper">
      <Loading />
      <p>
        We are calculating your lesson breakdown in real time. Please be patient as this process can take up to 30 seconds.
      </p>
    </div>
  )  
}

const HeadingBanner = ({title}) => {
  return (
    <div className="heading-banner-lessons">
      <h2 className="lesson-breakdown-title mb-0">{title}</h2>
    </div>
  )
}

const ErrorOrLoadingInfo = ({errorMsg, loading}) => {
  return (
    errorMsg ?
      <div className="lesson-breakdown-error">{errorMsg}</div>
      :
      loading && <LessonBreakdownLoadingMessage />
  )
}

const LessonBreakdownContent = ({
  lessonName,
  lessonId,
  nofParticipatedStudents, 
  errorMsg, loading, 
  subjectColor, nofTotalQuestions, scoredAnswers,
  questionsDetails,
  studentsDetails}) => {

  if (errorMsg || loading) return null

  let ReteachButton = ({text="LAUNCH RETEACH"}) => {
    let link = `/teacher-reteach/${lessonId}`
    return (
      <Button
        text={text}
        textColor="white"
        bgColor="blue"
        onClick={(event) => {
            event.preventDefault()
            window.open(link)
        }}
      /> 
    )
  }

  return (
    <div className="lesson-breakdown-content">
      <div className="lesson-breakdown-questions">
        <div className="question-breakdown">
          <h3>Question Breakdown</h3>
          <div className="launch-reteach">
            <ReteachButton />         
          </div>
          Total # of questions: {nofTotalQuestions}
        </div>

        <div className="lesson-breakdown-questions-details">
          {questionsDetails}
        </div>

        <ReteachButton />
      </div>

      <div className="lesson-breakdown-students">
        <h3>Student Information</h3> 
        Total # of students: {nofParticipatedStudents}
        <hr/>
        <div className="lesson-breakdown-students-details">
          {studentsDetails}
        </div>
      </div>
    </div>
  )
}
