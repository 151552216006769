// Add constants here that are used throughout the application

//--- CDN ---
export const CDN_PUB_URL = `https://pub.winwardacademy.com` // OT: This is our CDN (AWS S3 bucket)
export const CDN_PUB_URL_ASSETS = `${CDN_PUB_URL}/assets`
export const CDN_PUB_URL_HANDOUTS = `${CDN_PUB_URL_ASSETS}/handouts`
export const CDN_PUB_URL_RESOURCES = `${CDN_PUB_URL}/resources`
export const CDN_PUB_URL_RESOURCES_ASSESSMENTS = `${CDN_PUB_URL_RESOURCES}/assessments`
export const CDN_PUB_URL_RESOURCES_ENGAGEMENT_AND_CULTURE = `${CDN_PUB_URL_RESOURCES}/engagement-and-culture`

//--- Generic Vals ---
export const NA = "N/A"
export const SENTINEL = -1
export const EMPTY = ""
export const NOT_STARTED = "Not Started"
export const YES = "yes"
export const NO = "no"
export const COLLEGE_READY_COLOR_THRESHOLD = 70 // 70%
export const NO_COMPLETED_DATE = "No completed date"
export const NOT_SPECIFIED = "Not specified"

//--- Category Names ---
export const CATEGORY_ENGLISH = "English"
export const CATEGORY_MATH    = "Math"
export const CATEGORY_READING = "Reading"
export const CATEGORY_SCIENCE = "Science"
export const CATEGORY_WRITING = "Writing"

// LowerCase status keys (mostly partial keys)
export const STATUS_LC_KEY_NOT_STARTED = NOT_STARTED.toLowerCase()
export const STATUS_LC_KEY_BASELINE    = "baseline"
export const STATUS_LC_KEY_VIDEO       = "video"
export const STATUS_LC_KEY_ON_VIDEO    = "on video"
export const STATUS_LC_KEY_QUESION     = "question"
export const STATUS_LC_KEY_COMPLETED   = "completed"

//--- Generic Error messages ---
export const ERR_CANNOT_GET_DATA = "Cannot get data! Please try again."
export const ERR_CANNOT_GET_DATA_FROM_SERVER = "Server error: Cannot get data from the server"
export const ERR_RETEACH_TITLE = "Oops!"
export const ERR_RETEACH_NO_USER_INFO = "No User Info Found!"
export const ERR_RETEACH_NO_QUESTIONS_SELECTED = "It seems like there are no questions selected for reteaching."

//--- App timeout values ---
export const TIMEOUT_IN_SECONDS = 900   // 15 mins
export const TIMEOUT_LIVE_MINUTES = 60  // 60 minutes
export const TIMEOUT_IDLE_MINUTES = 120 // 120 minutes


//--- ACT & SAT Scores ---
export const ACT_CATEGORY_MIN_SCORE_VALUE = 1   // Categoris: English, Math, Reading & Science
export const SAT_CATEGORY_MIN_SCORE_VALUE = 200 // Categoris: EBWR, Math (Calc & No Calc)


//--- Study Buddies ---
export const SB_TOAST_MESSAGE_DURATION_MS = 10_000
export const SB_STATUS_ACCEPTED = "accepted"
export const SB_STATUS_REQUESTING = "requesting"
export const SB_STATUS_PENDING = "pending"
export const SB_CURRENT_STUDY_BUDDIES = "Current Study Buddies"
export const SB_NO_CURRENT_STUDY_BUDDIES = "Invite friends to connect and support each other’s studying."
export const SB_CONNECTION_REQUESTS = "Connection Requests"
export const SB_NO_CONNECTION_REQUESTS = "No requests to connect from others."
export const SB_PENDING_INVITES = "Pending Invites"
export const SB_NO_PENDING_INVITES = "No pending requests that you’ve sent."
export const SB_INVALID_EMAIL_ADDRESS = "Invalid email address!"
export const SB_WAS_SENT_AN_INVITE = "was sent an invite!"
export const SB_FRIENDS_WHO_CAN_HELP_WITH_QUESTION = "Friends who can help you solve this question."

