import './LessonStepByStepModal.css';

import * as React from 'react';
import Modal from '../../../../components/Modal/Modal';
import Button from '../../../../components/Button/Button';

interface Props {
    onClose: () => void;
    show: boolean;
}

const LessonStepByStepModal: React.SFC<Props> = (props) => {
    let buttonColor = 'blue';
    let textColor = 'white';
    let widthBtn = 'tc-modal-btn';

    return (
      <Modal className="heading-banner-tc modal" show={props.show} onClose={props.onClose}>
      <div className="row text-center">
          <div className="col-lg-12">
            <h2 className="cushion-element-tc">Lesson Step-by-Step Summary</h2>
          </div>
          <button type="button" className="modal-close" title="Close Modal" aria-label="Close" onClick={props.onClose}>
            <img
                className="modal-icon"
                src={`/assets/images/icons/v2/ico-modal-close-white.svg`}
                width="24"
                height="24"
            />
          </button>
          <div className="body-tc container-fluid coverBottom-tc">
          <div className="ordered-list-container">
              <ol className="black-text-tc">
                  <li>Complete <strong>baseline questions</strong> (do your best or just skip if you have no clue).</li>
                  <li>Print the <strong>lesson handout</strong> to take notes as you follow along.</li>
                  <li>Watch the <strong>video lesson</strong>
                      <ul>
                          <li>Feel free to adjust the speed of the lesson to adapt to your desired learning pace</li>
                      </ul>
                  </li>
                  <li>Answer the <strong>multiple choice questions</strong>
                      <ul>
                          <li>Review feedback on correct and incorrect answers.</li>
                          <li>Watch a TEACH ME video for an explanation.</li>
                          <li>Write yourself a hint (i.e., put what you learn into YOUR own words). That hint
              is saved and you can read what you wrote to yourself when you review your
              missed questions in your personal Mistake Bank.</li>
                      </ul>
                  </li>
                  <li>Review your <strong>summary page</strong> to get a sense of how your performance changed
              from baseline and what you need to review.</li>
                  <li>Redo your missed questions in your <strong>Mistake Bank</strong> and be sure to read the hint
              you wrote to yourself. When you read what you put into your voice, your memory
              will consolidate much better on the topic!</li>
                  <li>Generate a <strong>personalized study guide</strong> based on the hints you wrote to yourself.</li>
                  <li>Repeat and keep learning 😊</li>
              </ol>
              </div>
              <Button
                  text="I AM READY TO LEARN!"
                  textColor={textColor as any}
                  bgColor={buttonColor as any}
                  className={widthBtn as any}
                  onClick={props.onClose}
              />
          </div>
      </div>
      </Modal>
    );
};

LessonStepByStepModal.displayName = 'LessonStepByStepModal';

export default LessonStepByStepModal;
