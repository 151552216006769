import * as React from 'react';
import Modal from '../../components/Modal/Modal';
import BrightcoveVideo from '../../components/BrightcoveVideo/BrightcoveVideo';
import './PracticeTestInfoCard.css';
import Loading from '../../components/Loading/Loading';

interface Props {
    testType: string;
}

interface State {
    showVideoInModal: boolean
    videoId: string
    showLoading: boolean
}

// tslint:disable:max-line-length
export class PracticeTestInfoCard extends React.Component<Props, State> {
    videoRef = React.createRef<BrightcoveVideo>()
    
    state = {
        showVideoInModal: false,
        videoId: "",
        showLoading: true
    }

    showVideo = (videoId) => {
        this.setState({showVideoInModal: true, videoId})

        // OT: Loading the video causes flashing, so I first cover it...
        // and after a short time I reveal the video in order to prevent flashing!
        setTimeout(() => {
            this.setState({showLoading: false})
        }, 500)
    }

    handleOnModalClose = () => {
        this.setState({showVideoInModal: false, showLoading: true})
        this.videoRef.current?.pause()
    }

    render() {
        let {testType} = this.props
        let testName = 'ACT';
        let testUrl = 'http://www.act.org/content/act/en/products-and-services/the-act/test-preparation/the-official-guide.html';
        let selectedVideoId = this.state.videoId
        let desiredWidth = "80%"
        let bigPlayerStyle = {
            width: '100%',
            maxWidth: '100%',
            height: 'auto'
        }

        if (testType === 'sat') {
            testName = 'SAT';
            testUrl = 'http://www.act.org/content/act/en/products-and-services/the-act/test-preparation/the-official-guide.html';
        }

        return (
            <div className="mistake-bank-info-card">
            <div className="mistake-bank-info-card__mix-em-up d-flex">
                <img src={`/assets/images/icons/sections/practice-tests-gradient-v2_white.svg`} />
            </div>
            {(testType === 'sat') &&
                <div>
                    <div>
                        <h6 className="info-card-title">SAT Practice Tests</h6>
                        <p>
                            All SAT test explanations are provided by CollegeBoard.
                        </p>
                        <p>
                            <strong>Here are the steps when you're ready to take a timed practice {testName} test:</strong>
                        </p>
                        <ol>
                            <li>Start with a Diagnostic, so you can get a sense of your baseline strengths and weaknesses and identify how your ACT and SAT scores compare.</li>
                            <li>Find a quiet space to emulate real testing conditions.</li>
                            <li>Use our platform to score your test, see what was tested on every question, read step-by-step explanations, 
                                and view the analysis with the breakdown of your accuracy by lesson.</li>
                        </ol>
                    </div>
                </div>
            } 
            {(testType === 'act') &&
                <div>
                    <h6 className="info-card-title">ACT Practice Tests</h6>
                    <p>
                        We are excited to <strong>partner with ACT </strong> to receive special permission to film step-by-step explanation videos to provide you with personalized review of real ACT tests.
                    </p>                                      
    
                    <p>
                        <strong>Here are the steps when you're ready to take a timed practice {testName} test:</strong>
                    </p>
                    <ol>
                        <li>Start with a Diagnostic, so you can get a sense of your baseline strengths and weaknesses and identify how your ACT and SAT scores compare.</li>
                        <li>Find a quiet space to emulate real testing conditions.</li>
                        <li>Use our platform to score your test, see what was tested on every question, read step-by-step explanations,
                            and view the analysis with the breakdown of your accuracy by lesson.</li>
                    </ol>
              </div>
            }
            {testType === 'ia' &&
                <div>
                    <h6 className="info-card-title">Interim Assessments Overview</h6>
                    <p>When you take practice exams at school, you can enter your answers from your scantron. Then you can sort by accuracy, lesson, or topic to review your mistakes, track your progress, and watch step-by-step explanation videos for every question. Be sure to use these practice tests as a guide for what lessons and topics to continue reviewing to make sure you're ready for test day.</p>
                </div>
            }
            <Modal 
                show={this.state.showVideoInModal}
                width={desiredWidth}
                showXTopRight={true}
                onClose={() => this.handleOnModalClose()}>  
                    <div style={{position:"relative"}}>                 
                        <BrightcoveVideo 
                            ref = {this.videoRef}                          
                            id={`video-in-modal-${selectedVideoId}`}
                            videoId={selectedVideoId}
                            style={bigPlayerStyle}                        
                        />
                        {this.state.showLoading &&
                            <div style={{position:"absolute", 
                                height: "100%", 
                                width: "100%",
                                top: 0,
                                zIndex: 1, 
                                backgroundColor: "white"}}>
                                <div style={{position: "absolute", top: "42%", left: "50%"}}>
                                    <div style={{position: "relative", left: "-50%", top: "-50%"}}>
                                        <Loading />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
            </Modal>
          </div>
        )
    }
}

export default PracticeTestInfoCard;
