import * as React from 'react';
import ExplanationButton from './ExplanationButton/ExplanationButton';
import ExplanationButtonArrow from './ExplanationButton/ExplanationButtonArrow';
import { ScantronBubble } from './ScantronBubble';
import PriorityStar from './PriorityStar/PriorityStar';
import AddHintButton from './AddHintButton';
import BookmarkIcon from './BookmarkIcon/BookmarkIcon';

export interface Props {
    questionNumber: number;
    disabled: boolean;
    lessonName: string;
    topicName: string;
    existingAnswer: string;
    correctAnswer: string;
    mcLetters: Array<string>;
    onAnswerSelect: (questionNumber: number, letter: string) => void;
    onExplanationButtonClicked: () => void;
    onPriorityUpdated: () => void;
    onBookmarkUpdated: () => void;
    gridIn?: string;
    isTeacher: boolean;
    isPriority: boolean;
    isBookmarked?: boolean;
    isBookmarkDisabled?: boolean;
    gridInRequired: boolean;
    diagnosticType: string;
    onAddHintRequested: (questionNumber: number) => void;
}

const ScoreSheetRow: React.SFC<Props> = ({ 
    questionNumber, 
    disabled, 
    lessonName, 
    topicName,
    existingAnswer,
    correctAnswer,
    mcLetters,
    onAnswerSelect,
    onExplanationButtonClicked,
    onPriorityUpdated,
    onBookmarkUpdated,
    isTeacher,
    gridInRequired,
    gridIn, // NAMING PLZ
    isPriority,
    isBookmarked,
    isBookmarkDisabled,
    onAddHintRequested,
    diagnosticType
}) => {
    return (
    <tr>
        <td className="question-number">{questionNumber}</td>
        <td className="bookmarkQuestion">
          <BookmarkIcon isBookmarked={isBookmarked} disabled={isBookmarkDisabled} onToggle={() => onBookmarkUpdated()}/>
        </td>
        <td className={`accuracy-column ${mcLetters.length > 4 ? 'accuracy-column--lg' : ''}`}>
          {mcLetters.map((letter: string) =>
                <ScantronBubble
                  key={`answer-bubble-${questionNumber}-${letter}`}
                  onClickHandler={() => onAnswerSelect(questionNumber, letter)}
                  letter={letter}
                  userAnswer={existingAnswer}
                  correctAnswer={correctAnswer}
                  disabled={disabled}
                />
          )}
        </td>
        {gridInRequired && <td className="grid-in-cell">{gridIn}</td>}
        <td className="lesson-name">{lessonName}</td>
        <td className="topic-name">{topicName}</td>
        <td className="explanation-cell text-center table-icon-link">
            {diagnosticType != 'sat' &&
              <ExplanationButton onClick={onExplanationButtonClicked} />
            }
            {diagnosticType == 'sat' &&
              <ExplanationButtonArrow onClick={onExplanationButtonClicked} />
            }
        </td>
        {!isTeacher &&
        <td className="text-center table-icon-link">
            <AddHintButton 
              onClicked={(qNumber) => {
                onAddHintRequested(qNumber);
                }
              }

              questionNumber={questionNumber}
            />
        </td>
        }
        {!isTeacher &&
        <td className="text-center priority-star-cell table-icon-link">
            <PriorityStar 
                isPriority={isPriority}
                onToggle={() => onPriorityUpdated()}
            />
        </td>
        }
    </tr>
    );
};

ScoreSheetRow.defaultProps = { };
ScoreSheetRow.displayName = 'AnalysisButton';

export default ScoreSheetRow;
