import './Card.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import { Redirect } from 'react-router-dom';

interface Props {
    title: string;
    titleColor: 'white' | 'blue';
    classTitle?: string;
    style?: React.CSSProperties;
    link?: string;
    onLinkClick?: () => void;
}

interface State {
    cardClicked: boolean;
}

export default class Card extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {cardClicked: false};
    }

    public render() {

        return (
            <div>
                <div className={this.props.classTitle} style={this.props.style}>
                    {this.props.title && !this.props.link &&
                        <div className={`title-text-${this.props.titleColor}`}>
                                {this.props.title}
                                {this.props.link && <div className="arrow"></div>}
                        </div>}
                    {this.props.title && this.props.link &&
                    <div>
                        <button className={`btn-div title-text-${this.props.titleColor}`} onClick={this.handleCardClick}>
                            {this.props.title}
                            {this.props.link && <div className="arrow"></div>}
                        </button>
                    </div>}
                    {this.props.link && <div className="card-divider"></div>}
                    {this.props.children}
                </div>
                {this.props.link && this.state.cardClicked && <Redirect to={this.props.link} />}
            </div>
        );
    }

    
    @boundMethod
    private handleCardClick() {
        if(this.props.onLinkClick) {
            this.props.onLinkClick();
        } else {
            this.setState({cardClicked: true});
        }
    }
}
