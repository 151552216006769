import './SignUp.css';

import * as React from 'react';
import * as Script from 'react-load-script';
import { boundMethod } from 'autobind-decorator';

import {
    StripePayment,
    registerUserWithPaymentCk
} from '../../services/account/account-rest-interface';
import { getStripeClientKey } from '../../services/utils/winward-stripe';

import { Bundle } from '../../services/bundles/bundle-types';
import PhoneLink from './PhoneLink';
import BlueGeometricBackground from '../../components/BlueGeometricBackground/BlueGeometricBackground';
import Button from '../../components/Button/Button';
import PaymentProcessingModal from '../../components/PaymentProcessingModal';
import { RouteComponentProps } from 'react-router-dom';
import SignUpForm from './SignUpForm';
import BundleControls from './components/BundleControls/BundleControls';
import { SignUpFields } from './SignUpForm';
import SignUpError from './SignUpError';
import SignUpHeader from './SignUpHeader';
import RegistrationContainer from './RegistrationContainer';
import { getBundles } from '../../services/bundles/bundle-service';
import ButtonLoading from '../../components/ButtonLoading/ButtonLoading';

interface Props {
  logo?: string;
  charityCode?: string;
  bundle?: boolean;
  gameChangerOnly?: boolean;
  satGameChanger?: boolean;
  gameChangerPersonalCoach?: boolean;
  gameChangerStudyPlan?: boolean;
  gameChangerCollegeKnowledge?: boolean;
}

interface RouteProps extends RouteComponentProps<any> {}

interface State {
    isLoading: boolean;
    showProgressModal: boolean;
    shouldValidate: boolean;
    validationMessage: string;
    serverMessage: string;
    newAccountInfo?: SignUpFields;
    bundleArray: Bundle[];
    bundleId: string;
    formIsValid: boolean;
    preSelectedBundleId: string;
    newPrice: number;
    includeCollegeKnowledge: boolean;
    selectedBundle: Bundle;
}


export default class SignUp extends React.PureComponent<Props & RouteProps, State> {
    constructor(props: Props & RouteProps) {
        super(props);

        let preSelected = this.props.match.params.id || '';
        if (props.bundle) {
            preSelected = preSelected;
        }
        if (props.gameChangerOnly || props.satGameChanger ||
            props.gameChangerPersonalCoach || 
            props.gameChangerStudyPlan || 
            props.gameChangerCollegeKnowledge) {
            preSelected = 'gameChanger';
        }
        this.state = {
            isLoading: false,
            showProgressModal: false,
            bundleArray: [],
            bundleId: preSelected,
            shouldValidate: false,
            validationMessage: '',
            serverMessage: '',
            formIsValid: false,
            preSelectedBundleId: preSelected,
            newPrice: 0,
            includeCollegeKnowledge: false,
            selectedBundle: {} as Bundle
        };
    }

    public UNSAFE_componentWillMount() {
        const self = this;
        getBundles().then(res => {
            let bundleInfo;
            if (self.props.bundle) {
                bundleInfo = res.find((element) => {
                    return element.bundleId === self.state.preSelectedBundleId;
                });
            }
            if (self.props.gameChangerOnly || self.props.satGameChanger
                || self.props.gameChangerPersonalCoach 
                || self.props.gameChangerStudyPlan 
                || self.props.gameChangerCollegeKnowledge) {
                bundleInfo = res.find((element) => {
                    return element.bundleId === 'gameChanger';
                });
            }
            self.setState({
                bundleArray: res.filter(val => val.bundleId !== 'freeTrial'),
                selectedBundle: bundleInfo
            });
        }).catch(err => {
            console.log('Problem retrieving bundles:', err);
        });
    }

    public render() {
        const { isLoading, selectedBundle } = this.state;
        const { charityCode, bundle, gameChangerOnly, satGameChanger,
             gameChangerPersonalCoach, 
             gameChangerStudyPlan, 
             gameChangerCollegeKnowledge } = this.props;

        return (
            <BlueGeometricBackground className="sign-up-container">
                {/* Actually just the wait for account setup modal */}
                <PaymentProcessingModal
                    showModal={this.state.showProgressModal}
                />
                {charityCode === 'padres-pedal' &&
                    <div className="charity-banner">
                        <div className="charity-branding d-flex justify-content-center">
                            <div className="charity-first-logo d-none d-sm-flex justify-content-center align-items-center">
                                <img
                                    className="img-fluid charity-image"
                                    src={'/assets/images/PadresPedal.png'}
                                />
                            </div>
                            <div className="charity-text d-flex justify-content-center flex-column">
                                <p className="bold">20% Give Back to Padres Pedal the Cause</p>
                                <p>Help us support life-saving cancer research and raise awareness for
                                    the unique challenges faced by teens who battle cancer.</p>
                                <p>In the month of November, Winward Academy is donating 20% of sales
                                    to support survivors, families, children, doctors, and researchers.</p>
                            </div>
                            <div className="charity-second-logo d-flex justify-content-center align-items-center">
                                {/* Placeholder for potential second image */}
                            </div>
                        </div>
                    </div>
                }

                    <div className="order-help-banner">
                        <div className="order-help-branding justify-content-between align-items-center d-none d-sm-flex">
                            <div className="charity-first-logo d-none d-sm-flex justify-content-center align-items-center">
                                <a href="https://www.winwardacademy.com" target="_blank">
                                    <img
                                        className="img-fluid order-help-image full-size"
                                        src={'/assets/images/winward-academy-logo-color.svg'}
                                    />
                                </a>
                            </div>
                            <div className="justify-content-center d-none d-sm-flex align-items-center flex-column order-help-text">
                                <p className="d-flex no-bottom-margin">Need help signing up?</p>
                                <p className="d-flex no-bottom-margin">

                                    <a href="mailto:help@winwardacademy.com?subject=Help Signing Up">Email us</a>&nbsp;or call&nbsp;
                                    <PhoneLink />
                                </p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center flex-column mobile-order-help-text order-help-text d-sm-none">
                            <p className="d-flex no-bottom-margin">Need help signing up?</p>
                            <p className="d-flex no-bottom-margin"><a href="mailto:help@winwardacademy.com?subject=Help Signing Up">Email us</a>&nbsp;or call&nbsp;
                                <PhoneLink />
                            </p>
                        </div>
                    </div>

                {/* Header text, errgh login__header needs refactoring */}
                <RegistrationContainer>
                    {gameChangerOnly &&
                        <div className="row sign-up-header">
                            <div className="col-12">
                                <h1 className="register-act-prep-title">Register for Winward Academy ACT Prep</h1>
                            </div>
                        </div>
                    }
                    {satGameChanger &&
                        <div className="row sign-up-header">
                            <div className="col-12">
                                <h1 className="register-act-prep-title">Register for Winward Academy SAT Prep</h1>
                            </div>
                        </div>
                    }
                    {(bundle || gameChangerPersonalCoach ||  gameChangerStudyPlan || gameChangerCollegeKnowledge) &&
                        <div className="row sign-up-header">
                            <div className="col-12">
                                <h1 className="register-act-prep-title">Register for Winward Academy</h1>
                            </div>
                        </div>
                    }
                    {!(bundle || (gameChangerOnly || satGameChanger || gameChangerPersonalCoach || gameChangerStudyPlan || gameChangerCollegeKnowledge)) &&
                        <SignUpHeader
                            text="Sign Up"
                        />
                    }

                    {!(bundle || (gameChangerOnly || satGameChanger || gameChangerPersonalCoach || gameChangerStudyPlan || gameChangerCollegeKnowledge)) &&
                        <div>
                            <h2>Step 1: Pick Bundle</h2>
                            <BundleControls
                                bundleTypes={this.state.bundleArray}
                                onBundleSelected={(id, includeCollegeKnowledge, newPrice) => {
                                    this.setState({
                                        bundleId: id,
                                        includeCollegeKnowledge: includeCollegeKnowledge,
                                        newPrice: includeCollegeKnowledge ? newPrice : 0
                                    });
                                }}
                                preSelectedBundleId={this.state.preSelectedBundleId}
                                darkPrint={false}
                            />
                        </div>
                    }

                    {!(bundle || (gameChangerOnly || satGameChanger || gameChangerPersonalCoach || gameChangerStudyPlan || gameChangerCollegeKnowledge)) &&
                        <h2>Step 2: Register Account</h2>
                    }
                    {(bundle || (gameChangerOnly || satGameChanger  || gameChangerPersonalCoach || gameChangerStudyPlan || gameChangerCollegeKnowledge)) &&
                        <h2 className="margin-bottom-20">Step 1: Enter Student Information</h2>
                    }
                    <SignUpForm
                        onChange={(newAccountInfo, formIsValid) => {
                            // some duplication here :(
                            if (formIsValid) {
                                this.setState({ validationMessage: '' });
                            }
                            this.setState({
                                newAccountInfo,
                                formIsValid
                            });
                        }}
                        shouldValidate={this.state.shouldValidate}
                        selectedBundle={selectedBundle}
                        gameChangerOnly={this.props.gameChangerOnly}
                        satGameChanger={this.props.satGameChanger}
                        gameChangerPersonalCoach={gameChangerPersonalCoach}
                        gameChangerStudyPlan={gameChangerStudyPlan}
                        gameChangerCollegeKnowledge={gameChangerCollegeKnowledge}
                    />

                    <SignUpError text={this.state.validationMessage}/>
                    <SignUpError text={this.state.serverMessage}/>

                    <div className="row">
                        <div className="col-12 text-center">
                            <Button
                                text={isLoading ? <ButtonLoading className="sign-up__loading" /> : 'SECURE CHECKOUT'}
                                className="sign-up__create-account"
                                onClick={this.handleCreateAccount}
                                bgColor="green"
                                disabled={isLoading}
                            />
                        </div>
                    </div>
                    <Script
                        url="https://checkout.stripe.com/checkout.js"
                        onCreate={() => {}}
                        onError={(e) => console.log('stripe problem', e)}
                        onLoad={(e) => console.log('stripe')}
                    />
                </RegistrationContainer>
            </BlueGeometricBackground>
        );
    }

    @boundMethod
    private toggleCreateAccountButton(enableBtn: boolean) {
        this.setState({ isLoading: enableBtn });
    }

    @boundMethod
    private submitAccountInfo(paymentInfo: StripePayment) {
        // if (emailsMatch && passwordsMatch) {

        this.setState({ validationMessage: '' });
        if (this.state.newAccountInfo === undefined) {
            throw new Error('NO account info defined');
        }

        const info = this.state.newAccountInfo;
        let accountInfo: any = {
            firstName: info.firstName,
            lastName: info.lastName,
            emailAddress: info.emailAddress,
            birthDate: info.dob,
            gender: info.gender, // Gender.Male,
            profileColor: info.profileColor,
            password: info.password,
            expirationDate: new Date(),
            bundleId: this.state.bundleId,
            includeCollegeKnowledge: this.state.includeCollegeKnowledge,
            charityCode: this.props.charityCode,
            includePersonalCoach: this.props.gameChangerPersonalCoach,
            includeStudyPlan: this.props.gameChangerStudyPlan,
            parentFirstName: info.parentFirstName,
            parentLastName: info.parentLastName,
            parentEmailAddress: info.parentEmailAddress,
        };

        if (this.props.gameChangerCollegeKnowledge) {
            accountInfo.includeCollegeKnowledge = true;
        }

        this.setState({ showProgressModal: true });

        registerUserWithPaymentCk(accountInfo, paymentInfo)
            .then(response => {
                this.setState({ showProgressModal: false });
                const email = encodeURIComponent(accountInfo.emailAddress);
                this.props.history.push(`/welcome?email=${email}`);
            }).catch(e => {
                console.log('Server err while registering user', { e });
                this.setState({
                    isLoading: false,
                    serverMessage: e.message,
                    showProgressModal: false,
                });
            });
    }

    private openStripeDialog() {
        if (!this.state.formIsValid) {
            this.setState({ isLoading: false });
            return;
        }

        const handleSubmit = this.submitAccountInfo;
        const showProgressModal = () => this.setState({ showProgressModal: true });
        const stripeClientKey = getStripeClientKey();
        const handler = StripeCheckout.configure({
          key: stripeClientKey,
          image: `/assets/images/winward-stripe-logo.png`,
          locale: 'auto',
          zipCode: true,
          billingAddress: true,
          token: function(token: any) {
              showProgressModal();
              handleSubmit({ token: token.id, email: token.email });
          }
        });

        const selectedBundle = this.state.bundleArray
            .find(b => b.bundleId === this.state.bundleId);

        if (selectedBundle) {
            const { newPrice } = this.state;
            let cost = newPrice > 0 ? newPrice : selectedBundle.price * 100;
            const toggleCreateAccount = this.toggleCreateAccountButton;
            const ckSuffix = this.state.includeCollegeKnowledge ? '+ College Knowledge' : '';
            let description = `${selectedBundle.bundleName}${ckSuffix}`;
            if (this.props.gameChangerOnly) {
                description = 'Game Changer for ACT';
            }
            if (this.props.satGameChanger) {
                description = 'Game Changer for SAT';
            }
            if (this.props.gameChangerPersonalCoach) {
                description = 'Game Changer + Personal Coach';
                cost = 249999;
            }
            if (this.props.gameChangerStudyPlan) {
                description = 'Game Changer + Study Plan';
                cost = 149999;
            }
            if (this.props.gameChangerCollegeKnowledge) {
                description = 'Game Changer + College Knowledge';
                cost = 74999;
            }
            handler.open({
                name: 'Winward Academy',
                description: description,
                amount: cost, // amount in cents
                closed: function() {
                    toggleCreateAccount(false);
                }
            });
        }
    }

    @boundMethod
    private handleCreateAccount() {
        this.setState({
            serverMessage: '',
            validationMessage: ''
        });
        const info = this.state.newAccountInfo;
        this.setState({ shouldValidate: true });
        if (this.state.bundleId.length === 0) {
            this.setState({ validationMessage: 'Please select a bundle'});
            return;
        }
        if (info) {
            const emailsMatch = info.emailAddress === info.confirmEmail;
            const passwordsMatch = info.password === info.confirmPassword;
            this.toggleCreateAccountButton(true);
            this.openStripeDialog();
      }

    }

}
