import React, { Component } from 'react'
import SuperintendentFilters, { ChartType, FilterSelections, SelectableItem, TimeFrame, prepareSelectionsInfo } from '../SuperintendentFilters'
import { ITonPDetailsReport, getAllTonpDetails } from '../../../stores/superintendent-store'
import { getHumanTime } from '../../../services/utils/date-util'
import { TonPProgressChartView } from './TonPProgressChartView'
import { TonPSchoolsView } from './TonPSchoolsView'
import "./index.css"

interface Props {
  filterSelections: FilterSelections
  onSchoolsChanged: (schoolNames: SelectableItem[]) => void
  onGraduationYearsChanged: (graduationYears: SelectableItem[]) => void
  onTimeFrameChanged: (timeFrame: TimeFrame) => void
  onChartTypeChanged: (chartTpe: ChartType) => void
  tonpDetailsReports: ITonPDetailsReport[]
}

interface State { }

export default class TonPDetailsView extends Component<Props, State> {
  render() {
    let {tonpDetailsReports, filterSelections} = this.props
    let {onSchoolsChanged, onGraduationYearsChanged, onTimeFrameChanged, onChartTypeChanged} = this.props
    let {schools, graduationYears, timeFrame, chartType} = filterSelections
    let selectedSchoolNames = schools.filter(s => s.selected).map(s => s.name)  
    let selectedGraduationYears = graduationYears.filter(gy => gy.selected).map(gy => gy.name)
    let details = getAllTonpDetails(tonpDetailsReports, selectedGraduationYears, selectedSchoolNames, timeFrame)
    let {summary, days, schools: schoolDetails} = details
    let summaryHeadline = prepareSelectionsInfo(filterSelections).selectionInfoForTonP
    let dataCollectionInfo = `* Please note that time data for Mistake Bank, Practice Tests, and Stay Sharp are valid from 10/29/2023 and beyond.`

    return (
      <div>
        <SuperintendentFilters
          filterSelections={filterSelections}
          showTimeFrameSelection={true}
          onSchoolsChanged={onSchoolsChanged}
          onGraduationYearsChanged={onGraduationYearsChanged}
          onTimeFrameChanged={onTimeFrameChanged}
          onChartTypeChanged={onChartTypeChanged}
          enableTestScoreKindSelection={false}
          enableChartTypeSelection={true}
        />
        <TonPProgressChartView days={days} chartType={chartType} timeFrame={timeFrame} subHeadline={dataCollectionInfo}/>
        <TonPOverallSummaryView summary={summary} summaryHeadline={summaryHeadline} subHeadline={dataCollectionInfo}/>
        <TonPSchoolsView schools={schoolDetails} summaryHeadline={summaryHeadline} subHeadline={dataCollectionInfo}/>
      </div>
    )
  }
}

interface TonPOverallSummaryProps {
  summaryHeadline: string
  subHeadline: string
  summary: {lessons: number, tests: number, mistakebanks: number, staysharps: number}
}

const TonPOverallSummaryView = (props: TonPOverallSummaryProps) => {
  let {summaryHeadline, subHeadline, summary} = props
  let {lessons, tests, mistakebanks, staysharps} = summary
  let total = lessons + tests + mistakebanks + staysharps
  let lessonsHT = getHumanTime(lessons, false, true).text
  let testsHT = getHumanTime(tests, false, true).text
  let mistakebanksHT = getHumanTime(mistakebanks, false, true).text
  let staysharpsHT = getHumanTime(staysharps, false, true).text
  let totalHT = getHumanTime(total, false, true).text

  return (
    <div className="si-tonp-details-overall-time-summary-container">
      <div>
        <h3 className="h3">Overall Time Summary</h3>
        <hr className="half mb-2"/>
      </div>
      
      <div className="summary-headline mb-1" dangerouslySetInnerHTML={{ __html: summaryHeadline }} />
      <div className="summary-headline--subtext"><i>{subHeadline}</i></div>
      <div className="si-tonp-details-overall-time-summary">
        <div className="row text-center">
          <div className="col-lg-4 mb-4 pb-2 pb-lg-0 mb-lg-0 px-0">
            <div className="row si-tonp-data-row">
              <div className="col-12 si-tonp-data-col">
                <div className="si-tonp-dataBlock si-tonp-dataBlock--total">
                  <div className="col-inner h-100">
                    <p className="c-blue stat--val" dangerouslySetInnerHTML={{__html: totalHT}}/>
                    <p className="stat--key">Total Time Spent</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 px-0">
            <div className="row si-tonp-data-row">
              <div className="col-sm-6 si-tonp-data-col top-col">
                <div className="si-tonp-dataBlock si-tonp-dataBlock--lesson">
                  <div className="col-inner">
                    <p className="stat--val" dangerouslySetInnerHTML={{__html: lessonsHT}}/>
                    <p className="stat--key">Spent on Lessons</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 si-tonp-data-col top-col">
                <div className="si-tonp-dataBlock si-tonp-dataBlock--mistake">
                  <div className="col-inner">
                    <p className="stat--val" dangerouslySetInnerHTML={{__html: mistakebanksHT}}/>
                    <p className="stat--key">Spent on Mistake Bank</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 mb-4 pb-2 pb-lg-0 mb-lg-0 si-tonp-data-col">
                <div className="si-tonp-dataBlock si-tonp-dataBlock--test">
                  <div className="col-inner">
                    <p className="stat--val" dangerouslySetInnerHTML={{__html: testsHT}}/>
                    <p className="stat--key">Spent on Practice Tests</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 mb-4 pb-2 pb-lg-0 mb-lg-0 si-tonp-data-col">
                <div className="si-tonp-dataBlock si-tonp-dataBlock--sharp">
                  <div className="col-inner">
                    <p className="stat--val" dangerouslySetInnerHTML={{__html: staysharpsHT}}/>
                    <p className="stat--key">Spent on Stay Sharp</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>   
  )
} 

