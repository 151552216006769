import './HeaderCell.css';

import * as React from 'react';

interface Props {
    title: string;
    sortable?: boolean;
    sorted?: boolean;
    width: any;
    customToolTip?:string;
    onClick: () => void;
}

// TODO make this use TableCell to reduce code duplication
const HeaderCell: React.SFC<Props> = (props) => {
    const sortedClass = props.sorted ? 'sorted' : '';
    const sortableClass = props.sortable ? 'sortable' : '';
    let style: React.CSSProperties = { flexBasis: props.width };
    if(!props.width) {
        style = { flex: '1 1 0' };
    }

    if(props.customToolTip) {
        return (
            <div className={`header-cell ${sortedClass} ${sortableClass}`} style={style} onClick={props.onClick}>
                <div className="th-tooltip">
                    <div>
                        <div dangerouslySetInnerHTML={{ __html: props.title + (props.sortable ? '<span class="header-cell__sort"></span>' : '') }} />
                    </div>
                    <span className="th-tooltip-text">{props.customToolTip}</span>
                </div>
            </div>
        )
    } else {
        return (
            <div className={`header-cell ${sortedClass} ${sortableClass}`} style={style} onClick={props.onClick}>
                <span className="p-rel d-inline-block">
                    <span dangerouslySetInnerHTML={{__html: props.title}}></span>
                    {props.sortable && <span className="header-cell__sort"/>}
                </span>
            </div>
        )
    }

};

HeaderCell.displayName = 'HeaderCell';

export default HeaderCell;
