import './IAHighestScore.css';

import * as React from 'react';
import RadialProgress from '../../../../components/RadialProgress/RadialProgress';

interface Props {
    score: number;
    subject: string;
    test: string;
    startColor: string;
    endColor: string;
    maxPossibleScore: number;
}

const IAHighestScore: React.SFC<Props> = props => {
    const { score, maxPossibleScore } = props;

    if (score === undefined) {
        return null;
    }
    return (
        <div className="statistics-container highest-score-card">
            <h4 className="section-title">{props.subject}</h4>
            <div style={{width: '150px', margin: '0 auto'}}>
                <RadialProgress
                    size={150}
                    radius={45}
                    startColor={props.startColor}
                    endColor={props.endColor}
                    pegOffset={30}
                    progress={score / maxPossibleScore * 100}
                    text={score + ''}
                    textStyle={{fontSize: '32px', color: 'black', fontWeight: 200}}
                    progressLineWidth={13}
                    backgroundLineWidth={13}
                />
                <h5 className="sub-title">Score</h5>
                <p className="sub-test">({props.test})</p>
            </div>
        </div>
    );
};

IAHighestScore.defaultProps = {};
IAHighestScore.displayName = 'IAHighestScore';

export default IAHighestScore;
