import React from "react"
import CollapsableCard, { CollapsableCardBody, CollapsableCardHeader } from "../../../components/CollapsableCard/CollapsableCard"
import { MiniCaret } from "../../../components/MiniCaret"
import { round } from "../../../services/utils/math-util"
import { getTestNamesInSpecialOrder } from "../../../services/utils/practice-test-util"
import { filterTests, TestsSummary, TestSummarySchool } from "../../../stores/superintendent-store"
import { FilterSelections, prepareSelectionsInfo, TestKind } from "../SuperintendentFilters"
import { GraduationYearsViewACTandIA, GraduationYearsViewSAT, StudentReportButton, TestViewProps, TestViewState } from "./shared"
import { CATEGORY_ENGLISH, CATEGORY_MATH, CATEGORY_READING, CATEGORY_SCIENCE, EMPTY } from "../../../constants"

export interface ViewByTestProps {
  summary: TestsSummary
  filterSelections: FilterSelections
  onShowStudentsTestsInsights: (schoolName: string, testName: string, graduationYear?: string) => void
  onShowStudentsTestsDetailsByCategory: (category: string, schoolName: string, testName: string, graduationYear?: string) => void
}

export const ViewByTest: React.FunctionComponent<ViewByTestProps> = (props) => {
  let {summary, filterSelections, onShowStudentsTestsInsights, onShowStudentsTestsDetailsByCategory} = props
  let {schools, graduationYears, testKind} = filterSelections
  let selectedSchoolNames = schools.filter(s => s.selected).map(s => s.name)
  let selectedGraduationYears = graduationYears.filter(gy => gy.selected).map(gy => gy.name)
  let filteredTests = filterTests(summary, selectedSchoolNames, selectedGraduationYears)
  let showACTSection = testKind === TestKind.ALL || testKind === TestKind.ACT
  let showIASection  = testKind === TestKind.ALL || testKind === TestKind.IA
  let showSATSection = testKind === TestKind.ALL || testKind === TestKind.SAT
  let subtitle = prepareSelectionsInfo(filterSelections).selectionInfoForReports
  
  return (
    <div className="si-practice-tests">  
      <div className="all-tests-sections">
        {showACTSection && <TestSummaryViewByTest schools={filteredTests} testKind={TestKind.ACT} title="ACT Summary" subtitle={subtitle} onShowStudentsTestsInsights={onShowStudentsTestsInsights} onShowStudentsTestsDetailsByCategory={onShowStudentsTestsDetailsByCategory}/>}
        {showSATSection && <TestSummaryViewByTest schools={filteredTests} testKind={TestKind.SAT} title="SAT Summary" subtitle={subtitle} onShowStudentsTestsInsights={onShowStudentsTestsInsights} onShowStudentsTestsDetailsByCategory={onShowStudentsTestsDetailsByCategory}/>}
        {showIASection  && <TestSummaryViewByTest schools={filteredTests} testKind={TestKind.IA}  title="IA Summary"  subtitle={subtitle} onShowStudentsTestsInsights={onShowStudentsTestsInsights} onShowStudentsTestsDetailsByCategory={onShowStudentsTestsDetailsByCategory}/>}
      </div>
    </div>
  )
}

//--- Helpers ---
interface IScoreBasic {
  nofStudents: number
  avg: number
}

interface IScore extends IScoreBasic {
  nofStudentsReady: number
  collegeReady: number
}

interface IDetailsBasic {
  nofStudents: number
  composite?: IScoreBasic
}
interface IDetailsForACTandIA extends IDetailsBasic {
  english?: IScore
  math?:    IScore
  reading?: IScore
  science?: IScore
}

interface IDetailsForSAT extends IDetailsBasic {
  math?: IScore
  ebrw?: IScore
}
interface IGraduationYearForACTandIA {
  year: string
  details: IDetailsForACTandIA
}

interface IGraduationYearForSAT {
  year: string
  details: IDetailsForSAT
}

interface ITestDetailForACTandIA {
  testDisplayName: string
  details?: IDetailsForACTandIA
  graduationYears?: IGraduationYearForACTandIA[]
}

interface ITestDetailForSAT {
  testDisplayName: string
  details?: IDetailsForSAT
  graduationYears?: IGraduationYearForSAT[]
}

interface ISchoolTestsInfo {
  name: string
  scores: {
    ACT?: {
      [testName: string]: ITestDetailForACTandIA
    },
    IA?: {
      [testName: string]: ITestDetailForACTandIA
    },
    SAT?: {
      [testName: string]: ITestDetailForSAT
    }
  }
}

interface IFlattenedTestInfo {
  schoolName: string
  testName: string
  testDisplayName: string
}

interface IFlattenedTestInfoForACTandIA extends IFlattenedTestInfo{  
  details: IDetailsForACTandIA
  graduationYears: IGraduationYearForACTandIA[]
}

interface IFlattenedTestInfoForSAT extends IFlattenedTestInfo{  
  details: IDetailsForSAT
  graduationYears: IGraduationYearForSAT[]
}

interface ISchoolsByTests {
  [testName: string]: ITest
}

interface ITest {
  testDisplayName: string
  schools: { [schoolName: string]: IFlattenedTestInfoForACTandIA | IFlattenedTestInfoForSAT}
}


function getTestNames(info: ISchoolTestsInfo[], testKind: "ACT" | "IA" | "SAT"): string[] {
  let allTestNames = info.flatMap(d => {
    let tests = d.scores[testKind]
    if (tests) {
      return Object.keys(tests)
    }
    return null
  })
  .filter(d => d !== null) as string[]
  
  let testNames = Array.from(new Set(allTestNames))
  
  testNames = getTestNamesInSpecialOrder(testNames, testKind)

  return testNames as string[] 
}

function getACTTests(info: ISchoolTestsInfo[], testName: string): IFlattenedTestInfoForACTandIA[] {
  let selected = info.map(d => {
      let tests = d.scores.ACT
      if (tests) {
        let schoolName = d.name
        let test = tests[testName]
        return { schoolName, test}
      }
      return null
    })
    .map(d => {
      if (d && !d.test) return null

      return {
        schoolName: d?.schoolName || "",
        testName,
        testDisplayName: d?.test.testDisplayName,
        details: d?.test.details,
        graduationYears: d?.test.graduationYears
      }
    })
    .filter(d => d !== null) as IFlattenedTestInfoForACTandIA[]

  return selected 
}

function getIATests(info: ISchoolTestsInfo[], testName: string): IFlattenedTestInfoForACTandIA[] {
  let selected = info.map(d => {
      let tests = d.scores.IA
      if (tests) {
        let schoolName = d.name
        let test = tests[testName]
        return { schoolName, test}
      }
      return null
    })
    .map(d => {
      if (d && !d.test) return null

      return {
        schoolName: d?.schoolName || "",
        testName,
        testDisplayName: d?.test.testDisplayName,
        details: d?.test.details,
        graduationYears: d?.test.graduationYears        
      }
    })
    .filter(d => d !== null) as IFlattenedTestInfoForACTandIA[]

  return selected 
}

function getSATTests(info: ISchoolTestsInfo[], testName: string): IFlattenedTestInfoForSAT[] {
  let selected = info.map(d => {
      let tests = d.scores.SAT
      if (tests) {
        let schoolName = d.name
        let test = tests[testName]
        return { schoolName, test}
      }
      return null
    })
    .map(d => {
      if (d && !d.test) return null

      return {
        schoolName: d?.schoolName || "",
        testName,
        testDisplayName: d?.test.testDisplayName,
        details: d?.test.details,
        graduationYears: d?.test.graduationYears
      }
    })
    .filter(d => d !== null) as IFlattenedTestInfoForSAT[]

  return selected 
}


function getAllACTTests(info: ISchoolTestsInfo[]): IFlattenedTestInfoForACTandIA[] {
  let testNames = getTestNames(info, "ACT")
  let allTests = testNames.flatMap(testName => getACTTests(info, testName))
  return allTests
}

function getAllIATests(info: ISchoolTestsInfo[]): IFlattenedTestInfoForACTandIA[] {
  let testNames = getTestNames(info, "IA")
  let allTests = testNames.flatMap(testName => getIATests(info, testName))
  return allTests
}

function getAllSATTests(info: ISchoolTestsInfo[]): IFlattenedTestInfoForSAT[] {
  let testNames = getTestNames(info, "SAT")
  let allTests = testNames.flatMap(testName => getSATTests(info, testName))
  return allTests
}

function getSchoolsInfoByTests(info: IFlattenedTestInfoForACTandIA[]): ISchoolsByTests {
  let schoolsByTests: ISchoolsByTests = {}
  
  info.forEach(d => {
    let testName = d.testName
    let schoolName = d.schoolName
    if (!schoolsByTests[testName]) {
      schoolsByTests[testName] = { testDisplayName: d.testDisplayName, schools: {} }
    }
    schoolsByTests[testName].schools[schoolName] = d
  })

  return schoolsByTests
}

class TestViewACTandIA extends React.Component<TestViewProps, TestViewState> {
  constructor(props) {
    super(props)
    this.state = {
      showDetails: false
    }
  }

  toggleDetails = () => {
    this.setState({showDetails: !this.state.showDetails})
  }

  render() {
    let {data, ind, testName, onShowStudentsTestsInsights, onShowStudentsTestsDetailsByCategory} = this.props
    let {showDetails} = this.state
    let {schoolName, details, graduationYears} = data 
    let nofStudents = details.nofStudents       
    // Averages
    let avgC = round(details.composite?.avg)
    let avgE = round(details.english?.avg)
    let avgM = round(details.math?.avg)
    let avgR = round(details.reading?.avg)
    let avgS = round(details.science?.avg)
    let infoAvgC = nofStudents > 0 ? avgC: EMPTY
    let infoAvgE = nofStudents > 0 ? avgE: EMPTY
    let infoAvgM = nofStudents > 0 ? avgM: EMPTY
    let infoAvgR = nofStudents > 0 ? avgR: EMPTY
    let infoAvgS = nofStudents > 0 ? avgS: EMPTY
    let nC = details.composite?.nofStudents
    let nE = details.english?.nofStudents
    let nM = details.math?.nofStudents
    let nR = details.reading?.nofStudents
    let nS = details.science?.nofStudents
    let infoNC = nofStudents > 0 ? `n=${nC}`: EMPTY
    let infoNE = nofStudents > 0 ? `n=${nE}`: EMPTY
    let infoNM = nofStudents > 0 ? `n=${nM}`: EMPTY
    let infoNR = nofStudents > 0 ? `n=${nR}`: EMPTY
    let infoNS = nofStudents > 0 ? `n=${nS}`: EMPTY

    return (
      <>
        <tr className={`isDropdown test-view ${(nofStudents > 0) ? '' : 'disabled '} ${(ind % 2 == 0) ? 'odd' : 'even'} `}>
          <td className="accordion-cell">
            <MiniCaret up={!showDetails} onClick={this.toggleDetails}/>
            <span className="si-practicetest-view-by-test">{schoolName}</span>
            <br/>
            <StudentReportButton className="si-student-test-reports-btn" onClick={(e) => onShowStudentsTestsInsights(schoolName, testName)}/>
          </td>
          <td className="cell-composite">{infoAvgC} <br/>{infoNC}</td>
          <td>{infoAvgE} <br/>{infoNE} <br/><StudentReportButton text="English Report" onClick={(e) => onShowStudentsTestsDetailsByCategory(CATEGORY_ENGLISH, schoolName, testName)}/></td>
          <td>{infoAvgM} <br/>{infoNM} <br/><StudentReportButton text="Math Report" onClick={(e) => onShowStudentsTestsDetailsByCategory(CATEGORY_MATH, schoolName, testName)}/></td>
          <td>{infoAvgR} <br/>{infoNR} <br/><StudentReportButton text="Reading Report" onClick={(e) => onShowStudentsTestsDetailsByCategory(CATEGORY_READING, schoolName, testName)}/></td>
          <td>{infoAvgS} <br/>{infoNS} <br/><StudentReportButton text="Science Report" onClick={(e) => onShowStudentsTestsDetailsByCategory(CATEGORY_SCIENCE, schoolName, testName)}/></td>
        </tr>
        {showDetails ?
          <GraduationYearsViewACTandIA 
            ind={ind}
            schoolName={schoolName}
            testName={testName}
            graduationYears={graduationYears} 
            onShowStudentsTestsInsights={onShowStudentsTestsInsights}
            onShowStudentsTestsDetailsByCategory={onShowStudentsTestsDetailsByCategory}
            />
          :
          ""
        }
      </>
    )
  }
}

class TestViewSAT extends React.Component<TestViewProps, TestViewState> {
  constructor(props) {
    super(props)
    this.state = {
      showDetails: false
    }
  }

  toggleDetails = () => {
    this.setState({showDetails: !this.state.showDetails})
  }

  render() {
    let {data, ind, onShowStudentsTestsInsights, testName} = this.props
    let {showDetails} = this.state
    let {schoolName, details, graduationYears} = data 
    let nofStudents = details.nofStudents
    let nofStudentsM = details.math?.nofStudents
    let nofStudentsEBRW = details.ebrw?.nofStudents

    // Averages
    let avgM = round(details.math?.avg)
    let avgEBRW = round(details.ebrw?.avg)
    let score = round(avgM + avgEBRW)
    let infoScore = nofStudents > 0 ? <span>{score}<br/>n={nofStudents}</span>: EMPTY
    let infoEBRW = nofStudents > 0 ? <span>{avgEBRW}<br/>n={nofStudentsEBRW}</span>: EMPTY
    let infoM = nofStudents > 0 ? <span>{avgM}<br/>n={nofStudentsM}</span>: EMPTY

    return (
      <>
        <tr className={`isDropdown test-view ${(nofStudents > 0) ? '' : 'disabled '} ${(ind % 2 == 0) ? 'odd' : 'even'}`}>
          <td className="accordion-cell">
            <MiniCaret up={!showDetails} onClick={this.toggleDetails}/>
            <span className="si-practicetest-view-by-test">{schoolName}</span>
            <br/>
            <StudentReportButton className="si-student-test-reports-btn" onClick={(e) => onShowStudentsTestsInsights(schoolName, testName)}/>
          </td>
          <td className="cell-composite">{infoScore}</td>
          <td>{infoEBRW}</td>
          <td>{infoM}</td>
        </tr>

        {showDetails ?
          <GraduationYearsViewSAT 
            ind={ind}
            schoolName={schoolName}
            testName={testName}
            graduationYears={graduationYears}
            onShowStudentsTestsInsights={onShowStudentsTestsInsights}
          />
          :
          ""
        }
      </>
    )
  }
}

interface TestSummaryViewProps {
  schools: TestSummarySchool[]
  testKind: TestKind
  title: string
  subtitle: string
  onShowStudentsTestsInsights: (schoolName: string, testName: string, graduationYear?: string) => void
  onShowStudentsTestsDetailsByCategory: (category: string, schoolName: string, testName: string, graduationYear?: string) => void
}

const TestSummaryViewByTest = (props: TestSummaryViewProps) => {
  let {schools, testKind, title, subtitle, onShowStudentsTestsInsights, onShowStudentsTestsDetailsByCategory} = props
  let specificTests 
  let testNames
  let tests

  if (testKind === TestKind.ACT) {
    specificTests = getAllACTTests(schools)
    testNames = getTestNames(schools, "ACT")
    tests = getSchoolsInfoByTests(specificTests)
  }
  else if (testKind === TestKind.IA) {
    specificTests = getAllIATests(schools)
    testNames = getTestNames(schools, "IA")
    tests = getSchoolsInfoByTests(specificTests)
  }
  else {
    specificTests = getAllSATTests(schools)
    testNames = getTestNames(schools, "SAT")
    tests = getSchoolsInfoByTests(specificTests)
  }

  function cardBodyContent(test: ITest, testKind: TestKind, testName: string) {
    let schools = test.schools
    let schoolNames = Object.keys(schools)
    let isACTorIA = testKind === TestKind.ACT || testKind === TestKind.IA
    return (
      <>
        {tests ?
        <>
          <thead className={isACTorIA ? "act-header-theme" : "sat-header-theme"}>
            <tr>
            {isACTorIA ?
              <>
                <th className="label-header p-0"><span className="border-header first">School</span></th>
                <th className="label-header p-0"><span className="border-header">Composite</span></th>
                <th className="label-header p-0"><span className="border-header">English</span></th>
                <th className="label-header p-0"><span className="border-header">Math</span></th>
                <th className="label-header p-0"><span className="border-header">Reading</span></th>
                <th className="label-header p-0"><span className="border-header">Science</span></th>
              </>
              :
              <>
                <th className="label-header p-0"><span className="border-header first">School</span></th>
                <th className="label-header p-0"><span className="border-header">Score</span></th>
                <th className="label-header p-0"><span className="border-header">EBRW</span></th>
                <th className="label-header p-0"><span className="border-header">Math</span></th>
              </>
            }
            </tr>
          </thead>
          <tbody>
            {schoolNames.map((schoolName, index) => {
              let schoolData = schools[schoolName]
              let isACTorIA = testKind === TestKind.ACT || testKind === TestKind.IA

              return (
                isACTorIA ?
                  <TestViewACTandIA 
                    ind={index} 
                    schoolName={schoolName} 
                    testName={testName} 
                    data={schoolData}
                    onShowStudentsTestsInsights={onShowStudentsTestsInsights}
                    onShowStudentsTestsDetailsByCategory={onShowStudentsTestsDetailsByCategory}
                  />
                  :
                  <TestViewSAT 
                    ind={index} 
                    schoolName={schoolName} 
                    testName={testName} 
                    data={schoolData}
                    onShowStudentsTestsInsights={onShowStudentsTestsInsights}
                    onShowStudentsTestsDetailsByCategory={onShowStudentsTestsDetailsByCategory}
                  />
              )
            })}
          </tbody>
        </>
        :
        <></>
        }
      </>
    )
  }

  return (
    <div className="mb-4">
      <h2>{title}</h2>
      {Object.keys(tests).length > 0 ?
        <>
          {subtitle &&
            <p className="summary-headline" dangerouslySetInnerHTML={{ __html: subtitle }} />
          }
          <div className="tests-summary">
            {testNames.map((testName, ind) => {
              let test = tests[testName]

              return (
                <CollapsableCard>
                  <CollapsableCardHeader>
                    <div className="lesson-list-card-header no-icon">
                      <span>{test.testDisplayName}</span>
                    </div>
                  </CollapsableCardHeader>
                  <CollapsableCardBody>
                  <div className="fixedHeaderTable">
                    <table className="lesson-summary-table test-summary-table">
                      {cardBodyContent(test, testKind, testName)}
                    </table>
                  </div>
                  </CollapsableCardBody>
                </CollapsableCard>
              )
            })}
          </div>
        </>
        :
        <>
          <p>No data are available for this test category</p>
        </>
      }
    </div>
  )
}
