import React, {Component} from 'react' 
import { MiniCaret } from '../../../components/MiniCaret'
import { CATEGORY_READING, CATEGORY_SCIENCE, NA } from '../../../constants'
import { convertToACTandSAT } from '../../../services/utils/act-sat-util'
import { getHumanTime } from '../../../services/utils/date-util'
import { isCategoryReadingOrScience } from '../../../services/utils/lesson-util'
import { IGroupedLesson, ILessonStats, IStats, IStatsCollegeReady, IStatsTonP } from '../../../stores/superintendent-store'
import { TestKind } from '../SuperintendentFilters'

interface LessonDetailProps {
  idx: number
  category: string
  groupedLesson: IGroupedLesson
  testKind: TestKind
}

interface LessonDetailState {
  isDetailsShowing: boolean
}

export class LessonPerformance extends Component<LessonDetailProps, LessonDetailState> {
  constructor(props) {
    super(props)
    this.state = {
      isDetailsShowing: false
    }
  }

  private toggleDetails = () => {
    this.setState({isDetailsShowing: !this.state.isDetailsShowing})
  }

  render() {
    let {idx, category, groupedLesson, testKind} = this.props
    let indexClass = idx % 2 === 0 ? "odd" : "even"
    let {isDetailsShowing} = this.state
    let getPercentage = (stats: IStats) => {
      let {total, size} = stats
      return size > 0 ? Math.round(total/size): -1
    }
    let getPercentageInfo = (stats: IStats) => {
      let {total, size} = stats
      return size > 0 ? `${Math.round(total/size)}%`: NA
    }
    let getAccuracyInfo = (stats: IStats) => {
      let {total, size} = stats
      let avg = size > 0 ? Math.round(total/size): -1
      let avgInfo = `${Math.round(total/size)}%`
      let scores = convertToACTandSAT(category, avg, avg)
      let conversionScore = testKind === TestKind.ACT ? scores.preLessonConversionScoreACT: scores.preLessonConversionScoreSAT
      let prefix = testKind === TestKind.ACT ? "ACT": "SAT"
      return (
        <>
          <div className="info-line" dangerouslySetInnerHTML={{__html: avgInfo}} />
          <div className="info-line act-or-sat-score-info">{prefix} <span className="info-second-line" dangerouslySetInnerHTML={{__html: conversionScore}}/></div>
        </>
      )
    }
    let getPercentageInfoForCollegeReady = (stats: IStatsCollegeReady) => {
      let {size, avgACT, avgSAT} = stats
      let avg = testKind === TestKind.ACT ? avgACT: avgSAT
      return size > 0 ? `${Math.round(avg)}%`: NA
    }
    let getTimeOnQuestionInfo = (tonpStats: IStatsTonP) => {
      let {avg, total} = tonpStats.timeOnQuestions
      let avgTimeInfo = avg > 0 ? getHumanTime(Math.round(avg), true, true).html: NA
      let totalTimeInfo = total > 0 ? getHumanTime(total, false, true).html: NA
      return (
        <>
          { avg > 0 ?
            <>
              <div className="info-line act-or-sat-score-info time-on-question-info">Average <span dangerouslySetInnerHTML={{__html: avgTimeInfo}}/></div>
              <div className="info-line act-or-sat-score-info time-on-question-info">Total <span className="info-second-line" dangerouslySetInnerHTML={{__html: totalTimeInfo}}/></div>
            </>
            :
            NA
          }
        </>
      )
    }
    let getTotalStudyTimeInfo = (tonpStats: IStatsTonP) => {
      let totalStudyTime = tonpStats.timeOnTotalStudy
      let totalStudyTimeInfo = getHumanTime(totalStudyTime, true, true).html
      return <span className="info-line" dangerouslySetInnerHTML={{__html: totalStudyTimeInfo}} />
    }
    let determineNAs = (stats: ILessonStats) => {
      // For Reading & Science pre-lesson accuracy & growth are N/A
      let isCategoryRS = isCategoryReadingOrScience(category)
      let isAccuracyPreNA = stats.statsPreAccuracy.size === 0 || isCategoryRS 
      let isAccuracyPostNA = stats.statsPostAccuracy.size === 0
      let isGrowthNA = isAccuracyPostNA || isCategoryRS
      let isHintsOnIncorrectNA = isAccuracyPostNA
      let isCollegeReadyNA = isAccuracyPostNA
      return {
        isAccuracyPreNA,
        isAccuracyPostNA,
        isGrowthNA,
        isHintsOnIncorrectNA,
        isCollegeReadyNA
      }
    }
    let {lessonName, stats: lessonStats, schoolsStats} = groupedLesson
    let NAs = determineNAs(lessonStats)
    let nofSchools = schoolsStats.length
    let nofStudents = lessonStats.nofStudents
    let percentageAccuracyPre = getPercentage(lessonStats.statsPreAccuracy)
    let percentageAccuracyPost = getPercentage(lessonStats.statsPostAccuracy)
    let lessonGrowth = percentageAccuracyPost - percentageAccuracyPre
    let infoAccuracyPre = NAs.isAccuracyPreNA ? NA: getAccuracyInfo(lessonStats.statsPreAccuracy)
    let infoAccuracyPost = NAs.isAccuracyPostNA ? NA: getAccuracyInfo(lessonStats.statsPostAccuracy)
    let infoLessonGrowth = NAs.isGrowthNA ? NA:  percentageAccuracyPost !== -1 ? `${lessonGrowth}%`: NA
    let infoHintsOnIncorrect = NAs.isHintsOnIncorrectNA? NA: getPercentageInfo(lessonStats.statsHintsOnIncorrect)
    let infoCollegeReady = NAs.isCollegeReadyNA ? NA: getPercentageInfoForCollegeReady(lessonStats.statsCollegeReady)
    let infoTimeOnQuestion = getTimeOnQuestionInfo(lessonStats.statsTonP)
    let infoTimeTotalStudy = getTotalStudyTimeInfo(lessonStats.statsTonP)
    let arrowClass = NAs.isGrowthNA ? "": lessonGrowth > 0 ? "after--up-arrow": lessonGrowth < 0 ? "after--down-arrow": ""

    return ( 
      <>
        <tr className={`subject-performance-summary ${indexClass}`} onClick={this.toggleDetails}>
          <td className="school-cell">
            {nofSchools > 0 && <MiniCaret up={!isDetailsShowing}/>}
            <span className="lesson-name">{lessonName}</span>
          </td>
          <td>{nofStudents}</td>
          <td>{infoCollegeReady}</td>
          <td>{infoAccuracyPre}</td>
          <td>{infoAccuracyPost}</td>
          <td><span className={arrowClass}>{infoLessonGrowth}</span></td>
          <td>{infoHintsOnIncorrect}</td>
          <td className="ignore-bold">{infoTimeOnQuestion}</td>
          <td className="ignore-bold">{infoTimeTotalStudy}</td>
        </tr>

        {isDetailsShowing && schoolsStats.map(schoolStat => {
          let {schoolName, nofStudents} = schoolStat
          let NAs = determineNAs(schoolStat)
          let percentageAccuracyPre = getPercentage(schoolStat.statsPreAccuracy)
          let percentageAccuracyPost = getPercentage(schoolStat.statsPostAccuracy)
          let lessonGrowth = percentageAccuracyPost - percentageAccuracyPre
          let infoAccuracyPre = NAs.isAccuracyPreNA ? NA: getAccuracyInfo(schoolStat.statsPreAccuracy)
          let infoAccuracyPost = NAs.isAccuracyPostNA ? NA: getAccuracyInfo(schoolStat.statsPostAccuracy)
          let infoLessonGrowth = NAs.isGrowthNA ? NA:  percentageAccuracyPost !== -1 ? `${lessonGrowth}%`: NA
          let infoHintsOnIncorrect = NAs.isHintsOnIncorrectNA? NA: getPercentageInfo(schoolStat.statsHintsOnIncorrect)
          let infoCollegeReady = NAs.isCollegeReadyNA ? NA: getPercentageInfoForCollegeReady(schoolStat.statsCollegeReady)
          let infoTimeOnQuestion = getTimeOnQuestionInfo(schoolStat.statsTonP)
          let infoTimeTotalStudy = getTotalStudyTimeInfo(schoolStat.statsTonP)
          let arrowClass = NAs.isGrowthNA ? "": lessonGrowth > 0 ? "after--up-arrow": lessonGrowth < 0 ? "after--down-arrow": ""

          return (
            <tr className={`subject-performance-detail ${indexClass}`}>
              <td className="school-name-info">{schoolName}</td>
              <td>{nofStudents}</td>
              <td>{infoCollegeReady}</td>
              <td>{infoAccuracyPre}</td>
              <td>{infoAccuracyPost}</td>
              <td><span className={arrowClass}>{infoLessonGrowth}</span></td>
              <td>{infoHintsOnIncorrect}</td>
              <td className="ignore-bold">{infoTimeOnQuestion}</td>
              <td className="ignore-bold"><span className="">{infoTimeTotalStudy}</span></td>
            </tr>
          )
        })}
      </>
    )
  }
}

